import React, {useState, useEffect} from 'react'
import styles from 'themes/spins/styles/themesSlider.module.scss';
import CMS from "utils/cms";
import Link from 'next/link';
import Skeleton from '@mui/material/Skeleton'
import clsx from 'clsx'
import {useRouter} from "next/router";

interface Proprs {
    item:any;
    apiId: any
}

const ThemeItem:React.FC<Proprs> = ({item, apiId}) => {

    const [imageLoaded, setImageLoaded] = useState<boolean>(false)
    const router = useRouter()
    const isCurrentTheme = router.asPath === "/"+item['api_id']


    const loadImage = (src:string) => {
        return new Promise((resolve, reject) => {
            const img = document.createElement('img');
            img.src = src;

            img.onload = () => {
                resolve(true);
            }

            img.onerror = () => {
                resolve(false);
            }
        })
    }

    useEffect(() => {

        const loadImages = async () => {
            const imageGame = CMS.getPublicFilePath(item.image[0].name, apiId, item.image[0].path);
            let imageArr:Array<string> = [imageGame]
            let arr = []

            await loadImage(imageArr[0])
            arr.push(imageArr[0])

            if(arr.length === 1) {
                setImageLoaded(true)

            }
        }

        loadImages()

    }, [])


    return imageLoaded ?  (
        <Link
            aria-label={"slide"}
            className={clsx(
                styles.cardLink,
                isCurrentTheme && styles.selected
            )}
            href={'/'+item.api_id || ''}
        >
            <img
                width={500}
                height={289}
                style={{width: '100%', height: 'auto'}}
                loading={"lazy"}
                className={styles.themes__image}
                src={CMS.getPublicFilePath(item.image[0].name, apiId, item.image[0].path)}
                alt={'image'}
            />
        </Link>
    ) : (
        <div className={styles.cardLinkSkeleton}>
            <Skeleton style={{transform: 'translateY(10px)'}} animation="wave" variant="rectangular" className={clsx('themeCategorySkeleton', 'themes_lazy_game_thumb__preview')} />
        </div>
    )
}

export default ThemeItem