import {useEffect, useState} from 'react';
import clsx from 'clsx';
import { openSportTicket} from 'store/modals/actions';
import {useDispatch, useSelector} from 'react-redux';
import styles from '@/template/styles/sportTicketModal.module.scss';
import Modal from 'react-modal';
import ModalLayout from "@/template/ui/modal_layout";

const SportTicketModal = ({}) => {
    const dispatch = useDispatch();
    const open = useSelector((state:any) => state.modals.openSportTicketModal);
    const [cuponId, setCuponId] = useState<string>('')

    useEffect(() => {
        if (typeof window !== 'undefined') {
            const url = new URL(window.location.href);
            const hasParameter = url.searchParams.has('sportTicketId');

            if(hasParameter) {
                const sportTicketId = url.searchParams.get('sportTicketId');
                if(sportTicketId) setCuponId(sportTicketId)
            }
        }

    }, [])


    return (
        <Modal
            ariaHideApp={false}
            className={clsx(styles.main, 'theme_box_bg_shaded3')}
            isOpen={open}
            contentLabel="Example Modal"
        >

            <ModalLayout title={'Bilet'} onClose={() => dispatch(openSportTicket(false))}>

                <>

                    <div className={styles.main__content} >
                        {/*CONTENT HERE*/}
                        {cuponId.length ? (
                            <iframe
                                className={styles.main__iframe}
                                src={`https://exalogic.cashpot.ro/xsportapp/xsport_desktop_cashpot/checkbet?ticket=${cuponId}&system_code=CASHPOT&language=RO`}
                                title="Embedded Website" />
                        ) : (
                            <></>
                        )}
                    </div>

                </>
            </ModalLayout>

        </Modal>
    )
}

export default SportTicketModal