import Image from 'next/image';
import Link from 'next/link';
import {useRouter} from 'next/router';
import useAuth from "hooks/auth";
import strings from 'utils/strings';
import clsx from 'clsx';
import {ClickAwayListener} from '@mui/base';
import React, {useEffect, useState} from 'react';
import {openDeposit, openVerificationModal} from "store/modals/actions";
import {useDispatch, useSelector} from 'react-redux';
import styles from 'themes/spins/styles/balance.module.scss'
import {navigateToPendingTickets} from "store/common/actions";
import CircularProgress from '@mui/material/CircularProgress';
import {getDocumentValidation, getWithdrawal} from "services/account";
import {validationTypes} from "utils/constants";
import candyQuestLvs from "../../public/json/static/quests.json";
import moment from 'moment';
import ArrowRight from "@/template/svg/arrow-right";
import AddIconSpin from 'themes/spins/svg/AddIconSpin';
import VerificationStatus from "@/AbsoluteComponents/interfaces/VerificationStatus";
import useCommon from "@/AbsoluteComponents/hooks/common";
import {hardcoded_quest} from "@/template/config/constants";
import CMS from "@/AbsoluteComponents/utils/cms";

const SpinsBalanceView:React.FC = ({ ... blProps}) => {

    const data = useAuth();
    const router = useRouter();
    const progress = data.getWithdrawProgress();
    const dispatch = useDispatch();
    const freeBonus = data.getFreeBonuses();
    const freeSpins =  data.getFreeSpins();
    const common_hook = useCommon()
    const betBalance = useSelector((state:any) => state.account.betBalance)
    const leaderboard: any = useSelector((state:any) => state.account.questLeaderboard)

    const openPendingWithdraw = () => {
        router.push('/account/transactions/withdrawal?status=pending');
    };


    const changeClass = () => {
        localStorage.setItem('bonus', freeBonus.toString());
        localStorage.setItem('spins', freeSpins.toString());

        const styleClass = document.querySelector('#myDIV');
        let removeStyle =  styleClass?.classList.remove('btnBalance-activeSpins');

    }

    const [open, setOpen] = useState(false);
    const [transactionsCount, setTransactionsCount] = useState(0);

    const getPendingWithdraw = async () => {
        const data = await getWithdrawal(0, 10, 'pending', moment().subtract(2, 'days').format('YYYY-MM-DD HH:mm:ss'),  moment().format('YYYY-MM-DD HH:mm:ss'));

        if (!data.error) {
            setTransactionsCount(data.response?.transactions?.length || 0)
        }
    };

    useEffect(() => {
        setOpen(false);
    }, [router.pathname]);

    useEffect(() => {
        if (open) {
            getPendingWithdraw();
        } else {
            setTransactionsCount(0);
        }
    }, [open]);

    const [documents, setDocument] = useState([]);

    const fetchValidation = async () => {

        const result:any = [];

        const documents = await getDocumentValidation();

        if(!documents.error) {
            documents.response.map((item:any) => {
                result.push({
                    documentName: typeof item['documentName'] === 'string' ? item['documentName'] : '',
                    status: typeof item['status'] === 'string' ? item['status'] : '',
                    type: typeof item['type'] === 'string' ? item['type'] : '',
                    updatedTime: typeof item['updatedTime'] === 'number' ? item['updatedTime'] : 0,
                    uploadedTime: typeof  item['uploadedTime'] === 'number' ? item['uploadedTime'] : 0,
                    verificationType:typeof  item['verificationType'] === 'string' ? item['verificationType'] : 0,
                })
            })

            setDocument(result);
        }
    }


    useEffect(() => {
        fetchValidation()
    }, []);

    const types:Array<string> = validationTypes.map(item => item.key);

    let approved = documents.find((item:any) => {
        return item.status === 'approved' && types.includes(item.type) ;
    });

    const status:boolean = documents.length === 0 ? true : approved !== undefined;
    let allVerified:boolean = true;
    
    const verificationStatus:Array<VerificationStatus> = useSelector((state:any) => state.account.verificationStatus);
    const opened:Array<VerificationStatus> = useSelector((state:any) => state.account.validation_opened);
    
    verificationStatus.map(item => {
        if (!item.verified) allVerified = false;
    });

    let userCandyStatus = data.getCandyQuestStatus()
    //ADDED START  ADDED START  ADDED START  ADDED START  ADDED START  ADDED START
    
    let currentLevel:any = {}
    
    if(userCandyStatus.level !== 0) {
       currentLevel = candyQuestLvs.find((lv:any) => {return lv.level === userCandyStatus.level})
    } else {
        currentLevel = {
            level: 0,
            points: 0
        }
    }
    
    const getPercentage = (): number => {

        let minPoints:number = (currentLevel?.points as number);
        let maxPoints:any = candyQuestLvs[userCandyStatus.level].points
        let currentLvUserPoints:number = userCandyStatus.points - minPoints;

        return common_hook.getPercentage(minPoints, maxPoints, currentLvUserPoints);
    }

    let balance_parts = data.getUserBalance().toFixed(2).split('.')

    let output_balance = {
        a: balance_parts[0],
        b: `.${balance_parts[1] || ''}`,
    };
    
    let blink_balance = data.getFreeBets() > 0 || data.getFreeBonuses() > 0 || data.getFreeSpins() > 0
    
    return (
        <>

            {open && (
                <div className={styles.drop_down_overlay}>

                </div>
            )}
            
            <ClickAwayListener onClickAway={() => {
                setOpen(false);
            }}>
                <div className={clsx(styles.dropDownBalanceWrapper,
                    open && 'themes_balance_wrapper__open'
                )}>

                    <button
                        id="myDIV"
                        className={clsx(
                            styles.btnBalance,
                            'themes_balance_wrapperHeader',
                            open && 'bgOpenUserDrop',
                            blink_balance && styles.btnBalance_activeSpins
                        )}
                        onClick={() => {
                            setOpen(!open);
                            changeClass();

                        }}
                    >

                        <span className={clsx(styles.useBalanceWrapper)}>
                            <span style={{color: 'white'}} className={clsx(styles.useBalance, 'theme_textColor_secondary')}>
                                {output_balance.a}{output_balance.b}
                            </span>
                            
                            <span className={styles.balanceCurrency}>
                                {data.getCurrency()}
                            </span>
                        </span>

                        <div
                            onClick={(e) => {
                                e.stopPropagation();
                                dispatch(openDeposit(true));
                            }}
                            style={{border: '1px solid transparent'}}
                            className={styles.btn_balance_add}
                        >
                            <span className={clsx('addIconBalance', 'themeAddIcon')}>
                                <AddIconSpin />
                            </span>
                        </div>

                    </button>

                    <div className={clsx(styles.dropDownBalance, open && styles.active, 'theme_dropDownBalance')}>
                        <div className={clsx(styles.dropDownBalance__items,  'theme_dropDownBalance__item')}>
                            <p className={clsx(styles.dropDownBalance__title, 'theme_textColor_secondary')}>{strings['balanta_totala']}</p>
                            <div className={styles.dropDownBalance__data}>
                                <span className={styles.dropDownBalance__dataSpan}>{data.getUserBalance()}</span>
                                <span className={clsx(styles.dropDownBalance__currency, 'theme__headerContainer__list')}>
                                {data.getCurrency()}
                            </span>
                            </div>
                        </div>

                        <div className={clsx(styles.dropDownBalance__cash,)}>
                            <div className={clsx(styles.dropDownBalance__cashAm, styles.dropDownBalance__items, 'theme_dropDownBalance__item')} style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                WebkitAlignItems: 'center'
                            }}>
                                <p className={clsx(styles.dropDownBalance__title, 'theme_textColor_secondary')}>{strings['bani_cash']}</p>
                                <p className={styles.dropDownBalance__data}>
                                    <span className={styles.dropDownBalance__dataSpan}>
                                        {data.getCash()}
                                    </span>
                                    <span className={clsx(styles.dropDownBalance__currency, 'theme__headerContainer__list')}>
                                        {data.getCurrency()}
                                    </span>
                                </p>
                            </div>

                            <div className={clsx(styles.dropDownBalance__items, 'theme_dropDownBalance__item')}>
                                <p className={clsx(styles.dropDownBalance__title, 'theme_textColor_secondary')}>{strings['bonus']} </p>
                                <p className={styles.dropDownBalance__data}>
                                 <span className={styles.dropDownBalance__dataSpan}>
                                    {data.getBonus()}
                                 </span>
                                    <span className={clsx(styles.dropDownBalance__currency, 'theme__headerContainer__list')}>
                                    {data.getCurrency()}
                                </span>
                                </p>
                            </div>


                            {/*ADDED START ADDED START ADDED START ADDED START ADDED START */}

                            <div className={styles.dropDownBalance__progressBlock}>
                                <div style={{padding: '12px', height: '88px'}}
                                     className={clsx(styles.dropDownBalance__wrappers, styles.dropDownBalance__items, 'theme_dropDownBalance__item')}>
                                    <div>
                                        <p className={clsx(styles.dropDownBalance__title, 'theme_textColor_secondary')}> {strings['rulaj_necesar']}</p>
                                        <div className={clsx(styles.dropDownBalance__more)}>Poate fi retras la 100%</div>
                                    </div>
                                    <div className={clsx(styles.dropDownBalance__progressBlock, styles.progressWrap, 'theme_progressWrap')}>

                                        <CircularProgress
                                            className={'necesar_progress_circular'}
                                            variant="determinate"
                                            value={progress}

                                        />
                                        
                                        <span className={styles.dropDownBalance__percent}>
                                        <>
                                            <div style={{color: '#FBB90E', display: 'flex', flexDirection: 'column'}}>
                                                {progress?.toFixed(2) }
                                                <span style={{textAlign: 'center'}} className={'theme__headerContainer__list'}>%</span>
                                            </div>
                                        </>
                                    </span>
                                    </div>

                                </div>
                            </div>

                            
                            {/*ADDED END ADDED END ADDED END ADDED END ADDED END */}

                        </div>

                        {/*ADDED START  ADDED START  ADDED START  ADDED START  ADDED START  */}

                        {!CMS.getCurrentQuest().hardcoded ? (
                            userCandyStatus.hasOwnProperty('optIn') &&
                            userCandyStatus?.optIn &&
                            leaderboard?.state.hasOwnProperty("status") &&
                            leaderboard?.state?.status === "Running"
                        ) && (
                            
                            <div className={clsx(styles.questBalance, styles.clickable)}>
                                
                                <div
                                    onClick={() => {
                                        router.push('/'+CMS.getCurrentQuest().url_id)
                                    }}
                                    className={clsx(
                                        styles.questBalance__section,
                                        styles.section1,
                                        'theme_dropDownBalance__item',
                                        'clickable',
                                        router.asPath === '/candy-quest' && 'selected'
                                    )}>

                                    <div className={styles.questBalance__headings}>
                                        <h4 style={{textTransform: "capitalize"}} className={'theme_textColor_secondary'}>
                                            Aventura {CMS.getCurrentQuest().name}
                                        </h4>
                                        <span className={styles.score}>
                                            {userCandyStatus.level && (
                                                <span className={styles.yellow}>
                                                    {userCandyStatus.levelName}
                                                </span>
                                            )}
                                                /{candyQuestLvs.length}
                                        </span>
                                    </div>

                                    <div style={{margin: 0}}
                                         className={clsx(styles.dropDownBalance__progress, 'theme_score_bg')}>

                                        <p
                                            style={{margin: 0}}
                                            className={clsx(
                                                styles.progressData,
                                                'theme_white_dark_text'
                                            )}
                                        >
                                            Nivelul {userCandyStatus.level}

                                            <span style={{marginLeft: '10px'}}>
                                            <>
                                                {data.currentUserQuestPercentage()}%
                                            </>
                                        </span>
                                        
                                        </p>

                                        <div className={'theme_yellow_bg'}
                                             style={{height: 24, borderRadius: '30px', width: `${data.currentUserQuestPercentage()}%`}}
                                        />
                                    </div>
                                </div>

                            </div>
                        ) : null}

                        {/*ADDED END  ADDED END  ADDED END  ADDED END  ADDED END  ADDED END  */}


                        {data.getFreeSpins() > 0 && (
                            <Link
                                href={'/free-spins'}
                                style={{textDecoration: 'none'}}
                                className={clsx(
                                    styles.dropDownBalance__items,
                                    'theme_dropDownBalance__item',
                                    router.asPath === '/free-spins' && 'selected',
                                    'clickable',
                                    styles.dropDownBalance__itemsSpins,
                                    styles.spinsStyle
                                )}
                                title={strings['rotiri_gratuite']}
                            >

                                <p className={clsx(styles.dropDownBalance__title, 'theme_textColor_secondary')}>
                                    {strings['rotiri_gratuite']}
                                </p>

                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    {data.getFreeSpins() === 0 ? (
                                        <p className={clsx(styles.dropDownBalance__data,)}>
                                            {data.getFreeSpins()}
                                        </p>
                                    ) : (
                                        <span className={clsx(styles.dropDownBalance__data,)}>
                                        {data.getFreeSpins()}
                                    </span>
                                    )}
                                    <div className={'theme_text_mainColor'}>
                                        <ArrowRight />
                                    </div>
                                </div>

                            </Link>
                        )}

                        {data.getFreeBonuses() > 0 &&
                            <Link
                                aria-label={"free-bonus"}
                                href={'/free-bonus'}
                                title={'Speciale Gratuite'}
                                className={clsx(
                                    styles.dropDownBalance__items,
                                    'theme_dropDownBalance__item',
                                    'clickable',
                                    router.asPath === '/free-bonus'  && 'selected',
                                    styles.bonusStyle
                                )}
                            >

                                <p className={clsx(styles.dropDownBalance__title, 'theme_textColor_secondary')}>
                                    {strings['speciale_gratuite']}
                                </p>

                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    {data.getFreeSpins() === 0 ? (
                                        <p className={styles.dropDownBalance__data}>
                                            {data.getFreeBonuses()}
                                        </p>
                                    ) : (
                                        <span className={styles.dropDownBalance__data}>
                                        {data.getFreeBonuses()}
                                    </span>
                                    )}

                                    <div className={'theme_text_mainColor'}>
                                        <ArrowRight />
                                    </div>
                                </div>
                            </Link>
                        }


                        {data.getFreeBets() > 0 &&
                            <Link
                                aria-label={"breebet"}
                                href={'/freebet'}
                                className={clsx(
                                    styles.dropDownBalance__items,
                                    'theme_dropDownBalance__item',
                                    'clickable',
                                    router.asPath === '/freebet'  && 'selected',
                                    styles.betStyle
                                )}
                                title={strings['rotiri_gratuite']}
                            >

                                <p className={clsx(styles.dropDownBalance__title, 'theme_textColor_secondary')}>
                                    {strings['pariuri_gratuite']}
                                </p>

                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    {data.getFreeBets() === 0 ? (
                                        <p className={styles.dropDownBalance__data}>
                                            {data.getFreeBets()}
                                        </p>
                                    ) : (
                                        <span className={styles.dropDownBalance__data}>
                                        {data.getFreeBets()}
                                    </span>
                                    )}
                                    <div className={'theme_text_mainColor'}>
                                        <ArrowRight />
                                    </div>
                                </div>

                            </Link>
                        }
                        
                        {data.getTimedFreeSpins() > 0 &&
                            
                            <Link
                                aria-label={"/free-timed-spins"}
                                href={'/free-timed-spins'}
                                className={clsx(
                                    styles.dropDownBalance__items,
                                    'theme_dropDownBalance__item',
                                    'clickable',
                                    router.asPath === '/freebet'  && 'selected',
                                    styles.betStyle
                                )}
                                title={strings['rotiri_gratuite']}
                            >
                                
                                <p className={clsx(styles.dropDownBalance__title, 'theme_textColor_secondary')}>
                                    {strings['rotiri_gratuite']}
                                </p>
                                
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <p className={styles.dropDownBalance__data}>
                                        {data.getTimedFreeSpins()} sec.
                                    </p>
                                    
                                    {/*<div className={'theme_text_mainColor'}>*/}
                                    {/*    <ArrowRight />*/}
                                    {/*</div>*/}
                                </div>
                            
                            </Link>
                        }


                        {betBalance > 0 ? (
                            <div
                                style={{cursor: 'pointer'}}
                                onClick={() => {
                                    router.push('/account/transactions/betting?status=pending')
                                    dispatch(navigateToPendingTickets(true))
                                }}
                                className={clsx(
                                    styles.dropDownBalance__items,
                                    'theme_dropDownBalance__item' ,
                                    'clickable',
                                )}
                            >
                                <p className={clsx(styles.dropDownBalance__title, 'theme_textColor_secondary')}>
                                    {strings['balanta-pariuri']}
                                </p>

                                <p className={styles.dropDownBalance__data}>
                                    {betBalance}
                                    <span style={{marginLeft: '2px'}} className={clsx(styles.dropDownBalance__currency, 'theme__headerContainer__list')}>
                                    {data.getCurrency()}
                                </span>
                                </p>

                            </div>
                        ) : null}
                        
                        
                        
                        {opened &&
                            <Link
                                aria-label={strings["ver_identity"]}
                                href={'/account/account-validation'}
                                className={clsx(
                                    styles.balanceValidation,
                                    styles.dropDownBalance__items,
                                    'theme_dropDownBalance__item',
                                    'clickable',
                                    router.asPath === '/account/account-validation'  && 'selected'
                                )}
                            >

                                <p
                                    style={{margin: '8px 0', fontWeight: 600}}
                                    className={clsx(styles.dropDownBalance__title)}
                                >
                                    {strings["ver_identity"]}
                                </p>

                            </Link>
                        }
                        
                        {!allVerified &&
                            <div
                                style={{cursor: "pointer"}}
                                onClick={() => {dispatch(openVerificationModal(true))}}
                                className={clsx(
                                    styles.balanceValidation,
                                    styles.dropDownBalance__items,
                                    'theme_dropDownBalance__item',
                                    'clickable',
                                )}
                            >

                                <p
                                    style={{margin: '8px 0', fontWeight: 600}}
                                    className={clsx(styles.dropDownBalance__title)}
                                >
                                    {strings["ver_email"]}
                                </p>

                            </div>
                        }

                        {transactionsCount > 0 &&
                            <Link
                                aria-label={"Retrageri"}
                                href={'/account/transactions/withdrawal?status=pending'}
                                style={{cursor: 'pointer'}}
                                className={clsx(
                                    styles.dropDownBalance__items,
                                    'theme_dropDownBalance__item' ,
                                    'clickable',
                                )}
                            >
                                <div className={clsx(styles.dropDownBalance__title, 'theme_textColor_secondary')}>
                                    Retrageri în așteptare
                                </div>

                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <div className={styles.dropDownBalance__data}>
                                        <span className={styles.dropDownBalance__dataSpan}>{transactionsCount}</span>
                                    </div>
                                    <div className={'theme_text_mainColor'}>
                                        <ArrowRight />
                                    </div>
                                </div>
                                
                            </Link>
                        }
                    </div>

                </div>

            </ClickAwayListener>
        </>
    )
}
export default SpinsBalanceView;

