import Modal from 'react-modal';
import {useDispatch, useSelector} from 'react-redux';
import {openUploadDocument, openUploadMainDocument} from 'store/modals/actions';
import TextField from "ui/textfield";
import clsx from 'clsx';
import {useState, useEffect, useRef} from 'react';
import strings from 'utils/strings';
import {updateSnackBar} from "store/common/actions";
import {addDocumentName} from "services/account";
import {updateToggleDocumentsList} from "store/account/actions";
import Button from "@/template/small_ui/button";
import SelectDropDown from "ui/selectDropDown";
import {validationTypes} from 'utils/constants';
import {useRouter} from 'next/router';
import ModalLayout from "@/template/ui/modal_layout";
import styles from '../../../styles/modals.module.scss'
import UploadDocIcon from "themes/spins/svg/uploadDocIcon";
// import Reload from "@/template/svg/Reload";
import Reload from "themes/spins/svg/Reload";
import auth from "@/AbsoluteComponents/hooks/auth";
import useAuth from "@/AbsoluteComponents/hooks/auth";

const UploadDocument = () => {
    const [image, setImage] = useState(null);
    const [name, setName] = useState<string>('');
    const [updateCount, setUpdateCount] = useState(0);
    const [errors, setErrors] = useState<any>({});
    const [uploading, setUploading] = useState<boolean>(false);
    const [isDragOver, setIsDragOver] = useState<boolean>(false)
    const [dragFile, setDragFile] = useState<FormDataEntryValue>()
    const dispatch = useDispatch();
    const router = useRouter();
    
    const isRegular = useSelector((state:any) => state.modals.openUploadDocument);
    const isMain = useSelector((state:any) => state.modals.openUploadMainDocument);
    const user_docs = useSelector((state:any) => state.account.user_docs);
    const auth = useAuth()
    const open = isRegular || isMain;
    const isMobile:boolean = useSelector((state:any) => state.common.isMobile);
    const fileInputRef: React.RefObject<HTMLInputElement>  = useRef(null);
    const rules = [
        strings["pronoun"],
        strings["surname"],
        strings["data_nasterii"],
        strings["available_till"],
        strings["complete_address"],
        strings["idnp_series"],
    ]
    
    const onSubmit = async (e:any) => {

        e.preventDefault();
        setErrors({});

        const form = new FormData(e.target);
        const body = new FormData();
        const document_name: string = form.get('document_name')?.toString() || '';
        const document_type: string = form.get('document_type')?.toString() || '';

        let imageFile: FormDataEntryValue  | null |any = null;

        if (dragFile) {
            form.set('image', dragFile);
            imageFile = dragFile;
        } else {
            const inputImageFile = form.get('image');
            if (inputImageFile instanceof File) {
                imageFile = inputImageFile;
            }
        }

        if (imageFile?.size > 0) {
            body.append('file', imageFile);
        }

        if (imageFile?.size >= 9437184) {
            dispatch(updateSnackBar('Dimensiunea fișierului ar trebui să fie mai mică decât 9 MB', 'error'))
            return;
        }

        
        if (!document_name.length && isMain ) {
            setErrors((err:any) => ({
                ...err,
                name: 'Acest câmp trebuie completat'
            }));
            return;
        }

        if (!document_type.length  && isRegular ) {
            setErrors((err:any) => ({
                ...err,
                type: 'Acest câmp trebuie completat'
            }));
            return;
        }

        setUploading(true);
        const request = await addDocumentName(document_name?.toString() || '', body, document_type);
        setUploading(false);
        
        if(!request.error) {
            
            auth.updateDocs();
            dispatch(updateSnackBar('Documentul a fost încărcat cu succes', 'success'));
            dispatch(updateToggleDocumentsList());

            setName('');
            setImage(null);
            setUpdateCount(updateCount + 1);

            closeHandle();

            // if(isRegular) {
                router.push('/account/my-documents');
            // }

            router.replace({query: {needUpdate:'true'}})

        } else {
            dispatch(updateSnackBar( request.message || '', 'error'));
        }
    }

    const chooseFile = (e:any) => {
        if (Object.keys(e.target.files).length) {
            const file = e.target.files[0];

            const reader = new FileReader();

            reader.onload = (e:any) => {
                setImage(e.target.result);
            };

            reader.readAsDataURL(file);
        }
    };

    const fileOptions = {
        onChange: chooseFile
    };

    const closeHandle = () => {
        dispatch(openUploadDocument(false));
        dispatch(openUploadMainDocument(false));

        const root =  document.getElementsByTagName('html')[0];
        root.removeAttribute('style');
    };

    const handleDragOver = (e: any) => {
        e.preventDefault();
    };

    const handleDrop = (e: any) => {
        e.preventDefault();
        const files = e.dataTransfer.files;
        // Add logic to handle the files (e.g., upload, process, etc.)
        const file = e.dataTransfer.files[0];

        const reader = new FileReader();

        reader.onload = (e:any) => {
            setImage(e.target.result);
        };

        reader.readAsDataURL(file);

        setDragFile(file)
        setIsDragOver(false)
    };

    const triggerFileInput = () => {
        if(fileInputRef.current === null) return
        fileInputRef.current.click();
    }

    useEffect(() => {
        if (!open) {
            setName('');
            setImage(null);
            setUpdateCount(updateCount + 1);
        }
    }, [open]);

    return(

        <Modal
            isOpen={open}
            onRequestClose={closeHandle}
            contentLabel="Example Modal"
            ariaHideApp={false}
            className={clsx('upload-modal', styles.upload_document, 'theme_box_bg_shaded3')}
        >
            <ModalLayout

                title={strings['incarca_document']}
                onClose={closeHandle}
            >
                <>
                    <div className="ReactModal__Inner__Content">
                        <div className={clsx('blockPopUp')}>
                            <div className={'blockPopUp-wrapper'}>

                                {isRegular &&
                                    <p className={styles.upload_document__info_text}>
                                        Pentru a încărca un fișier, te rugăm să alegi tipul documentului și să-i faci o poză cu telefonul tău mobil. De asemenea, poți încărca documentul din fișierele tale.
                                    </p>
                                }

                                {isMain &&
                                    <p className={styles.upload_document__info_text}>
                                        Vă rugăm să ne furnizați documentele necesare pentru validarea contului
                                    </p>
                                }

                                <div className={styles.upload_document__content}>
                                    <form onSubmit={onSubmit}>

                                        {isRegular &&
                                            <>
                                            <div className={'mb-3'}>
                                                <SelectDropDown
                                                    id='dropdown'
                                                    label={'Tipul documentului'}
                                                    name={'document_type'}
                                                    defaultValue={'identification_card'}
                                                    onChange={(e) => {

                                                    }}
                                                    options={validationTypes}
                                                />
                                            </div>

                                            {errors['type'] && <p style={{marginTop: -12}} className="errorMsg">{errors['type']}</p>}
                                            </>
                                        }
                                        
                                        {isMain &&
                                            <>
                                                <TextField
                                                    label={strings['document_name']}
                                                    type={'text'}
                                                    {...{
                                                        name: 'document_name',
                                                        value: name,
                                                        onChange: (e:any) => setName(e.target.value),
                                                        // required: isRegular
                                                    }}
                                                    placeholder={strings['document_name']}
                                                />
                                        
                                                {errors['name'] && <p style={{marginTop: -12}} className="errorMsg">{errors['name']}</p>}
                                            </>
                                        }
                                        
                                        {isRegular ? (
                                            <div className={styles.upload_rules}>
                                                <span className={"theme_text_mainColor"}>
                                                    {strings["doc_upload_mkshure"]}
                                                </span>
                                                
                                                <ul className={"theme_white_dark_text"}>
                                                    {rules.map((x, i) => {
                                                        return (
                                                            <li key={"rule"+i}>
                                                                {x}
                                                            </li>
                                                        )
                                                    })}
                                                </ul>
                                            </div>
                                        ) : null}

                                        {image &&
                                            <div
                                                className={clsx(styles.upload_document__upload_file, styles.img_container)}
                                                onDragEnter={() => {
                                                    setIsDragOver(true);
                                                }}
                                                onDrop={handleDrop}
                                                onDragOver={handleDragOver}
                                            >

                                                {isDragOver && (
                                                    <div
                                                        onDragLeave={() => {
                                                            setIsDragOver(false);
                                                        }}

                                                        className={styles.upload_document__drag_overlay}
                                                    >
                                                        Această imagine va fi înlocuită cu una nouă.
                                                    </div>
                                                )}

                                                <img className={'blockPopUp__preview'} src={image} alt={'Document'}/>

                                                <button
                                                    onClick={() => {
                                                        setImage(null);
                                                        setUpdateCount(updateCount + 1);
                                                    }}
                                                    type={'button'}
                                                >
                                                    <Reload />
                                                    {strings['retrimite']}
                                                </button>
                                            </div>
                                        }

                                        <div
                                            className={clsx(styles.upload_document__upload_file, 'theme_box_dashed')}
                                            key={'updateCount-'+updateCount}
                                            style={{display: image ? 'none' : ''}}
                                            onDragEnter={() => {
                                                setIsDragOver(true);
                                            }}
                                            onDrop={handleDrop}
                                            onDragOver={handleDragOver}
                                        >
                                            {isDragOver && (
                                                <div
                                                    onDragLeave={() => {
                                                        setIsDragOver(false);
                                                    }}
                                                    className={clsx(styles.upload_document__drag_overlay, 'theme_drag_box')}
                                                >
                                                    Lasă fișierul aici
                                                </div>
                                            )}
                                            <div style={{cursor: 'pointer'}} onClick={triggerFileInput}>
                                                <UploadDocIcon />
                                            </div>

                                            <div className={clsx(styles.upload_document__info_container, 'theme_text_mainColor')} style={{display: 'flex'}}>
                                                {!isMobile && (
                                                    <span className={styles.upload_document__drag_doc_text}>
                                                        {strings['drag_document']}
                                                    </span>
                                                )}

                                                <label className={styles.upload_document__input_container}>
                                                    {strings['choose_file']}
                                                    <input
                                                        ref={fileInputRef}
                                                        className={styles.upload_document__upload_input}
                                                        type="file"
                                                        name={'image'}
                                                        accept={'application/pdf,image/jpeg,image/png'}
                                                        {...fileOptions}
                                                    />
                                                </label>

                                            </div>

                                            {image &&
                                                <button
                                                    className={clsx('btn__connection', 'ReactModal__btnResend', 'btn-minWidth')}
                                                    onClick={() => {
                                                        setImage(null);
                                                        setUpdateCount(updateCount + 1);
                                                    }}
                                                    type={'button'}
                                                >
                                                    {strings['retrimite']}
                                                </button>
                                            }

                                        </div>

                                        <Button loading={uploading} type={'submit'} text={strings['incarca_documente']} disabled={image === null}/>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            </ModalLayout>

        </Modal>
    )
}
export default UploadDocument;