import styles from 'themes/spins/styles/search.module.scss';
import clsx from 'clsx';
import React, {FC, useState, useEffect, useRef} from 'react';
import GameGrid from "@/template/ui/game/grid";
import {useDispatch, useSelector} from "react-redux";
import GameInterface from "interfaces/game";
import Game from "utils/game";
import {useRouter} from "next/router";
import {showSearchBar as showSearchBarFunc, updateSearchValue} from "store/common/actions";
import useCommon from "@/AbsoluteComponents/hooks/common";

const SpinsSearchOverlayView = () => {

    const value:string = useSelector((state:any) => state.common.searchValue);
    const showSearchBar:boolean = useSelector((state:any) => state.common.showSearchBar);
    const activeProviders:Array<any> = useSelector((state:any) => state.common.activeSearchProviders);
    
    const searchSort:string = useSelector((state:any) => state.common.searchSort);
    const games:Array<GameInterface> = Game.getAll();
    const rootOverlay:any = useRef();
    const router = useRouter();
    const dispatch = useDispatch();
    const common_hook = useCommon()

    const [isTransition, setIsTransition] = useState<boolean>(false)

    const matchGames = (): Array<GameInterface> => {
        let result:Array<GameInterface> = [];

        games?.map((game:GameInterface) => {
            const nameExist:boolean = value.length ? game.friendlyName.toLowerCase().includes(value.toLowerCase()) : false;
            // const hasProvider:boolean = activeProviders.filter((id:any) => id === game.vendor).length > 0;
            const hasProvider = activeProviders.length === 0 || game.categories.some(category => activeProviders.includes(category));

            if (game.hideFromSearch) return;

            if (
                (value.length && activeProviders.length && hasProvider && nameExist) ||
                (!value.length && activeProviders.length && hasProvider) ||
                (value.length && !activeProviders.length && nameExist)
            ) {
                result.push(game);
            }
        });


        if (searchSort === 'a-z' || searchSort === 'z-a') {
            result.sort((a:any, b:any):number => {
                const prevName = a.friendlyName;
                const nextName = b.friendlyName;

                if (prevName > nextName) {
                    return 1;
                }

                if (prevName < nextName) {
                    return -1;
                }

                return 0;
            });

            if (searchSort === 'z-a') result.reverse();
        }

        return result;
    };

    const showSearch:boolean = value.length > 0 || activeProviders.length > 0;
    const availableGames = matchGames();

    useEffect(() => {
        setTimeout(() => {
            setIsTransition(true)
        }, 700)
    }, [])

    useEffect(() => {


        if (value.length || activeProviders.length) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';

            if (window.scrollY > 0) {
                document.querySelector('.header-scroll')?.classList.add('fixed-scroll');
            }
        }
    }, [value]);

    useEffect(() => {
        dispatch(showSearchBarFunc(false));
        dispatch(updateSearchValue(''));

        const field:any = document.getElementById('nav-search-input');
        if (field) field.value = '';
    }, [router.pathname, router.query]);

    useEffect(() => {
        const search_overlay = document.getElementById('search_overlay')
        const search_bar = document.getElementById('search_bar')
        
         const setTop = () => {
             if(search_bar && search_overlay) {
                 const rect = search_bar?.getBoundingClientRect()
                 const top = rect.top + rect.height
                 search_overlay.style.top = top + 'px'
             }
         }
        
        const resetTop = () => {
            if(search_bar && search_overlay) {
                const rect = search_bar?.getBoundingClientRect()
                const top = rect.height
                search_overlay.style.top = top + 'px'
            }
        }

         if(showSearch) {
            setTop()
             common_hook.hideZendesk(true)
         } else {
             common_hook.hideZendesk(false)
         }

        window.addEventListener("resize", setTop)
        window.addEventListener("reset_search_overlay", resetTop)
        

        return () => {
            window.removeEventListener("resize", setTop)
            window.removeEventListener("reset_search_overlay", resetTop)
        }

    }, [showSearch]);

    return(
        <div
            style={{
                transition: `${isTransition && 'opacity .2s linear, transform .2s linear'}`,
            }}
            data-section={'search'}
            id={'search_overlay'}
            className={clsx(styles.overlay,(showSearch && showSearchBar) && styles.active, 'theme_overly')}
            ref={rootOverlay}
        >
            <div className={styles.overlay__results}>
                <div className={clsx("page-container", 'containerSearch', styles.grid)}>
                    <GameGrid
                        onCardClick={/*sendGameInfo*/ () => {}}
                        games={availableGames}
                        page={'search'}
                        innerScroll
                    />
                </div>
            </div>

            {availableGames.length === 0 && (
                <div className={styles.overlay__empty}>NU S-A GĂSIT NIMIC</div>
                )
            }
        </div>
    )
}
export default SpinsSearchOverlayView;
