import style from "themes/spins/styles/wheel.module.scss";
import React, {useState, useEffect} from 'react'
import Button from "themes/spins/small_ui/button";
import {openDeposit, openLoginModal, openSignupModal, openWheelModal} from "store/modals/actions";
import clsx from 'clsx'
import { useDispatch } from 'react-redux'
import useAuth from "hooks/auth";
import Countdown from 'react-countdown';
import { useMediaQuery } from 'react-responsive';
import moment from 'moment';
import {useSelector} from 'react-redux'
import Check from "@/template/svg/check";

interface Props {
    setIsLoggingIn(x: boolean):void
    setEnable(x: boolean):void
    setSpinnedToday(x: boolean): void
    isSpinnedToday: boolean
    imagesLoaded: boolean
    setDataLoaded(x:boolean):void
    audioLoaded: boolean
    spinEnded: boolean
    isDefault: boolean | null
    closeModal():void
    test:boolean
}


const Bottom:React.FC<Props> = ({setIsLoggingIn, setEnable, isSpinnedToday, imagesLoaded, setDataLoaded, audioLoaded, spinEnded, isDefault, closeModal, test}) => {

    const [canSpin, setCanSpin] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const dispatch = useDispatch();
    const auth = useAuth();

    const targetTime = new Date();
    targetTime.setHours(0, 0, 0, 0);
    targetTime.setDate(new Date().getDate() + 1)

    const previousDay = moment().subtract(1, 'days');
    const depositNum:number = useSelector((state:any) => state.account.depositNum);

    const renderer = (args:any) => {

        const { hours, minutes, seconds, completed} = args;

        const paddedHours = hours.toString().padStart(2, '0');
        const paddedMinutes = minutes.toString().padStart(2, '0');

        if (completed) {
            return (<></>);
        } else {
            return (
                <span className={clsx(style.countdown)} >
                    {hours > 9? hours : paddedHours } ore {minutes > 9 ? minutes : paddedMinutes} minute
                    {/*{seconds > 9 ? seconds : paddedSeconds} secunde*/}
                </span>

            );
        }
    };

    const isMedia1 = useMediaQuery({
        query: '(max-height: 920px)'
    });



    useEffect(() => {
        const getMethods = async() => {
            if(auth.isLoggedIn()) {
                setIsLoading(false)
                setDataLoaded(true)

            } else if (!auth.isLoggedIn() && !auth.isUpdating()) {
                setDataLoaded(true)
            }
        }

        if(auth.isLoggedIn()) {
            getMethods();
            auth.updateDeposits()
        }

        if(!auth.isLoggedIn()) {
            setEnable(false)
            setCanSpin(false)
            setIsLoading(true)
        }


    }, [auth.isLoggedIn()])

    useEffect(() => {
        if(isSpinnedToday) {
            setCanSpin(false)
        }

    }, [isSpinnedToday])

    useEffect(() => {
        if (!auth.isLoggedIn() && !auth.isUpdating()) {
            setDataLoaded(true)
            setIsLoading(false)
        }



    }, [])

    useEffect(() => {

        if(!isDefault) {
            setEnable(true)
            setCanSpin(true)
        } else {
            setCanSpin(false)
        }
    }, [isDefault])
    

    return (imagesLoaded && audioLoaded ) ? (
        <div className={clsx(style.depositLock)}>

            {(depositNum < 3 && !isLoading) && (
                <>
                    {!test && (
                        <div className={clsx(style.addDeposit)}>
                            <div className={clsx(style.lockMessageContainer, style.deposits)}>
                                <span className={clsx(style.lockMessage)}>Faceți 3+ depuneri și deblocați roata!</span>
                            </div>
                            
                            <div className={style.depositSteps}>
                                
                                <div className={clsx(style.step, style.firstStep, depositNum >= 1 && style.completed)}>
                                    <div className={style.tick_container}>
                                        <Check/>
                                    </div>
                                </div>
                                
                                <div className={clsx(style.step, style.secondStep, depositNum >= 2 && style.completed)}>
                                    <div className={style.tick_container}>
                                        <Check/>
                                    </div>
                                </div>
                                
                                <div className={clsx(style.step, style.thirdStep, depositNum >= 3 && style.completed)}>
                                    <div className={style.tick_container}>
                                        <Check/>
                                    </div>
                                </div>
                            
                            </div>
                        </div>
                    )}
                
                
                </>
            )}
            
            
            {(!auth.isLoggedIn() && !auth.isUpdating()) && (
                <div className={style.buttonContainer}>
                    <Button
                        onClick={() => {
                            closeModal()
                            setTimeout(() => {
                                dispatch(openLoginModal(true));
                                setIsLoggingIn(true)
                            })
                        }}
                        outlined
                        text={'conectare'}
                    />
                    
                    <Button
                        onClick={() => {
                            closeModal()
                            setTimeout(() => {
                                dispatch(openSignupModal(true));
                                setIsLoggingIn(true)
                            })
                        }}
                        
                        text={'cont nou'}
                    />
                </div>
            
            )}
            
            
            {(auth.isLoggedIn() && canSpin && !isSpinnedToday && !isLoading && !isDefault) && (
                <div className={clsx(style.lockMessageContainer, style.messagePressSpin)}>
                    <span className={clsx(style.lockMessage)}>
                        Apasă butonul SPIN și obține bonusul garantat!
                    </span>
                </div>
            )}

            {(auth.isLoggedIn() && !canSpin && depositNum >= 3 && !isLoading && !isSpinnedToday && isDefault) &&  (
                <>
                    <div className={clsx(style.lockMessageContainer, style.messagePressSpin)}>
                        <span  className={clsx(style.lockMessage)}>
                            Efectuează o depunere de min. 100 RON și activează o rotire!
                        </span>
                    </div>
                    
                </>
            )}

            {(auth.isLoggedIn() && isSpinnedToday && !isLoading && spinEnded) ? (
                <div className={clsx(style.allDayLock)}>
                    <span className={clsx(style.lockMessage)}>
                        Ai dreptul la o singură rotire pe zi! Roata va fi deblocată în  {isMedia1 && <br/>}  <Countdown date={targetTime}  renderer={renderer}  intervalDelay={1000}/>
                    </span>

                    <Button
                        onClick={closeModal}
                        text={'OK'}
                    />
                </div>
            ):(
                <></>
            )}
            
            {((auth.isLoggedIn() && depositNum < 3 && !isLoading) || (auth.isLoggedIn() && isDefault && !isLoading)) && (
                <div className={style.buttonContainer}>
                    <Button
                        style={{maxWidth: "200px", textAlign: "center"}}
                        onClick={() => {
                            closeModal()
                            setTimeout(() => {
                                dispatch(openDeposit(true));
                                setIsLoggingIn(true)
                                
                            }, 100)
                        }}
                        text={'depune'}
                    />
                </div>
            )}

        </div>
    ) : (
        <>
        </>
    )
}

export default Bottom