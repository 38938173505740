import Script from "next/script";
import {useEffect, useRef} from "react";
import useAuth from "@/AbsoluteComponents/hooks/auth";
import {getAuthToken } from "@/AbsoluteComponents/utils/cookies";
import process from "process";
import SmarticoClass from "@/AbsoluteComponents/utils/smartico_class";
import {jwtDecode} from "jwt-decode";
import {setSmrticoUserProps} from "@/AbsoluteComponents/store/account/actions";
import {useDispatch} from "react-redux";
import {openAccountSidebar, showAccountSideBar, showGuestSideBar} from "@/AbsoluteComponents/store/common/actions";
import JavaScriptChannels from "@/AbsoluteComponents/utils/js_channels";


const Smartico = () => {
    
    const auth = useAuth()
    const dispatch = useDispatch()
    const loaded = useRef(false)

    if(typeof window !== "undefined") {
        window._smartico_callback = SmarticoClass.triggerLoad;
    }
    
    useEffect(() => {
        if(auth.isLoggedIn() && getAuthToken().length) {
            let playerId:string = (jwtDecode(getAuthToken()) as any ).playerId
            
            if(getAuthToken().length && playerId.length && typeof window !== "undefined" && window.hasOwnProperty("_smartico")) {
                
                (window as any)._smartico_user_id = playerId;
                (window as any)._smartico_language = 'ro';
                // (window as any)._smartico.initVisitorMode(`${process.env.NEXT_PUBLIC_SMARTICO_LABEL}`, { brand_key: `${process.env.NEXT_PUBLIC_SMARTICO_BRAND_ID}`, lang: 'RO' })
                
                if(process.env.NODE_ENV !== 'production') {
                    (window as any)._smartico_allow_localhost = true;
                }
                
                (window as any)._smartico.on(SmarticoClass.events.identify, () => {
                    auth.updSmarticoMiniGames()
                });

                const smartico_update = new CustomEvent(SmarticoClass.events.smartico_update)

                window._smartico.on(SmarticoClass.events.props_change, function(props:any){
                    let updated = { ... SmarticoClass.getPublicProps(),  ... props}
                    dispatch(setSmrticoUserProps(updated))
                    window.dispatchEvent(smartico_update)

                    if (props.avatar_id !== undefined) {
                        // console.log('Avatar URL', props.avatar_id);
                        JavaScriptChannels.updateAvatarId(props.avatar_id);
                    }
                })

                window._smartico.on(SmarticoClass.events.gf_closing, () => {
                    dispatch(openAccountSidebar(false))
                    // dispatch(showAccountSideBar(false))
                    dispatch(showGuestSideBar(false))
                });
            }
        }
    }, [auth.isLoggedIn()])
    
    useEffect(() => {


    }, []);

    return (
      
        
        <Script id={"smartico-script"}>
            {`
                (function(d, r, b, h, s){h=d.getElementsByTagName('head')[0];s = d.createElement('script');s.onload=b;s.src=r;h.appendChild(s);})
                (document, 'https://libs.smartico.ai/smartico.js', function() {
                   if(typeof window !== "undefined" && window._smartico_callback === "function") window._smartico_callback(); _smartico.init('${process.env.NEXT_PUBLIC_SMARTICO_LABEL}', { brand_key: '${process.env.NEXT_PUBLIC_SMARTICO_BRAND_ID}' });
                });
            `}
        </Script>

    )
}

export default Smartico