import styles from '../../styles/notifications.module.scss';
import Item from "./item";
import { FC } from 'react';
import { useSelector} from 'react-redux';

const SpinsNotificationListsView:FC = ({ ... listProps }) => {
    const notifications = useSelector((state:any) => state.common.notifications)
    return (
        <div className={styles.lists}>
            {notifications.map((item:any, i:number) => {
                return(
                    <Item
                        active={item.active}
                        key={'notification'+ i}
                        message={item.message}
                        type={item.type}
                        index={i}
                        link_function={item?.link_function && item?.link_function}
                        link_label={item?.link_label && item?.link_label}
                    />
                )
            })}
        </div>
    )
}
export default SpinsNotificationListsView