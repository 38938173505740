const UploadDocIcon = () => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="64" height="65" viewBox="0 0 64 65" fill="none">

            <path className={'svg-icon'} d="M34.6654 31.1667C34.6654 28.9575 36.4562 27.1667 38.6654 27.1667C40.8745 27.1667 42.6654 28.9575 42.6654 31.1667C42.6654 33.3758 40.8745 35.1667 38.6654 35.1667C36.4562 35.1667 34.6654 33.3758 34.6654 31.1667Z" fill="white"/>
            <path className={'svg-icon'} d="M29.6947 35.44C29.3292 34.8552 28.6883 34.5 27.9987 34.5C27.3091 34.5 26.6682 34.8552 26.3027 35.44L19.636 46.1067C19.2507 46.7232 19.2303 47.5003 19.5827 48.1362C19.9352 48.7721 20.605 49.1667 21.332 49.1667H42.6654C43.4229 49.1667 44.1154 48.7387 44.4542 48.0611C44.793 47.3835 44.7199 46.5727 44.2654 45.9667L40.2654 40.6333C39.8877 40.1297 39.2949 39.8333 38.6654 39.8333C38.0359 39.8333 37.4431 40.1297 37.0654 40.6333L34.8155 43.6332L29.6947 35.44Z" fill="white"/>
            <path className={'svg-icon'} fillRule="evenodd" clipRule="evenodd" d="M18.6654 6.5C14.6153 6.5 11.332 9.78324 11.332 13.8333V51.1667C11.332 55.2168 14.6153 58.5 18.6654 58.5H45.332C49.3821 58.5 52.6654 55.2168 52.6654 51.1667V21.7471C52.6654 20.7317 52.3342 19.744 51.7221 18.9339L43.728 8.35344C42.846 7.18613 41.4676 6.5 40.0046 6.5H18.6654ZM15.332 13.8333C15.332 11.9924 16.8244 10.5 18.6654 10.5H37.9987V22.2255C37.9987 23.33 38.8941 24.2255 39.9987 24.2255H48.6654V51.1667C48.6654 53.0076 47.173 54.5 45.332 54.5H18.6654C16.8244 54.5 15.332 53.0076 15.332 51.1667V13.8333Z" fill="white"/>

            <circle cx="49" cy="49.5" r="14" fill="#FBB90E"/>

            <mask id="mask0_1153_91123" maskUnits="userSpaceOnUse" x="37" y="38" width="22" height="22">
                <rect x="37.7695" y="38.2705" width="20.9836" height="20.9836" fill="#D9D9D9"/>
            </mask>

            <g mask="url(#mask0_1153_91123)">
                <path d="M43.0143 55.7576C42.5334 55.7576 42.1219 55.5866 41.7797 55.2444C41.437 54.9017 41.2656 54.4899 41.2656 54.009V52.2604C41.2656 51.7775 41.6571 51.386 42.1399 51.386C42.6228 51.386 43.0143 51.7775 43.0143 52.2604V54.009H53.5061V52.2604C53.5061 51.7775 53.8975 51.386 54.3804 51.386C54.8633 51.386 55.2547 51.7775 55.2547 52.2604V54.009C55.2547 54.4899 55.0836 54.9017 54.7415 55.2444C54.3987 55.5866 53.9869 55.7576 53.5061 55.7576H43.0143ZM48.2602 52.2604C47.7773 52.2604 47.3858 51.8689 47.3858 51.386V45.1347L45.7466 46.7739C45.3983 47.1222 44.832 47.1173 44.4899 46.7629C44.1561 46.4172 44.1609 45.8678 44.5007 45.528L48.2602 41.7686L52.0196 45.528C52.3594 45.8678 52.3642 46.4172 52.0305 46.7629C51.6883 47.1173 51.122 47.1222 50.7737 46.7739L49.1345 45.1347V51.386C49.1345 51.8689 48.743 52.2604 48.2602 52.2604Z" fill="#15191E"/>
            </g>

        </svg>
    )
}

export default UploadDocIcon