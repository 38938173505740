import * as React from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import {FC} from "react";

import {useState} from "react";

interface Props {
    label: string,
    defaultValue?: string,
    onChange(date:any): void,
}

 const UnstyledButtonsSimple: FC<Props>  = ({onChange, label, defaultValue}) => {

     const [value, setValue] = useState<Dayjs | null>(
         dayjs(),
     );

     const [open, setOpen] = useState<boolean>(false)

     const handleChange = (newValue: Dayjs | null) => {
         setValue(newValue);
         onChange(newValue);
     };


     React.useEffect(() => {
         if (defaultValue) {
             setValue(dayjs(defaultValue, 'YYYY-MM-DD HH:mm:ss'));
         }
     }, []);

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>

            <DatePicker
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                open={open}
                label={label}
                value={value}
                onChange={handleChange}
                inputFormat="DD/MM/YYYY"
                renderInput={(params) => <TextField onClick={(e) => {
                    setOpen(true)
                }} {...params} classes={{root: 'datepicker'}}/>
                }
            />

        </LocalizationProvider>
        // <></>
    );
}
export  default UnstyledButtonsSimple;