
function RtpHotSpins() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
            {/*<mask id="mask0_1001_2578" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="25">*/}
            {/*    <rect y="0.589844" width="24" height="24" fill="#D9D9D9"/>*/}
            {/*</mask>*/}
            <g mask="url(#mask0_1001_2578)">
                <path d="M4 14.5898C4 12.8398 4.41667 11.2815 5.25 9.91484C6.08333 8.54818 7 7.39818 8 6.46484C9 5.53151 9.91667 4.81901 10.75 4.32734L12 3.58984V6.88984C12 7.50651 12.2083 7.99401 12.625 8.35234C13.0417 8.71068 13.5083 8.88984 14.025 8.88984C14.3083 8.88984 14.5792 8.83151 14.8375 8.71484C15.0958 8.59818 15.3333 8.40651 15.55 8.13984L16 7.58984C17.2 8.28984 18.1667 9.26068 18.9 10.5023C19.6333 11.744 20 13.1065 20 14.5898C20 16.0565 19.6417 17.394 18.925 18.6023C18.2083 19.8107 17.2667 20.7648 16.1 21.4648C16.3833 21.0648 16.6042 20.6273 16.7625 20.1523C16.9208 19.6773 17 19.1732 17 18.6398C17 17.9732 16.875 17.344 16.625 16.7523C16.375 16.1607 16.0167 15.6315 15.55 15.1648L12 11.6898L8.475 15.1648C7.99167 15.6482 7.625 16.1815 7.375 16.7648C7.125 17.3482 7 17.9732 7 18.6398C7 19.1732 7.07917 19.6773 7.2375 20.1523C7.39583 20.6273 7.61667 21.0648 7.9 21.4648C6.73333 20.7648 5.79167 19.8107 5.075 18.6023C4.35833 17.394 4 16.0565 4 14.5898ZM12 14.4898L14.125 16.5648C14.4083 16.8482 14.625 17.1648 14.775 17.5148C14.925 17.8648 15 18.2398 15 18.6398C15 19.4565 14.7083 20.1523 14.125 20.7273C13.5417 21.3023 12.8333 21.5898 12 21.5898C11.1667 21.5898 10.4583 21.3023 9.875 20.7273C9.29167 20.1523 9 19.4565 9 18.6398C9 18.2565 9.075 17.8857 9.225 17.5273C9.375 17.169 9.59167 16.8482 9.875 16.5648L12 14.4898Z" fill="#F15B1B"/>
            </g>
        </svg>
    )
}
export default RtpHotSpins