import { FC, Ref } from 'react';
import clsx from 'clsx';
import ArrowRight from 'themes/default/svg/arrow-right';

interface Props {
    leftArrow?: boolean,
    onClick?: Function,
    className?: string,
    rightArrow?: boolean,
    btnRef?: Ref<HTMLDivElement>,
}

const PaginationArrow: FC<Props>  =  ({ btnRef, leftArrow, className, onClick, rightArrow}) => {
    return (
        <div
            ref={btnRef}
            className={clsx('carouselArrowWrap',rightArrow && 'right-direction', leftArrow && 'left-direction')}
            onClick={() => onClick ? onClick() : null}
        >
            <div className={
                clsx(rightArrow && 'right-direction',
                    className && className,
                    leftArrow && 'left-direction',
                    rightArrow && 'right-arrow',
                    'carouselArrow'
                )}
                 onClick={() => onClick ? onClick() : null}
            >
                <ArrowRight/>
            </div>
        </div>
    );
}

export default PaginationArrow;