const QuestIcon = () => {
    return (
        <svg className={"svg-icon"} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M10.28 3H24.84C23.6913 3 22.76 3.97013 22.76 5.16667V25.2083C22.76 26.1057 22.0618 26.8333 21.2 26.8333C20.3382 26.8333 19.64 26.1057 19.64 25.2083V24.9375C19.64 23.8904 18.825 23.0417 17.82 23.0417H8.2V5.16667C8.2 3.97013 9.13133 3 10.28 3ZM14.44 6.25C13.8657 6.25 13.4 6.73507 13.4 7.33333C13.4 7.9316 13.8657 8.41667 14.44 8.41667H18.6C19.1743 8.41667 19.64 7.9316 19.64 7.33333C19.64 6.73507 19.1743 6.25 18.6 6.25H14.44ZM12.36 10.5833C11.7857 10.5833 11.32 11.0684 11.32 11.6667C11.32 12.2649 11.7857 12.75 12.36 12.75H18.6C19.1743 12.75 19.64 12.2649 19.64 11.6667C19.64 11.0684 19.1743 10.5833 18.6 10.5833H12.36ZM12.36 14.9167C11.7857 14.9167 11.32 15.4017 11.32 16C11.32 16.5983 11.7857 17.0833 12.36 17.0833H18.6C19.1743 17.0833 19.64 16.5983 19.64 16C19.64 15.4017 19.1743 14.9167 18.6 14.9167H12.36Z"
                  fill="#FBB90E"/>
            <path
                d="M24.84 6.25C24.84 5.05347 25.7713 4.08333 26.92 4.08333C28.0687 4.08333 29 5.05347 29 6.25V16H24.84V6.25Z"
                fill="#FBB90E"/>
            <path d="M21.72 29C19.4227 29 17.56 27.06 17.56 24.6667H3C3 27.06 4.86266 29 7.16 29H21.72Z"
                  fill="#FBB90E"/>
       
        </svg>
    )
}

export default QuestIcon