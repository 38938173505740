import CMSFields from "./cms_fields";
import client from "https";
// import documents from 'public/json/documents.json';
import static_docs from 'public/json/static_docs.json';

// const CMSFields = require("./cms_fields");
// const client = require("https");
// const staticCopyright = require('../public/json/copyright.json');
// const staticBonusConditions = require('../public/json/bonus_conditions.json');
// const oldPromotions = require('../public/json/old_promotions.json');
// const expiredPromotions = require('../public/json/expired_promotions.json');
// const quest_settings = require('../public/json/quest_settings.json');
// const documents = [];


class CMS {
    static isWebview:boolean = false;
    static loadedDocument:any = {};
    static general_settings:Array<any> = [];
    static API_ID_SLOTS = 'slots_test';
    static API_ID_LIVE = 'live_test';
    static CANDY_QUEST_API = 'candy_quest';
    static API_ID_FAQ = 'frequent_questions';
    static API_ID_ABOUT_US = 'about_us';
    static API_ID_PHILOSOPHY = 'cashpot_philosophy';
    static API_ID_PRIVACY_POLICY = 'privacy_policy';
    static API_ID_TERMS = 'terms_and_conditions';
    static API_ID_COPYRIGHT = 'copyright';
    static API_ID_BONUS_CONDITIONS = 'bonus-conditions';
    static API_ID_QUEST_SETTINGS = 'quest_settings';

    static LIVE_RTP_GAMES_CAROUSEL:string = 'live_rtp_games_carousel'
    static BLOCK_FULL_WIDTH_CAROUSEL:string = 'block_full_width_carousel';
    static BLOCK_TOP_GAMES_SLIDER:string = 'block_top_games_slider';
    static BLOCK_CATEGORY_GAMES_SLIDER:string = 'block_category_games_slider';
    static BLOCK_CATEGORIES_BUTTONS_SLIDER:string = 'block_categories_buttons_slider';
    static BLOCK_THEMES_SLIDER:string = 'block_themes_slider';
    static BLOCK_BLOCK_PAGES:string = 'page_type_information';
    static BLOCK_CATEGORIES_ROUTES:string = 'categories_routes';
    static BLOCK_PROVIDERS:string = 'list_type_providers';
    static BLOCK_BONUS_CONDITIONS:string = 'block_category_games_slider';
    static BLOCK_WINNERS_SLIDER:string = 'block_winners_slider';
    static BLOCK_QUESTIONNAIRE:string = 'questionnaire';
    static BLOCK_PROVIDERS_SLIDER:string = 'block_providers_slider';
    static BLOCK_BANNER_GO_TO_ACTION:string = 'block_banner_go_to_action';
    static BLOCK_GAME_BANNER:string = 'block_game_banner';
    static BLOCK_FOOTER:string = 'page_type_copyright';
    static BLOCK_DETAIL_PROMOTION:string = "page_type_promotion";
    static BLOCK_PROMOTIONS:string = 'grid_type_promotion';
    static BLOCK_FAVORITE_GAMES:string = 'block_favorite_games';
    static BLOCK_SEO:string = 'block_seo';
    static BLOCK_GENERAL_SETTINGS:string = 'general_settings';
    static BLOCK_CATEGORY_GAMES_PAGE:string = 'page_type_category_games';
    static BLOCK_QUEST_DESCRIPTIONS:string = 'quest_descriptions';
    static BLOCK_QUEST:string = 'page_type_quest';
    static COOKIE_WARN_BLOCK:string = 'cookie_warn';
    static BLOCK_SEO_BOX:string = 'block_seo_box';
    static BLOCK_VIP_QUEST:string = 'vip_quest';
    static BLOCK_LUCKY_TICKET:string = 'lucky_ticket';
    static BLOCK_ALL_PROVIDERS:string = 'page_type_providers';
    static ALL_PROVIDERS:string = 'all_providers';
    static BLOCK_THEMES_PAGE:string = 'page_type_themes';
    static BLOCK_CATEGORIES_BUTTONS = 'categories_buttons';
    static BLOCK_OLD_PROMOTIONS = 'block_old_promotions';
    static LIVE_JACKPOTS = "block_live_jackpots";
    static BLOCK_LANDING_PAGE = "landing_page";
    static BINGO_API_ID = "bingo";
    static BLOCK_SMARTICO_WIDGET_PAGE = "smartico_widget_page";
    static BLOCK_SMARTICO_MINI_GAME = "smartico_minigame_page_type";
    static GET_APP_BLOCK = "get_app_block";
    static INSTANT_WIN = "instant-win"
    static STATIC_PATHS:Array<string> = [
        '404',
        'casino-live',
        'contact',
        'favorites',
        'free-bonus',
        'free-spins',
        'informations',
        'live-rtp',
        'registration',
        'slots',
        'tombola',
        'joc',
        'promotii',
        'account',
        'forgot-password',
        'api',
    ];
    static BANNER_MAIN = 'block_game_banner'
    
    static getAllFields = (json:any) => {
        let result:any = {}
        
        if(typeof json === "object") {
            let keys = Object.keys(json)
            
            keys.forEach((k:string) => {
                let res = CMSFields.detectFieldType(json[k])
                if(res) result[k] = res
            })
            
            let result_keys = Object.keys(result)
            
            result_keys.forEach((k) => {
                if(Array.isArray(result[k])){
                    
                    result[k].map((item:any, i:number) => {
                        if(typeof item === "object") {
                            let item_keys = Object.keys(item)
                            
                            item_keys.forEach((k1) => {
                                if(typeof item[k1] == "object") {
                                    let item_keys1 = Object.keys(item[k1])
                                    
                                    item_keys1.forEach((k2) => {
                                        
                                        if (Array.isArray(result[k][i][k1][k2])) {
                                            for (let j = 0; j < result[k][i][k1][k2].length; j++) {
                                                const currentItem = result[k][i][k1][k2][j];
                                                if (typeof currentItem === "object" && Object.keys(currentItem).length > 0) {
                                                    const res3 = this.getAllFields(currentItem);
                                                    if (typeof res3 === "object" && Object.keys(res3).length > 0) {
                                                        result[k][i][k1][k2][j] = res3;
                                                    }
                                                }
                                            }
                                        }
                                    })
                                }
                            })
                        }
                    })
                }
            })
        }
        
        
        return result
    }

    static fbTrackRegister = (userValues: any) => {
        // @ts-ignore
        if (typeof window.fbq === "function") {
            // @ts-ignore
            window.fbq('track', 'Register', {userValues});
        }
    }

    static gtagTrackRegister = (userValues: any) => {
        // @ts-ignore
        if (typeof window.gtag === 'function') {
            // @ts-ignore
            window.gtag('event', 'Register', {userValues})
        }
    }

    static  fbTrackDeposit = (type: string, depositInfo: any) => {
        // @ts-ignore
        if (typeof window.fbq === 'function') {
            // @ts-ignore
            window.gtag('event', type, {depositInfo})
        }
    }

    static gtagTrackDeposit = (type: string,depositInfo: any) => {
        // @ts-ignore
        if (typeof window.gtag === 'function') {
            // @ts-ignore
            window.gtag('event', type, {depositInfo})
        }
    }

    static fbTrackGames = (gameInfo: any) => {
        // @ts-ignore
        if (typeof window.fbq === 'function') {
            // @ts-ignore
            window.fbq('event', 'gameEnterFrom', {gameInfo})
        }
    }

    static gtagTrackGames = (gameInfo: any) => {
        // @ts-ignore
        if (typeof window.gtag === 'function') {
            // @ts-ignore
            window.gtag('event', `gameEnterFrom`, {gameInfo})
        }
    }

    static getDocument = async (id: string): Promise<Array<object>> => {
        // const data = await getDocumentService(id);
        let result: Array<object> = [];
        //
        // if (data.error) {
        //
        // } else {
        //     result = data.response;
        // }

        return result;
    };

    static jsonFullWidthCarousel = (json:any) => {
        let sliders:any = [];
        let result:any = [];

        if (typeof json === "object" && json['type'] == this.BLOCK_FULL_WIDTH_CAROUSEL) {
            sliders = CMSFields.repeatedFields(json['full_width_carousel']['repeated_fields']);

            sliders.map((item:any) => {
                if (Array.isArray(item?.document?.types)) {
                    item.document.types.map((block:any) => {
                        if ( block.type === this.BLOCK_DETAIL_PROMOTION && CMSFields.checkboxField(block['web'])) {
                            result.push({
                                'image': CMSFields.fileField(block['image']),
                                'mobile_image': CMSFields.fileField(block['mobile_image']),
                                'title': CMSFields.textField(block['title']),
                                'excerpt': CMSFields.textField(block['excerpt']),
                                'api_id': item.document?.api_id || '',
                                'url_extern': CMSFields.textField(block['url_extern']),
                                "web": CMSFields.checkboxField(block['web'])
                            })
                        }
                    })
                }
            });
        }

        return {
            sliders: result,
        };
    };

    static jsonTopGameSlider = (json:any) => {
        let games:any = [];
        let title:string = '';

        if (typeof json === "object" && json['type'] == this.BLOCK_TOP_GAMES_SLIDER) {
            games = CMSFields.repeatedFields(json['games']['repeated_fields']);
            title = CMSFields.textField(json['title']);
        }

        return {
            games,
            title
        }
    }

    static liveRtpGamesCarousel = (json:any) => {

        // let title:string = '';
        let hourlyHot: boolean = false;
        let hourlyCold: boolean = false;
        let dailyCold: boolean = false;
        let dailyHot: boolean = false;
        if (typeof json === "object" && json['type'] == this.LIVE_RTP_GAMES_CAROUSEL) {
            // title = CMSFields.textField(json['title']);
            hourlyHot = CMSFields.checkboxField(json['hourly_hot']);
            hourlyCold = CMSFields.checkboxField(json['hourly_cold']);
            dailyCold = CMSFields.checkboxField(json['daily_cold']);
            dailyHot = CMSFields.checkboxField(json['daily_hot']);
        }

        return {
            // title,
            hourlyHot,
            hourlyCold,
            dailyCold,
            dailyHot
        }

    }

    static jsonBonusConditions = (json:any) => {
        let text:string = '';
        let title:string = '';

        if(typeof  json === 'object' && json['type'] == this.BLOCK_BONUS_CONDITIONS) {
            title = CMSFields.textField(json['title']);
            text = CMSFields.textField(json['text']);
        }

        return {
            text,
            title
        }
    }

    static jsonCategoryGamesSlider = (json:any) => {
        let minimize:boolean = false;
        let category_id:string = '';
        let api_id:string = '';
        let category_title:string = '';
        let count:number = 20;

        if(typeof json === "object" && json['type']  == this.BLOCK_CATEGORY_GAMES_SLIDER) {
            minimize = CMSFields.checkboxField(json['minimize']);
            category_id = CMSFields.textField(json['category_id']);
            category_title = CMSFields.textField(json['category_title']);
            api_id = CMSFields.textField(json['api_id']);

            if (CMSFields.textField(json['count']).length) {
                count = Number(CMSFields.textField(json['count']));
            }

        }

        return {
            minimize,
            category_id,
            category_title,
            count,
            api_id,
        }
    }

    static jsonThemesSlider = ( json:any) => {
        let limit:number = 10;
        let themes:any = [];
        let title:string = '';
        let api_id:string = '';

        if(typeof json === 'object' && json['type'] === this.BLOCK_THEMES_SLIDER) {

            title = CMSFields.textField(json['title']);
            // themes = CMSFields.repeatedFields(json['themes']['repeated_fields']);
            const document_with_themes:any = CMSFields.documentField(json['document_with_themes']);
            api_id = document_with_themes.api_id || '';

            if ( Array.isArray(document_with_themes.types) ) {
                document_with_themes.types.map((block:any) => {
                    if (block.type === this.BLOCK_THEMES_PAGE && Array.isArray(block?.themes?.repeated_fields)) {
                        themes = CMSFields.repeatedFields(block['themes']['repeated_fields']);
                    }
                })
            }

            if(CMSFields.textField(json['limit']).length) {
                limit = Number(CMSFields.textField(json['limit']));
            }
        }

        return {
            api_id,
            limit,
            themes,
            title,
        }
    }

    static jsonThemesPage = ( json:any) => {
        let themes:any = [];
        let title:string = '';

        if(typeof json === 'object' && json['type'] === this.BLOCK_THEMES_PAGE) {
            themes = CMSFields.repeatedFields(json['themes']['repeated_fields']);
        }
        if(typeof json === 'object' && json['type'] === this.BLOCK_THEMES_PAGE) {
            title = CMSFields.textField(json['title'])
        }

        return {
            themes,
            title
        }
    }

    static jsonAllProvidersPages = () => {

        const docs = this.getAllStaticDocs()

        // let json = this.getStaticDocument('all-providers')

        let json:any = {}

        if(docs.hasOwnProperty('all-providers')) {
            json = docs['all-providers']
        }

        let providers :any = [];
        let title: string = '';

        if(json !== null && json.types[0].type === this.BLOCK_ALL_PROVIDERS) {
            providers = CMSFields.repeatedFields(json.types[0].providers.repeated_fields);
        }
        if(json !== null && json.types[0].type === this.BLOCK_ALL_PROVIDERS) {
            title =  CMSFields.textField(json.types[0].title)
        }

        return {
            providers,
            title

        }

    }

    static jsonBlockPages = (json:any) => {
        let title:string = '';
        let sections:Array<object> = [];

        if(typeof json === 'object' && json['type'] === this.BLOCK_BLOCK_PAGES) {
            title = CMSFields.textField(json['title']);
            sections = CMSFields.repeatedFields(json['sections']['repeated_fields']);
        }
        return {
            title,
            sections,
        }
    }

    static jsonCategoriesButtonsSlider = (json:any) => {
        let slider:Array<object> = [];
        let move_higher:boolean = false;

        if(typeof json === 'object' && json['type'] === this.BLOCK_CATEGORIES_BUTTONS_SLIDER){
            move_higher = CMSFields.checkboxField(json['move_higher']);

            const category_document = CMSFields.documentField(json['document_with_categories_buttons']);

            if(Array.isArray(category_document.types)) {
                category_document.types.map((block:any) => {
                    if(block.type === this.BLOCK_CATEGORIES_BUTTONS) {
                        slider = CMSFields.repeatedFields((block['categories-buttons']['repeated_fields']));
                    }
                })
            }
        }

        if(slider)

            return {
                slider,
                move_higher,
            }
    }

    static jsonBnnerRandomGame = (json:any) => {
        let desktop_image:Array<any> = [];
        let title:string = '';
        let button_text:string = '';
        let image:Array<any> = [];

        let game_id:string = '';
        let random_mode_image:Array<any> = [];
        let run_random_game:boolean = false;
        let thumbnail_src:string = '';
        let provider_src :string = '';
        let game_name:string = '';
        let provider_name:string = '';



        if(typeof json === 'object' && json['type'] === this.BANNER_MAIN) {
            desktop_image = CMSFields.fileField(json['desktop_image']);
            title = CMSFields.textField(json['title']);
            button_text = CMSFields.textField(json['button_text']);
            image = CMSFields.fileField(json['image']);

            game_id = CMSFields.textField(json['game_id']);
            thumbnail_src  = CMSFields.textField(json['thumbnail_src ']);
            provider_src   = CMSFields.textField(json['provider_src  ']);
            provider_name   = CMSFields.textField(json['provider_name  ']);
            game_name   = CMSFields.textField(json['game_name  ']);
            random_mode_image = CMSFields.fileField(json['[mobile]_random_mode_image']);
            run_random_game = CMSFields.checkboxField(json['run_random_game']);


            return {
                desktop_image,
                title,
                button_text,
                image,
                run_random_game,
                random_mode_image,
                game_id,
                thumbnail_src,
                provider_src,
                game_name,
                provider_name


            }
        }

    }

    static jsonSearchFilterProviders = (json:any) => {
        let providers:Array<object> = [];

        if(typeof json === 'object' && json['type'] === this.BLOCK_PROVIDERS){
            providers = CMSFields.repeatedFields(json['providers']['repeated_fields']);
        }

        return {
            providers
        }
    }

    static jsonWinnersSlider = (json:any) => {
        let title:string = '';

        if(typeof json === 'object' && json['type'] === this.BLOCK_WINNERS_SLIDER) {
            title = CMSFields.textField(json['title']);

        }

        return {
            title,
        }
    }

    static jsonBannerGoToAction = (json:any) => {
        let title:string = '';
        let live_rtp:boolean = false;
        let image:Array<any> = [];
        let desktop_image:Array<any> = [];

        if(typeof json === 'object' && json['type'] === this.BLOCK_BANNER_GO_TO_ACTION) {
            title = CMSFields.textField(json['title']);
            live_rtp = CMSFields.checkboxField(json['live_rtp']);
            image = CMSFields.fileField(json['image']);
            desktop_image = CMSFields.fileField(json['desktop_image']);
        }

        return {
            title,
            live_rtp,
            image,
            desktop_image,
        }
    }

    static jsonJackpotsData = (json:any) => {
        let title: string = ''

        let arr = Object.values(json);
        // let allJackpots:Array<any> = arr.filter((x:any) => x.name !== "Title" && x.name !== undefined);
        let allJackpots:Array<any> = [];

        if(typeof json === 'object' && json['type'] === this.LIVE_JACKPOTS) {
            title = CMSFields.textField(json['title'])
            allJackpots = json.jackpots_array.repeated_fields
        }

        return {
            jackpotsData: allJackpots,
            title: title,
        }

    }

    static jsonLandingPage = (json:any) => {
        let header_image:Array<any> = [];
        let banner_title:string = '';
        let banner_num_rounds:string = '';
        let rounds_title:string = '';
        let banner_free_rounds:string = '';
        let free_rounds_title:string = '';
        let banner_sum:string = '';
        let banner_currency:string = '';
        let column_1_image:Array<any> = [];
        let column_1_title:string = '';
        let column_1_description:string = '';
        let column_1_link:string = '';
        let column_2_image:Array<any> = [];
        let column_2_title:string = '';
        let column_2_description:string = '';
        let column_2_link:string = '';
        let column_3_image:Array<any> = [];
        let column_3_title:string = '';
        let column_3_description:string = '';
        let column_3_link:string = '';
        let full_image:Array<any> = [];
        let full_image_title:string = '';
        let full_image_description:string = '';
        let full_width_link:string = '';
        let title_rounds:string = '';
        let title_free_rounds:string = '';
        let prize_title:string = '';
        let prize_sum:string = '';



        if(typeof  json === 'object' && json['type'] === this.BLOCK_LANDING_PAGE) {
            header_image = CMSFields.fileField(json['header_image']);
            banner_title = CMSFields.textField(json['banner_title']);
            banner_num_rounds = CMSFields.textField(json['banner_num_rounds']);
            rounds_title = CMSFields.textField(json['rounds_title']);
            title_rounds = CMSFields.textField(json['title_rounds']);
            title_free_rounds = CMSFields.textField(json['title_free_rounds']);
            prize_title = CMSFields.textField(json['prize_title']);
            prize_sum = CMSFields.textField(json['prize_sum']);
            banner_free_rounds = CMSFields.textField(json['banner_free_rounds']);
            free_rounds_title = CMSFields.textField(json['free_rounds_title']);
            banner_sum = CMSFields.textField(json['banner_sum']);
            banner_currency = CMSFields.textField(json['banner_currency']);
            column_1_image = CMSFields.fileField(json['column_1_image']);
            column_1_title = CMSFields.textField(json['column_1_title']);
            column_1_description = CMSFields.contentField(json['column_1_description']);
            column_1_link = CMSFields.textField(json['column_1_link']);
            column_2_image = CMSFields.fileField(json['column_2_image']);
            column_2_title = CMSFields.textField(json['column_2_title']);
            column_2_description = CMSFields.contentField(json['column_2_description']);
            column_2_link = CMSFields.textField(json['column_2_link']);
            column_3_image = CMSFields.fileField(json['column_3_image']);
            column_3_title = CMSFields.textField(json['column_3_title']);
            column_3_description = CMSFields.contentField(json['column_3_description']);
            column_3_link = CMSFields.textField(json['column_3_link']);
            full_image = CMSFields.fileField(json['full_image']);
            full_image_title = CMSFields.textField(json['full_image_title']);
            full_image_description = CMSFields.contentField(json['full_image_description']);
            full_width_link = CMSFields.textField(json['full_width_link'])
        }


        return {
            header_image,
            column_1_image,
            column_1_title,
            column_1_description,
            column_1_link,
            column_2_image,
            column_2_title,
            column_2_description,
            column_2_link,
            column_3_image,
            column_3_title,
            column_3_description,
            column_3_link,
            full_image,
            full_image_title,
            full_image_description,
            full_width_link,
            banner_title,
            banner_num_rounds,
            rounds_title,
            banner_free_rounds,
            free_rounds_title,
            banner_sum,
            banner_currency,
            title_rounds,
            title_free_rounds,
            prize_title,
            prize_sum,
        }
    }
    
    static jsonCandyQuest = (json:any) => {

        let title:string = '';
        let map_image:Array<any> = [];
        let map_bg:Array<any> = [];
        let opened_chest_image:Array<any> = [];
        let closed_chest_image:Array<any> = [];
        let leader_image:Array<any> = [];
        let leader_pin:Array<any> = [];
        let level_board_image:Array<any> = [];
        let logo:Array<any> = [];
        let player_image:Array<any> = [];
        let player_pin:Array<any> = [];
        let description:string = '';
        let terms_and_conditions:string = '';
        let how_to:string = '';
        let about:string = '';
        let prizes:Array<any> = [];
        let quest_end:Array<any> = [];

        if(typeof  json === 'object' && json['type'] === this.BLOCK_QUEST) {
            title = CMSFields.textField(json['title']);
            map_image = CMSFields.fileField(json['map_image']);
            map_bg = CMSFields.fileField(json['map_bg']);
            opened_chest_image = CMSFields.fileField(json['opened_chest_image']);
            closed_chest_image = CMSFields.fileField(json['closed_chest_image']);
            leader_image = CMSFields.fileField(json['leader_image']);
            leader_pin = CMSFields.fileField(json['leader_pin']);
            level_board_image = CMSFields.fileField(json['level_board_image']);
            logo = CMSFields.fileField(json['logo']);
            player_image = CMSFields.fileField(json['player_image']);
            player_pin = CMSFields.fileField(json['player_pin']);
            description = CMSFields.textField(json['description']);
            terms_and_conditions = CMSFields.contentField(json['terms_and_conditions']);
            how_to = CMSFields.contentField(json['how_to']);
            about = CMSFields.contentField(json['about']);
            quest_end = CMSFields.contentField(json['quest_end']);
            prizes = CMSFields.repeatedFields(json['prizes']['repeated_fields']);
        }

        return {
            title,
            map_image,
            map_bg,
            opened_chest_image,
            closed_chest_image,
            leader_image,
            leader_pin,
            level_board_image,
            logo,
            player_image,
            player_pin,
            description,
            terms_and_conditions,
            prizes,
            how_to,
            about,
            quest_end
        }
    }

    static jsonProvidersSlider = (json:any) => {
        let title:string = '';
        let limit:string = '';
        let providers:Array<object> = [];

        if(typeof json === 'object' && json['type'] === this.BLOCK_PROVIDERS_SLIDER){
            title = CMSFields.textField(json['title']);
            limit = CMSFields.textField(json['limit']);

            const  document_with_providers = CMSFields.documentField(json['document_with_providers']);

            if(Array.isArray(document_with_providers.types)) {
                document_with_providers.types.map((block:any) =>{
                    // if(block.type === this.BLOCK_ALL_PROVIDERS && Array.isArray(block?.providers?.repeated_fields)) {
                    if(block.type === this.BLOCK_ALL_PROVIDERS)  {
                        providers = CMSFields.repeatedFields(block['providers']['repeated_fields'])
                    }
                })
            }
        }

        return {
            title,
            limit,
            providers
        }
    }

    static jsonGameBanner = (json:any) => {
        let title:string = '';
        let game_id:string = '';
        let thumbnail_src:string = '';
        let provider_src:string = '';
        let game_name:string = '';
        let provider_name:string = '';
        let desktop_image:Array<any> = [];
        let mobile_random_mode_image:Array<any> = [];
        let run_random_game:boolean = false;

        if(typeof json === 'object' && json['type'] === this.BLOCK_GAME_BANNER){
            title = CMSFields.textField(json['title']);
            game_id = CMSFields.textField(json['game_id']);
            thumbnail_src = CMSFields.textField(json['thumbnail_src']);
            provider_src = CMSFields.textField(json['provider_src']);
            game_name = CMSFields.textField(json['game_name']);
            provider_name = CMSFields.textField(json['provider_name']);
            desktop_image = CMSFields.fileField(json['desktop_image']);
            mobile_random_mode_image = CMSFields.fileField(json['[mobile]_random_mode_image']);
            run_random_game = CMSFields.checkboxField(json['run_random_game']);
        }

        return {
            title,
            game_id,
            thumbnail_src,
            provider_src,
            game_name,
            provider_name,
            desktop_image,
            mobile_random_mode_image,
            run_random_game,
        }
    }

    static jsonFavoriteGames = (json:any) => {
        let title:string = '';
        let minimize:boolean = false;

        if(typeof json === 'object' && json['type'] === this.BLOCK_FAVORITE_GAMES){
            title = CMSFields.textField(json['title']);
            minimize = CMSFields.checkboxField(json['minimize']);
        }

        return {
            title,
            minimize,
        }
    }

    static jsonSeo = (json:any) => {
        let title:string = '';
        let keywords:string = '';
        let description:string = '';
        let opengraph_title:string = '';
        let opengraph_description:string = '';
        let opengraph_images:Array<object> = [];
        // console.log("json['opengraph_images']", )
        if(typeof json === 'object' && json['type'] === this.BLOCK_SEO){
            title = CMSFields.textField(json['title']);
            keywords = CMSFields.textField(json['keywords']);
            description = CMSFields.textField(json['description']);
            opengraph_title = CMSFields.textField(json['opengraph_title']);
            opengraph_description = CMSFields.textField(json['opengraph_images']["repeated_fields"]);
            // opengraph_images = CMSFields.repeatedFields(json['opengraph_images'])
        }

        return {
            title,
            keywords,
            description,
            opengraph_title,
            opengraph_description,
            opengraph_images
        }
    }

    static jsonSeoBox = (json:any) => {
        let title:string = '';
        let content;

        if(typeof json === 'object' && json['type'] === this.BLOCK_SEO_BOX){
            title = CMSFields.textField(json['title']);
            content = CMSFields.documentField(json['document_with_seo_content']);
        }

        return {
            title,
            content,
        }
    }

    static jsonCategoryGamesPage = (json:any) => {
        let title:string = '';
        let category_id:string = '';

        if(typeof json === 'object' && json['type'] === this.BLOCK_CATEGORY_GAMES_PAGE){
            title = CMSFields.textField(json['title']);
            category_id = CMSFields.textField(json['category_id']);
        }

        return {
            title,
            category_id
        }
    }

    static getFooterInfo = (json:any) => {
        let image:Array<any> = [];
        let web_images:Array<any> = [];
        let content: string = '';
        let onjn_url: string = ""

        if(typeof json === 'object' && json['type'] === this.BLOCK_FOOTER) {
            image = CMSFields.fileField(json['images']);
            web_images = CMSFields.fileField(json['web_images']);
            content = CMSFields.contentField(json['content'])
            onjn_url = CMSFields.textField(json['onjn_url'])
        }

        return {
            image,
            web_images,
            content,
            onjn_url
        }
    }
    
    static getCopyright = () => {

        let json:any = {}
        const docs = this.getAllStaticDocs()

        if(docs.hasOwnProperty('copyright')) {
            json = docs['copyright']
        }

        let image:Array<any> = [];
        let content: string = '';
        let onjn_url: string = '';

        if(typeof json === 'object') {
            image = CMSFields.fileField(json?.types[0]['images']);
            content = CMSFields.contentField(json?.types[0]['content'])
            onjn_url = CMSFields.textField(json?.types[0]['onjn_url'])
        }
        

        return {
            image,
            content,
            onjn_url
            
        }
    }

    static getPromotions = (json:any) => {
        let document:Array<any> = [];
        let all_promotions:any = [];


        if (typeof json === "object" && json['type'] == this.BLOCK_PROMOTIONS) {

            if (typeof json['promotions']['repeated_fields'] === 'object') {
                document = Object.keys(json['promotions']['repeated_fields']).map((index:any) => json['promotions']['repeated_fields'][index]);

            } else if ( Array.isArray(json['promotions']['repeated_fields']) ) {
                document = json['promotions']['repeated_fields'];
            }

            document.map((item:any) => {
                let types:Array<any> = [];
                let api_id:string = '';

                if ( Array.isArray(item?.document?.types) ) {
                    types = item?.document?.types;
                    api_id = item.document?.api_id || '';
                } else if ( Array.isArray(item?.document?.value?.d_value?.types) ) {
                    types = item?.document?.value?.d_value?.types;
                    api_id = item?.document?.value?.d_value?.api_id || '';
                }

                types.map((block:any) => {
                    if (block.type === this.BLOCK_DETAIL_PROMOTION && CMSFields.checkboxField(block['web'])) {
                        all_promotions.push({
                            'image': CMSFields.fileField(block['image']),
                            'mobile_image': CMSFields.fileField(block['mobile_image']),
                            'title': CMSFields.textField(block['title']),
                            'excerpt': CMSFields.textField(block['excerpt']),
                            'api_id': api_id,
                            'url_extern': CMSFields.textField(block['url_extern']),
                            'is_sport': CMSFields.checkboxField(block['is_sport']),
                            'old_promotion':CMSFields.checkboxField(block['old_promotion']),
                            'expired_promotion':CMSFields.checkboxField(block['expired_promotion']),
                        })
                    }
                })
            });

        }

        return {
            all_promotions,
        };
    }
    
    static getOldPromotions = ():Array<any> => {
        const list:Array<any> = [];

        const docs = this.getAllStaticDocs()

        const oldPromotions  = docs.old_expired_proms.old

        try {
            //@ts-ignore
            oldPromotions.map((data:any) => {
                //@ts-ignore
                data?.types.map((block:any) => {
                    //@ts-ignore
                    if (block.type === CMS.BLOCK_DETAIL_PROMOTION &&  CMSFields.checkboxField(block['web'])) {
                        //@ts-ignore
                        list.push({
                            ...this.getIdPromotion(block),
                            api_id: data.api_id,
                            'url_extern': CMSFields.textField(block['url_extern'])
                        })
                    }
                });
            });
        } catch (e) {

        }

        return list;
    }
    
    static getExpiredPromotions = ():Array<any> =>{
        const list:Array<any> = [];

        const docs = this.getAllStaticDocs()

        const expiredPromotions  = docs.old_expired_proms.expired

        try {
            //@ts-ignore
            expiredPromotions.map((data:any) => {
                //@ts-ignore
                data?.types.map((block:any) => {
                    //@ts-ignore
                    if (block.type === CMS.BLOCK_DETAIL_PROMOTION && CMSFields.checkboxField(block['web'])) {
                        //@ts-ignore
                        list.push({
                            ...this.getIdPromotion(block),
                            api_id: data.api_id,
                            'url_extern': CMSFields.textField(block['url_extern'])
                        })
                    }
                });
            });

        } catch (e) {

        }

        return list;
    }

    static getPublicFilePath = (fileName:string, api_id:string, devFallbackPath?:string) => {
        // if (process.env.NODE_ENV === "development" && devFallbackPath) return process.env.NEXT_PUBLIC_CMS_API+devFallbackPath;
        // if (devFallbackPath) return process.env.NEXT_PUBLIC_CMS_API+devFallbackPath;

        return `/media/${api_id}/${fileName.replace(/\s/g,'')}`;
    }
    
    
    // TODO : De lamurit chestia asta cu Octavian neaparat
    static getFileFromCMS = (path:string) => {
        return "https://cmscp.cashpot.ro" + path
    }
    
    // static getFilePath = (fileName:string, api_id:string) => {
    //     return `/cms/images/${api_id}` + (fileName.length ? '/'+fileName.replaceAll(' ', '') : '');
    // };

    static downloadFile = (url:string, filePath:string, fs:any) => {
        client.get(url, (res:any) => {
            res.pipe(fs.createWriteStream(filePath.replaceAll(' ', '')));
        });
    }

    static deleteFile = async (filePath:string, fs:any) => {
        try {
            await fs.unlinkSync(filePath.replaceAll(' ', ''));
        } catch (error) {

        }
    };

    static storeFieldsFiles = ( types:Array<any> ):Array<any> => {
        const fields:Array<any> = [];
        let json;

        types.map((block:any) => {

            if (block['type'] === this.BLOCK_ALL_PROVIDERS) {
                json = this.jsonAllProvidersPages();

                json.providers.map((slide:any) => {
                    slide['image_dark']?.map((file:any) => {
                        fields.push(file);
                    });

                    slide['image_light']?.map((file:any) => {
                        fields.push(file);
                    });
                });
            }

            if (block['type'] === this.BLOCK_PROVIDERS_SLIDER) {
                json = this.jsonProvidersSlider(block);

                json.providers.map((slide:any) => {
                    slide['image_dark']?.map((file:any) => {
                        fields.push(file);
                    });

                    slide['image_light']?.map((file:any) => {
                        fields.push(file);
                    });
                });
            }

            if (block['type'] === this.BLOCK_FULL_WIDTH_CAROUSEL) {
                json = this.jsonFullWidthCarousel(block);

                json.sliders.map((slide:any) => {
                    slide['image']?.map((file:any) => {
                        fields.push(file);
                    });

                    slide['mobile_image']?.map((file:any) => {
                        fields.push(file);
                    });
                });
            }

            if (block['type'] === this.BLOCK_GAME_BANNER) {
                json = this.jsonGameBanner(block);

                json.desktop_image.map((file:any) => {
                    fields.push(file);
                });

                json.mobile_random_mode_image.map((file:any) => {
                    fields.push(file);
                });
            }

            if (block['type'] === this.BLOCK_CATEGORIES_BUTTONS_SLIDER) {
                json = this.jsonCategoriesButtonsSlider(block);

                json?.slider.map((slide:any) => {
                    slide['icon']?.map((file:any) => {
                        fields.push(file);
                    });
                });
            }

            if (block['type'] === this.BLOCK_THEMES_SLIDER) {
                json = this.jsonThemesSlider(block);

                json.themes.map((slide:any) => {
                    slide['image']?.map((file:any) => {
                        fields.push(file);
                    });
                });
            }

            if (block['type'] === this.BLOCK_THEMES_PAGE) {
                json = this.jsonThemesPage(block);

                json.themes.map((slide:any) => {
                    slide['image']?.map((file:any) => {
                        fields.push(file);
                    });
                });
            }

            if (block['type'] === this.BLOCK_BANNER_GO_TO_ACTION) {
                json = this.jsonBannerGoToAction(block);

                json.desktop_image.map((file:any) => {
                    fields.push(file);
                });

                json.image.map((file:any) => {
                    fields.push(file);
                });
            }

            if (block['type'] === this.BLOCK_PROMOTIONS) {
                json = this.getPromotions(block);

                json.all_promotions.map((field:any) => {
                    field['mobile_image']?.map((file:any) => {
                        fields.push(file);
                    });

                    field['image']?.map((file:any) => {
                        fields.push(file);
                    });
                });
            }

            if (block['type'] === this.BLOCK_DETAIL_PROMOTION) {
                json = CMS.getIdPromotion(block);

                json.image?.map((file:any) => {
                    fields.push(file);
                });

                json.mobile_image?.map((file:any) => {
                    fields.push(file);
                });
            }

            if (block['type'] === this.BLOCK_FOOTER) {
                json = CMS.getFooterInfo(block);

                json.image?.map((file:any) => {
                    fields.push(file);
                });
            }

            if (block['type'] === this.BLOCK_LANDING_PAGE) {
                json = CMS.jsonLandingPage(block);

                json.header_image?.map((file:any) => {
                    fields.push(file);
                });

                json.column_1_image?.map((file:any) => {
                    fields.push(file);
                });

                json.column_2_image?.map((file:any) => {
                    fields.push(file);
                });

                json.column_3_image?.map((file:any) => {
                    fields.push(file);
                });

                json.full_image?.map((file:any) => {
                    fields.push(file);
                });
            }

            if (block['type'] === this.BLOCK_QUEST) {
                json = CMS.jsonCandyQuest(block);

                json.map_image?.map((file:any) => {
                    fields.push(file);
                });

                json.map_bg?.map((file:any) => {
                    fields.push(file);
                });

                json.closed_chest_image?.map((file:any) => {
                    fields.push(file);
                });

                json.opened_chest_image?.map((file:any) => {
                    fields.push(file);
                });

                json.leader_image?.map((file:any) => {
                    fields.push(file);
                });

                json.leader_pin?.map((file:any) => {
                    fields.push(file);
                });

                json.player_pin?.map((file:any) => {
                    fields.push(file);
                });

                json.level_board_image?.map((file:any) => {
                    fields.push(file);
                });

                json.logo?.map((file:any) => {
                    fields.push(file);
                });

                json.player_image?.map((file:any) => {
                    fields.push(file);
                });

                json.prizes?.map((item:any) => {
                    item.prize_image?.map((file:any) => {
                        fields.push(file)
                    })
                })
            }
        });

        return fields;
    }
    
    static getIdPromotion = (json:any) => {

        let title:string = '';
        let image:Array<any> = [];
        let mobile_image:Array<any> = [];
        let content: string = '';
        let excerpt: string = '';
        let terms_and_conditions: string = '';
        let show_validation_button: boolean = false;
        let show_play_button: boolean = false;
        let deposit_button: boolean = false;
        let category_games_button: boolean = true;
        let play_game_button: boolean = false;
        let category_id: string = '';
        let category_api_id: string = '';
        let game_id: string = '';
        let bonus_id: string = '';
        let smartico_tournament_id: string = '';
        let smartico_mission_id: string = '';
        let smartico_custom_section_id: string = '';
        let smartico_custom_section_name: string = '';
        let show_giftbox_btn: boolean = false;
        let create_account: boolean = false;
        let open_smartico_dashboard: boolean = false;
        let open_smartico_leaderboard: boolean = false;
        let mobile_app: boolean = false;
        let web: boolean = false;


        if (typeof json === "object" && json['type'] == this.BLOCK_DETAIL_PROMOTION) {
            title = CMSFields.textField(json['title']);
            image = CMSFields.fileField(json['image']);
            mobile_image = CMSFields.fileField(json['mobile_image']);
            content = CMSFields.contentField(json['content']);
            excerpt = CMSFields.textField(json['excerpt']);
            terms_and_conditions = CMSFields.contentField(json['terms_and_conditions']);
            show_validation_button = CMSFields.checkboxField(json['show_validation_button']);
            show_play_button = CMSFields.checkboxField(json['show_play_button']);
            deposit_button = CMSFields.checkboxField(json['deposit_button']);
            category_games_button = CMSFields.checkboxField(json['category_games_button']);
            play_game_button = CMSFields.checkboxField(json['play_game_button']);
            category_id = CMSFields.textField(json['category_id']);
            category_api_id = CMSFields.textField(json['category_api_id']);
            game_id = CMSFields.textField(json['game_id']);
            bonus_id = CMSFields.textField(json['bonus_id']);
            smartico_tournament_id = CMSFields.textField(json['smartico_tournament_id']);
            smartico_mission_id = CMSFields.textField(json['smartico_mission_id']);
            smartico_custom_section_id = CMSFields.textField(json['smartico_custom_section_id']);
            smartico_custom_section_name = CMSFields.textField(json['smartico_custom_section_name']);
            show_giftbox_btn = CMSFields.checkboxField(json['show_giftbox_btn']);
            create_account = CMSFields.checkboxField(json['create_account']);
            open_smartico_dashboard = CMSFields.checkboxField(json['open_smartico_dashboard']);
            open_smartico_leaderboard = CMSFields.checkboxField(json['open_smartico_leaderboard']);
            mobile_app = CMSFields.checkboxField(json['mobile_app']);
            web = CMSFields.checkboxField(json['web']);
        }

        return {
            title,
            image,
            mobile_image,
            content,
            excerpt,
            terms_and_conditions,
            show_validation_button,
            show_play_button,
            deposit_button,
            category_games_button,
            play_game_button,
            category_id,
            category_api_id,
            game_id,
            bonus_id,
            smartico_tournament_id,
            smartico_mission_id,
            smartico_custom_section_id,
            smartico_custom_section_name,
            show_giftbox_btn,
            create_account,
            open_smartico_dashboard,
            open_smartico_leaderboard,
            mobile_app,
            web
        }
    };

    static getDocumentGeneralSettings = () => {
        // const settings = this.getStaticDocument(this.BLOCK_GENERAL_SETTINGS)?.types[0]

        const docs = this.getAllStaticDocs()

        let settings:any = {}

        if(docs.hasOwnProperty('general_settings') && docs.general_settings.types.length) {
            settings = docs.general_settings.types[0]
        }
        
        let result = {
            google_tag_manager_id: '',
            mobile_demo_notice:'',
            facebook:'',
            instagram:'',
            game_meta_title:'',
            game_meta_description:'',
            game_meta_keywords:'',
            demo_game_head_thumbnail: [],
            app_links: [],
            app_slogans: [],
            app_icon: []
            // app_block_visible: false,
            // app_links_hide_on: []
        }

        if(Object.keys(settings).length > 0) {

            result.google_tag_manager_id = CMSFields.textField(settings['google_tag_manager_id'])
            result.mobile_demo_notice = CMSFields.textField(settings['mobile_demo_notice'])
            result.facebook = CMSFields.textField(settings['facebook'])
            result.instagram = CMSFields.textField(settings['instagram'])
            result.game_meta_title = CMSFields.textField(settings['game_meta_title'])
            result.game_meta_description = CMSFields.textField(settings['game_meta_description'])
            result.game_meta_keywords = CMSFields.textField(settings['game_meta_keywords'])
            // @ts-ignore
            result.demo_game_head_thumbnail = CMSFields.fileField(settings["demo_game_head_thumbnail"])
            // @ts-ignore
            result.app_links = CMSFields.detectFieldType(settings["app_links"])
            // @ts-ignore
            result.app_slogans = CMSFields.repeatedFields(settings["app_slogan"])
            // @ts-ignore
            result.app_icon = CMSFields.detectFieldType(settings["app_icon"])
            
            // result.app_block_visible = CMSFields.checkboxField(settings["app_block_visible"])
            // result.app_links_hide_on = CMSFields.detectFieldType(settings["app_links_hide_on"])
        }

        return result
    }
    
    static getGenSettingsJSON(){
        const docs = this.getAllStaticDocs()
        
        if(docs.hasOwnProperty('general_settings') && docs.general_settings.types.length) {
           
           return  docs.general_settings.types[0]
           
        } else {
            
            return {}
            
        }
        
    }

    static getCopyrightBlocks():Array<any> {

        try {
            const docs = this.getAllStaticDocs()

            return Array.isArray(docs['copyright']['types']) ? docs['copyright']['types'] : [];

        } catch (e) {

        }

        return [];
    }

    static getBonusConditions():Array<any> {
        
        try {
            const docs = this.getAllStaticDocs()
            
            return Array.isArray(docs['bonus_conditions']['types']) ? docs['bonus_conditions']['types'] : [];
            
        } catch (e) {
        
        }
        
        
        return [];
    }
    
    static getWheelPrizes():Array<any> {
        
        try {
            const docs = this.getAllStaticDocs()
            
            return Array.isArray(docs['wheel-prizes']['types']) ? docs['wheel-prizes']['types'] : [];
            
        } catch (e) {
        
        }
        
        return [];
    }
    
    static getSlotsSeo():Array<any> {
        try {
            const docs = this.getAllStaticDocs()
            
            return Array.isArray(docs['slots_page_seo']['types']) ? docs['slots_page_seo']['types'] : [];
            
        } catch (e) {
        
        }
        
        return [];
    }
    
    static getFooterMenu():Array<any> {
        try {
            const docs = this.getAllStaticDocs()
            
            return Array.isArray(docs['footer_menu']['types']) ? docs['footer_menu']['types'] : [];
            
        } catch (e) {
        
        }
        
        return [];
    }
    
    static getLuckyTicket():Array<any> {
        try {
            const docs = this.getAllStaticDocs()
            return Array.isArray(docs['lucky-ticket-block']['types']) ? docs['lucky-ticket-block']['types'] : [];
            
        } catch (e) {
        
        }
        
        return [];
    }
    
    static getCurrentQuest = ():any => {
        const docs = this.getAllStaticDocs()
        return docs['current_quest'] ? this.getAllFields(docs['current_quest']["types"][0]) : {}
    }
    
    static getCurrentGiftBox = () => {
        const docs = this.getAllStaticDocs()
        return docs['current_gift_box'] ? this.getAllFields(docs['current_gift_box']["types"][0]) : {}
    }
    
    static getInstantWinPage = () => {
        const docs = this.getAllStaticDocs()
        return docs['instant-win'] ? docs['instant-win']["types"] : []
    }
    
    static getInstantWin = () => {
        const docs = this.getAllStaticDocs()
        return docs['instant-win'] ? this.getAllFields(docs['instant-win']["types"].find((x:any) => x.type === "smartico_minigame_page_type")) : {}
    }
    

    
    static getClaimablePromoImg = () => {
        const docs = this.getAllStaticDocs()
        return docs['claimable_offers_inf'] ? this.getAllFields(docs['claimable_offers_inf']["types"].find((x:any) => x.type === "claimable_offers_inf")) : {}
    }
    
    static getVipQuest():Array<any> {
        try {
            const docs = this.getAllStaticDocs()
            
            return Array.isArray(docs['vip-quest-block']['types']) ? docs['vip-quest-block']['types'] : [];
            
        } catch (e) {
        
        }
        
        return [];
    }

    static getQuestSettings() {
    
        const docs = this.getAllStaticDocs()
        
        return this.getAllFields(docs.quest_settings.types[0]);
    }

    static getInfoMenuData():any {
        const docs = this.getAllStaticDocs()
        let menu_doc = []

        if(docs.hasOwnProperty('main_informational_menu')) {
            menu_doc = docs.main_informational_menu?.types[0]?.menu.repeated_fields
        }

        return menu_doc

    }

    static getAllStaticDocs():any {
        try {
            const docs:any = static_docs as {};
            const data:any = {}

            Object.keys(docs).map((key:any) => {
                data[key] = docs[key]
            })
            return data

        } catch (e) {

            return {};
        }
    }
    
    static getStaticDocument(apiId:string):{api_id:string,name:string,types:any[]} | null {
        // const list = this.getAllStaticDocuments();

        // const document = list.filter((item:any) => item.api_id === apiId);
        //
        // if (document.length) {
        //     return {
        //         api_id: document[0].api_id || '',
        //         name: document[0].name || '',
        //         types: document[0].types || [],
        //     };
        // }

        return null;
    };
}

module.exports = CMS
export default CMS;

export class STATIC_PATHS {}