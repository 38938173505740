import Modal from "react-modal";
import clsx from "clsx";
import styles from "@/template/styles/ClaimPromotionModal.module.scss";
import {openClaimPromotion, openShareTIcket} from "@/AbsoluteComponents/store/modals/actions";
import Button from "@/template/small_ui/button";

import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import ModalLayout from "@/template/ui/modal_layout";
import CMS from "@/AbsoluteComponents/utils/cms";
import useCommon from "@/AbsoluteComponents/hooks/common";
import {info_icon} from "@/template/config/constants";
import {campaign_claim} from "@/AbsoluteComponents/services/account";
import useAuth from "@/AbsoluteComponents/hooks/auth";
import {updateSnackBar} from "@/AbsoluteComponents/store/common/actions";
import strings from "@/AbsoluteComponents/utils/strings";
import CircularProgress from "@mui/material/CircularProgress";
import StandartCountdown from "@/template/small_ui/standart_countdown";



const ClaimPromotionModal = () => {
    const data = useSelector((state:any) => state.modals.openClaimPromotion)
    const [selected, set_selected] = useState<string>("")
    const [claim_prize, set_claim_prize] = useState<any>(undefined)
    const [loading, set_loading] = useState<boolean>(false)
    const dispatch = useDispatch()
    const imgs = CMS.getClaimablePromoImg()
    const common = useCommon()
    const auth = useAuth()
    
    
    const {
        bonus_img,
        cash_img,
        tax_info
    } = imgs
    
    
    const variants = [
        {
            label: "prize",
            title: "Premiu",
            img: Boolean(data) ? data?.textPrize.imageUrl : "",
            description: Boolean(data) ? data?.textPrize.title : "",
            obj: Boolean(data)? data?.textPrize : undefined,
            importance: 3,
            best_offer: false
        },
        
        {
            label: "bonus",
            title: "Bani bonus",
            img: CMS.getPublicFilePath(bonus_img[0]['name'], "claimable_offers_inf", bonus_img[0]['path']),
            description: Boolean(data) ? data?.bonusPrize.amount + " RON": "",
            obj: Boolean(data)? data?.bonusPrize : undefined,
            importance: 1,
            best_offer: true
            
        },
        
        {
            label: "cash",
            title: "Bani cash",
            img: CMS.getPublicFilePath(cash_img[0]['name'], "claimable_offers_inf", cash_img[0]['path']),
            description: Boolean(data) ? data?.cashPrize.amount + " RON" : "",
            obj: Boolean(data)? data?.cashPrize : undefined,
            importance: 2,
            best_offer: false
        }
        
    ]
    
    const claim = async (obj:any) => {
        const test = false
        
        if(data?.campaignId && typeof obj?.obj.index === "number") {
            
            set_loading(true)
            
            if(test) {
                
                const timeout = setTimeout(() => {
                    set_loading(false)
                    clearTimeout(timeout)
                }, 1500)
                
            } else {
                const res = await campaign_claim(data?.campaignId, obj?.obj.index)
                
                if(res.response && res.response.hasOwnProperty("success") && res.response.success) {
                    if(obj.label === "prize"){
                        dispatch(updateSnackBar("Felicitări! În curând o să primiți un email cu informații despre retragerea premiului.", 'success', false))
                        
                    } else {
                        await auth.fetchClaimable()
                        dispatch(updateSnackBar("Felicitări! Premiul a fost inclus în balanța Dvs.", 'success', false))
                    }
                    
                    dispatch(openClaimPromotion(undefined))
                    
                } else {
                    dispatch(updateSnackBar(strings["error"], 'error', false))
                    dispatch(openClaimPromotion(undefined))
                }
                
                set_loading(false)
            }
            // console.log(res)
        }
    }
    
    // console.log("claim_prize", claim_prize)
    
    useEffect(() => {
        
        if(Boolean(data)) {
            common.hideZendesk(true)
        } else {
            common.hideZendesk(false)
        }
        
    }, [data]);
    
    return (
        <Modal
            ariaHideApp={false}
            isOpen={Boolean(data)}
            className={clsx(styles.modal, 'ReactModal__Deposit', 'theme_box_bg_shaded3')}
            onRequestClose={() => {
                dispatch(openClaimPromotion(undefined))
                set_claim_prize(undefined)
            }}
        >
            
            <ModalLayout
                onClose={() => {
                    dispatch(openClaimPromotion(undefined))
                    set_claim_prize(undefined)
                }}
            >
                <>
                    
                    {Boolean(data) && (
                        <StandartCountdown
                            pretext={"Expiră în"}
                            endTime={data.expireAtTs}
                            className={styles.countdown}
                        />
                    )}
                    
                    
                    <div className={styles.inner}>
                        
                        {Boolean(claim_prize) ? (
                            <div className={styles.submit_prizes}>
                                
                                <img className={styles.prize_img} src={claim_prize.img} alt="claim_prize.img"/>
                                
                                <span className={styles.prize_name}>
                                {claim_prize.obj.title}
                                
                            </span>
                                
                                {claim_prize.label === "prize" && (
                                    <div
                                        className={styles.tax_content}
                                        dangerouslySetInnerHTML={{
                                            __html: tax_info.replace("{{icon}}", info_icon).replace("{{tax}}", claim_prize.obj.tax)
                                        }}
                                    />
                                )}
                                
                                <div className={styles.controllers}>
                                    {variants.filter((x) => x.label !== claim_prize.label)
                                        .sort((a, b) => a.importance - b.importance)
                                        .map((x, i) => {
                                            
                                            
                                            return (
                                                <Button
                                                    key={`other_variant_${i}`}
                                                    text={`Alege ${x.label === "prize" ? x.obj?.title || "" : x.description || ""} ${x.label}`}
                                                    outlined={i === 1}
                                                    onClick={() => {
                                                        claim(x)
                                                    }}
                                                />
                                            )
                                        })}
                                    
                                    <button
                                        onClick={async () => {
                                            let obj = variants.find((x) => x.label === "prize")
                                            
                                            if (obj) {
                                                claim(obj)
                                            }
                                            // campaign_claim()
                                        }}
                                        className={styles.continue}
                                    >
                                        Continuă
                                    </button>
                                
                                </div>
                            
                            </div>
                        ) : (
                            <>
                                
                                <strong className={styles.title}>
                                    Felicitări! <br />
                                    {data?.title || ""}
                                </strong>
                                
                                <p className={styles.description}>
                                    Ești eligibil pentru a alege unul dintre premiile de mai jos.
                                </p>
                                
                                
                                <div className={styles.prizes_type}>
                                    {variants.map((x, i) => {
                                        
                                        return (
                                            <div
                                                onClick={() => {
                                                    set_selected(x.label)
                                                }}
                                                className={clsx(
                                                    styles.variant,
                                                    selected === x.label && styles.selected,
                                                    x.best_offer && styles.best_offer_variant,
                                                )}
                                                key={`prise_variant_${i}`}
                                            >
                                                
                                                {x.best_offer && (
                                                    <div className={styles.best_offer}>
                                                        Best Value
                                                    </div>
                                                )}
                                                
                                                <div className={styles.radio}/>
                                                
                                                <img src={x.img} alt="prize_img"/>
                                                
                                                <div className={styles.inf}>
                                                <span className={styles.inf_title}>
                                                    {x.title}
                                                </span>
                                                    
                                                    <span className={styles.inf_decription}>
                                                    {x.description}
                                                </span>
                                                </div>
                                            
                                            </div>
                                        )
                                    })}
                                </div>
                                
                                <Button
                                    text={"REVENDICĂ"}
                                    onClick={async () => {
                                        let obj = variants.find((x) => x.label === selected)
                                        
                                        if (selected === "prize") {
                                            
                                            if (obj) {
                                                set_claim_prize(obj)
                                            }
                                            
                                        } else {
                                            
                                            claim(obj)
                                        }
                                        
                                    }}
                                />
                            </>
                        )}
                        
                        
                        {loading && (
                            <div className={styles.loader}>
                                <CircularProgress/>
                            </div>
                        )}
                    </div>
                </>
            
            
            </ModalLayout>
        
        </Modal>
    )
}

export default ClaimPromotionModal