import { FC } from 'react';
import CMS from "utils/cms";
import styles from '@/template/styles/seoBox.module.scss';
import clsx from 'clsx';

interface Props {
    json: object;
    api_id: string;
}

const SeoBox:FC<Props> = ({json}) => {
    const data = CMS.jsonSeoBox(json);
    const content = data?.content?.types[0]?.content.value

    return (
        <div className={clsx(styles.main, 'theme_textColor_secondary')}>
            <h1 className={styles.main__heading}>{data.title}</h1>
            <div className={clsx(styles.main__content, "theme_text_mainColor")} dangerouslySetInnerHTML={{__html: content}}/>
        </div>
    )
}

export default SeoBox