import {categoryGamesService} from '../services/game';
import GameInterface from '../interfaces/game';
import {candyQuestServices} from "@/AbsoluteComponents/services/account";
import {updateLeaderboard} from "@/AbsoluteComponents/store/account/actions";
import {useSelector, useDispatch} from 'react-redux'
import QuestLeaderboardInterface from "@/AbsoluteComponents/interfaces/quest_leaderboard_interface";
import Game from "@/AbsoluteComponents/utils/game";
import {hardcoded_quest_json} from "@/template/config/constants";
import {getAuthToken, getPlayerId} from "@/AbsoluteComponents/utils/cookies";
import useCommon from "@/AbsoluteComponents/hooks/common";
import {raffleService} from "services/game"
import CMS from "utils/cms";
import {updRaffleStatus, updRaffleWinners, updUserRaffleStatus} from "@/AbsoluteComponents/store/common/actions";
import {RaffleType} from "@/AbsoluteComponents/utils/small_types";

interface CategoryGamesInterface {
    total: number,
    games: Array<GameInterface>,
}

function useGame() {

    const dispatch = useDispatch()
    const leaderboard = useSelector((state:any) => state.account.questLeaderboard)
    const common_hook = useCommon()
    
    const raffle_raw_data = CMS.getLuckyTicket().find((row) => row.hasOwnProperty("raffle_id"))
    const raffle_data = CMS.getAllFields(raffle_raw_data)
    const raffle = raffleService(raffle_data.raffle_id)
    
    const updateQuestLaderboard = async () => {
        
        const {hardcoded} = CMS.getCurrentQuest()
        
        if(hardcoded) {
            let h_leaderboard:QuestLeaderboardInterface = hardcoded_quest_json
            dispatch(updateLeaderboard(h_leaderboard))
            
        } else {
            const req  = await candyQuestServices().getLeaderboard()
            let obj:QuestLeaderboardInterface | any = {}

            if(!req.error) {
                obj = Game.quetsLeaderboardFactory(req.response)
                dispatch(updateLeaderboard(obj))
            } else {
                obj = Game.quetsLeaderboardFactory({"apiFail": true})
                dispatch(updateLeaderboard(obj))
            }
            
        }
    }
    
    const getLeaderPointsPercentage = (leader:any):any => {
        if(!Object.keys(leader).length && !leader.hasOwnProperty("points")) return
        
        if(!getAuthToken().length && !Object.keys(leaderboard.leaderBoard).length && Object.keys(status).length) return 0
        
        let level = leader?.level || 0
        let next_level = level + 1
        let user_points = leader?.points || 0
        let from = leaderboard.leaderBoard[level]?.pointsNeeded || 0
        let to = leaderboard.leaderBoard[next_level]?.pointsNeeded || 0
        
        return common_hook.getPercentage(from, to, user_points)
        
    }
    
    // RAFFLE START   RAFFLE START    RAFFLE START    RAFFLE START    RAFFLE START
    const updateRaffleStatus = async (playerId?:string) => {
        
        // const status = await raffle.getRaffleStatus(raffle_data.raffle_id, playerId)
        //
        // if(typeof status.response !== "undefined" && status.response.hasOwnProperty("runningInstances")) {
        //     // TODO : create a type and factory for status
        //     dispatch(updRaffleStatus(status.response))
        // }
    }
    
    const userRaffleStatusUpdate = async (method: "POST" | "GET") => {
        
        // const status = await raffle.handleOptIn(method)
        //
        // if(typeof status.response !== "undefined" && status.response.hasOwnProperty("status")) {
        //
        //     dispatch(updUserRaffleStatus(status.response))
        // }
        //
        // if(method === "POST") {
        //     let player_id = getPlayerId()
        //     const timeout = setTimeout(() => {
        //         updateRaffleStatus(player_id)
        //     }, 200)
        //
        //     clearTimeout(timeout)
        // }
    }
    
    const updateRaffleWinners = async (raffleType:RaffleType, instanceId:string, playerId?:string) => {
        
        // const winners = await raffle.getWinners(raffleType, instanceId, playerId && playerId)
        //
        // if(typeof winners.response === "object" && winners.response.hasOwnProperty("winners")) {
        //     dispatch(updRaffleWinners(winners.response["winners"]))
        // }
        //
    }
    
    // RAFFLE END   RAFFLE END    RAFFLE END    RAFFLE END    RAFFLE END
    
    return {
        updateQuestLaderboard,
        getLeaderPointsPercentage,
        updateRaffleStatus,
        userRaffleStatusUpdate,
        updateRaffleWinners
    }
}

export default useGame;