export default class Statics {
    static paymentIcons:any = {'credit_card': <div className={'payment_method_icon'}><img src="/spins/images/viza0.svg" alt="credit_card1"/></div>,
        'pay_safe_card': <div className={'payment_method_icon'}><img src="/spins/images/paySafe.svg" alt="credit_card2"/></div>,
        'cnp': <div className={'payment_method_icon'}><img src="/spins/images/topPay.svg" alt="credit_card3"/></div>,
        'debit_card': <div className={'payment_method_icon'}>
            <img data-hide-on-theme='light' src="/default/icons/credit_card.svg" alt="credit_card4"/>
            <img data-hide-on-theme='dark' src="/default/icons/credit_card_dark.svg" alt="credit_card4"/>
        </div>,
        'bank_transfer': <div className={'payment_method_icon'}><img width={20} height={20} src="/spins/images/aircash.png" alt="bank_transfer"/></div>,
        'voucher': <div className={'payment_method_icon'}><img width={20} height={20} src="/spins/images/abon.png" alt="bank_transfer"/></div>,
        
    };
    
    static paymentCardTypes =  {
        'credit_card':
            <div>
                <img src="/spins/images/viza1.svg" alt="type-card"/>
                <img src="/spins/images/viza2.svg" alt="type-card"/>
                <img src="/spins/images/vizaTheme.svg" alt="type-card"/>
                <img src="/spins/images/revolutTheme.svg" alt="type-card"/>
                <img src="/spins/images/gPayTheme.svg" alt="type-card"/>
                <img src="/spins/images/aPayTheme.svg" alt="type-card"/>
            </div>,
        
        "debit_card":
            <div>
                <img src="/spins/images/viza1.svg" alt="type-card"/>
                <img src="/spins/images/viza2.svg" alt="type-card"/>
                <img src="/spins/images/vizaTheme.svg" alt="type-card"/>
                <img src="/spins/images/revolutTheme.svg" alt="type-card"/>
                <img src="/spins/images/gPayTheme.svg" alt="type-card"/>
                <img src="/spins/images/aPayTheme.svg" alt="type-card"/>
            </div>,
    }
}