import {useEffect, useState} from 'react';
import {useRouter} from 'next/router';
import {useDispatch} from 'react-redux';
import {
    openDeposit,
    openForgotPasswordModal,
    openLoginModal, openResetPwdModal,
    openSignupModal,
    openWheelModal
} from "store/modals/actions";
import useAuth from "hooks/auth";
import {getAuthToken} from "utils/cookies";

const QueryModalsWatcher = () => {
    const router = useRouter();
    const dispatch = useDispatch();
    const auth = useAuth();

    const [runChecker, setRunChecker] = useState(false);
    const [depositOnLogin, setDepositOnLogin] = useState(false);

    useEffect(() => {
        const {modal, redirect} = router.query;

        if (!auth.isLoggedIn() && (redirect && redirect.length)) {
            dispatch(openLoginModal(true));
            return;
        }

        if (getAuthToken().length && !runChecker) return;

        switch (modal) {
            case 'deposit':

                if (auth.isLoggedIn()) {
                    setTimeout(() => {
                        dispatch(openDeposit(true));
                    }, 100);
                } else {
                    setTimeout(() => {
                        dispatch(openLoginModal(true));
                        setDepositOnLogin(true);
                    }, 100);
                }

                break;

            case 'register':
                if (!getAuthToken().length) dispatch(openSignupModal(true));
                break;

            case 'login':
                if (!auth.isLoggedIn()) dispatch(openLoginModal(true));
                break;

            case 'wheel':
                dispatch(openWheelModal(true));
                break;
                
            case 'forgot-password':
                dispatch(openForgotPasswordModal(true));
                break;

            default:
        }

    }, [router.query, runChecker]);


    useEffect(() => {
        if (auth.isLoggedIn()) setRunChecker(true);

        if (depositOnLogin && auth.isLoggedIn()) {
            dispatch(openDeposit(true));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth.isLoggedIn()]);

    return null;
}

export default QueryModalsWatcher