import React  from 'react'
import {
    WheelComponent,
    CenteredImages,
    Center,
    PriseImage,
    PriseImageContainer,
    PriseImageShadow, PriseInfoContainer, Label, Value,
} from "./StyledWheel";
import clsx from 'clsx';
import Image from "next/image";
import CMS from "@/AbsoluteComponents/utils/cms";


interface Props {
    startSpinDeg: number,
    rotateTo: number,
    isSpinning: boolean,
    isStartSpinning: boolean,
    isEndSpinning: boolean
    mainImage: any
    prizes_list: Array<any>
    initialPosition: number | null
    rotateBefore: number | null
    imagesLoaded: boolean
    audioLoaded: boolean
    dataLoaded: boolean
    prise: any
}

const WheelSpinningElements:React.FC<Props> = ({
   startSpinDeg,
   rotateTo,
   isSpinning,
   isEndSpinning,
   isStartSpinning,
   mainImage,
   initialPosition,
   rotateBefore,
   prizes_list,
   prise
}) => {
    
    let src = "/spins/images/wheel/wheel_prises/"

    return (
        <WheelComponent
            
            startSpinDeg={startSpinDeg}
            initialPosition={ typeof initialPosition === 'number' ? initialPosition : 0}
            rotateBefore={typeof rotateBefore === 'number' ? rotateBefore : 0}
            rotateTo={rotateTo}
            className={clsx(
                isSpinning && "spinning",
                isStartSpinning && "startSpinning",
                isEndSpinning && "endSpinning"
            )}
        >
        
            <Center>
                
                {(Array.isArray(prizes_list) && prizes_list.length) && prizes_list.map((item:any, i:number) => {
                    
                    const {cash, order, value, free_spin} = item
                    let isActive = isEndSpinning && rotateTo === i * 30
                    
                    return (
                        <PriseImageContainer
                            id={"spin_wheel_prize_" + order}
                            className={clsx(isActive && "active")}
                            key={"prize"+order}
                            deg={30 * i}
                            order={Number(order)}
                        >
                            
                            <div
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    position: "relative",
                                    
                                }}>
                                
                                <PriseImageShadow
                                    className={clsx(isActive && "active")}
                                    src={src + "1.webp"}
                                    alt={"shadow prise"}
                                    width={200}
                                    height={400}
                                />
                                
                                <PriseImage
                                    src={src + order + ".webp"}
                                    alt={order + " prise"}
                                    width={200}
                                    height={400}
                                    className={clsx(isActive && "active")}
                                    
                                />
                                
                                <PriseInfoContainer>
                                    <Value>
                                        {value}
                                    </Value>
                                    
                                    <Label>
                                        {cash && "ron"}
                                        {free_spin && "freespins"}
                                    </Label>
                                </PriseInfoContainer>
                                
                            </div>
                            
                        </PriseImageContainer>
                        
                    )
                })}
                
            </Center>
            
        </WheelComponent>
    )
}

export default WheelSpinningElements