function ActivitateIconSpins(){
    return(
        <svg className={'svg-icon'} xmlns="http://www.w3.org/2000/svg" width="26" height="28" viewBox="0 0 26 28" fill="none">
            {/*<g filter="url(#filter0_di_912_88102)">*/}
                <path fillRule="evenodd" clipRule="evenodd" d="M6.89062 14.209C6.89062 10.8354 9.62545 8.10059 12.999 8.10059C16.3726 8.10059 19.1074 10.8354 19.1074 14.209C19.1074 17.5826 16.3726 20.3174 12.999 20.3174C9.62545 20.3174 6.89062 17.5826 6.89062 14.209ZM13.538 10.6158C13.538 10.3181 13.2967 10.0768 12.999 10.0768C12.7014 10.0768 12.46 10.3181 12.46 10.6158V14.209C12.46 14.3948 12.5558 14.5675 12.7134 14.666L14.8693 16.0135C15.1217 16.1712 15.4542 16.0945 15.612 15.8421C15.7697 15.5897 15.693 15.2571 15.4406 15.0994L13.538 13.9103V10.6158Z" fill="white"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M5.54958 7.63978C5.66762 7.89261 5.89186 8.07996 6.16167 8.15114L6.96217 8.36233C8.20352 7.04279 9.90279 6.15896 11.8061 5.96468C15.9314 5.36274 19.9456 7.93218 21.051 12.0578C22.2425 16.5045 19.6036 21.0752 15.1569 22.2667C10.7102 23.4582 6.13949 20.8193 4.94799 16.3726C4.56834 14.9557 4.57747 13.529 4.90879 12.2027C5.03464 11.6989 4.72827 11.1885 4.22449 11.0627C3.72071 10.9368 3.21029 11.2432 3.08445 11.747C2.67773 13.3751 2.66729 15.1262 3.13165 16.8593C4.59194 22.3091 10.1937 25.5433 15.6436 24.083C21.0935 22.6227 24.3277 17.021 22.8674 11.5711C21.4071 6.12123 15.8053 2.88703 10.3554 4.34732C10.3205 4.35667 10.2866 4.36786 10.2538 4.38077L9.33537 2.41356C9.18104 2.083 8.84942 1.87155 8.4846 1.87109C8.11979 1.87064 7.78764 2.08127 7.63249 2.41145L5.55056 6.84219C5.4319 7.09473 5.43153 7.38694 5.54958 7.63978Z" fill="white"/>
            {/*</g>*/}
            <defs>
                <filter id="filter0_di_912_88102" x="-1" y="0" width="28" height="28" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="1"/>
                    <feGaussianBlur stdDeviation="1"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_912_88102"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_912_88102" result="shape"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="1"/>
                    <feGaussianBlur stdDeviation="1"/>
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.6 0"/>
                    <feBlend mode="normal" in2="shape" result="effect2_innerShadow_912_88102"/>
                </filter>
            </defs>
        </svg>
    )
}
export default ActivitateIconSpins