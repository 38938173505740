import {FC, useState} from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import styles from 'themes/spins/styles/themesSlider.module.scss';
import clsx from 'clsx';
import "swiper/css";
import "swiper/css/pagination";
import {Navigation} from "swiper";
import {Pagination} from "swiper";
import Link from 'next/link';
import CMS from "utils/cms";
import strings from "utils/strings";
import Slide from "./Slide";
import ArrowForwardSpins from "themes/spins/svg/arrowForwardSpins";
import ArrowRight from "@/template/svg/arrow-right";


interface Props {
    json: object;
    api_id: string;
}


const ThemesSlider: FC<Props> = ({json, api_id}) => {
    const [swiper, setSwiper] = useState<any>();
    const data = CMS.jsonThemesSlider(json);
    const [isEnd, setIsEnd] = useState<boolean>(false);
    const [isBeginning, setIsBeginning] = useState<boolean>(true);

    return (
        <div className={clsx(styles.themes, 'theme-slider','slideClass', 'theme-slider__responsive' )}>
            <div className={styles.themes__box}>
                <div className={styles.themes__wrapper}>
                    <span className={clsx('gamesSlider__title', 'theme_textColor_secondary')}>{data.title}</span>
                </div>
                {data.api_id.length > 0 &&
                    <div className={'buttons-wrapperSlide'}>

                        <Link   href={'/' + data.api_id} className={clsx('linkMore-btn', 'btnSlideGame', 'btn__bg__theme', 'theme_text_responsive_mainMobile')}>
                            {/*<button className={'linkMore-btn'}>*/}
                            {/*<span style={{marginRight: '5px'}} className={clsx('theme__svgArrow','theme_arrow_white')}><ArrowForwardSpins/></span>*/}
                        {strings['vezi_toate']}
                            {/*</button>*/}
                        </Link>

                        <div className={'arrowButtons-wrapperSlide'}>
                            <button
                                aria-label={"slide"}
                                disabled={isBeginning}
                                onClick={() => {
                                    swiper.slidePrev()
                                    setIsEnd(false)
                                }}
                                className={clsx('btnSlider-first', 'theme_btnSlider_arrow',  isBeginning && 'locked')}
                            >
                                <ArrowRight/>
                            </button>
                            <button
                                aria-label={"slide"}
                                disabled={isEnd}
                                onClick={() => {
                                    swiper.slideNext()
                                    setIsBeginning(false)
                                }}

                                className={clsx('btnSlider-second', 'theme_btnSlider_arrow', isEnd && 'locked')}>
                                <ArrowRight/>
                            </button>
                        </div>

                    </div>
                }

            </div>

            {/*<Link className={clsx( 'btn-responsive', 'btnResponsive-themesGame')} style={{top: 0}} href={/${data.api_id}}>{strings['vezi_mai_multe']}<NewArrow /></Link>*/}

            <Swiper
                pagination={{
                    clickable: true,
                }}

                modules={[Pagination, Navigation]}
                className={clsx(styles.themes__mySwiper, 'swiper-wrapperThemes', 'swiper-wrapper',  'swiper-init-padding',)}
                slidesPerView='auto'
                onSwiper={(sw: any) => {
                    setSwiper(sw)
                }}

                onReachBeginning={() => setIsBeginning(true)}
                onReachEnd={() => setIsEnd(true)}

                onSlideNextTransitionStart={() => {setIsBeginning(false)}}
                onSlidePrevTransitionStart={() => {setIsEnd(false)}}
            >
                {data.themes.map((item: any, i: number) => {

                    if (item.image.length > 0) {
                        return (
                            <SwiperSlide className={clsx(styles.theme__Slide, 'slide-themesSlider')} key={`theme-${i}`+ 1}>

                                <Slide item={item} apiId={api_id} />

                            </SwiperSlide>
                        )
                    }
                })}
            </Swiper>
        </div>
    )
}

export default ThemesSlider;