 import Modal from 'react-modal';
import {useDispatch, useSelector} from 'react-redux';
import {openLoginModal, openSignupModal} from 'store/modals/actions';
import useAuth from 'hooks/auth';
import {useEffect, useState} from 'react';
import {useRouter} from 'next/router';
import {deleteRedirectTarget, getRedirectTarget} from "utils/cookies";
import LoginModalView from "@/template/ui/modals/login";
import ModalLayout from "@/template/ui/modal_layout";
import strings from 'utils/strings';
import clsx from 'clsx'
import {updateSnackBar} from "@/AbsoluteComponents/store/common/actions";
import useCommon from "@/AbsoluteComponents/hooks/common";
import CommonClass from "@/AbsoluteComponents/utils/common_class";
 import eventEmitter from "@/AbsoluteComponents/utils/event_emmiter_instance";
 import {events} from "@/AbsoluteComponents/utils/constants";

function Login() {
    const [loading, setLoading] = useState(false);
    const router = useRouter();
    const {redirect} = router.query;
    const resetPwdPhoneEnding = useSelector((state:any) => state.account.resetPwdPhoneEnding)

    const dispatch = useDispatch();
    const auth = useAuth();
    const open = useSelector((state:any) => state.modals.openLogin);
    const common_hook = useCommon()
    const ct_code = common_hook.getCountryCode("RO")?.dial_code || "+40"
    
    const onSubmit = async (fields:any, e:any) => {
        e.preventDefault();
        e.stopPropagation();

        const redirectTarget:string = getRedirectTarget();

        const form = new FormData(e.target);

        const email:string = form.get('email') !== null ? form.get('email')!.toString() : '';
        const password:string = form.get('password') !== null ? form.get('password')!.toString() : '';

        setLoading(true);
        const done = await auth.signIn(email, password);
        setLoading(false);

        if (done) {

            dispatch(openLoginModal(false))

            const root =  document.getElementsByTagName('html')[0];
            root.removeAttribute('style');

            if (redirect && redirect.length) {
                router.push(redirect.toString());
                // deleteRedirectTarget();
                // return;
            }

            if (redirectTarget.length) {
                setTimeout(() => {
                    router.push(redirectTarget);
                    deleteRedirectTarget();
                }, 300)
            }
        }
    };
    
    const onWrongPasswordSubmit = async (fields:any, e:any) => {
        e.preventDefault();
        e.stopPropagation();
        
        const formData = new FormData(e.target)
        
        let phone  = formData.get("phone")
        
        if(typeof phone === "string" && !phone.length) {
            dispatch(updateSnackBar(strings["require_phone_err"], "error"))
            return
        }
        
        if(typeof phone === "string" && phone.length) {
            setLoading(true)
            auth.frozenLoginPhonePost(ct_code + phone)
            setLoading(false)
        }
    }
    
    const verifyPhoneSubmit = async (fields:any, e:any) => {
        e.preventDefault();
        e.stopPropagation();
        
        const formData = new FormData(e.target)
        const arr_entries = Array.from(formData.entries())
        let code = ""
        const local_storage_item = localStorage.getItem(CommonClass.local_storage_items.send_pin_info)
        const info = (typeof local_storage_item === "string" &&  local_storage_item.length && typeof JSON.parse(local_storage_item) === "object") ? JSON.parse(local_storage_item) : {}
        
        arr_entries.map((arr) => {
            if(typeof arr[1] === "string" && arr[1].length === 1) {
                code += arr[1]
            }
        })
        
        if(code.length !== 4 || !Object.keys(info).length){
            dispatch(updateSnackBar("Codul este incomplet", "error"))
            return
        }
        
        setLoading(true)
        
        auth.frozenLoginPhoneVerify(code, info.phone)
        
        setLoading(false)
        
    }
    
    
    const emailProps = {
        name: 'email'
    };

    const passwordProps = {
        name: 'password'
    };

    const newAccountHandle = (e:any) => {
      e.preventDefault();

        dispatch(openLoginModal(false));
        dispatch(openSignupModal(true));
    }

    const loginModalProps = {
        loading,
        onSubmit,
        emailProps,
        passwordProps,
        newAccountHandle,
        onWrongPasswordSubmit,
        verifyPhoneSubmit
    }

    return (
        <Modal
            ariaHideApp={false}
            isOpen={open}
            onRequestClose={() => {
                eventEmitter.emit(events.close_login_modal)
                dispatch(openLoginModal(false))
                localStorage.removeItem(CommonClass.local_storage_items.send_pin_info)
                const root =  document.getElementsByTagName('html')[0];
                root.removeAttribute('style');
            }}
            contentLabel="Example Modal"
            className={clsx('ReactModal__SM', 'theme_background_1')}
            onAfterClose={() => {
                deleteRedirectTarget()
            }}
        >

            <ModalLayout
                title={!resetPwdPhoneEnding.length && strings['conectare']}
                onClose={() => {
                    eventEmitter.emit(events.close_login_modal)
                    dispatch(openLoginModal(false))
                    const root =  document.getElementsByTagName('html')[0];
                    root.removeAttribute('style');
                }}
            >

                <LoginModalView { ... loginModalProps}/>

            </ModalLayout>

        </Modal>
    )
}

export default Login