function RetrageriIconSpins() {
    return(
        <svg className={'svg-icon'} xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
            {/*<g filter="url(#filter0_di_912_70920)">*/}
                <path fillRule="evenodd" clipRule="evenodd" d="M12.9989 1.5L20.183 1.66631C21.6348 1.69581 22.8655 2.74233 23.128 4.17046C23.7149 7.36355 23.7149 10.6369 23.128 13.83C22.8655 15.2581 21.6348 16.3046 20.183 16.3341L18.3136 16.3885C17.2441 16.4196 16.3395 15.6033 16.2609 14.5362L16.1369 12.8531C16.06 11.8084 15.1899 11 14.1423 11H11.3268C10.2924 11 9.42872 11.7887 9.33501 12.8188L9.17682 14.5576C9.0809 15.6119 8.17998 16.4089 7.12184 16.3754L5.81482 16.3341C4.36307 16.3046 3.13232 15.2581 2.86984 13.83C2.28297 10.6369 2.28297 7.36355 2.86984 4.17046C3.13232 2.74233 4.36307 1.69581 5.81482 1.66631L12.9989 1.5ZM20.9989 9.00018C21.5512 9.00018 21.9989 8.55247 21.9989 8.00018C21.9989 7.4479 21.5512 7.00018 20.9989 7.00018H4.9989C4.44662 7.00018 3.9989 7.4479 3.9989 8.00018C3.9989 8.55247 4.44662 9.00018 4.9989 9.00018H20.9989Z" fill="white"/>
                <path d="M11.6298 12.0713C11.1747 12.0713 10.7951 12.4191 10.7553 12.8725C10.6194 14.4231 10.5954 15.9815 10.6835 17.5353C10.465 17.5473 10.2466 17.5613 10.0283 17.5772L8.71044 17.6733C8.20234 17.7104 7.9183 18.2769 8.19261 18.7062C9.13223 20.1767 10.343 21.4551 11.7603 22.4732L12.2882 22.8524C12.562 23.0491 12.9308 23.0491 13.2047 22.8524L13.7326 22.4732C15.1499 21.4551 16.3607 20.1767 17.3003 18.7062C17.5746 18.2769 17.2905 17.7104 16.7824 17.6733L15.4646 17.5772C15.2463 17.5613 15.0279 17.5473 14.8094 17.5353C14.8974 15.9814 14.8735 14.4231 14.7375 12.8725C14.6978 12.4191 14.3182 12.0713 13.8631 12.0713H11.6298Z" fill="white"/>
            {/*</g>*/}
            <defs>
                <filter id="filter0_di_912_70920" x="-1" y="-1" width="28" height="28" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="1"/>
                    <feGaussianBlur stdDeviation="1"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_912_70920"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_912_70920" result="shape"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="1"/>
                    <feGaussianBlur stdDeviation="1"/>
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.6 0"/>
                    <feBlend mode="normal" in2="shape" result="effect2_innerShadow_912_70920"/>
                </filter>
            </defs>
        </svg>
    )
}
export default RetrageriIconSpins