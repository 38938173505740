import styles from "@/template/styles/Header.module.scss";
import a from "@/template/styles/Header.module.scss";
import {showAccountSideBar} from "@/AbsoluteComponents/store/common/actions";
import strings from "@/AbsoluteComponents/utils/strings";
import AccountSideBar from "themes/spins/ui/header/accPagesNav";
import {useDispatch, useSelector} from 'react-redux';
import clsx from 'clsx';
import {useEffect} from "react";
import useCommon from "@/AbsoluteComponents/hooks/common";


const AccPagesSidebar = () => {
    const dispatch = useDispatch();
    const showAccount: boolean = useSelector((state: any) => state.common.showAccountSideBar);
    const inView = useSelector((state:any) => state.common.footerInView)
    const common_hook = useCommon()
    
    useEffect(() => {
        let w_width = window.innerWidth
        if(showAccount && w_width < 440) {
            common_hook.hideZendesk(true)
        } else {
            common_hook.hideZendesk(false)
        }
    }, [showAccount]);
    
    
    return (
        <>
            <div
                onClick={() => {
                    dispatch(showAccountSideBar(false))
                }}
                className={clsx(styles.accountSideBarOverlay, showAccount && styles.active)}
            >
            
            </div>
            
            <div
                id={'accountSideBar'}
                className={clsx(
                    styles.accountSideBar,
                    'ModeBgSideBarAccount',
                    'theme_front_elements_bg',
                    'theme_acc_page_sidebar',
                    showAccount && styles.active,
                    inView && styles.footerInView
                )}>
                
                <div className={clsx(styles.linksBox, styles.acc)}>
                    
                    <div className={styles.accountSideBar__top_btn_container}>
                        
                        <button
                            onClick={() => {
                                dispatch(showAccountSideBar(false))
                            }}
                            type={'button'}
                            className={clsx('btn-account', styles.titleButton)}
                            aria-label={"back"}
                        >
                            <img src={'/default/icons/backicon.svg'} alt="img"/>
                        </button>
                        
                        <div className={clsx(a.accountSideBar__close)}>
                            <span className={'theme_textColor_secondary'}>
                                {strings['contul_meu']}
                            </span>
                        </div>
                    
                    </div>
                    
                    <AccountSideBar/>
                
                </div>
            
            </div>
        </>
    
    )
}

export default AccPagesSidebar