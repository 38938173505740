import Modal from "react-modal";
import clsx from "clsx";
import styles from "@/template/styles/ReopenAccount.module.scss";
import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import ModalLayout from "@/template/ui/modal_layout";
import Button from "@/template/small_ui/button";
import {reopen_account} from "@/AbsoluteComponents/services/account";
import useAuth from "@/AbsoluteComponents/hooks/auth";
import {openReopenAccountModal} from "@/AbsoluteComponents/store/modals/actions";
import {updateSnackBar} from "@/AbsoluteComponents/store/common/actions";
import strings from "@/AbsoluteComponents/utils/strings";
import CommonClass from "@/AbsoluteComponents/utils/common_class";



const ReopenAccountModal = () => {
    const data = useSelector((state:any) => state.modals.openReopenAccount)
    const dispatch = useDispatch()
    const auth = useAuth()
    const [loading, set_loading] = useState(false)
    
    const formatedDate = (d:string) => {
        const date = new Date(d);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // months are 0-based
        const year = date.getFullYear();
        
        return `${day}.${month}.${year}`;
        
    }
    
    
    return (
        <Modal
            ariaHideApp={false}
            isOpen={Boolean(data)}
            className={clsx(styles.modal, 'ReactModal__Deposit', 'theme_box_bg_shaded3')}
        >
            
            <ModalLayout>
                
                <div className={styles.inner}>
                    {/*<Info />*/}
                    
                    <div className={styles.top}>
                        <img
                            // width={12}
                            src={'/spins/images/exclamation.png'}
                            alt={'exclamation'}
                        />
                        <strong>
                            Deschide contul și revino în joc
                        </strong>
                    </div>
                    
                    
                    {typeof data === "object" ? (
                        <>
                            <span className={styles.inf}>
                                
                                Ai dorit închiderea contului la data <strong>
                                     {formatedDate(data.updatedAt)}
                                </strong> cu motivul <strong>{CommonClass.reasonFactory(data?.reasonId)}</strong>.
                                
                            </span>
                            
                            <div className={styles.controllers}>
                                
                                <Button
                                    loading={loading}
                                    text={"Redeschide contul"}
                                    onClick={async () => {
                                        set_loading(true)
                                        
                                        const res = await reopen_account(data.email, data.password)
                                        
                                        if (res.response && res.response.success) {
                                            auth.signIn(data.email, data.password)
                                            dispatch(openReopenAccountModal(undefined))
                                            
                                        } else {
                                            dispatch(updateSnackBar(strings['unknown_error'], 'error', false));
                                            
                                        }
                                        
                                        set_loading(false)
                                    }}
                                />
                                
                                <button
                                    onClick={() => {
                                        auth.signOut()
                                    }}
                                    className={styles.cancel}
                                >
                                    Renunță
                                </button>
                            
                            </div>
                        
                        </>
                    ) : null}
                
                
                </div>
            
            </ModalLayout>
        
        </Modal>
    )
}

export default ReopenAccountModal