import styles from 'themes/spins/styles/scrollToTop.module.scss'
import clsx from "clsx";
import ArrowBack from "@/template/svg/arrowBack";
import ArrowForwardSpins from "@/AbsoluteComponents/themes/spins/svg/arrowForwardSpins";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";

const ScrollToTop = () => {

    const [canShow, setCanShow] = useState<boolean>(false)
    const isProperPage = useSelector((state:any) => state.common.scrollToTop)

    useEffect(() => {
        const enableButton = () => {

            const main_container = document.getElementById("main_container")

            if(main_container) {
                if(main_container.scrollHeight > 2300 && window.scrollY > 300) {
                    setCanShow(true)
                } else {
                    setCanShow(false)
                }
            }
        }

        enableButton()

        if(isProperPage) {
            window.addEventListener("scroll", enableButton)
            window.addEventListener("resize", enableButton)
        }

        return () => {
            window.removeEventListener("scroll", enableButton)
            window.removeEventListener("resize", enableButton)
        }

    }, [isProperPage]);

    const scrollToTop = () => {
        if(window !== undefined) {
            window.scrollTo({top: 0})
        }
    }

    return (
        <div
            className={clsx(
                styles.root,
                canShow && styles.active,

            )}>

            <div className={styles.root__relatve_container}>
                <button
                    aria-label="Scroll to top"
                    onClick={scrollToTop}
                    className={clsx(
                        styles.root__button,
                        "theme_white_dark_text",
                    )}
                >
    
                    <span className={styles.root__text}>
                        Scroll to top
                    </span>

                    <span  className={clsx("theme_white_dark_text", styles.root__icon)}>
                        <ArrowForwardSpins />
                    </span>

                </button>
            </div>

        </div>
    )
}

export default ScrollToTop