import React, {useState, useEffect} from 'react'
import styles from "themes/spins/styles/themes.module.scss";
import CMS from "utils/cms";
import Button from "@/template/small_ui/button";
import Link from 'next/link';
import Skeleton from '@mui/material/Skeleton'
import clsx from "clsx";


interface Proprs {
    item:any;
    apiId: any
}


const ThemeItem:React.FC<Proprs> = ({item, apiId}) => {

    const [imageLoaded, setImageLoaded] = useState<boolean>(false)

    const loadImage = (src:string) => {
        return new Promise((resolve, reject) => {
            const img = document.createElement('img');
            img.src = src;

            img.onload = () => {
                resolve(true);
            }

            img.onerror = () => {
                resolve(false);
            }
        })
    }

    useEffect(() => {

        const loadImages = async () => {
            const imageGame = CMS.getPublicFilePath(item.image[0].name, apiId, item.image[0].path);
            let imageArr:Array<string> = [imageGame]
            let arr = []

            await loadImage(imageArr[0])
            arr.push(imageArr[0])
            // console.log(imageArr[0])
            if(arr.length === 1) {
                setImageLoaded(true)
            }
        }

        loadImages()

    }, [])


    return (
        <div  className={styles.grid__col}>
            {imageLoaded && (
                <>

                    {/*<Link href={'/'+item.api_id || ''} className={styles.item}>*/}
                    {/*    <img width={500} height={289} className={styles.themes__image} src={CMS.getPublicFilePath(item.image[0].name, apiId, item.image[0].path)} alt={'image'}/>*/}

                    {/*</Link>*/}

                    <Link
                        aria-label={"see all"}
                        className={clsx(
                            styles.cardLink,
                        )}
                        href={'/'+item.api_id || ''}
                    >
                        <img
                            width={500}
                            height={289}
                            style={{width: '100%', height: 'auto'}}
                            loading={"lazy"}
                            className={styles.image}
                            src={CMS.getPublicFilePath(item.image[0].name, apiId, item.image[0].path)}
                            alt={'image'}
                        />

                        <Button
                            className={styles.item__button}
                            onClick={() => {}}
                            text={'Vezi toate jocurile'}
                            type={'button'}
                        />

                    </Link>
                </>
            )}

            {!imageLoaded && (
                <div className={'ThemeSkeleton'}>
                    <Skeleton  animation="wave" variant="rectangular" className={'themeCategorySkeleton'} />
                </div>
            )}
        </div>
    )
}

export default ThemeItem