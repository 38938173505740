import React from 'react'
import One from 'themes/spins/svg/numbers/one'
import Two from 'themes/spins/svg/numbers/two'
import Three from 'themes/spins/svg/numbers/three'
import Four from 'themes/spins/svg/numbers/four'
import Five from 'themes/spins/svg/numbers/five'
import Six from 'themes/spins/svg/numbers/six'
import Seven from 'themes/spins/svg/numbers/seven'
import Eight from 'themes/spins/svg/numbers/eight'
import Nine from 'themes/spins/svg/numbers/nine'
import Ten from 'themes/spins/svg/numbers/ten'

interface Props {
    number: number
    className: string
}

const TopGamesNumber:React.FC<Props> = ({className ,number}) => {
    return (
        <div className={className}>
           <span>{number === 1 && <One />}</span>
            {number === 2 &&  <Two />}
            {number === 3 &&  <Three />}
            {number === 4 &&  <Four />}
            {number === 5 &&  <Five />}
            {number === 6 &&  <Six />}
            {number === 7 &&  <Seven />}
            {number === 8 &&  <Eight />}
            {number === 9 &&  <Nine />}
            {number === 10 &&  <Ten />}
        </div>
    )
}

export default TopGamesNumber

