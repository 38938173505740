import {FC, useState, useEffect} from 'react';
import VerificationStatus from "interfaces/VerificationStatus";
import TextField from "ui/textfield";
import Button from "@/template/small_ui/button";
import styles from '@/template/styles/signup.module.scss';
import clsx from 'clsx';
import {
    confirmEmailVerification,
    confirmPhoneVerification,
    editProfile,
    requestEmailVerification,
    requestPhoneVerification,
    validateUserData,
    getVerificationData,
} from "services/account";
import {updateSnackBar} from 'store/common/actions';
import {useDispatch, useSelector} from 'react-redux';
import strings from "utils/strings";
import {updateVerificationStatus} from "store/account/actions";
import CheckCircleIcon from 'themes/default/svg/checkCircle';
import CancelCircleIcon from 'themes/default/svg/cancelCircle';
import useAuth from "hooks/auth";
import UserInterface from "interfaces/user";
import Link from 'next/link';


interface Prop {
    data:VerificationStatus
}

const Form:FC<Prop> = ({data}) => {
    const dispatch = useDispatch();
    const auth = useAuth();

    const verificationStatus:Array<VerificationStatus> = useSelector((state:any) => state.account.verificationStatus);

    const [code, setCode] = useState<string>('');
    const [value, setValue] = useState<string>('');
    const [requesting, setRequesting] = useState<boolean>(false);
    const [applying, setApplying] = useState<boolean>(false);
    const [editing, setEditing] = useState<boolean>(false);
    const [verified, setVerified] = useState<boolean | null>(null);

    let editFieldLabel:string = '';
    let codeFieldLabel:string = '';

    if (data.method === 'email') {
        editFieldLabel = 'Adresa ta de email';
        codeFieldLabel = 'Cod prin email';
    }

    if (data.method === 'phone') {
        editFieldLabel = 'Numărul tau de telefon';
        codeFieldLabel = 'Cod prin SMS';
    }

    const requestCode = async () => {
        let response:any = null;

        setRequesting(true);

        if (data.method === 'email') {
            response = await requestEmailVerification();
        }

        if (data.method === 'phone') {
            response = await requestPhoneVerification();
        }

        setRequesting(false);

        if (response?.error) {
            dispatch(updateSnackBar(response?.message || '', 'error'));
        } else {
            if ( typeof response?.response === "object" && response?.response?.method) {
                dispatch(updateSnackBar('Codul a fost trimis cu succes', 'success'));

                updateVerifications('attempts');
            } else {
                dispatch(updateSnackBar(strings['unknown_error'], 'error'));
            }
        }
    }

    const applyCode = async () => {
        let response:any = null;

        setApplying(true);
        setVerified(null);

        if (data.method === 'email') {
            response = await confirmEmailVerification(code);
        }

        if (data.method === 'phone') {
            response = await confirmPhoneVerification(code);
        }

        if (response?.error) {
            // dispatch(updateSnackBar(response?.message || '', 'error'));
            setVerified(false);
        } else {
            if ( typeof response?.response === "object" && response?.response?.verified) {
                setVerified(true);

                setTimeout(() => {
                    updateVerifications('verified');
                }, 2000);
            } else {
                setVerified(false);
            }
        }

        setApplying(false);
    };

    const responseValidate = (validations:Array<any>) => {
        const errors:any = {};

        validations.map((item:any) => {
            if (item.validateDetailsType === 'email' && !item.available) {
                if (item?.error?.exists) {
                    errors['email'] = 'E-mail deja utilizat';
                }

                if (item?.error?.invalid) {
                    errors['email'] = 'E-mail invalid';
                }
            }

            if (item.validateDetailsType === "phoneNumber" && !item.available) {
                if (item?.error?.exists) {
                    errors['phone'] = 'Număr de telefon existent';
                }

                if (item?.error?.invalid) {
                    errors['phone'] = 'Număr de telefon invalid';
                }
            }
        });

        return errors;
    }

    const editValue = async () => {
        const user:UserInterface = auth.getUser();

        setEditing(true);

        let validations = await validateUserData(data.method === 'email' ? {email: value} : {phoneNumber: value});

        if ( validations.error ) {
            dispatch(updateSnackBar(validations.message || '', 'error'));
        } else {
            if (Array.isArray(validations.response)) {
                const errors = responseValidate(validations.response);

                if (errors[data.method]) {
                    dispatch(updateSnackBar(errors[data.method], 'error'));
                } else {
                    if (data.method === 'email') user.email = value;
                    if (data.method === 'phone') user.phoneNumber = value;

                    const edit = await editProfile(user);

                    if (edit.error) {
                        dispatch(updateSnackBar(edit.message || '', 'error'));
                    } else {
                        if (data.method === 'email') dispatch(updateSnackBar('E-mailul a fost editat cu succes', 'success'));
                        if (data.method === 'phone') dispatch(updateSnackBar('Numărul de telefon a fost editat cu succes', 'success'));

                        const newData = await getVerificationData(data.method);

                        updateVerifications('value');

                        if (newData.response?.leftCountAttempt > 0) updateVerifications('attempts_reset', Number(newData.response?.leftCountAttempt));
                        if (newData.response?.value) updateVerifications('value', newData.response?.value.toString());
                    }
                }
            } else {
                dispatch(updateSnackBar(strings['unknown_error'], 'error'));
            }
        }

        setEditing(false);
    };

    const disableEditValue = ():boolean => {
        return value.length === 0;
    };

    const updateVerifications = (type:'attempts' | 'verified' | 'value' | 'attempts_reset', value?:any) => {
        const prevVerifications:Array<VerificationStatus> = JSON.parse(JSON.stringify(verificationStatus));

        prevVerifications.map((item, i:number) => {
            if (item.method === data.method && data.leftCountAttempt > 0 && type === 'attempts') {
                item.leftCountAttempt -= 1;
            }

            if (item.method === data.method && type === 'attempts_reset' && value) {
                item.leftCountAttempt = value;
            }

            if (item.method === data.method && type === 'verified') {
                item.verified = true;
            }

            if (item.method === data.method && type === 'value' && value) {
                item.value = value;
            }
        });

        dispatch(updateVerificationStatus(prevVerifications));
    }

    useEffect(() => {



        const onResize = () => {
            let vw = window.innerWidth
            let inputs = document.getElementsByClassName('verification_containers')
            Array.from(inputs).forEach(input => {
                if(vw < 575) {
                    input.classList.remove('theme_box_bg_shaded2')
                } else {
                    input.classList.add('theme_box_bg_shaded2')
                }
            });

        }

        onResize()

        setValue(data.value);

        window.addEventListener('resize', onResize)

        return () => {
            window.removeEventListener('resize', onResize)
        }
    }, []);

    return (
        <div className={styles.verification}>
            
            <div id={'input_theme'}
                 className={clsx(styles.verification__value, styles.box, 'theme_box_bg_shaded2', 'verification_containers')}>
                
                <TextField
                    type={'text'}
                    label={editFieldLabel}
                    placeholder={editFieldLabel}
                    defaultValue={data.value}
                    className={clsx(styles.verification__value__field, "no_focus")}
                    {...{
                        onChange: (e: any) => {
                            setValue(e.target.value);
                        }
                    }}
                />
                
                <Button
                    style={{borderColor: '#27222D'}}
                    outlined
                    loading={editing}
                    type={'button'}
                    text={'Editează'}
                    disabled={disableEditValue()}
                    onClick={editValue}
                    large
                    className={styles.verification__value__btn}
                />
            
            </div>
            
            <div className={clsx(styles.verification__divider, 'theme_divider')}>
            
            </div>
            
            <div>
                <div
                    className={clsx(styles.verification__value, styles.box, 'theme_box_bg_shaded2', 'verification_containers')}>
                    
                    <TextField
                        type={'text'}
                        label={codeFieldLabel}
                        placeholder={codeFieldLabel}
                        className={styles.verification__value__field}
                        {...{
                            onChange: (e: any) => {
                                setCode(e.target.value);
                            }
                        }}
                    />
                    
                    <Button
                        type={'button'}
                        text={'Verificare'}
                        large
                        disabled={code.length === 0}
                        loading={applying}
                        onClick={applyCode}
                        className={styles.verification__value__btn}
                    />
                
                </div>
                
                <p className={clsx(styles.attempts_number, 'theme_text_mainColor')}>
                    Mai ai {data.leftCountAttempt}
                    {data.leftCountAttempt === 1 ? ' încercare' : ' încercări'}
                </p>
                
            </div>
            
            
            <div className={clsx(styles.verification__value, styles.with_label)}>
                
                {/*<TextField*/}
                {/*    type={'text'}*/}
                {/*    label={codeFieldLabel}*/}
                {/*    placeholder={codeFieldLabel}*/}
                {/*    className={styles.verification__value__field}*/}
                {/*    {...{*/}
                {/*        onChange: (e: any) => {*/}
                {/*            setCode(e.target.value);*/}
                {/*        }*/}
                {/*    }}*/}
                {/*/>*/}
                
                
                {/*<p className={clsx(styles.attempts_number, 'theme_text_mainColor')}>*/}
                {/*    Mai ai {data.leftCountAttempt}*/}
                {/*    {data.leftCountAttempt === 1 ? ' încercare' : ' încercări'}*/}
                {/*</p>*/}
                
                <div className={styles.button_container}>
                    <Button
                        type={'button'}
                        text={'Trimite cod'}
                        large
                        outlined
                        disabled={data.leftCountAttempt === 0}
                        loading={requesting}
                        className={clsx(styles.verification__codeBtn)}
                        onClick={requestCode}
                    />
                    
                    {/*<Button*/}
                    {/*    type={'button'}*/}
                    {/*    text={'Verificare'}*/}
                    {/*    large*/}
                    {/*    disabled={code.length === 0}*/}
                    {/*    loading={applying}*/}
                    {/*    onClick={applyCode}*/}
                    {/*    className={styles.verification__value__btn}*/}
                    {/*/>*/}
                </div>
            
            </div>
            
            
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            {data.method === 'phone' &&
                <p className={clsx(styles.paragraph, 'theme_text_mainColor')}>
                    Dacă nu ai primit SMS-ul, te rugăm să verifici corectitudinea numărului de telefon și să apeși
                    "Retrimite cod" pentru a mai încerca o dată. În cazul în care întâmpini în continuare probleme, te
                    rugăm să contactezi
                    <Link   style={{marginLeft: '3px'}} target={'_blank'} title={'Asistență clienți'} href={'/contact'}>Asistență
                        clienți</Link>.
                </p>
            }
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            {data.method === 'email' &&
                <p className={clsx(styles.paragraph, 'theme_text_mainColor')}>
                    Dacă nu ai primit e-mailul, te rugăm să verifici corectitudinea adresei de e-mail și să apeși
                    "Retrimite cod" pentru a mai încerca o dată. În cazul în care întâmpini în continuare probleme, te
                    rugăm să contactezi
                    <Link   style={{marginLeft: '3px'}} target={'_blank'} title={'Asistență clienți'} href={'/contact'}>Asistență
                        clienți</Link>.
                </p>
            }
            
            {verified === true &&
                <div className={styles.verification__footer}>
                    <div className={styles.verification__msg}>
                        <CheckCircleIcon/>
                        
                        {data.method === 'email' && <span>E-mailul tău a fost verificat cu succes.</span>}
                        {data.method === 'phone' && <span>Numărul de telefon a fost verificat cu succes.</span>}
                    </div>
                </div>
                
            }
            
            {verified === false &&
                <div className={styles.verification__footer}>
                    <div className={clsx(styles.verification__msg, styles.verification__msg__error)}>
                        <CancelCircleIcon/>
                        
                        <span>Codul introdus nu este valid.</span>
                    </div>
                </div>
            }
        
        
        </div>
    )
}

export default Form;