import React from 'react'
import clsx from 'clsx'
import {useSelector} from "react-redux";


const WheelSpins = () => {
    const enableSpin: string = useSelector((state: any) => state.common.enableSpinWheel);

    return (
        <svg style={{transform: "translateY(-1px)"}} className={clsx('svg-icon' /*, enableSpin === 'enable' && 'wheel_enabled' */)} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M9.01293 3.46582C5.08934 4.72724 2.25 8.4069 2.25 12.7497C2.25 18.1345 6.61522 22.4997 12 22.4997C17.3848 22.4997 21.75 18.1345 21.75 12.7497C21.75 8.4069 18.9107 4.72724 14.9871 3.46582" stroke="#A4AAB6" strokeLinecap="round"/>
            <path d="M14.99 18.2378C15.1222 18.4803 15.0334 18.7858 14.7818 18.8997C13.9017 19.298 12.9445 19.5036 11.9745 19.5002C11.0046 19.4967 10.0488 19.2843 9.17156 18.8797C8.9208 18.7641 8.83422 18.4579 8.96811 18.2164L11.0167 14.5213C11.315 14.6867 11.6503 14.774 11.9913 14.7752C12.3324 14.7764 12.6683 14.6915 12.9678 14.5282L14.99 18.2378Z" fill="#A4AAB6"/>
            <path d="M7.77926 17.3598C7.5928 17.5635 7.275 17.5786 7.08567 17.3776C6.42331 16.6744 5.91823 15.8356 5.6068 14.917C5.29536 13.9984 5.18617 13.0254 5.28425 12.0644C5.31228 11.7897 5.57379 11.6084 5.84568 11.6567L10.0056 12.3955C9.94593 12.7314 9.97208 13.0768 10.0816 13.3999C10.1911 13.7229 10.3805 14.013 10.6321 14.2433L7.77926 17.3598Z" fill="#A4AAB6"/>
            <path d="M6.35666 10.0615C6.10737 9.94274 5.99996 9.64325 6.13686 9.40343C6.6158 8.56445 7.27031 7.83627 8.05775 7.26994C8.84518 6.70361 9.74377 6.3148 10.6915 6.12769C10.9625 6.0742 11.2122 6.27131 11.2455 6.54544L11.7548 10.7396C11.4162 10.7807 11.0935 10.9067 10.8166 11.1059C10.5397 11.305 10.3176 11.5709 10.1708 11.8788L6.35666 10.0615Z" fill="#A4AAB6"/>
            <path d="M12.8326 6.55539C12.8694 6.28171 13.1216 6.08782 13.3918 6.14477C14.3371 6.344 15.2306 6.7443 16.0108 7.32067C16.7909 7.89704 17.436 8.63354 17.9042 9.47859C18.038 9.72014 17.9267 10.0182 17.676 10.1338L13.8388 11.9021C13.696 11.5923 13.4774 11.3236 13.203 11.121C12.9287 10.9183 12.6076 10.7882 12.2695 10.7427L12.8326 6.55539Z" fill="#A4AAB6"/>
            <path d="M18.1468 11.6333C18.4185 11.5839 18.6807 11.7642 18.7098 12.0388C18.8116 12.9995 18.7061 13.9729 18.3981 14.8926C18.0902 15.8124 17.5883 16.6531 16.9286 17.3588C16.7401 17.5606 16.4222 17.5466 16.235 17.3436L13.3703 14.2381C13.6211 14.0068 13.8093 13.7159 13.9176 13.3925C14.0259 13.0691 14.0507 12.7235 13.9898 12.3879L18.1468 11.6333Z" fill="#A4AAB6"/>
            <path d="M11.9995 5.23242L13.5131 2.20608C13.6793 1.87362 13.4376 1.48242 13.0659 1.48242H10.9339C10.5622 1.48242 10.3205 1.87353 10.4867 2.20598L11.9995 5.23242Z" fill="#A4AAB6"/>
        </svg>
    )
}
export default WheelSpins