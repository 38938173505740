import style from 'themes/spins/styles/wheel.module.scss';
import React, { useEffect, useState} from 'react';
import Fireworks from "./fireworks";
import Button from "themes/spins/small_ui/button";
import Image from "next/image";
import strings from "@/AbsoluteComponents/utils/strings";
import clsx from "clsx";

interface Props {
    prise: any
    closePopup():void
    setPlay(type: string):void
    clickEnd(type: boolean): void
    endSpinning():void
    showPrisePopup:boolean
}

const PriseContainer: React.FC<Props> = ({prise, closePopup, setPlay, clickEnd, endSpinning, showPrisePopup}) => {

    const [toggleAnim, setToggleAnim] = useState<boolean>(false);
    const base_url = "/spins/images/wheel/win_container/"
    const {cash, free_spin, value} = prise
    function formatNumber(number: number) {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    }
    
    const congrats = (
        <>
            Felicitări!
            <br />
            Ai câștigat
        </>
    );
    const img_names = [
        "rays",
        "platform",
        "gift_upper",
        "gift_bottom",
        "back_rays",
        "rays_front",
        "stars"
    ]
    
    const images = img_names.reduce((result:any, item) => {
        result[item] = {
            src: base_url + item + ".webp",
            alt: item,
        };
        return result;
    }, {});
    
    useEffect(() => {
        setPlay('tada')

        setTimeout(() => {
            setPlay('')
        }, 5000)
    }, [])

    useEffect(() => {
        setTimeout(() => {
            setToggleAnim(!toggleAnim)
        }, 1000)
    }, [toggleAnim])
    
    return (
        <div className={clsx(style.overlayContainer, showPrisePopup && style.active)}>
            <Fireworks/>

            <div className={style.main}>
                <Image className={style.rays_bg} src={images.rays.src} alt={images.rays.alt} width={1000} height={777}/>
                
                <div className={style.platform}>
                    <Image src={images.platform.src} alt={images.platform.alt} width={1372} height={1492} />
                </div>
                
                 <div className={style.front_container}>
                     <div className={style.gift_box}>
                         <div className={style.gift_box__bg_light}>
                         
                         </div>
                         
                         <Image
                             src={images.stars.src}
                             alt={images.stars.alt}
                             width={268}
                             height={191}
                             className={style.gift_box__stars}
                         />
                         
                         <Image
                             src={images.back_rays.src}
                             alt={images.back_rays.alt}
                             width={310}
                             height={268}
                             className={style.gift_box__back_rays}
                         />
                         <Image
                             src={images.gift_bottom.src}
                             alt={images.gift_bottom.alt}
                             width={600}
                             height={700}
                             className={style.gift_box__gift_bottom}
                         />
                         <Image
                             src={images.rays_front.src}
                             alt={images.rays_front.alt}
                             width={316}
                             height={255}
                             className={style.gift_box__rays_front}
                         />
                         <Image
                             src={images.gift_upper.src}
                             alt={images.gift_upper.alt}
                             width={536}
                             height={382}
                             className={style.gift_box__gift_upper}
                         />
                         
                         
                     </div>
                 
                     <div className={style.prize_box}>
                         <div className={style.win_congrats}>
                             <span className={style.congrat_text}>
                                 {congrats}
                             </span>
                             
                             <span className={style.shadow_text}>
                                {congrats}
                             </span>
                         </div>
                         
                         <div className={style.win_amount}>
                             <span className={style.amount}>
                                 {formatNumber(Number(value))}
                             </span>
                             
                             <span className={style.currency}>
                                 {cash && "RON"}
                                 {free_spin && "freespins"}
                             </span>
                         </div>
                         
                         <Button
                             onClick={() => {
                                 closePopup();
                                 clickEnd(true);
                                 endSpinning()
                             }}
                             text={'închide'}
                         />
                         
                     </div>
                 </div>
                
                
            
            </div>
        
        </div>
    )
}

export default PriseContainer

