function TranzactiiIconSpins(){
    return(
        <svg className={'svg-icon'} xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
            <path d="M22.25 11.125C22.25 13.2361 21.6493 15.3036 20.5181 17.0861L17.7002 15.2978C18.492 14.05 18.9125 12.6028 18.9125 11.125H22.25Z" fill="white"/>
            <path d="M18.9916 18.9916C17.4988 20.4843 15.6121 21.5216 13.5518 21.9821L12.8238 18.725C14.266 18.4026 15.5867 17.6765 16.6316 16.6316L18.9916 18.9916Z" fill="white"/>
            <path d="M11.125 22.25C9.0139 22.25 6.94638 21.6493 5.16393 20.5181L6.95225 17.7002C8.19997 18.492 9.64723 18.9125 11.125 18.9125L11.125 22.25Z" fill="white"/>
            <path d="M3.25844 18.9916C1.76567 17.4988 0.728447 15.6121 0.267926 13.5518L3.52505 12.8238C3.84741 14.266 4.57347 15.5867 5.61841 16.6316L3.25844 18.9916Z" fill="white"/>
            <path d="M0 11.125C1.84558e-07 9.0139 0.60067 6.94638 1.73185 5.16393L4.5498 6.95225C3.75797 8.19997 3.3375 9.64723 3.3375 11.125H0Z" fill="white"/>
            <path d="M3.25844 3.25844C4.75121 1.76567 6.6379 0.728448 8.69816 0.267926L9.42621 3.52505C7.98403 3.84741 6.66334 4.57347 5.61841 5.61841L3.25844 3.25844Z" fill="white"/>
            <path d="M11.125 0C13.2361 2.51746e-08 15.3036 0.600671 17.0861 1.73185L15.2978 4.5498C14.05 3.75797 12.6028 3.3375 11.125 3.3375V0Z" fill="white"/>
            <path d="M18.9916 3.25844C20.4843 4.75121 21.5216 6.6379 21.9821 8.69815L18.725 9.42621C18.4026 7.98403 17.6765 6.66334 16.6316 5.61841L18.9916 3.25844Z" fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M11.125 5.2793C7.89649 5.2793 5.2793 7.89649 5.2793 11.125C5.2793 14.3534 7.89649 16.9706 11.125 16.9706C14.3534 16.9706 16.9706 14.3534 16.9706 11.125C16.9706 7.89649 14.3534 5.2793 11.125 5.2793ZM11.3451 7.81102C11.3451 7.39681 11.0093 7.06102 10.5951 7.06102C10.1809 7.06102 9.84507 7.39681 9.84507 7.81102V11.8399V12.2659L10.211 12.4841L12.7268 13.9842C13.0826 14.1963 13.543 14.0799 13.7551 13.7241C13.9672 13.3683 13.8508 12.908 13.495 12.6958L11.3451 11.4139V7.81102Z" fill="white"/>
        </svg>
    )
}
export default TranzactiiIconSpins