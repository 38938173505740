function ArrowSearchSpins() {
    return(
        <svg className={"svg-icon"} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            {/*<mask id="mask0_1072_38951" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">*/}
            {/*    <rect width="24" height="24" transform="matrix(-1 0 0 1 24 0)" fill="white"/>*/}
            {/*</mask>*/}
            {/*<g mask="url(#mask0_1072_38951)">*/}
                <path d="M15.975 22L17.75 20.225L9.525 12L17.75 3.775L15.975 2L5.975 12L15.975 22Z" fill="white"/>
            {/*</g>*/}
        </svg>
    )
}
export default ArrowSearchSpins