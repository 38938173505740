function PersonalInfoIconSpins() {
    return(
        <svg className={'svg-icon'} xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
            {/*<g filter="url(#filter0_di_903_88411)">*/}
                <path fillRule="evenodd" clipRule="evenodd" d="M16.4574 2.88301C13.5016 2.45827 10.5002 2.45827 7.54444 2.88301C6.07895 3.0936 4.94986 4.28299 4.81573 5.75744L4.68876 7.15318C4.39541 10.378 4.39541 13.6227 4.68876 16.8475L4.81573 18.2432C4.94986 19.7177 6.07895 20.9071 7.54444 21.1176C10.5002 21.5424 13.5016 21.5424 16.4574 21.1176C17.9229 20.9071 19.052 19.7177 19.1861 18.2432L19.3131 16.8475C19.6064 13.6227 19.6064 10.378 19.3131 7.15317L19.1861 5.75744C19.052 4.28299 17.9229 3.0936 16.4574 2.88301ZM10.0009 9.00034C10.0009 7.89577 10.8963 7.00034 12.0009 7.00034C13.1055 7.00034 14.0009 7.89577 14.0009 9.00034C14.0009 10.1049 13.1055 11.0003 12.0009 11.0003C10.8963 11.0003 10.0009 10.1049 10.0009 9.00034ZM8.00092 15.5003C8.00092 13.8435 9.34406 12.5003 11.0009 12.5003H13.0009C14.6578 12.5003 16.0009 13.8435 16.0009 15.5003C16.0009 16.0526 15.5532 16.5003 15.0009 16.5003H9.00092C8.44863 16.5003 8.00092 16.0526 8.00092 15.5003Z" fill="#FBB90E"/>
            {/*</g>*/}
            <defs>
                <filter id="filter0_di_903_88411" x="-2" y="-1" width="28" height="28" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="1"/>
                    <feGaussianBlur stdDeviation="1"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_903_88411"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_903_88411" result="shape"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="1"/>
                    <feGaussianBlur stdDeviation="1"/>
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.6 0"/>
                    <feBlend mode="normal" in2="shape" result="effect2_innerShadow_903_88411"/>
                </filter>
            </defs>
        </svg>
    )
}
export default PersonalInfoIconSpins