import {useEffect, useRef} from 'react';
import useAuth from "hooks/auth";
import {getAuthToken, getProfileLoaded} from 'utils/cookies';
import {useRouter} from 'next/router';


function RoutesGuard() {
    const auth = useAuth();
    const router = useRouter();

    const timeout = useRef<any>(null);
    const loginStart = useRef<any>(null);

    const checkToken = () => {

        const token:string = getAuthToken();

        const pushBack = () => {
            router.push('/?redirect=' + window.location.href)
        };

        if (!token.length) {
            const path = window.location.href;
            const isRealGame:boolean = path.search('/joc') > -1 && path.search('/real') > -1;

            if ( path.search('/account') > -1 || path === '/free-spins' || path === '/free-bonus' || path === '/free-bonus' || isRealGame) {
                pushBack();
            }

            auth.signOut();
        }
    };

    const autoLogin = () => {
        const token:string = getAuthToken();
        const profileLoaded:string = getProfileLoaded();

        if ( token.length  && profileLoaded !== '1') {

            auth.updateProfile();

        }
    };

    useEffect(() => {
        if (!document.hidden) return;

        if (auth.isLoggedIn() && !timeout.current) {
            timeout.current = setInterval(checkToken, 1000);

            clearInterval(loginStart.current);
            loginStart.current = null;
        } else if (!auth.isLoggedIn()) {
            clearInterval(timeout.current);
            timeout.current = null;

            loginStart.current = setInterval(autoLogin, 1000);
        }

    }, [auth.isLoggedIn()]);

    useEffect(() => {
        return () => {
            clearInterval(timeout.current);
            timeout.current = null;

            clearInterval(loginStart.current);
            loginStart.current = null;
        };
    }, []);


    return null
}

export default RoutesGuard