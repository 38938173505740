import styled from "styled-components";
import Image from "next/image";


interface WheelComponentProps {
    startSpinDeg: number;
    rotateTo: number;
    initialPosition: number
    rotateBefore:number
}

interface PriseImage {
    deg:number
    order:number
}

export const WheelComponent = styled.div<WheelComponentProps>`
    width: 100%;
    height: 100%;
    transition: 0.5s all;
    -webkit-transition: 0.5s all;
    transform: rotate(${(props) => props.initialPosition}deg);
    //transform: rotate(0);
    padding: 11px;
    
   
 
    &.startSpinning {
        -webkit-animation: startspin;
        -webkit-animation-duration: 2s;
        animation: startspin;
        animation-duration: 2s;
        
        @keyframes startspin {
              0% {
                transform: rotate(${(props) => props.initialPosition}deg);
              }
              30% {
                transform: rotate(${(props) => props.initialPosition - props.startSpinDeg}deg);
              }
              100% {
                transform: rotate(720deg);
              }
        }
    }

    &.endSpinning {
        transition: 0.5s all;
        -webkit-transition: 0.5s all;
        transform: rotate(${(props) => (1440 + props.rotateTo)}deg);
        -webkit-animation: endspinn;
        -webkit-animation-duration: 3.5s;
        animation: endspinn;
        animation-duration: 3.5s;
        
        @keyframes endspinn {
          0% {
            transform: rotate(${(props) => (1440 + props.rotateTo)});
          }
         
          100% {
            transform: rotate(${(props) => props.rotateTo + 2880}deg);
          }
        }
    }

    &.spinning {
        -webkit-animation: spinn linear infinite;
        -webkit-animation-duration: 0.5s;
        animation: spinn linear infinite;
        animation-duration: 0.5s;
    
        @keyframes spinn {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(720deg);
            //transform: rotate(360deg);
          }
        }
    }
`;

export const CenteredImages = styled.img`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
    
     &.enabledSpin {
     -webkit-animation: scale linear infinite;   
        -webkit-animation-duration: 2.5s;   
        animation: scale linear infinite;   
        animation-duration: 2.5s;   
       
    }
    
    &.active {
    -webkit-animation: scale infinite;
        -webkit-animation-duration: 1.5s; 
        animation: scale infinite;
        animation-duration: 1.5s; 
    }
   
    
   &.spinnedToday {
        animation: none;
        
        &:hover {
            animation: none;
        }
   }
    
    @keyframes scale {
      0% {
        
        transform: translate(-50%, -50%) scale(1) rotate(-2deg);
      }
      
      50% {
      
        transform:translate(-50%, -50%) scale(1.1) rotate(7deg);
      }
      
      100% {
      
        transform:translate(-50%, -50%) scale(1) rotate(-2deg);
      }
    }
    
    &.appear {
        -webkit-animation: appearborder ;
          animation: appearborder ;
          -webkit-animation-duration: 1s;
          animation-duration: 1s;
          border: none;
          transform: translate(-50%, -50%) scale(0.7);
          opacity: 0.6;
          @keyframes appearborder {
              0% {
                transform: translate(-50%, -50%) scale(0.7);
                opacity: 0.6;
              }
              100% {
                transform: translate(-50%, -50%) scale(1.0);
                opacity: 1;
              }
          }
    }
`

export const Center = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateY(2px);
`

export const PriseImageContainer = styled.div<PriseImage>`
    width: 82px;
    height: 158px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%) rotate(${(props) => props.deg}deg);
    transform-origin: top;
    
    &.appear {
        animation: loadWheel 1s forwards;
        -webkit-animation: loadWheel 1s forwards;
        // animation-delay: calc(0.1s - (0.03s * ${(props) => props.order}));

        @keyframes loadWheel {
            0% {
                transform: translateX(-50%) rotate(0);
            }
            100% {
                transform: translateX(-50%) rotate(${(props) => props.deg}deg) !important;
            }
        }
    }
    
    &.active {
        z-index: 99999999;
    }
`

export const PriseImage = styled(Image)`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    //z-index: -10;

    &.active {
        display: block;
        //transform: scale(1.2);
        //filter: brightness(70%) sepia(100%) hue-rotate(180deg) saturate(500%) contrast(0.8);

    }
`

export const PriseImageShadow = styled(Image)`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: none;

    &.active {
        display: block;
        transform: scale(1.12);
        transform-origin: bottom;
        filter: brightness(1000%) blur(9px);
        
    }

`

export const PriseInfoContainer = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: end;
    padding-bottom: 13px;
`

export const Value = styled.span`
    transform: rotate(90deg) translateX(-45%);
    color: #FFF;
    text-align: right;
    text-shadow: -1.6px 0 0 rgba(0, 0, 0, 0.15), 0 0 1.6px rgba(0, 0, 0, 0.25);
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 24px */
    text-transform: uppercase;
`

export const Label = styled.span`
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    text-transform: uppercase;
    color: #FFF;
`

