type Listener = (...args: any[]) => void;

class EventEmitter {
    private events: Record<string, Listener[]>;

    constructor() {
        this.events = {};
    }

    on(event: string, listener: Listener): void {
        if (!this.events[event]) {
            this.events[event] = [];
        }
        this.events[event].push(listener);
    }

    once(event: string, listener: Listener): void {
        const onceWrapper: Listener = (...args: any[]) => {
            listener(...args);
            this.off(event, onceWrapper);
        };
        this.on(event, onceWrapper);
    }

    emit(event: string, ...args: any[]): void {
        if (!this.events[event]) {
            return;
        }
        this.events[event].forEach(listener => listener(...args));
    }

    off(event: string, listener: Listener): void {
        if (!this.events[event]) {
            return;
        }
        this.events[event] = this.events[event].filter(l => l !== listener);
    }

    removeAllListeners(event: string): void {
        if (!this.events[event]) {
            return;
        }
        delete this.events[event];
    }
}

export default EventEmitter;
