import styles from "themes/spins/styles/OldPromotions.module.scss";
import React, {useState, useEffect} from 'react';
import PromoCard from "./promotionCard";
import strings from "utils/strings";
import {useRouter} from 'next/router';
import clsx from 'clsx';
// import SpinsPromotionIcon from "@/template/svg/spinsPromotionIcon";
import SpinsPromotionIcon from "themes/spins/svg/spinsPromotionIcon";
// import PromotionItem from "@/template/cms-views/promotions/promotion_item";
import PromotionItem from "themes/spins/cms-views/promotions/promotion_item";

interface Props {
    isMedia1: boolean
    isImageLoaded: boolean
    oldPromotions: Array<any>
}

const SpinsOldPromSidebarView:React.FC<Props> = ({isMedia1, isImageLoaded, oldPromotions}) => {

    const [asideH, setAsideH] = useState<number | string>('unset')

    const router = useRouter();

    useEffect(() => {
        const onResize = () => {
            let aside = document.getElementById('pagePromId')

            if(isImageLoaded && aside) {
                let height = aside.clientHeight
                setAsideH(height + 'px')
            }
        }
        onResize()

        window.addEventListener('resize', onResize)

        return () => {
            window.removeEventListener('resize', onResize)
        }

    }, [isMedia1, router, isImageLoaded])


    return (
        <div style={{height: asideH }} id={'toSetHeight'} className={clsx(styles.promotionsAside, 'theme_box_bg_unshaded1')}>

            <h3 className={clsx(styles.promotionsAside__title_container, 'theme_white_dark_text')}>
                <div className={clsx(styles.promo_svg, 'theme_text_yellow')}>
                    <SpinsPromotionIcon />
                </div>
                {strings['old_promotions']}
            </h3>

            <div className={clsx(styles.promotionsAside__divider, 'theme_divider')}>

            </div>

            <div  className={styles.mobileSlider}>
                <div className={styles.oldPromotionContainer}>
                    {oldPromotions?.map((x, i) => {
                        return (
                            <PromotionItem inSidebar key={'promo side card ' + i} item={x} />
                        )}
                    )}
                </div>
            </div>
        </div>
    )
}

export default SpinsOldPromSidebarView