function DarkModeIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
            {/*<mask id="mask0_902_41697" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="24">*/}
            {/*    <rect x="0.25" width="24" height="24" fill="#D9D9D9"/>*/}
            {/*</mask>*/}
            {/*<g mask="url(#mask0_902_41697)">*/}
                <path d="M12.25 21C9.75 21 7.625 20.125 5.875 18.375C4.125 16.625 3.25 14.5 3.25 12C3.25 9.5 4.125 7.375 5.875 5.625C7.625 3.875 9.75 3 12.25 3C12.4833 3 12.7125 3.00833 12.9375 3.025C13.1625 3.04167 13.3833 3.06667 13.6 3.1C12.9167 3.58333 12.3708 4.2125 11.9625 4.9875C11.5542 5.7625 11.35 6.6 11.35 7.5C11.35 9 11.875 10.275 12.925 11.325C13.975 12.375 15.25 12.9 16.75 12.9C17.6667 12.9 18.5083 12.6958 19.275 12.2875C20.0417 11.8792 20.6667 11.3333 21.15 10.65C21.1833 10.8667 21.2083 11.0875 21.225 11.3125C21.2417 11.5375 21.25 11.7667 21.25 12C21.25 14.5 20.375 16.625 18.625 18.375C16.875 20.125 14.75 21 12.25 21Z" fill="#929396"/>
            {/*</g>*/}
        </svg>
    )
}
export default DarkModeIcon