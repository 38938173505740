interface StaticValuesInterface {
    conditionsBonus:Array<any>,
    images:Array<string>,
    bonusConditions: Array<any>,
}

const StaticValues:StaticValuesInterface = {
    conditionsBonus: [],
    images: [],
    bonusConditions: [],
}

export default StaticValues;