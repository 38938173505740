import strings from "@/AbsoluteComponents/utils/strings";
import AccIcon from "@/AbsoluteComponents/themes/spins/svg/AccIcon";
import HistoryIcon from "@/AbsoluteComponents/themes/spins/svg/HistoryIcon";
import SettingsIcon from "@/AbsoluteComponents/themes/spins/svg/SettingsIcon";
import HelpIcon from "@/AbsoluteComponents/themes/spins/svg/HelpIcon";
import React from "react";
import styles from 'themes/spins/styles/Header.module.scss';
import {useRouter} from "next/router";


const AccNavs = () => {
    const router = useRouter()
    
    const acc_links = [
        {
            name: strings['acc'],
            path: "/account/profile",
            icon: <AccIcon/>,
            visible: true
        },
        {
            name: strings['history'],
            path: "/account/transactions/game",
            icon: <HistoryIcon/>,
            visible: true
        },
        {
            name: strings['settings'],
            path: "/account/responsable-game",
            icon: <SettingsIcon/>,
            visible: true
        },
        {
            name: strings['help'],
            path: "/account/support",
            icon: <HelpIcon/>,
            visible: true
        },
    ]
    
    return (
        <div className={styles.acc_navs}>
            {acc_links.map((item:any, i:number) => {
                return (
                    <button
                        key={"add_nav"+i}
                        onClick={() => {router.push(item.path)}}
                        aria-label={item.name}
                    >
                        {item.icon}
                        
                        {item.name}
                    </button>
                )
            })}
        </div>
    )
}

export default AccNavs