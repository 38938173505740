import React, {useState, useEffect, createRef} from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import styles from "../../styles/JackpotLive.module.scss";
import clsx from 'clsx'
import { Navigation } from "swiper";
import { Pagination } from "swiper";
import CMS from "utils/cms";
import PaginationArrow from "ui/paginationArrow";
import Skeleton from '@mui/material/Skeleton';
import ArrowRight from "@/template/svg/arrow-right";
import {useRouter} from "next/router";
import JackpotCardNew from "./jackpot_card_new";
import useCommon from "@/AbsoluteComponents/hooks/common";
import {useDispatch, useSelector} from "react-redux";
import JackpotCardDynamic from "./jackpot_card_dynamic";
import {updatePlayJackpot} from "@/AbsoluteComponents/store/common/actions";

interface Props {
    json: any
}

const LiveJackpots:React.FC<Props> = ({json}) => {
    const route = useRouter();
    const data = CMS.jsonJackpotsData(json)
    const [jackpotsData, setJackpotsData] = useState<Array<any>>([])
    const [isLoaded, setIsLoaded] = useState<boolean>(false)
    const [swiper, setSwiper] = useState<any>();
    const [isEnd, setIsEnd] = useState<boolean>(false);
    const [isBeginning, setIsBeginning] = useState<boolean>(true);
    const [imagesLoaded, setImagesLoaded] = useState<boolean>(false)
    const [jackpots, setJackpots] = useState<Array<any>>([])
    const dispatch = useDispatch()
    
    // REAL PLAY JACKPOT START
    const playJackpot = useSelector((state: any) => state.common.playJackpot)
    // REAL PLAY JACKPOT END
    
    const common_hook = useCommon()
    const elem:any = createRef();
    
    // TEST PLAY JACKPOT START
    // TEST PLAY JACKPOT END
    
    let skeletonLength = 10
    let skeletonArray:Array<any> = []
    for(let i = 0 ; i < skeletonLength; i++) {
        skeletonArray.push(i)
    }

    const fetchCredentials = async () => {
        const endpoint = process.env.NEXT_PUBLIC_CUSTOM_JACKPOTS_ENDPOINT || '/api/dynamic/jackpots.json'

        try {
            const response = await fetch( endpoint, {
                method: 'GET',
            });

            if (response.ok) {
                const data = await response.json();
                setJackpots(data);
            } else {
                console.error('Failed to fetch jackpots data:', response.status);
            }
        } catch (error) {
            console.error('Error fetching jackpots data:', error);
        }
    }

    const getJackpots = () => {
        let jackpots:Array<any> = []
        let initialJp = data.jackpotsData

        initialJp.map((x:any, i:number) => {
            if(typeof x?.visible === "object" && x.visible.value === true) {
                let jp:any = {
                    name: x.name.value.text,
                    order: Number(x.order.value.value),
                    api_id: x.url.value.text,
                    visible: x.visible.value
                }

                jackpots.push(jp)
            }
        })

        setJackpotsData(jackpots.sort((a,b) => a.order - b.order))
    }
    
    if(route.asPath.search('/jackpots') > -1 && typeof document !== "undefined") {
        let jackpotBlock =  document.getElementById('jackpotBlock')
        jackpotBlock?.classList.add('addMarginTop')
        // console.log('jackpots page')
    }
    
    let skyJackpot = jackpots.find((x:any) => {
        return x.jackpot_name === 'Sky Jackpot'
    })

    let hotLuck = jackpots.find((x:any) => {
        return x.jackpot_name === 'Hot Luck'
    })

    let egyptQuest = jackpots.find((x:any) => {
        return x.jackpot_name === 'Egypt Quest'
    })

    let jackpotCards = jackpots.find((x:any) => {
        return x.jackpot_name === 'Jackpot Cards'
    })

    let cloverChance = jackpots.find((x:any) => {
        return x.jackpot_name === 'Clover Chance'
    })

    let bellLink = jackpots.find((x:any) => {
        return x.jackpot_name === 'Bell Link'
    })
    
    let highCash = jackpots.find((x:any) => {
        return x.jackpot_name === 'High Cash'
    })

    let regalJackpot = jackpots.find((x:any) => {
        return x.jackpot_name === 'Regal Jackpot'
    })

    const imageLoaded = (src:string) => {
        return new Promise((resolve, reject) => {
            const img = document.createElement('img');
            img.src = src;
            
            img.onload = () => {
                resolve(true);
            }
            
            img.onerror = () => {
                resolve(false);
            }
        })
    }
    
    const loadImages = async () => {
        
        let allImages = [
            "skyBg",
            "hotLuckBg",
            "egiptQuestBg",
            "jackpotCardsBg",
            "cloverBg",
            "bellLinkBg",
            "highCashBg",
            "regalJpBg",
        ]
        
        let arr = []
        for (let i = 0; i <= allImages.length - 1; i++) {
            await imageLoaded(`/spins/images/JackpotsLive/${allImages[i]}.webp`)
            arr.push(allImages[i])
            
            if(arr.length === allImages.length) {
                setImagesLoaded(true)
            }
            
        }
    }
    
    useEffect(() => {
        loadImages()
        getJackpots()
        fetchCredentials()
        
    }, [])
    
    useEffect(() => {
        if(jackpotsData.length){
            // setTimeout(() => {
            //     setIsLoaded(true)
            // }, 1000)
            
            setIsLoaded(true)
        }
        
    }, [jackpotsData, playJackpot]);
    
    // return null
    return jackpotsData.length ?(
        <div
            ref={elem}
            id={'jackpotBlock'}
            className={clsx(
                styles.sliderContainer,
                'theme-slider',
                'sliderContainer__jackpot',
                'slideClass',
                'liveJPCarousel'
            )}
        >

            <div className={clsx('gamesSlider-box', 'title_lifeJackpot')}>

                <h1 className={clsx('gamesSlider__title', 'theme_textColor_secondary')}>
                    {data.title}
                </h1>

                <div className={'buttons-wrapperSlide'}>

                    <div className={'arrowButtons-wrapperSlide'}>
                        <button
                            aria-label={"slide"}
                            disabled={isBeginning}
                            onClick={() => {
                                swiper.slidePrev()
                                setIsEnd(false)
                            }}
                            className={clsx('btnSlider-first', 'theme_btnSlider_arrow', isBeginning && 'locked')}
                        >
                            <span className={'theme__svg'}><ArrowRight/></span>
                        </button>

                        <button
                            aria-label={"slide"}
                            disabled={isEnd}
                            onClick={() => {
                                swiper.slideNext()
                                setIsBeginning(false)
                            }}
                            className={clsx('btnSlider-second', 'theme_btnSlider_arrow', isEnd && 'locked')}
                        >
                            <span className={'theme__svg'}><ArrowRight /></span>
                        </button>
                    </div>

                </div>
            </div>

            <Swiper
                pagination={{
                    clickable: true,
                }}
                modules={[Pagination, Navigation]}
                className={clsx(styles.slider, 'swiper-wrapper',  'swiperJackpotInner', 'swiper-init-padding')}
                spaceBetween={16}
                slidesPerView='auto'
                onSwiper={(sw:any) => {
                    setSwiper(sw)
                }}
                onReachBeginning={() => setIsBeginning(true)}
                onReachEnd={() => setIsEnd(true)}
                onSlideNextTransitionStart={() => {setIsBeginning(false)}}
                onSlidePrevTransitionStart={() => {setIsEnd(false)}}
            >

                <PaginationArrow  onClick={() => swiper.slideNext()}/>
                <PaginationArrow  onClick={() => swiper.slidePrev()} rightArrow />

                {/*{(!isLoaded || Object.keys(playJackpot).length === 0 ) && skeletonArray?.map((i) => {*/}
                
                {/*    let firstSlide: boolean = skeletonArray.indexOf(i) === 0*/}
                
                {/*    return (*/}
                {/*        <SwiperSlide*/}
                {/*            key={'key' + i}*/}
                {/*            className={clsx(styles.slider__slide, firstSlide && styles.first)}*/}
                {/*        >*/}
                {/*            <Skeleton*/}
                {/*                variant="rectangular"*/}
                {/*                className={'skeletonJpCard'}*/}
                {/*                animation="wave"*/}
                {/*            />*/}
                {/*        </SwiperSlide>*/}
                {/*    )*/}
                {/*})}*/}
                
                {jackpotsData.length ? jackpotsData?.map((jp: any, i:number) => {
                    let firstSlide: boolean = jackpotsData.indexOf(jp) === 0
                    switch(jp.name){

                        case "Clover Chance API":
                            return (cloverChance !== undefined && cloverChance.hasOwnProperty('levels'))  ? (
                                <SwiperSlide key={'jackpot' + i + jp.name} className={clsx(styles.slider__slide, firstSlide && styles.first )} >
                                    <JackpotCardNew
                                        api_id={jp.api_id} jackpot={cloverChance}  />
                                </SwiperSlide>
                            ) : (
                                <SwiperSlide
                                    key={'key' + i}
                                    className={clsx(styles.slider__slide, firstSlide && styles.first)}
                                >
                                    <Skeleton
                                        variant="rectangular"
                                        className={'skeletonJpCard'}
                                        animation="wave"
                                    />
                                </SwiperSlide>
                            )

                        case "Bell Link API":
                            return (bellLink !== undefined && bellLink.hasOwnProperty('levels'))  ? (
                                <SwiperSlide key={'jackpot' + i + jp.name} className={clsx( styles.slider__slide, firstSlide && styles.first )} >
                                    <JackpotCardNew
                                        api_id={jp.api_id}
                                        jackpot={bellLink}
                                    />
                                </SwiperSlide>
                            ) : (
                                <SwiperSlide
                                    key={'key' + i}
                                    className={clsx(styles.slider__slide, firstSlide && styles.first)}
                                >
                                    <Skeleton
                                        variant="rectangular"
                                        className={'skeletonJpCard'}
                                        animation="wave"
                                    />
                                </SwiperSlide>
                            )

                        case "Hot Luck API":
                            return (hotLuck !== undefined && hotLuck.hasOwnProperty('levels'))  ? (
                                <SwiperSlide key={'jackpot' + i + jp.name} className={clsx( styles.slider__slide, firstSlide && styles.first )} >
                                    <JackpotCardNew  api_id={jp.api_id} jackpot={hotLuck}  />
                                </SwiperSlide>
                            ) : (
                                <SwiperSlide
                                    key={'key' + i}
                                    className={clsx(styles.slider__slide, firstSlide && styles.first)}
                                >
                                    <Skeleton
                                        variant="rectangular"
                                        className={'skeletonJpCard'}
                                        animation="wave"
                                    />
                                </SwiperSlide>
                            )

                        case "High Cash API":
                            return (highCash !== undefined && highCash.hasOwnProperty('levels')) ? (
                                <SwiperSlide key={'jackpot' + i + jp.name} className={clsx( styles.slider__slide, firstSlide && styles.first )} >
                                    <JackpotCardNew  api_id={jp.api_id} jackpot={highCash}  />
                                </SwiperSlide>
                            ) : (
                                <SwiperSlide
                                    key={'key' + i}
                                    className={clsx(styles.slider__slide, firstSlide && styles.first)}
                                >
                                    <Skeleton
                                        variant="rectangular"
                                        className={'skeletonJpCard'}
                                        animation="wave"
                                    />
                                </SwiperSlide>
                            )

                        case "Jackpot Cards API":
                            return (jackpotCards !== undefined && jackpotCards.hasOwnProperty('levels')) ? (
                                <SwiperSlide key={'jackpot' + i + jp.name}  className={clsx( styles.slider__slide, firstSlide && styles.first )} >
                                    <JackpotCardNew api_id={jp.api_id} jackpot={jackpotCards}  />
                                </SwiperSlide>
                            ) : (
                                <SwiperSlide
                                    key={'key' + i}
                                    className={clsx(styles.slider__slide, firstSlide && styles.first)}
                                >
                                    <Skeleton
                                        variant="rectangular"
                                        className={'skeletonJpCard'}
                                        animation="wave"
                                    />
                                </SwiperSlide>
                            )
                        
                        case "Egypt Quest API":
                            return (egyptQuest !== undefined && egyptQuest.hasOwnProperty('levels')) ? (
                                <SwiperSlide key={'jackpot' + i + jp.name}  className={clsx( styles.slider__slide, firstSlide && styles.first )} >
                                    <JackpotCardNew  api_id={jp.api_id} jackpot={egyptQuest}  />
                                </SwiperSlide>
                            ) : (
                                <SwiperSlide
                                    key={'key' + i}
                                    className={clsx(styles.slider__slide, firstSlide && styles.first)}
                                >
                                    <Skeleton
                                        variant="rectangular"
                                        className={'skeletonJpCard'}
                                        animation="wave"
                                    />
                                </SwiperSlide>
                            )

                        case "Sky Jackpot API":
                            return skyJackpot  ? (
                                <SwiperSlide key={'jackpot' + i + jp.name}  className={clsx( styles.slider__slide, firstSlide && styles.first )} >
                                    <JackpotCardNew  api_id={jp.api_id} jackpot={skyJackpot}  />
                                </SwiperSlide>
                            ) : (
                                <SwiperSlide
                                    key={'key' + i}
                                    className={clsx(styles.slider__slide, firstSlide && styles.first)}
                                >
                                    <Skeleton
                                        variant="rectangular"
                                        className={'skeletonJpCard'}
                                        animation="wave"
                                    />
                                </SwiperSlide>
                            )

                        case "Play Jackpot API":
                            return playJackpot.hasOwnProperty('levels') ? (
                                <SwiperSlide key={'jackpot' + i + jp.name}  className={clsx( styles.slider__slide, firstSlide && styles.first )} >
                                    <JackpotCardDynamic  api_id={jp.api_id} jackpot={playJackpot}  />
                                </SwiperSlide>
                            ) : (
                                <SwiperSlide
                                    key={'key' + i}
                                    className={clsx(styles.slider__slide, firstSlide && styles.first)}
                                >
                                    <Skeleton
                                        variant="rectangular"
                                        className={'skeletonJpCard'}
                                        animation="wave"
                                    />
                                </SwiperSlide>
                            )

                        case "Regal Jackpot API":
                            return (regalJackpot !== undefined && regalJackpot.hasOwnProperty('levels')) ? (
                                <SwiperSlide key={'jackpot' + i + jp.name}  className={clsx( styles.slider__slide, firstSlide && styles.first )} >
                                    <JackpotCardNew  api_id={jp.api_id} jackpot={regalJackpot}  />
                                </SwiperSlide>
                            ) : (
                                <SwiperSlide
                                    key={'key' + i}
                                    className={clsx(styles.slider__slide, firstSlide && styles.first)}
                                >
                                    <Skeleton
                                        variant="rectangular"
                                        className={'skeletonJpCard'}
                                        animation="wave"
                                    />
                                </SwiperSlide>
                            )

                        default: return null
                    }
                }) : skeletonArray?.map((i) => {
                    
                    let firstSlide: boolean = skeletonArray.indexOf(i) === 0
                    
                    return (
                        <SwiperSlide
                            key={'key' + i}
                            className={clsx(styles.slider__slide, firstSlide && styles.first)}
                        >
                            <Skeleton
                                variant="rectangular"
                                className={'skeletonJpCard'}
                                animation="wave"
                            />
                        </SwiperSlide>
                    )
                })}

            </Swiper>
        </div>
    ) : null
}

export default LiveJackpots