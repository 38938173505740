import { FC } from 'react';
import CMS from "utils/cms";
import clsx from 'clsx';
import strings from "utils/strings";
import {useState} from 'react';
import {
    openDeposit,
    openDepositAfterLogin,
    openLoginModal,
    openSignupModal,
    updateCampaignId
} from "store/modals/actions";
import {useDispatch, useSelector} from 'react-redux';
import { useRouter } from 'next/router';
import {useEffect} from 'react';
import useAuth from "hooks/auth";
import styles from '../../styles/OldPromotions.module.scss'
import { useMediaQuery } from 'react-responsive';
import OldPromSidebar from '../../ui/promotions/oldPromSidebar'
import Button from 'themes/spins/small_ui/button'
import Chevrone from "@/template/svg/Chevrone";
import {ChevronLeft} from "@mui/icons-material";
import VerificationStatus from "@/AbsoluteComponents/interfaces/VerificationStatus";
import {smartico_visible} from "@/template/config/constants";
import SmarticoWidget from "@/template/cms-views/idPromotion/smartico_elems/smartico_widgets";
import SmarticoClass from "@/AbsoluteComponents/utils/smartico_class";
import SmarticoMiniGame from "@/AbsoluteComponents/interfaces/smartico_minigames";
import {updateSnackBar} from "@/AbsoluteComponents/store/common/actions";
import Page404 from "@/template/ui/page404";

interface Props {
    json: object;
    api_id: string;
}

const IdPromotions:FC<Props> = ({json,api_id}) => {
    const auth = useAuth();
    const triedToOpenDeposit:boolean = useSelector((state: any) => state.modals.depositAfterLogin);
    const router = useRouter();
    const opened:Array<VerificationStatus> = useSelector((state:any) => state.account.validation_opened);

    const dispatch = useDispatch();

    const data = CMS.getIdPromotion(json);
    const giftbox = auth.getSmarticoMiniGames().find((x:SmarticoMiniGame) =>
        x.saw_game_type === SmarticoClass.saw_game_types.giftbox
    )

    const giftbox_visible = typeof giftbox !== "undefined" && Boolean(Object.keys(giftbox).length)
    
    const [isShown, setIsShown] = useState(false);
    const [asideH, setAsideH] = useState<number | string>('unset')
    const [update, setUpdate] = useState(0);
    const [isImageLoaded, setIsImageLoaded] = useState<boolean>(false)

    const isMedia1 = useMediaQuery({
        query: '(max-width: 1024px)'
    });

    const oldPromotions: Array<any> = CMS.getOldPromotions();
    const expiredPromotions:Array<any> = CMS.getExpiredPromotions()

    const registerToContinue = () => {
        if (!auth.isLoggedIn()  && !auth.isUpdating()) {
            dispatch(openLoginModal(true));
        }else {
            router.push('/account/account-validation');

        }
    };

    const registerToPlay = () => {
        if (!auth.isLoggedIn()  && !auth.isUpdating()) {
            dispatch(openLoginModal(true));
        }else {
            router.push('/');

        }
    };
    const registerToDeposit = () => {

        if(data.bonus_id.length > 0) {
            dispatch(updateCampaignId(data.bonus_id))
        }

        if (!auth.isLoggedIn()  && !auth.isUpdating()) {
            dispatch(openLoginModal(true));
            dispatch(openDepositAfterLogin(true));
        }else {
            dispatch(openDeposit(true))
        }
    };

    const registerForGame = (path:string) => {
        if (!auth.isLoggedIn()  && !auth.isUpdating()) {
            dispatch(openLoginModal(true));
        }else {
            router.push('/'+path)

        }
    };


    const handleClick = () => {
        setIsShown(isShown => !isShown);
    };

    const imageLoaded = (src: any) => {
        return new Promise((resolve, reject) => {
            const img = document.createElement('img');
            img.src = src;

            img.onload = () => {
                resolve(true);
            }

            img.onerror = () => {
                resolve(false);
            }
        })
    }

    const loadImage = async () => {
        let arr = []

        await imageLoaded(CMS.getPublicFilePath(data.image[0].name, api_id, data.image[0].path)).then(() => {
            setIsImageLoaded(true)
        })

    }

    useEffect(() => {
        setUpdate(update + 1)
        loadImage()
    }, []);

    useEffect(() => {
        let aside = document.getElementById('pagePromId')
        //@ts-ignore

        if(isImageLoaded) {
            if(isMedia1) {
                setAsideH('unset')
            } else {
                //@ts-ignore
                setAsideH(aside.clientHeight)
            }
        }

    }, [isMedia1, router, isImageLoaded])

    useEffect(() => {
        if(auth.isLoggedIn() && triedToOpenDeposit) {
            dispatch(openDeposit(true));
        }
    }, [auth.isLoggedIn()])
    
    if(!data.web) {
        return <Page404 />
    }

    return (
        <div className={styles.main}>
            <div id={'pagePromId'} className={clsx(styles.main__promo_block, 'theme_text_mainColor')}>


                {data.image.length > 0 &&
                    <div className={styles.hero}>
                        <h1
                            className={clsx(styles.title)}>{data?.title}
                        </h1>
                        
                        <img
                            width={846}
                            height={256}
                            style={{width: '100%', height: 'auto'}}
                            className={clsx(
                                'idPromotions-image',
                                'promo__desktopImage',
                                styles.main__image
                            )}
                            src={CMS.getPublicFilePath(data.image[0].name, api_id, data.image[0].path)} alt="img"
                        />
                    </div>
                    
                }
                
                {data.mobile_image.length > 0 &&
                    <div className={styles.mobile_hero}>
                        <h1
                            className={clsx(styles.title)}>{data?.title}
                        </h1>
                        <img
                            width={680}
                            height={513}
                            style={{width: '100%', height: 'auto'}}
                            className={clsx(
                                'idPromotions-image',
                                'promo__mobileImage',
                                styles.main__mobile_image
                            )}
                            src={CMS.getPublicFilePath(data.mobile_image[0].name, api_id, data.mobile_image[0].path)}
                            alt="img"
                        />
                    </div>
                }
                
                <div className={styles.main__text_content}>
                    <p>{data.excerpt}</p>
                    
                    <div className={'idPromotions-content'} dangerouslySetInnerHTML={{__html: data.content}}/>
                    
                    <div className={styles.main__controllers}>
                        {((!auth.isLoggedIn() && data.show_validation_button) || (auth.isLoggedIn() && data.show_validation_button && opened)) &&
                            <div
                                className={'wrappButton'}
                                style={{textAlign: 'center'}}
                            >
                                <Button
                                    onClick={registerToContinue}
                                    className={clsx('idPromotions-btn')}
                                    text={strings['valideaza_contul']}
                                />
                            </div>
                        }
                        
                        {data.show_play_button &&
                            <div
                                className={'wrappButton'}
                                style={{textAlign: 'center'}}
                            >
                                <Button
                                    onClick={registerToPlay}
                                    className={clsx('idPromotions-btn')}
                                    text={'play'}
                                />
                            </div>
                        }
                        
                        {data.deposit_button &&
                            <div
                                className={'wrappButton'}
                                style={{textAlign: 'center'}}
                            >
                                <Button
                                    onClick={registerToDeposit}
                                    className={clsx('idPromotions-btn')}
                                    text={strings['depune_acum']}
                                />
                            </div>
                        }
                        
                        {(data.category_games_button && data.category_api_id.length > 0) &&
                            <div
                                className={'wrappButton'}
                                style={{textAlign: 'center'}}
                            >
                                
                                <Button
                                    onClick={() => registerForGame(data.category_api_id)}
                                    className={clsx('idPromotions-btn')}
                                    text={'DESCOPERĂ JOCURILE'}
                                />
                            
                            </div>
                        }
                        
                        {(data.play_game_button) &&
                            <div
                                className={'wrappButton'}
                                style={{textAlign: 'center'}}
                            >
                                
                                <Button
                                    onClick={() => router.push('/live-rtp')}
                                    className={clsx('idPromotions-btn')}
                                    text={'DESCOPERĂ LIVE RTP'}
                                />
                            
                            </div>
                        }
                        
                        {(data.create_account && !auth.isLoggedIn()) &&
                            <div
                                className={'wrappButton'}
                                style={{textAlign: 'center'}}
                            >
                                
                                <Button
                                    onClick={() => {
                                        dispatch(openSignupModal(true))
                                    }}
                                    className={clsx('idPromotions-btn')}
                                    text={'DESCHIDE CONT'}
                                />
                            
                            </div>
                        }
                        
                        {(data.smartico_tournament_id && smartico_visible) && (
                            <div style={{display: "flex", justifyContent: "center", width: "100%"}}>
                                <SmarticoWidget
                                    type={SmarticoClass.WidgetTypes.Tournaments}
                                    tournament_id={data.smartico_tournament_id}
                                    iframe_id={`smartico-${SmarticoClass.WidgetTypes.Tournaments}-${data.smartico_tournament_id}`}
                                    openWidget={() => {
                                        SmarticoClass.showWidget(
                                            SmarticoClass.WidgetTypes.Tournaments,
                                            `smartico-${SmarticoClass.WidgetTypes.Tournaments}-${data.smartico_tournament_id}`,
                                            data.smartico_tournament_id
                                        )
                                    }}
                                />
                            </div>
                        )}
                        
                        {(data.smartico_custom_section_id && smartico_visible) && (
                            <div style={{display: "flex", justifyContent: "center", width: "100%"}}>
                                
                                <SmarticoWidget
                                    type={SmarticoClass.WidgetTypes.Missions}
                                    tournament_id={data.smartico_tournament_id}
                                    iframe_id={`smartico-${SmarticoClass.WidgetTypes.Missions}-${data.smartico_mission_id}`}
                                    openWidget={() => {
                                        SmarticoClass.openCustomInlineSection(
                                            SmarticoClass.WidgetTypes.Missions,
                                            `smartico-${SmarticoClass.WidgetTypes.Missions}-${data.smartico_mission_id}`,
                                            600,
                                            data.smartico_custom_section_id
                                        )
                                    }}
                                />
                            </div>
                        )}
                        
                        {data.show_giftbox_btn &&
                            <div
                                className={'wrappButton'}
                                style={{textAlign: 'center'}}
                            >
                                
                                <Button
                                    onClick={() => {
                                        if(auth.isLoggedIn()) {
                                            if(typeof giftbox !== "undefined" && Boolean(Object.keys(giftbox).length)) {
                                                SmarticoClass.fwPopupMiniGame(String(giftbox.id))
                                            } else {
                                                dispatch(updateSnackBar("La moment nu puteți accesa oferta", "error", false))
                                            }
                                        } else {
                                            dispatch(openLoginModal(true))
                                        }
                                        
                                    }}
                                    disabled={auth.isLoggedIn() && !giftbox_visible}
                                    className={clsx('idPromotions-btn')}
                                    text={'DESCHIDE ACUM'}
                                />
                            
                            </div>
                        }
                        
                        {data.open_smartico_dashboard &&
                            <div
                                className={'wrappButton'}
                                style={{textAlign: 'center'}}
                            >
                                
                                <Button
                                    onClick={() => {
                                        if(auth.isLoggedIn()) {
                                            SmarticoClass.openDashboard()
                                        } else {
                                            dispatch(openLoginModal(true))
                                        }
                                    }}
                                    className={clsx('idPromotions-btn')}
                                    text={'DESCHIDE '+strings["play_hub"].toUpperCase()}
                                />
                            
                            </div>
                        }
                        
                        {data.open_smartico_leaderboard &&
                            <div
                                className={'wrappButton'}
                                style={{textAlign: 'center'}}
                            >
                                
                                <Button
                                    onClick={() => {
                                        if(auth.isLoggedIn()) {
                                            SmarticoClass.openLeaderboard()
                                        } else {
                                            dispatch(openLoginModal(true))
                                        }
                                    }}
                                    className={clsx('idPromotions-btn')}
                                    text={"VEZI CLASAMENTUL"}
                                />
                            
                            </div>
                        }
                        
                        {data.terms_and_conditions &&
                            <button
                                className={clsx(
                                    'idPromotions-wrapperBtn',
                                    isShown && styles.active,
                                    styles.terms_btn,
                                    'theme_text_mainColor'
                                )}
                                
                                onClick={handleClick}
                            >
                                <span
                                    className={'idPromotions-termsConditions'}
                                >
                                    {strings['termeni_și_condiții']}
                                </span>
                                
                                <ChevronLeft/>
                            </button>
                        }
                    
                    </div>
                    
                    
                    {data.terms_and_conditions &&
                        <>
                            {isShown &&
                                <p
                                    style={{marginTop: '24px'}}
                                    className={'idPromotions-termsConditions'}
                                    dangerouslySetInnerHTML={{__html: data.terms_and_conditions}}
                                />
                            }
                        </>
                    }
                </div>
            
            </div>
            
            {/*SIDEBAR*/}
            {oldPromotions.length ? (
                <OldPromSidebar isMedia1={isMedia1} oldPromotions={oldPromotions} isImageLoaded={isImageLoaded}/>
            ) : null}
            {/*SIDEBAR END*/}
        
        </div>
    )
}

export default IdPromotions