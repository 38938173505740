function ChangePasswordSpins() {
    return(
        <svg className={'svg-icon'} xmlns="http://www.w3.org/2000/svg" width="25" height="26" viewBox="0 0 25 26" fill="none">
            {/*<g filter="url(#filter0_di_912_83075)">*/}
                <path d="M6.30844 7.12345C6.04441 7.05405 5.82495 6.87138 5.70943 6.62484C5.59391 6.37831 5.59427 6.09339 5.7104 5.84715L7.74784 1.52689C7.89967 1.20494 8.22472 0.999562 8.58174 1C8.93876 1.00044 9.26329 1.20662 9.41432 1.52894L10.3131 3.4471C10.3453 3.43451 10.3784 3.4236 10.4126 3.41448C15.746 1.99061 21.228 5.14416 22.6571 10.4581C24.0862 15.7721 20.9211 21.2342 15.5877 22.6581C13.7683 23.1438 11.9316 23.0969 10.2444 22.6132C9.77398 22.4783 9.53754 21.9695 9.69367 21.5057C9.86039 21.0105 10.4075 20.7611 10.9127 20.8948C12.2448 21.2474 13.6846 21.268 15.1114 20.8871C19.4631 19.7253 22.0456 15.2685 20.8796 10.9327C19.7326 6.66773 15.386 4.10836 11.1024 5.13153L12.0093 7.06688C12.1603 7.3892 12.1105 7.76948 11.8816 8.04247C11.6528 8.31546 11.286 8.43187 10.9408 8.34113L6.30844 7.12345Z" fill="white"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M2 14C2 11.7909 3.79607 10 6.01163 10C7.53296 10 8.85552 10.8443 9.53544 12.087H15.7791C16.4534 12.087 17 12.632 17 13.3043V15.0435C17 15.3316 16.7657 15.5652 16.4767 15.5652H14.907V16.7826C14.907 17.0708 14.6727 17.3043 14.3837 17.3043H12.6395C12.3505 17.3043 12.1163 17.0708 12.1163 16.7826V15.5652H9.70434C9.09353 16.9961 7.67074 18 6.01163 18C3.79607 18 2 16.2091 2 14ZM6.01163 12.6087C5.241 12.6087 4.61628 13.2316 4.61628 14C4.61628 14.7684 5.241 15.3913 6.01163 15.3913C6.78226 15.3913 7.40698 14.7684 7.40698 14C7.40698 13.2316 6.78226 12.6087 6.01163 12.6087Z" fill="white"/>
            {/*</g>*/}
            <defs>
                <filter id="filter0_di_912_83075" x="-1" y="-1" width="28" height="28" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="1"/>
                    <feGaussianBlur stdDeviation="1"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_912_83075"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_912_83075" result="shape"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="1"/>
                    <feGaussianBlur stdDeviation="1"/>
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.6 0"/>
                    <feBlend mode="normal" in2="shape" result="effect2_innerShadow_912_83075"/>
                </filter>
            </defs>
        </svg>
    )
}
export default ChangePasswordSpins