import styles from "@/template/styles/PageTypeInformation.module.scss";
import {FC, useEffect} from 'react'
import clsx from 'clsx';
import { useRouter } from 'next/router'
import strings from "@/AbsoluteComponents/utils/strings";
import ArrowLeft from "@/template/svg/arrow-left";
import ArrowForwardSpins from "themes/spins/svg/arrowForwardSpins";
import {useDispatch, useSelector} from 'react-redux';
import {setFromAccPg} from "@/AbsoluteComponents/store/common/actions";
import VerificationStatus from "@/AbsoluteComponents/interfaces/VerificationStatus";



interface Props {
    title:string
    onClick():void
    infoPage?: boolean
}

const AccPageTitle:FC<Props> = ({title, onClick, infoPage}) => {
    const router = useRouter()
    const isValidation:boolean = router.pathname.search('account-validation') > -1
    const fromAccPg:boolean = useSelector((state:any) => state.common.fromAccPg)
    const dispatch = useDispatch()
    const opened:Array<VerificationStatus> = useSelector((state:any) => state.account.validation_opened);

    useEffect(() => {

        const onResize = () => {
            let btn = document.getElementById("acc_page_btn") as HTMLElement
            let text = document.getElementById("acc_page_title_text")
            let vw = window.innerWidth

            if(btn && text) {
                let btnTop = btn.getBoundingClientRect().top
                let textTop = text.getBoundingClientRect().top
                let gap = textTop - btnTop

                if(gap > 30) {
                    text.style.marginTop= "4px"

                    if(vw <= 1300) {
                        text.style.marginLeft= "4px"
                    } else {
                        text.style.marginLeft = "0"
                    }

                } else {
                    text.style.marginTop = "0"
                }
            }
        }

        onResize()

        window.addEventListener("resize", onResize)

        return () => {
            window.removeEventListener("resize", onResize)
        }

    }, []);



    return (
        <div className={clsx(styles.titleContainer, fromAccPg && styles.with_margin)}>
            {fromAccPg && (
                <div
                    onClick={() => {
                        dispatch(setFromAccPg(false))
                        router.back()
                    }}
                    className={clsx(styles.back_to_profile, 'btn-account')}
                >
                    <ArrowLeft />
                
                </div>
            )}
            
            <button
                id={'acc_page_btn'}
                onClick={onClick}
                type={'button'}
                className={clsx(styles.titleButton)}
            >
                
                
                <div className={clsx( 'btn-account', styles.btn_icon)}>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>

                <span className={clsx(styles.compartment_name, 'theme_white_dark_text')}>
                    {infoPage ? strings["util_details"] : strings['contul_meu']}
                </span>
            </button>
            
          
            <div className={clsx(styles.arrow_container, 'theme_text_mainColor')}>
                <ArrowForwardSpins />
            </div>

            <h2 id={'acc_page_title_text'} className={'theme_text_title_color'}>
                {title}
            </h2>
            
            {(isValidation && opened) && (
                <div className={clsx(styles.unverifiedWarning, 'theme_white_dark_text')}>
                    {strings['unverified_profile']}
                </div>
            )}

        </div>
    )
}

export default AccPageTitle
