import styles from '@/template/styles/selectDropDown.module.scss';
import {FC, useState, useEffect} from 'react';
import DropdownCaret from 'themes/default/svg/dropdownCaret';
import clsx from 'clsx';
import SelectorPortal from "./Portal";


interface Options {
    key:string,
    label:string,
    icon?:any,
    methodLogo?:any,
    card_types?:any
}

interface Props {
    label?:string,
    name?:string,
    labelIcon?:any,
    defaultValue?:string,
    options:Array<Options>,
    onOpen?(): void,
    onClose?(): void,
    onChange?(key:string): void,
    formatLabel?(options:Options): string,
    disableClickAwayListener?: boolean,
    inputProps?: any,
    className?: string,
    id?: string,
    noShadow?: boolean,
    bgStyle?:boolean,

}

const SelectDropDown: FC<Props> = ({
                                       label,
                                       labelIcon,
                                       options,
                                       name,
                                       onOpen,
                                       onClose,
                                       disableClickAwayListener,
                                       onChange,
                                       defaultValue,
                                       inputProps,
                                       className,
                                       formatLabel,
                                       id,
                                       noShadow,
                                       bgStyle
}) => {

    const [anchor, setAnchor] = useState<Element | null>(null);
    const [selectedKey, selectKey] = useState(options[0]?.key || '');
    
    
    
    const handleChange = (key:string) => {
        if (typeof onChange === "function") onChange(key);
        selectKey(key);
        setAnchor(null);
    };

    const option:Options | undefined = options.find((item:Options) => item.key === selectedKey);

    useEffect(() => {
        if (defaultValue) {
            handleChange(defaultValue);
        }

        if (options.length === 1) {
            handleChange(options[0].key);
        }
    }, []);

    useEffect(() => {
        if (Boolean(anchor)) {
            if (typeof onOpen === "function") onOpen();
        } else {
            if (typeof onClose === "function") onClose();
        }
    }, [anchor]);

    return (
        <>
            <div
                id={id}
                className={clsx(
                    styles.root,
                    bgStyle && 'bgStyleDropdownBonus',
                    Boolean(anchor) && styles.active,
                    option?.icon && styles.hasIcon,
                    className,
                    noShadow && styles.noShadow,
                    'theme_box_bg_shaded2',
                )}
                onClick={(e:any) => {
                    if (options.length > 1) setAnchor(e.currentTarget);
                }}
                style={{
                    marginRight: '12px'
                }}
            >
                <input
                    type="text"
                    style={{opacity: 0, position: 'absolute', pointerEvents: 'none'}}
                    name={name}
                    value={selectedKey}
                    onChange={() => {}} {...inputProps}

                />

                <div  className={clsx(styles.root__inner, 'theme_basic_input')}>

                    {option?.icon && <span className={styles.root__inner__icon}>{option?.icon}</span>}

                    <div>
                        {label !== undefined && <div className={styles.root__label}>{label}</div>}
                        <div className={styles.root__labelWrapper}>
                            <div className={clsx(styles.root__selected, 'theme_basic_input')}>
                                {(formatLabel && option) ? formatLabel(option) : (option?.label || '')} {option?.methodLogo}
                            </div>

                            {options.length > 1 && <span className={clsx(styles.root__caret, 'theme__svg')}><DropdownCaret/></span>}

                        </div>
                    </div>
                </div>
            </div>


            <SelectorPortal
                open={Boolean(anchor)}
                onClose={() => {setAnchor(null)}}
            >

                {options.length > 1 &&
                    <div
                        className={clsx(styles.root__options,
                            'theme_front_elements_bg',
                            'theme_front_element_border',
                        )}
                        style={{
                            width: anchor?.getBoundingClientRect()?.width || 0,
                            top: anchor?.getBoundingClientRect()?.top || 0,
                            // top:400,
                            left: anchor?.getBoundingClientRect()?.left || 0,
                        }}
                    >
                        {options.map((item:Options, i:number) => {
                            return (
                                <div
                                    key={`option-item-${i}-${item.key}`}
                                    onClick={() => handleChange(item.key)}
                                    className={clsx(styles.root__options__item,'theme_textColor_secondary' ,selectedKey === item.key &&  'themeItemBg_selected')}
                                >
                                    {item.icon && <span className={styles.root__options__item__icon}>{item.icon}</span>}
                                    
                                    {item.card_types ? (
                                        <div className={styles.specified}>
                                            <div>
                                                <span>{item.label}</span> {item.methodLogo}
                                            </div>
                                            
                                            {/*<div dangerouslySetInnerHTML={{__html: item.card_types}} />*/}
                                            {item.card_types}
                                            
                                        </div>
                                    ) : (
                                        <span>{item.label}</span>
                                    )}
                                    
                                    
                                </div>
                            );
                        })}
                    </div>
                }

            </SelectorPortal>

        </>
    );
}

export default SelectDropDown