import React, {FC, useEffect} from 'react';
import CMS from "utils/cms";
import styles from 'themes/spins/styles/themes.module.scss';
import gameStyles from 'themes/spins/styles/card.module.scss';
import ThemeItem from './ThemeItem';
import clsx from "clsx";
import {useRouter} from "next/router";
import { useDispatch} from 'react-redux'
import {setIsThemePage} from "@/AbsoluteComponents/store/common/actions";
interface Props {
    json:any;
    api_id:string;
}

const ThemesPage:FC<Props> = ({json, api_id}) => {
    const data = CMS.jsonThemesPage(json);
    const router = useRouter();
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setIsThemePage(true))

        return() => {
            dispatch(setIsThemePage(false))
        }
    }, []);

    //ADDED END
    return (
        <div className={'page-container'}>

            <h1 className={clsx(
                gameStyles.page_title,
                'page_titleThemes',
                'theme_textColor_secondary'
            )}>
                {data.title}
                <span className={gameStyles.page_title__count}>
                    {data.themes.length}
                </span>
            </h1>

            <div className={styles.grid}>
                {data.themes.map((item:any, i:number) => {
                    return (
                        <ThemeItem key={'theme' + i} apiId={api_id} item={item}/>
                    )
                })}
            </div>
        </div>
    )
};

export default ThemesPage