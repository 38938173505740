import clsx from "clsx";
import styles from "@/AbsoluteComponents/themes/spins/styles/Header.module.scss";
import React from "react";
import {useRouter} from 'next/router';


interface Props {
    primary_links:Array<any>
    className: string
}

const Navs:React.FC<Props> = ({primary_links, className}) => {
    const importantStyle = {height: 40, cursor: 'pointer'}
    const router = useRouter();

    return (
        <div className={clsx(
            styles.headerContainer__nav,
            'theme__container__bg__border',
            className && className
        )}>
            
            {primary_links.map((item: any, i: number) => {
                
                let isPage = router.asPath.search(item.path) > -1
                
                return item.visible ? (
                    <div
                        key={"nav_link"+i}
                        onClick={() => {
                            router.push(item.path)
                        }}
                        style={importantStyle}
                        className={clsx(
                            styles.headerContainer__list,
                            'header-page-list',
                            isPage && 'activePageHeader',
                            isPage && styles.active_link,
                            "theme_text_mainColor"
                        )}
                    >
                        <div style={{display:"flex", gap: "4px"}} className={clsx(styles.item, styles.link)}>
                            <span className={""}>{item.icon}</span>
                            <span
                                className={clsx('btn__outline', 'listBtn', 'theme__headerContainer__list', styles.item_text)}
                            >
                                {item.name}
                            </span>
                        </div>
                    </div>
                ) : null
            })}
            
        </div>
    )
}

export default Navs