function SportIconSpins() {
    return(
        <svg className={'svg-icon'} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            {/*<g clipPath="url(#clip0_934_57692)">*/}
                {/*<mask id="mask0_934_57692" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">*/}
                {/*    <rect width="24" height="24" fill="#D9D9D9"/>*/}
                {/*</mask>*/}
                {/*<g mask="url(#mask0_934_57692)">*/}
                    <path d="M2.0498 10.9748C2.13314 10.1248 2.30814 9.32064 2.5748 8.5623C2.84147 7.80397 3.20814 7.09147 3.6748 6.4248C4.25814 7.0248 4.74564 7.72064 5.1373 8.5123C5.52897 9.30397 5.78314 10.1248 5.8998 10.9748H2.0498ZM18.0998 10.9748C18.2165 10.1248 18.4665 9.30814 18.8498 8.5248C19.2331 7.74147 19.7248 7.0498 20.3248 6.4498C20.7915 7.0998 21.1581 7.80814 21.4248 8.5748C21.6915 9.34147 21.8665 10.1415 21.9498 10.9748H18.0998ZM3.6748 17.5498C3.20814 16.8998 2.84147 16.1956 2.5748 15.4373C2.30814 14.679 2.13314 13.8748 2.0498 13.0248H5.8998C5.78314 13.8748 5.52897 14.6915 5.1373 15.4748C4.74564 16.2581 4.25814 16.9498 3.6748 17.5498ZM20.3248 17.5498C19.7248 16.9498 19.2331 16.2581 18.8498 15.4748C18.4665 14.6915 18.2165 13.8748 18.0998 13.0248H21.9498C21.8665 13.8581 21.6915 14.6581 21.4248 15.4248C21.1581 16.1915 20.7915 16.8998 20.3248 17.5498ZM7.9248 10.9748C7.79147 9.77481 7.46647 8.66647 6.9498 7.6498C6.43314 6.63314 5.75814 5.71647 4.9248 4.8998C5.7248 4.0998 6.6373 3.45814 7.6623 2.9748C8.6873 2.49147 9.79147 2.18314 10.9748 2.0498V10.9748H7.9248ZM13.0248 10.9748V2.0498C14.2081 2.18314 15.3123 2.49147 16.3373 2.9748C17.3623 3.45814 18.2748 4.0998 19.0748 4.8998C18.2248 5.6998 17.5456 6.6123 17.0373 7.6373C16.529 8.6623 16.2081 9.77481 16.0748 10.9748H13.0248ZM10.9748 21.9498C9.77481 21.8165 8.66647 21.504 7.6498 21.0123C6.63314 20.5206 5.7248 19.8748 4.9248 19.0748C5.7748 18.2748 6.45397 17.3665 6.9623 16.3498C7.47064 15.3331 7.79147 14.2248 7.9248 13.0248H10.9748V21.9498ZM13.0248 21.9498V13.0248H16.0748C16.2081 14.2248 16.529 15.3373 17.0373 16.3623C17.5456 17.3873 18.2248 18.2998 19.0748 19.0998C18.2748 19.8998 17.3623 20.5415 16.3373 21.0248C15.3123 21.5081 14.2081 21.8165 13.0248 21.9498Z" fill="#929396"/>
                {/*</g>*/}
            {/*</g>*/}
            <defs>
                <clipPath id="clip0_934_57692">
                    <rect width="24" height="24" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}
export default SportIconSpins