function EyeIcon() {
    return(

        <svg className={'svg-icon'} xmlns="http://www.w3.org/2000/svg" width="27" height="24" viewBox="0 0 27 24" fill="none">

            <path d="M13.8295 4.5C11.2233 4.50141 8.67796 5.21755 6.52466 6.55521C4.37135 7.89286 2.70984 9.7901 1.75635 12C2.70867 14.2106 4.36991 16.1085 6.52349 17.4462C8.67707 18.7839 11.2231 19.4994 13.8295 19.4994C16.436 19.4994 18.982 18.7839 21.1355 17.4462C23.2891 16.1085 24.9504 14.2106 25.9027 12C24.9492 9.7901 23.2877 7.89286 21.1344 6.55521C18.9811 5.21755 16.4357 4.50141 13.8295 4.5ZM13.8295 17C12.7441 17 11.6831 16.7068 10.7807 16.1573C9.87819 15.6079 9.17481 14.827 8.75945 13.9134C8.34409 12.9998 8.23541 11.9945 8.44716 11.0245C8.65891 10.0546 9.18157 9.16373 9.94905 8.46447C10.7165 7.7652 11.6944 7.289 12.7589 7.09607C13.8234 6.90315 14.9268 7.00216 15.9296 7.3806C16.9324 7.75904 17.7895 8.3999 18.3925 9.22215C18.9955 10.0444 19.3173 11.0111 19.3173 12C19.3173 13.3261 18.7391 14.5979 17.71 15.5355C16.6808 16.4732 15.285 17 13.8295 17ZM13.8295 9C13.1783 9 12.5417 9.17595 12.0002 9.50559C11.4587 9.83524 11.0367 10.3038 10.7875 10.8519C10.5383 11.4001 10.4731 12.0033 10.6001 12.5853C10.7272 13.1672 11.0407 13.7018 11.5012 14.1213C11.9617 14.5409 12.5484 14.8266 13.1871 14.9424C13.8259 15.0581 14.4879 14.9987 15.0896 14.7716C15.6912 14.5446 16.2055 14.1601 16.5673 13.6667C16.9291 13.1734 17.1222 12.5933 17.1222 12C17.1222 11.2044 16.7753 10.4413 16.1578 9.87868C15.5403 9.31607 14.7028 9 13.8295 9Z" fill="#929396"/>

            <defs>
                <clipPath id="clip0_740_181380">
                    <rect width="26.3415" height="24" fill="white" transform="translate(0.658691)"/>
                </clipPath>
            </defs>
        </svg>
    )
}
export default EyeIcon;