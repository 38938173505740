import Modal from 'react-modal';
import {useDispatch, useSelector} from 'react-redux';
import {openVerificationModal} from 'store/modals/actions';
import useAuth from 'hooks/auth';
import {useState, useEffect} from 'react';
import {useRouter} from 'next/router';
import {getVerificationStatus} from "utils/cookies";
import {updateVerificationStatus} from "store/account/actions";
import VerificationStatus from "interfaces/VerificationStatus";
import styles from "@/template/styles/modals.module.scss";
import ModalLayout from "themes/spins/ui/modal_layout";
import clsx from 'clsx';
import strings from "utils/strings";
import Form from './Form'
import MessageIcon from "themes/spins/svg/messageIcon";
import EmailIcon from "themes/spins/svg/emailIcon";


function VerificationModal() {
    const [tab, setTab] = useState(1);
    const router = useRouter();

    const dispatch = useDispatch();
    const auth = useAuth();
    const open = useSelector((state:any) => state.modals.openVerification);
    const verificationStatus:Array<VerificationStatus> = useSelector((state:any) => state.account.verificationStatus);
    const logged:boolean = auth.isLoggedIn();

    useEffect(() => {
        dispatch(updateVerificationStatus(auth.verificationStatusFactory(getVerificationStatus())));
    }, []);

    useEffect(() => {
        const {modal} = router.query;
        let allVerified:boolean = true;

        verificationStatus.map(item => {
            if (!item.verified) allVerified = false;
        });

        if (logged && modal === 'verification' && !open && !allVerified) {
            dispatch(openVerificationModal(true));
        }
    }, [logged, verificationStatus, router.query]);

    const phoneVerification:VerificationStatus | undefined = verificationStatus.find(item => item.method === 'phone' && !item.verified);
    const emailVerification:VerificationStatus | undefined = verificationStatus.find(item => item.method === 'email' && !item.verified);

    const phoneHeaderText = 'Bun venit la Spin Casino! Pentru a începe să joci, te rugăm să îți verifici numărul de telefon.';
    const emailHeaderText = 'Bun venit la Spin Casino! Pentru a începe să joci, te rugăm să îți verifici e-mailul. Ți-am trimis deja un cod de verificare prin e-mail, introdu-l mai jos și apoi apasă "Verificare".';

    return (
        <Modal
            ariaHideApp={false}
            isOpen={(open && (Boolean(phoneVerification) || Boolean(emailVerification)))}
            // isOpen={true}
            onRequestClose={() => dispatch(openVerificationModal(false))}
            contentLabel="Example Modal"
            className={clsx(styles.verification_modal, 'theme_box_bg_shaded3')}
        >

            <ModalLayout withPadding title={strings['verification']} onClose={() => dispatch(openVerificationModal(false))}>

                {open &&
                    <div className={clsx("ReactModal__Inner__Content", styles.verification_modal__content)}>
                        {(phoneVerification && emailVerification) ?
                            (
                                <>
                                    {tab === 1 &&
                                        <p className={clsx(styles.verification_modal__info_text, styles.phone_welcome, 'theme_text_mainColor')}>
                                            {phoneHeaderText}
                                        </p>
                                    }

                                    {tab === 2 &&
                                        <p className={clsx(styles.verification_modal__info_text, styles.phone_welcome, 'theme_text_mainColor')}>
                                            {emailHeaderText}
                                        </p>
                                    }

                                    <div  className={clsx(styles.verification_modal__swith_container, 'theme_box_bg_shaded2')}>

                                        {phoneVerification &&
                                            <button
                                                type={'button'}
                                                className={clsx(styles.left, '','theme_active_switch_btn', tab === 1 && 'active')}
                                                onClick={() => setTab(1)}
                                            >
                                                <MessageIcon/>
                                                Codul primit prin SMS
                                            </button>
                                        }

                                        {emailVerification &&
                                            <button
                                                type={'button'}
                                                className={clsx(styles.right, 'theme_active_switch_btn', tab === 2 && 'active')}
                                                onClick={() => setTab(2)}
                                            >
                                                <EmailIcon />
                                                Codul primit prin email
                                            </button>
                                        }

                                    </div>
                                </>
                            ) : (
                                <>
                                    {phoneVerification !== undefined &&
                                        <p className={clsx(styles.verification_modal__info_text, styles.phone_welcome, 'theme_text_mainColor')}>
                                            {phoneHeaderText}
                                        </p>
                                    }

                                    {emailVerification !== undefined &&
                                        <p className={clsx(styles.verification_modal__info_text, styles.phone_welcome, 'theme_text_mainColor')}>
                                            {emailHeaderText}
                                        </p>
                                    }
                                </>
                            )
                        }

                        {(phoneVerification && emailVerification) ? (

                            <>
                                <div
                                    className={styles.verification_modal__form_block}
                                    style={{display: tab === 1 ? 'block' : 'none'}}
                                >
                                    <Form data={phoneVerification}/>
                                </div>

                                <div
                                    className={styles.verification_modal__form_block}
                                    style={{display: tab === 2 ? 'block' : 'none'}}
                                >
                                    <Form data={emailVerification}/>
                                </div>
                            </>

                        ) : (
                            <div className={styles.verification_modal__form_block}>

                                {phoneVerification && <Form data={phoneVerification}/>}
                                {emailVerification && <Form data={emailVerification}/>}

                            </div>
                        )}
                    </div>
                }
            </ModalLayout>

        </Modal>
        )
}

export default VerificationModal