
function InstagramSpins() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none">
            <path d="M16.0002 12.2256C13.607 12.2256 11.6669 14.1657 11.6669 16.559C11.6669 18.9522 13.607 20.8923 16.0002 20.8923C18.3935 20.8923 20.3336 18.9522 20.3336 16.559C20.3336 14.1657 18.3935 12.2256 16.0002 12.2256Z" fill="#929396"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M9.02629 4.6676C13.6238 4.15377 18.3767 4.15377 22.9742 4.6676C25.5054 4.9505 27.547 6.94487 27.8441 9.48494C28.3938 14.1849 28.3938 18.933 27.8441 23.633C27.547 26.1731 25.5054 28.1675 22.9742 28.4504C18.3767 28.9642 13.6238 28.9642 9.0263 28.4504C6.4951 28.1675 4.45351 26.1731 4.15642 23.633C3.60671 18.933 3.60671 14.1849 4.15642 9.48494C4.45351 6.94487 6.4951 4.9505 9.02629 4.6676ZM22.6669 8.55898C21.9305 8.55898 21.3336 9.15593 21.3336 9.89231C21.3336 10.6287 21.9305 11.2256 22.6669 11.2256C23.4033 11.2256 24.0002 10.6287 24.0002 9.89231C24.0002 9.15593 23.4033 8.55898 22.6669 8.55898ZM9.66691 16.559C9.66691 13.0612 12.5024 10.2256 16.0002 10.2256C19.498 10.2256 22.3336 13.0612 22.3336 16.559C22.3336 20.0568 19.498 22.8923 16.0002 22.8923C12.5024 22.8923 9.66691 20.0568 9.66691 16.559Z" fill="#929396"/>
        </svg>
    )
}

export default InstagramSpins