import strings from "utils/strings";
import Modal from 'react-modal';
import {useEffect} from 'react';
import {openSupport} from 'store/modals/actions';
import {useDispatch, useSelector} from 'react-redux';
import ContactForm from "@/template/ui/contactForm";
import ModalLayout from "@/template/ui/modal_layout";
import clsx from 'clsx';

const SupportForm = ({}) => {

    const dispatch = useDispatch();
    const open = useSelector((state:any) => state.modals.openSupport);

    useEffect(() => {
        if(open) {
            const root =  document.getElementsByTagName('html')[0];
            root.setAttribute('style', 'overflow-y: hidden');
        } else {
            const root =  document.getElementsByTagName('html')[0];
            root.removeAttribute('style');
        }
    })

    return (
        <Modal
            ariaHideApp={false}
            className={clsx('ReactModal__SM', 'theme_box_bg_shaded3')}
            isOpen={open}
            onRequestClose={() => dispatch(openSupport(false))}
            contentLabel="Example Modal"
        >
            <ModalLayout title={strings['assistance']} onClose={() => dispatch(openSupport(false))}>

                <>
                    <div style={{paddingBottom: '20px'}} className="ReactModal__Inner__Content">
                        <ContactForm publicForm={false} modal/>
                    </div>
                </>

            </ModalLayout>
        </Modal>
    )
}

export default SupportForm