import {showAccountSideBar, showGuestSideBar} from "store/common/actions";
import {useDispatch} from 'react-redux';
import {FC} from 'react';
import {useRouter} from 'next/router';
import TitleView from "@/template/ui/title";

interface Props {
    title: string,
    forGuest?: boolean,
    marginTitle?: boolean,
    homePage?:boolean;
    count?:number;
    h1?:boolean;
}

export const Title: FC<Props>  = ({title, forGuest, marginTitle, homePage, count, h1}) => {

    const dispatch = useDispatch();
    const router = useRouter();

    const clickHandle = () => {
        if(homePage) {
          router.push('/');
        } else if (forGuest) {
            dispatch(showGuestSideBar(true));
        } else {
            dispatch(showAccountSideBar(true));
        }
    }

    const titleProps = {
        title,
        forGuest,
        marginTitle,
        homePage,
        count,
        h1,
        clickHandle
    }

    return <TitleView { ... titleProps } />
}




























