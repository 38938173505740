import CMS from "@/AbsoluteComponents/utils/cms";
import {useEffect, useRef, useState} from "react";
import st from "@/template/styles/Ui.module.scss"
import clsx from "clsx";
import Close from "@/template/svg/close";
import styles from "@/template/styles/Ui.module.scss";
import strings from "@/AbsoluteComponents/utils/strings";
import process from "process";
import {useRouter} from "next/router";
import {DeviceType} from "@/AbsoluteComponents/interfaces/types";



const GetAppPopup = () => {
    const [src, set_src] = useState<any>({})
    const is_src = Boolean(typeof src === "object" && Boolean(Object.keys(src).length))
    
    const storage_name = "dwn_app_bar_exp_ts"
    const expire_minutes = process.env.NODE_ENV === "production" ? 240 : 0.2
    const raw_gen_settings = CMS.getGenSettingsJSON()
    const general_settings = CMS.getAllFields(raw_gen_settings)
    const {app_links, app_slogans, app_icon} = general_settings
    
    const getRandomObject = (arr:Array<any>) => {
        const randomIndex = Math.floor(Math.random() * arr.length);
        return arr[randomIndex];
    }
    
    const set_padding_top = (x:boolean) => {
        const main_container = document.getElementById("main_wrapper")

        if(main_container) {
            
            if(x) {
                main_container.setAttribute("data-attr", "dwn_app_bar")
                // main_container
            } else {
                main_container.removeAttribute("data-attr")
            }
        }
        
        const myCustomEvent = new CustomEvent('reset_search_overlay');
        window.dispatchEvent(myCustomEvent);
    }
    
    const refresh_ts = () => {
        let currentDate = new Date();
        let futureDate = new Date(currentDate.getTime() + (expire_minutes * 60000));
        localStorage.setItem(storage_name, futureDate.toISOString());
    }
    
    useEffect(() => {
        
        
        const device_spot = () => {
            
            let type:Array<any> = []
            
            let ts = localStorage.getItem(storage_name)
            const userAgent = navigator.userAgent.toLowerCase();
            
            if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
                type = ["App Store"]
            } else if (/Huawei/i.test(navigator.userAgent) && (userAgent.includes('huawei') || userAgent.includes('honor'))) {
                type = ["AppGallery"]
            } else if (userAgent.includes('android') && !userAgent.includes('huawei')) {
                type = ["Google Play", "Android"]
            }
            
            const handle_show_bar = () => {
                if(Array.isArray(app_links) && app_links.length && type.length) {
                    app_links.map((l:any) => {
                        if(type.includes(l.dwn_from_text) && l?.primary) {
                            set_src({...l, ...getRandomObject(app_slogans)})
                            set_padding_top(true)
                            refresh_ts()
                        }
                    })
                }
            }
            
            if(type.length > 0) {
                
                if(ts === null) {
                    handle_show_bar()
                    
                } else {
                    
                    const storedDate = new Date(ts);
                    const currentDate = new Date();
                    
                    if (storedDate < currentDate) {
                        handle_show_bar()
                        
                    }
                }
            }
        }
        
        device_spot()
        
        
    }, []);
    
    if(!is_src) {
        return null
    }
    
    return  (
        <div className={clsx(st.get_app_popup)}>
            
            <div className={st.inner}>
                
                <button
                    className={st.close_btn}
                    onClick={() => {
                        set_src({})
                        set_padding_top(false)
                    }}
                    aria-label={"close"}
                >
                    <Close/>
                </button>
                
                <div className={st.slogan}>
                    
                    <strong>
                        {strings["mobile_app"]}
                    </strong>
                    
                    <span>
                        {src?.slg && src?.slg}
                    </span>
                
                </div>
                
                <button
                    onClick={() => {
                        if(typeof src.store_url === "string" && src.store_url.length) {
                            window.open(src.store_url, "_blank")
                        }
                    }}
                    className={st.dwn_app_btn}
                >
                    {(Array.isArray(src.logo) && src.logo.length) ? (
                        <img src={CMS.getPublicFilePath(src.logo[0].name, "general_settings", src.logo[0].path)}
                             width={46}
                             height={46} alt={src.dwn_from_text}/>
                    ) : null}
                    
                    <div className={styles.inf}>
                        <strong>{strings["dwn_app"]}</strong>
                    </div>
                </button>
            
            </div>
        
        
        </div>
    )
}

export default GetAppPopup