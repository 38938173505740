

function MessageIcon() {
    return (
        <svg className={'svg-icon'} xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" >

            <path d="M8.25 11.5C8.53333 11.5 8.77083 11.4042 8.9625 11.2125C9.15417 11.0208 9.25 10.7833 9.25 10.5C9.25 10.2167 9.15417 9.97917 8.9625 9.7875C8.77083 9.59583 8.53333 9.5 8.25 9.5C7.96667 9.5 7.72917 9.59583 7.5375 9.7875C7.34583 9.97917 7.25 10.2167 7.25 10.5C7.25 10.7833 7.34583 11.0208 7.5375 11.2125C7.72917 11.4042 7.96667 11.5 8.25 11.5ZM12.25 11.5C12.5333 11.5 12.7708 11.4042 12.9625 11.2125C13.1542 11.0208 13.25 10.7833 13.25 10.5C13.25 10.2167 13.1542 9.97917 12.9625 9.7875C12.7708 9.59583 12.5333 9.5 12.25 9.5C11.9667 9.5 11.7292 9.59583 11.5375 9.7875C11.3458 9.97917 11.25 10.2167 11.25 10.5C11.25 10.7833 11.3458 11.0208 11.5375 11.2125C11.7292 11.4042 11.9667 11.5 12.25 11.5ZM16.25 11.5C16.5333 11.5 16.7708 11.4042 16.9625 11.2125C17.1542 11.0208 17.25 10.7833 17.25 10.5C17.25 10.2167 17.1542 9.97917 16.9625 9.7875C16.7708 9.59583 16.5333 9.5 16.25 9.5C15.9667 9.5 15.7292 9.59583 15.5375 9.7875C15.3458 9.97917 15.25 10.2167 15.25 10.5C15.25 10.7833 15.3458 11.0208 15.5375 11.2125C15.7292 11.4042 15.9667 11.5 16.25 11.5ZM2.25 22.5V4.5C2.25 3.95 2.44583 3.47917 2.8375 3.0875C3.22917 2.69583 3.7 2.5 4.25 2.5H20.25C20.8 2.5 21.2708 2.69583 21.6625 3.0875C22.0542 3.47917 22.25 3.95 22.25 4.5V16.5C22.25 17.05 22.0542 17.5208 21.6625 17.9125C21.2708 18.3042 20.8 18.5 20.25 18.5H6.25L2.25 22.5Z" />

        </svg>
    )
}
export default MessageIcon
