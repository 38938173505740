import styles from 'themes/spins/styles/card.module.scss';
import clsx from 'clsx';
import CloseIcon from "../../svg/closeicon";
import React, {useState, useEffect} from 'react';
import GameInterface from "interfaces/game";
import strings from "utils/strings";
import useAuth from "hooks/auth";
import {openMiniGameModal, openFullGameModal} from "store/modals/actions";
import { useRouter } from 'next/router';
import {useDispatch, useSelector} from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react';
import FavoriteButton from "./favoriteButton";
import Game from  'utils/game';
import PlayNowIconSpins from "themes/spins/svg/playNowIconSpins";
import CMS from 'utils/cms'
import Button from 'themes/spins/small_ui/button';
import useFavorite from 'hooks/favourite_games'
import FavoriteIconGame from 'themes/spins/svg/favoriteIconGame'
import DeleteFromFavorite from "themes/spins/svg/DeleteFromFavorite";

const PopUpFull= () => {

    const [jackpot, setJackpot] = useState<Array<any>>([]);

    const auth = useAuth();
    const dispatch = useDispatch();
    const fullList:Array<GameInterface> =  Game.getAll();

    const game:GameInterface | undefined = useSelector((state:any) => state.modals.openFullGame);
    const allProviders = CMS.jsonAllProvidersPages()
    const categories = game?.categories
    const fav = useFavorite()
    let isFav:boolean = game? fav.isFavorite(game) : false

    let localProvider;

    if (categories && categories.length > 0) {
        localProvider = allProviders.providers.find((prov: any) => game?.categories.includes(prov.category_id));
    }
    const hide:any = () => {
        dispatch(openFullGameModal(undefined))
    }

    const keepOverlay:any = (e:any) => {
        e.stopPropagation();
    }

    const jackpotEgt = async() => {
        if (game !== undefined) {
            const data:any = await Game.getJackpot(game)
            setJackpot(data)
        }
    }

    const router = useRouter();

    const [isTransition, setIsTransition] = useState<boolean>(false)

    useEffect(() => {
        setTimeout(() => {
            setIsTransition(true)
        }, 700)
    }, [])

    useEffect(() => {
        dispatch(openFullGameModal(undefined))
    }, [router.pathname])

    useEffect(() => {
        // let scrollPosition = 0;
        if(game){
            const root =  document.getElementsByTagName('html')[0];
            root.classList.add('bodyScrollHide');

        } else if(!game) {
            const root =  document.getElementsByTagName('html')[0];
            root.classList.remove('bodyScrollHide')
        }

    }, [game]);

    const recommendedGame = ():Array<GameInterface> => {
        const games:Array<GameInterface> = [];

        const idCategorie:string = '9d1c8035-4da3-4cce-991b-e0c643b3ea6e';

        fullList.map((item) => {
           const id = item.categories.find(i => i === idCategorie)

            if (id) {
                games.push(item)
            }
        })

        return games.length > 10 ? games.slice(0, 10) : games;

    }

    useEffect(() => {
        // jackpotEgt()

        let classList = window.document.body.classList

        if(game) {
            classList.add("hideZendesk")
        } else {
            classList.remove("hideZendesk")
        }

    }, [game]);

    return(
        <div style={{transition: `${isTransition && 'all 0.3s ease'}`}} className={clsx(styles.overlay,  game && styles.active)} onClick={hide}>
            <div style={{transition: `${isTransition && 'all 0.3s ease'}`}} className={clsx(styles.popup, 'themes__popup' , styles.popup__fullWrapper,  Boolean(game) && styles.active)} onClick={keepOverlay}>
                <div
                    style={{top:2}}
                    className={clsx(styles.popup__closeicon, 'themes__popup')}
                >
                    <span
                        style={{cursor: 'pointer'}}
                        onClick={() => dispatch(openFullGameModal(undefined))}
                        className={'theme__svg_white'}
                    >
                        <CloseIcon />
                    </span>
                </div>

                <div className={styles.thumbnailWrapper}>
                    <div  style={{maxWidth: 195, margin:'auto'}}><img style={{width: '100%'}} src={game?.thumbnail} alt={game?.friendlyName || "game"}/></div>

                    {localProvider !== undefined && (
                        <div className={styles.popup__providerImage}>

                            {!Boolean(localProvider.image_dark?.length) && (
                                <img src={localProvider['thumbnail_src']}  alt={localProvider['name']}/>
                            )}

                            {Boolean(localProvider.image_dark?.length) && (
                                <>
                                    <img data-hide-on-theme="dark" src={CMS.getPublicFilePath(localProvider.image_dark[0].name, 'slots_test', localProvider.image_dark[0].path)}  alt={localProvider['name']}/>
                                    <img data-hide-on-theme="light" src={localProvider['thumbnail_src']}  alt={localProvider['name']}/>
                                </>
                            )}
                        </div>
                    )}


                </div>
                <div className={clsx(styles.popup__btnFavorite, )}>
                    <div className={clsx(styles.popup__fullBlock, styles.popup__fullBlockWrapper)}>
                        {/*{ auth.isLoggedIn()  && (game !== undefined &&  <FavoriteButton game={game} styledIcon />)}*/}
                        <div className={clsx(styles.popup__fullGameName, 'theme_textColor_secondary')}>{game?.friendlyName}</div>
                        
                        {/*{(typeof game?.rtp === "string" && Number(game.rtp) > 0 && !game?.hotRTP && !game?.coldRTP) &&*/}
                        {/*    <div className={styles.card__subtitle}>RTP {(Number(game?.rtp) * 100).toFixed(2)}%</div>*/}
                        {/*}*/}
                    </div>

                    <Button
                        onClick={game ? () => auth.runGame(game, false) : () => {}}
                        className={clsx( styles.popup__btnSeeMore)}
                        text={strings['joaca']}
                        icon={<PlayNowIconSpins />}
                    />

                    <div
                        className={clsx(styles.favoriteDemoBtnWrap, auth.isLoggedIn() && styles.loggedIn)}
                    >
                        {auth.isLoggedIn() &&
                            <Button
                                onClick={() => {
                                    if(game) {
                                        fav.addToFavorites(game, isFav)
                                    }
                                }}
                                icon={isFav ? <DeleteFromFavorite /> : <FavoriteIconGame />}
                                outlined
                                disabled={fav.isUpdating()}
                                text={ !isFav ? strings['adauga_favorit'] : strings['delete_favorit']}
                                className={clsx('theme_bg_popup', styles.bgPopup)}
                            />

                        }

                        <Button
                            outlined
                            onClick={game ? () => auth.runGame(game, true) : () => {}}
                            text={'demo'}
                        />
                    </div>
                </div>
                { jackpot?.length > 0 && <div className={clsx(styles.popup__fullCategories, 'theme_borderTop')}>
                    {jackpot?.map((item: any, i: number) => {
                        return (
                            <div className={clsx(styles.popup__wrapperPlatinum, 'theme_wrapperPlatinum')} key={'jackpot ' + i}>
                                <div>
                                    <img className={clsx(styles.popup__iconFullBlock, )} src={item.image} alt="img"/>
                                    <span className={'theme_textColor_secondary'}>{item.label}</span>
                                </div>
                                <div className={styles.popup__currency}>
                                    <span className={styles.popup__prize}>{item.prize}</span>
                                    <span>{item.currency}</span>
                                </div>
                            </div>
                        )
                    })}
                </div>}

                {recommendedGame().length > 0 &&
                    <>
                        <h5 className={clsx(styles.popup__recommendedGame__BlockTitle,'theme_borderTop' ,'theme__headerContainer__list')}>{strings['jocuri_recomandate']}</h5>
                        <div className={styles.popup__recommendedGame__block}>
                            <Swiper
                                pagination={{
                                    clickable: true,
                                }}
                                slidesPerView='auto'
                            >
                                <div style={{display:'flex'}}>
                                    {recommendedGame().map((item) => {
                                        return (

                                            <SwiperSlide  key={'recomendedgameID-'+item.gameId}
                                                          className={styles.popup__recommendedGame}>
                                                <img onClick={() =>{
                                                    dispatch(openMiniGameModal(item));
                                                    dispatch(openFullGameModal(undefined))}
                                                } src={item.thumbnail} alt={item.friendlyName}/>
                                            </SwiperSlide>
                                        )
                                    })}
                                </div>
                            </Swiper>
                        </div>
                    </>
                }
            </div>
        </div>
    )
}
export default PopUpFull;