import style from 'themes/spins/styles/wheel.module.scss';
import React from 'react';
import {openWheelModal} from "store/modals/actions";
import { useDispatch } from 'react-redux'

interface Props {
    closePopup():void
}

const HaveParticipated: React.FC<Props> = ({ closePopup}) => {

    const dispatch = useDispatch()

    return (
        <div className={style.overlayContainer}>

            <div className={style.prisePopup} >
                <h1>
                    Ne cerem scuze, puteti participa inca o data doar dupa XX ore
                </h1>


                <button onClick={() => {dispatch(openWheelModal(false))}}>
                    ok
                </button>
            </div>
        </div>
    )
}

export default HaveParticipated