function LiveIcon () {
    return (
        <svg className={'svg-icon'} width="26" height="26" viewBox="0 0 26 26"  xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.5 9.74035V10.5295C12.0446 10.6842 11.6842 11.0446 11.5295 11.5H10.7398C10.5642 11.186 10.2284 10.9736 9.84307 10.9736C9.27606 10.9736 8.81641 11.4333 8.81641 12.0003C8.81641 12.5673 9.27606 13.027 9.84307 13.027C10.2287 13.027 10.5646 12.8144 10.7401 12.5H11.5296C11.6845 12.9552 12.0447 13.3154 12.5 13.4701V14.2612C12.1854 14.4367 11.9727 14.7727 11.9727 15.1585C11.9727 15.7255 12.4323 16.1852 12.9993 16.1852C13.5663 16.1852 14.026 15.7255 14.026 15.1585C14.026 14.7733 13.8138 14.4376 13.5 14.262V13.4699C13.955 13.3151 14.3151 12.955 14.4699 12.5H15.2605C15.436 12.8144 15.7719 13.027 16.1575 13.027C16.7245 13.027 17.1842 12.5673 17.1842 12.0003C17.1842 11.4333 16.7245 10.9736 16.1575 10.9736C15.7722 10.9736 15.4364 11.186 15.2608 11.5H14.4701C14.3154 11.0447 13.9552 10.6845 13.5 10.5296V9.73959C13.8138 9.56395 14.026 9.22829 14.026 8.84307C14.026 8.27606 13.5663 7.81641 12.9993 7.81641C12.4323 7.81641 11.9727 8.27606 11.9727 8.84307C11.9727 9.22884 12.1854 9.56491 12.5 9.74035ZM12.4473 11.9998C12.4473 11.6946 12.6946 11.4473 12.9998 11.4473C13.3049 11.4473 13.5523 11.6946 13.5523 11.9998C13.5523 12.3049 13.3049 12.5523 12.9998 12.5523C12.6946 12.5523 12.4473 12.3049 12.4473 11.9998ZM9.8418 11.9737V12.0269C9.82766 12.0263 9.81641 12.0146 9.81641 12.0003C9.81641 11.986 9.82766 11.9743 9.8418 11.9737ZM16.1842 12.0003C16.1842 12.015 16.1724 12.0269 16.1577 12.027V11.9736C16.1724 11.9737 16.1842 11.9856 16.1842 12.0003ZM13.026 15.1585C13.026 15.1732 13.0141 15.1852 12.9993 15.1852C12.9846 15.1852 12.9727 15.1732 12.9727 15.1585H13.026ZM12.9993 8.81641C13.014 8.81641 13.0258 8.82818 13.026 8.84277H12.9727C12.9728 8.82818 12.9847 8.81641 12.9993 8.81641Z"
            />
            
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.5 12C2.5 6.20101 7.20101 1.5 13 1.5C18.799 1.5 23.5 6.20101 23.5 12C23.5 17.799 18.799 22.5 13 22.5C7.20101 22.5 2.5 17.799 2.5 12ZM6.64529 4.93819C8.21302 3.52653 10.2533 2.62944 12.5 2.51293V5.20259C10.9939 5.31167 9.62339 5.91015 8.54689 6.83978L6.64529 4.93819ZM5.93819 5.64529C4.52653 7.21302 3.62944 9.25326 3.51293 11.5H6.20168C6.31098 9.99375 6.90977 8.62325 7.83971 7.54682L5.93819 5.64529ZM6.64559 19.0621C8.21328 20.4736 10.2534 21.3706 12.5 21.4871V18.7982C10.9938 18.6891 9.62329 18.0906 8.54676 17.1609L6.64559 19.0621ZM5.93846 18.355L7.8396 16.4539C6.90955 15.3773 6.31076 14.0065 6.20162 12.5H3.51293C3.62944 14.7469 4.52664 16.7873 5.93846 18.355ZM13.5 21.4871C15.747 21.3706 17.7874 20.4733 19.3552 19.0614L17.4535 17.1597C16.3771 18.0898 15.0064 18.6888 13.5 18.7981V21.4871ZM22.4871 12.5C22.3706 14.7465 21.4737 16.7865 20.0623 18.3542L18.1605 16.4524C19.0898 15.376 19.6882 14.0058 19.7972 12.5H22.4871ZM13.5 2.51293C15.7471 2.62945 17.7877 3.52683 19.3555 4.93892L17.4534 6.84103C16.377 5.91092 15.0064 5.31202 13.5 5.20267V2.51293ZM20.0626 5.64611L18.1604 7.54827C19.0896 8.62448 19.6879 9.99442 19.7972 11.5H22.4871C22.3706 9.25365 21.4738 7.21373 20.0626 5.64611ZM12.9994 6.18457C9.78743 6.18457 7.18359 8.78841 7.18359 12.0004C7.18359 15.2124 9.78743 17.8162 12.9994 17.8162C16.2114 17.8162 18.8153 15.2124 18.8153 12.0004C18.8153 8.78841 16.2114 6.18457 12.9994 6.18457Z"
            />
            
        </svg>
        
    )
}

export default LiveIcon