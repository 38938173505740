import {FC} from 'react';
import CloseIcon from "@/template/svg/closeicon";
import clsx from 'clsx';


interface Props {
    onClose?():void,
    children:JSX.Element
    title?: string
    titleClass?: string
    withPadding?: boolean
}

const ModalLayout:FC<Props> = ({onClose, children, title, titleClass, withPadding}) => {

    return (
        <>
            <div className="ReactModal__Inner">

                <div className={clsx("ReactModal__Inner__Header", withPadding && "modal_header_margin")}>

                    <h1 className={clsx('ReactModal__Title', titleClass, 'theme_headings')}>
                        {title}
                    </h1>

                    {typeof onClose === 'function' && (
                        <button
                            aria-label={"close"}
                            onClick={onClose}
                            className={clsx('ReactModal__Close', 'theme_white_dark_text')}
                        >
                            <CloseIcon />
                        </button>
                    )}

                </div>

                {children}

            </div>
        </>
    )

};

export default ModalLayout