import strings from "./strings";
import PaymentMethodStoredData from "../interfaces/paymentMethodStoredData";
import PaymentMethod from "../interfaces/paymentMethod";
import Offer from "../interfaces/offer";
import Balance from "../interfaces/balance";
import Statics from "@/template/config/statics";


export default class Payment {
    
    static is_new = true
    
    
    static translates:any = {
        'Card to Card': strings['cardToCard']
    };

    static icons:any = Statics.paymentIcons

    static iconsPayments:any = {
        'credit_card':
            <div>
                <img src="/spins/images/viza1.svg" alt="type-card"/>
                <img src="/spins/images/viza2.svg" alt="type-card"/>
                <img src="/spins/images/viza3.svg" alt="type-card"/>
                <img src="/spins/images/viza4.svg" alt="type-card"/>
                <img src="/spins/images/viza5.svg" alt="type-card"/>
                <img src="/spins/images/viza6.svg" alt="type-card"/>
            </div>,
        
        "debit_card":
            <div>
                <img src="/spins/images/viza1.svg" alt="type-card"/>
                <img src="/spins/images/viza2.svg" alt="type-card"/>
                <img src="/spins/images/viza3.svg" alt="type-card"/>
                <img src="/spins/images/viza4.svg" alt="type-card"/>
                <img src="/spins/images/viza5.svg" alt="type-card"/>
                <img src="/spins/images/viza6.svg" alt="type-card"/>
            </div>,

    };
    
    static logos: any = {
        "debit_card": <img src="/default/icons/pay_com.svg" alt="pay_com"/>,
        "credit_card": <img src="/default/icons/nuvei.svg" alt="nuvei" />
    }
    
    static iconsPaymentsTheme:any = Statics.paymentCardTypes

    static paymentMethodFactory = (json:any, whitdrawal?:boolean):PaymentMethod => {
        const storedData:Array<PaymentMethodStoredData> = [];


        if (Array.isArray(json['storedData'])) {
            json['storedData'].map((data:any) => {
                storedData.push({
                    reference: data.reference || '',
                    creditCardNum: data.creditCardNum || '',
                    description: data.description || '',
                    paymentMethodId: data.paymentMethodId || '',
                    cardEnding: data.cardEnding || '',
                    methodIcon: data.methodIcon || '',
                    phoneNumber: data.phoneNumber || ''
                });
            });
        }

        const customAmountList = [50, 100, 500, 1000, 2500, 5000];
        
        const method_simplified = ():string => {
            
            if(json?.trxType === 'card' && json?.integrationSystem === "pay_com") {
                return "debit_card"
                
            } else if (json?.trxType === 'card' && json?.integrationSystem === "safecharge") {
                return "credit_card"
                
            } else if (json['trxMethod'] === 'pay_safe_card' && json["trxType"] === "voucher") {
                return "pay_safe_card"
                
            } else if (json['trxType'] === 'pos') {
                return "cnp"
                
            } else if (json['trxType'] === 'wallet' && json["trxMethod"] === "aircash") {
                return "bank_transfer"
                
            } else if (json['trxType'] === 'voucher' && json["trxMethod"] === "abon") {
                return "voucher"
                
            } else return ""
            
        }

        return {
            methodType: typeof json['methodType'] === "string" ? json['methodType'] : '',
            methodName: whitdrawal ? (
                    typeof json['methodAssets'] === "object" ? (
                        this.translates[json['methodAssets']['name']] ?
                            this.translates[json['methodAssets']['name']] :
                            (json['methodAssets']['name'] === 'Card' || json['methodAssets']['name'] === "Card de credit" ? 'Retrage pe card'  : json['methodAssets']['name']  )
                    )   : ''
                ) : (
                    typeof json['methodAssets'] === "object" ? (
                        this.translates[json['methodAssets']['name']] ?
                            this.translates[json['methodAssets']['name']] :
                            (json['methodAssets']['name'] === 'Card' || json['methodAssets']['name'] === "Card de credit" ? 'Plata cu Cardul'  : json['methodAssets']['name']  )
                    )   : ''
                )
                ,
            methodLogo: this.is_new ? this.logos[method_simplified()] : this.logos[json['methodType']],
            methodIcon: this.is_new ? this.icons[method_simplified()] : this.icons[json['methodType']],
            methodIconsPayment: this.is_new ? this.iconsPayments[method_simplified()] : this.iconsPayments[json['methodType']],
            methodIconsPaymentTheme: this.is_new ? this.iconsPaymentsTheme[method_simplified()] : this.iconsPaymentsTheme[json['methodType']],
            feeValue: typeof json['fee'] === "object" ? Number(json['fee']['value']) : 0,
            taxValue: typeof json['tax'] === "object" ? Number(json['tax']['value']) : 0,
            feeType: typeof json['fee'] === "object" ? json['fee']['type'] : '',
            taxType: typeof json['tax'] === "object" ? json['tax']['type'] : '',
            feeInclude: typeof json['fee'] === "object" ? json['fee']['include'] : false,
            maxAmount: typeof json['maxAmount'] ? Number(json['maxAmount']) : 0,
            minAmount: typeof json['minAmount'] ? Number(json['minAmount']) : 0,
            method: typeof json['method'] === "string" ? json['method'] : '',
            storedData: storedData,
            // amountList: Array.isArray(json['amountList']) ? json['amountList'] : []
            amountList: customAmountList,
            trxMethod: typeof json['trxMethod'] === "string" ? json['trxMethod'] : '',
            trxType: typeof json['trxType'] === "string" ? json['trxType'] : '',
            integrationSystem: typeof json['integrationSystem'] === "string" ? json['integrationSystem'] : '',
            method_simplified: method_simplified()
        };
    };

    static offerFactory = (json:any):Offer => {
        
        // let minDepositAmount = typeof json['minDepositAmount'] === 'number' ? json['minDepositAmount'] : 0;
        
        // if (minDepositAmount === 0) undefined;
        
        const is_sport = () => {
            
            let hasFreeSpin: boolean = Array.isArray(json['freeSpin']) && json['freeSpin'].length > 0
            let hasExtraData:boolean = Array.isArray(json['freeSpin']) &&  json['freeSpin'].length > 0 ?  json['freeSpin'][0].hasOwnProperty('gameProviderExtraData') : false;
            let hasSport: boolean = (hasExtraData && hasExtraData) ? json['freeSpin'][0].gameProviderExtraData.hasOwnProperty('allowBetsBeforeEventStarts') : false
            
            if(hasFreeSpin && hasSport) {
                return true
            } else if (hasFreeSpin && !hasSport) {
                return false
            } else {
                return false
            }
        }
        
        return {
            campaignId: typeof json['campaignId'] === "string" ? json['campaignId'] : '',
            name: typeof json['name'] === "string" ? json['name'] : '',
            title: typeof json['title'] === "string" ? json['title'] : '',
            minDepositAmount: json['minDepositAmount'] ? Number(json['minDepositAmount']) || 0 : 0,
            freeSpin: Array.isArray(json['freeSpin']) ? json['freeSpin'] : [],
            promo: Array.isArray(json['promo']) ? json['promo'] : [],
            freeBonus: Array.isArray(json['freeBonus']) ? json['freeBonus'] : [],
            cash:Array.isArray(json['cash']) ? json['cash'] : [],
            timedFreeSpin: Array.isArray(json['timedFreeSpin']) ? json['timedFreeSpin'] : [],
            methods: Array.isArray(json['methods']) ? json['methods'] : [],
            is_sport: is_sport()
            
        };
    };

    static balanceFactory = (json:any):Balance => {
        let promo:number = 0;
        let cash:number = 0;
        let withdrawProgress:number = 0;
        let timedFreeSpins:number = 0

        let freeBets:number = 0;
        let freeSpins:number = typeof json['freeSpins'] === "number" ? json['freeSpins'] : 0;
        let grantedFreeSpins:number = typeof json['grantedFreeSpins'] === "number" ? json['grantedFreeSpins'] : 0;

        if (typeof json['promo'] === "number") promo = json['promo'];
        if (typeof json['bonus'] === "number") promo = json['bonus'] / 100;

        if (typeof json['cash'] === "number") cash = json['cash'];
        if (typeof json['balanceForWithdraw'] === "number") cash = json['balanceForWithdraw'] / 100;

        if (typeof json['withdrawProgress'] === "number") withdrawProgress = json['withdrawProgress'];
        if (typeof json['bonusProgress'] === "number") withdrawProgress = json['bonusProgress'];

        if (typeof json.wallet === "object") {
            const {accounts} = json.wallet;

            Object.keys(accounts).map((key:any) => {
                if (accounts[key] && accounts[key].type === 'freeSpins' && accounts[key].amount > 0) {
                    const isFreeBet:boolean = accounts[key]?.accountingExtraData?.isFreeBet || false;

                    if (isFreeBet) {
                        freeBets += accounts[key].amount;
                    }
                }
                
                if(accounts[key] && accounts[key].type === 'timedFreeSpins' && accounts[key].interval > 0) {
                    timedFreeSpins += accounts[key].interval
                }
            });
        }

        if (freeBets > 0 && freeSpins >= freeBets) freeSpins -= freeBets;
        if (freeBets > 0 && grantedFreeSpins >= freeBets) grantedFreeSpins -= freeBets;

        return {
            availableWithdrawal: typeof json['availableWithdrawal'] === "number" ? json['availableWithdrawal'] : 0,
            balance: typeof json['balance'] === "number" ? json['balance'] : 0,
            balanceForFreeSpinsWins: typeof json['balanceForFreeSpinsWins'] === "number" ? json['balanceForFreeSpinsWins'] : 0,
            cash: cash,
            currency: typeof json['currency'] === "string" ? json['currency'] : 'RON',
            currentTurnOver: typeof json['currentTurnOver'] === "number" ? json['currentTurnOver'] : 0,
            freeSpins: freeSpins,
            grantedFreeSpins: grantedFreeSpins,
            grantedFreeBonuses: typeof json['grantedFreeBonuses'] === "number" ? json['grantedFreeBonuses'] : 0,
            promo: promo,
            turnOver: typeof json['turnOver'] === "number" ? json['turnOver'] : 0,
            withdrawProgress: withdrawProgress,
            wallet: typeof json['wallet'] === "object" ? json['wallet'] : {},
            newWallet: typeof json['newWallet'] === "object" ? json['newWallet'] : {},
            freeBets: freeBets,
            timedFreeSpins: timedFreeSpins
        };
    };
}