import {FC, useState} from 'react';
import TextField from "ui/textfield";
import PhoneField from "ui/PhoneField";
import PasswordRules from "@/template/ui/passwordRules/rulesPassword";
import styles from '@/template/styles/signup.module.scss';
import Link from 'next/link';
import strings from 'utils/strings';
import clsx from 'clsx';


interface Props {
    errors:any
}

const StepOne:FC<Props> = ({errors}) => {
    const [password, setPassword] = useState<string>('')

    return (
        <div>
            <div className="mb-16">

                <TextField
                    type={'email'}
                    label={'Email'}
                    placeholder={'Email'}
                    className={styles.modal__field}
                    {...{
                        name: 'email',
                        autoComplete: 'off',
                        onChange: function(e:any) {
                            e.currentTarget.value = e.currentTarget.value.replace(/\s+/g, "");
                        }
                    }}
                />

                {errors['email'] &&
                    <p style={{paddingTop: 4}} className="errorMsg">
                        {errors['email']}
                    </p>
                }
            </div>

            <div className="mb-16">

                <PhoneField/>

                {errors['phoneNumber'] &&
                    <p style={{paddingTop: 4}} className="errorMsg">
                        {errors['phoneNumber']}
                    </p>
                }

            </div>

            <div className="mb-16">
                <TextField
                    type={'password'}
                    label={'Parola'}
                    className={styles.modal__field}
                    placeholder={'Parola'}
                    {...{
                        value: password,
                        name: 'password',
                        onChange: (e:any) => setPassword(e.target.value),
                    }}
                />

                {errors['password'] && <p style={{paddingTop: 4}} className="errorMsg">{errors['password']}</p>}
            </div>

            <PasswordRules password={password} passwordFocus={password.length > 0} passwordClass className={styles.modal__passwordRules} onError={msg => {/*console.log('msg', msg)*/}}/>

            <div className="mb-20">
                <label className="checkbox">
                    <input type="checkbox" name={'terms'} value={'agree'} defaultChecked/>

                    <span className={clsx("checkbox__toggle", "theme_checkbox_1")}/>

                    <span className={'terms_block'}>

                       <span  className={'responsalibity_text'}>
                           {strings['declare_on_my_own']}
                       </span>

                        <Link
                            href={'/terms-and-conditions'}
                            title={'Termenii și Condițiile'}
                            target={'_blank'}
                        >
                           <span className={clsx('terms_text', 'theme_white_dark_text')}> {strings['termeni_și_condiții']} , </span>
                        </Link>

                        <span className={'responsalibity_text'}>
                            {strings['as_well_as']}
                        </span>

                        <Link
                            href={'/privacy-policy'}
                            title={'Politica de Confidențialitate'}
                            target={'_blank'}
                        >
                            <span className={clsx('politics_text', 'theme_white_dark_text')}> {' '} {strings['politica_de_confidențialitate']}</span>
                        </Link>.

                    </span>

                </label>

                {errors['terms'] &&
                    <p style={{paddingTop: 4}} className="errorMsg">
                        {errors['terms']}
                    </p>
                }

            </div>
        </div>
    )
}

export default StepOne;