function MiddleBgSpinsLogo() {
    return(
        // <svg xmlns="http://www.w3.org/2000/svg" width="293" height="125" viewBox="0 0 293 125" fill="none">
        //     <path d="M-720 73.5V0.5H1011V73.5H290.101C281.907 73.5 274.058 76.7969 268.322 82.6478L241.845 109.654C232.536 119.149 219.797 124.5 206.499 124.5H86.5013C73.2031 124.5 60.4643 119.149 51.1547 109.654L24.6785 82.6478C18.9422 76.7968 11.0931 73.5 2.89923 73.5H-720Z" fill="#212632" stroke="#2D3543"/>
        // </svg>
        <svg xmlns="http://www.w3.org/2000/svg" width="258" height="105" viewBox="0 0 258 105" fill="none">
            <path d="M-736.5 55.5V-17.5H994.5V55.5H253.249C245.267 55.5 237.603 58.6294 231.901 64.2165L205.231 90.3535C195.978 99.4212 183.54 104.5 170.585 104.5H89.4155C76.4604 104.5 64.0217 99.4211 54.769 90.3535L28.0986 64.2165C22.3975 58.6294 14.7332 55.5 6.75073 55.5H-736.5Z" fill="#212632" stroke="#2D3543"/>
        </svg>
    )
}
export default MiddleBgSpinsLogo