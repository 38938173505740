import Modal from 'react-modal';
import React, { FC, useState, useEffect, useRef } from 'react';
import clsx from 'clsx';
import {useDispatch, useSelector} from 'react-redux';
import {openShareTIcket, setModalMiniGameId} from "store/modals/actions";
import {updateSnackBar, updateTicketId} from "store/common/actions";
import ModalLayout from "@/template/ui/modal_layout";
import st from "../../../styles/smartico_minigame_modal.module.scss"
import SmarticoClass from "@/AbsoluteComponents/utils/smartico_class";
import CircularProgress from "@mui/material/CircularProgress";


interface  Props {

}

const SmarticoMiniGameModal: FC<Props> = () => {
    const widget_id = useSelector((state:any) => state.modals.miniGameModalId)
    const dispatch = useDispatch()
    const iframe = useRef<any>(null)
    const progress = useRef<null>(null)
    let iframeId = `mini_game_modal_${widget_id}`
    
    useEffect(() => {
        if(widget_id) {
            
            const interval = setInterval(() => {
                if(iframe.current) {
                    SmarticoClass.openInlineMiniGame(widget_id, iframeId)
                    clearInterval(interval)
                    iframe.current.style.opacity = "1"
                }
            }, 200)
        }
        
        return() => {
            if(iframe.current) {
                iframe.current.style.opacity = "0"
            }
        }
        
    }, [widget_id, iframe]);
    
    
    return (
        <Modal
            ariaHideApp={false}
            isOpen={Boolean(widget_id)}
            className={clsx( 'ReactModal__Deposit', 'theme_box_bg_shaded3', st.modal)}
            onRequestClose={() => {
                dispatch(updateTicketId(''))
            }}
        >
            
            <ModalLayout  onClose={() => {dispatch(setModalMiniGameId(""))}} >
                <div className={st.container}>
                    <iframe
                        className={st.iframe}
                        ref={iframe}
                        id={iframeId}
                        width={700}
                        style={{
                            width: "100%",
                            height: "100%",
                            background: "none",
                            transition: "opacity 0.3s ease-in 1s",
                            opacity: 0,
                            position: "relative",
                            zIndex: 1
                        }}
                    />
                    
                    <div ref={progress} className={st.progress}>
                        <CircularProgress />
                    </div>
            
                </div>
            </ModalLayout>
        
        </Modal>
    )
}

export default SmarticoMiniGameModal