import * as actionTypes from './actions';
interface initialValues {
    uploadedFullWidthSlider: any
}

const initialState:initialValues = {
    uploadedFullWidthSlider: {},
};

const reducer = (state = initialState, action:any) => {
    switch (action.type) {
        case actionTypes.FULL_WIDTH_SLIDER_LOADED:
            return {...state, uploadedFullWidthSlider: {...state.uploadedFullWidthSlider, [action.payload]: true}};

        default: return state;
    }
}

export default reducer