function ArrowRight() {
    return (
        // <svg className={'svg-icon'} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        //     <path d="M11.3335 6.66671L20.6668 16L11.3335 25.3334" stroke="#84789C" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
        // </svg>

        <svg className={'svg-icon'} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            {/*<g id="arrow_forward_ios">*/}
                {/*<mask id="mask0_902_26285" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">*/}
                {/*    <rect id="Bounding box" width="24" height="24" transform="matrix(-1 0 0 1 24 0)" fill="white"/>*/}
                {/*</mask>*/}
                {/*<g mask="url(#mask0_902_26285)">*/}
                    <path id="arrow_forward_ios_2" d="M9.63711 2.8875C9.14696 2.39735 8.35227 2.39735 7.86211 2.8875C7.37196 3.37765 7.37196 4.17235 7.86211 4.6625L15.1996 12L7.86211 19.3375C7.37196 19.8277 7.37196 20.6223 7.86211 21.1125C8.35226 21.6027 9.14696 21.6027 9.63711 21.1125L18.7496 12L9.63711 2.8875Z" fill="#6C6F74"/>
                {/*</g>*/}
            {/*</g>*/}
        </svg>
    )
}

export default ArrowRight