const NameIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M10.7893 1C10.6065 1 10.4582 1.14826 10.4582 1.33115V3.86161C10.4582 4.71361 11.1489 5.40429 12.0009 5.40429C12.8529 5.40429 13.5435 4.71361 13.5435 3.86161V1.33115C13.5435 1.14826 13.3953 1 13.2124 1H10.7893ZM9.1591 3.15501H5.74219C5.1899 3.15501 4.74219 3.60272 4.74219 4.15501V22C4.74219 22.5523 5.1899 23 5.74219 23H18.259C18.8113 23 19.259 22.5523 19.259 22V4.15501C19.259 3.60272 18.8113 3.15501 18.259 3.15501H14.8426V3.76528C14.8426 5.33475 13.5703 6.60705 12.0009 6.60705C10.4314 6.60705 9.1591 5.33475 9.1591 3.76528V3.15501ZM11.9982 12.0675C12.5608 12.0675 13.017 11.6114 13.017 11.0487C13.017 10.4861 12.5608 10.0299 11.9982 10.0299C11.4355 10.0299 10.9794 10.4861 10.9794 11.0487C10.9794 11.6114 11.4355 12.0675 11.9982 12.0675ZM14.0192 12.5523C14.3319 12.1326 14.517 11.6123 14.517 11.0487C14.517 9.65765 13.3893 8.52995 11.9982 8.52995C10.6071 8.52995 9.47937 9.65765 9.47937 11.0487C9.47937 11.6133 9.66509 12.1344 9.97877 12.5545C9.03714 13.0605 8.39694 14.0549 8.39694 15.1987V16.8036C8.39694 17.3558 8.84466 17.8036 9.39694 17.8036H14.6051C15.1574 17.8036 15.6051 17.3558 15.6051 16.8036V15.1987C15.6051 14.0532 14.9631 13.0576 14.0192 12.5523ZM11.3969 13.6987H12.6051C13.4335 13.6987 14.1051 14.3703 14.1051 15.1987V16.3036H9.89694V15.1987C9.89694 14.3703 10.5685 13.6987 11.3969 13.6987ZM7.78601 20.3254C7.78601 19.9112 8.1218 19.5754 8.53601 19.5754H15.4519C15.8661 19.5754 16.2019 19.9112 16.2019 20.3254C16.2019 20.7397 15.8661 21.0754 15.4519 21.0754H8.53601C8.1218 21.0754 7.78601 20.7397 7.78601 20.3254Z"
                  fill="#9096A2"/>
        </svg>
    )
}

export default NameIcon