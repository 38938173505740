class JavaScriptChannels {
    static appOpenDepositScreen() {
        try {
            //@ts-ignore
            window.OPEN_DEPOSIT_SCREEN.postMessage('');
        } catch (e:any) {
            // console.log('JAVASCRIPT CHANNEL ERROR: ' + e.toString());
        }
    }

    static goBack() {
        try {
            //@ts-ignore
            window.GO_BACK.postMessage('');
        } catch (e:any) {}
    }

    static updateAvatarId(id:string) {
        try {
            //@ts-ignore
            window.UPDATE_AVATAR_ID.postMessage(id);
        } catch (e:any) {}
    }

    static setWebViewHeight(height:number) {
        try {
            //@ts-ignore
            window.SET_WEBVIEW_HEIGHT.postMessage(height);
        } catch (e:any) {}
    }
}

export default JavaScriptChannels;