import {FC} from 'react';
import {useSelector} from 'react-redux';
import VerificationStatus from "interfaces/VerificationStatus";
import useAuth from "hooks/auth";
import VerificationIndicatorView from "@/template/ui/VerificationIndicator";
import VerificationIndicatorViewInterface
    from "interfaces/ui-view-interfaces/verification_indicator_view_interface";

interface Props {
    mobile:boolean
    text?:string
    identity?:boolean
}

const VerificationIndicator:FC<Props> = ({mobile, text, identity}) => {

    const auth = useAuth();
    let allVerified:boolean = true;

    const verificationStatus:Array<VerificationStatus> = useSelector((state:any) => state.account.verificationStatus);

    verificationStatus.map(item => {
        if (!item.verified) allVerified = false;
    });

    const props:VerificationIndicatorViewInterface = {
        mobile,
        allVerified,
        text,
        identity
    }

    if (auth.isLoggedIn()) {
        return <VerificationIndicatorView { ... props } />
    }

    return null
}

export default VerificationIndicator