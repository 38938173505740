import clsx from "clsx";
import styles from "@/template/styles/modals.module.scss";
import ModalLayout from "@/template/ui/modal_layout";
import Modal from "react-modal";
import {useDispatch, useSelector} from "react-redux";
import {openResetPwdModal} from "@/AbsoluteComponents/store/modals/actions";
import TextField from "@/AbsoluteComponents/ui/textfield";
import {useEffect, useState} from "react";
import Button from "@/template/small_ui/button";
import {changePwd2} from "@/AbsoluteComponents/services/account";
import {setAuthToken} from "@/AbsoluteComponents/utils/cookies";
import useAuth from "@/AbsoluteComponents/hooks/auth";
import PasswordRules from "@/template/ui/passwordRules/rulesPassword";
import {useForm} from "react-hook-form";
import {passwordRules} from "@/AbsoluteComponents/utils/constants";
import strings from "@/AbsoluteComponents/utils/strings";
import {updateSnackBar} from "@/AbsoluteComponents/store/common/actions";
import CommonClass from "@/AbsoluteComponents/utils/common_class";
import {useRouter} from "next/router";

const ResetPwdModal = () => {
    
    const token = useSelector((state:any) => state.modals.openResetPwdModal)
    const [loading, setLoading] = useState<boolean>(false)
    const dispatch = useDispatch()
    const [pwd_state, set_pwd_state] = useState<string>("")
    const [passwordFocus, setPasswordFocus] = useState(true);
    
    const router = useRouter()
    
    const auth = useAuth()
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    
    const onSubmit = async (data:any) => {
        let pwd = data?.pwd

        if(typeof pwd === "string" && pwd.length) {
            setLoading(true)
            let res = await changePwd2(pwd, token)

            setLoading(false)
            
            if(!res.error) {
                dispatch(updateSnackBar(res?.message || strings["unknown_error"], "success", false))
                setAuthToken(res.response?.accessToken)
                let timeout = setTimeout(() => {
                    auth.updateProfile()
                    clearTimeout(timeout)
                }, 500)
                
                dispatch(openResetPwdModal(""))
            }
            
            if(res.error) {
                dispatch(updateSnackBar(res?.message || strings["unknown_error"], "error"))
                localStorage.removeItem(CommonClass.local_storage_items.change_pwd_token)
                router.replace({query: {modal: ""}})
            }
            
            localStorage.removeItem(CommonClass.local_storage_items.send_pin_info)
            
        }
    }
    
    
    return (
        <Modal
            ariaHideApp={false}
            className={clsx('ReactModal__SM', styles.reset_pwd_modal, 'theme_box_bg_shaded3')}
            isOpen={Boolean(token.length)}
            onRequestClose={() => {
                dispatch(openResetPwdModal(""))
            }}
            contentLabel="Example Modal"
        >
            <ModalLayout onClose={() => {dispatch(openResetPwdModal(""))}} title={'Resetează parola'}>
                
                <div className={clsx('ReactModal__Inner')}>
                    
                    <form onSubmit={handleSubmit(onSubmit)} >
                        <div className={styles.fields}>
                            
                            <TextField
                                type='password'
                                label={'Parola nouă'}
                                placeholder={'Parola'}
                                noMargin
                                {...register("pwd", {
                                    required: "Acest câmp trebuie completat",
                                    
                                    onChange: (e:any) => {
                                        set_pwd_state(e.target.value)
                                    },
                                    
                                    validate: {
                                        passwordFormat: (value) => {
                                            let result = true;
                                            
                                            passwordRules.map((item, i) => {
                                                if(!item['match']!(value)) {
                                                    result = false
                                                }
                                            })
                                            
                                            return result;
                                        }
                                    },
                                    
                                    onBlur: (e:any) => {
                                        setPasswordFocus(false)
                                    },
                                })}
                                onInputFocus={() => {
                                    setPasswordFocus(true)
                                }}
                            />
                            {errors.pwd?.type === "required" && (
                                <p className={styles.errortext}>
                                    {typeof errors?.pwd?.message === "string" ? errors.pwd.message : ""}
                                </p>
                            )}
                        </div>
                        
                        <div>
                            <PasswordRules
                                password={pwd_state}
                                passwordFocus={true}
                                passwordClass
                                className={styles.pwd_rules}
                            />
                            
                            {errors.pwd?.type === "passwordFormat" && (
                                <p className={styles.errortext}>
                                    {strings["continutul_parolei"]}
                                </p>
                            )}
                        </div>
                        
                        <Button loading={loading} type={"submit"} text={"Resetează"}/>
                        
                    </form>
                
                </div>
            </ModalLayout>
        
        </Modal>
    )
}

export default ResetPwdModal