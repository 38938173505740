import GameInterface from "../../interfaces/game";
import Winner from "../../interfaces/winner";
import Vendor from "../../interfaces/vendor";

export const UPDATE_ALL_GAMES_LIST = '@common/UPDATE_ALL_GAMES_LIST';
export const ALL_GAMES_LIST_IS_UPDATING = '@common/ALL_GAMES_LIST_IS_UPDATING';

export const UPDATE_SEARCH_VALUE = '@common/UPDATE_SEARCH_VALUE';
export const SHOW_SEARCH_BAR = '@common/SHOW_SEARCH_BAR';
export const APPLY_SEARCH_PROVIDERS_FILTER = '@common/APPLY_SEARCH_PROVIDERS_FILTER';
export const APPLY_SEARCH_SORT = '@common/APPLY_SEARCH_SORT';

export const UPDATE_LIVE_RTP = '@common/UPDATE_LIVE_RTP';
export const UPDATE_WINNERS_LIST = '@common/UPDATE_WINNERS_LIST';
export const UPDATE_FILTER_PROVIDERS_LIST = '@common/UPDATE_FILTER_PROVIDERS_LIST';

export const UPDATE_SNACKBAR = '@common/UPDATE_SNACKBAR';
export const REMOVE_SNACKBAR = '@common/REMOVE_SNACKBAR';

export const SAVE_CATEGORY_GAMES_CAROUSELS = '@common/SAVE_CATEGORY_GAMES_CAROUSELS';

export const UPDATE_PROVIDERS_NAMES = '@common/UPDATE_PROVIDERS_NAMES';
export const UPDATE_VENDORS_NAMES = '@common/UPDATE_VENDORS_NAMES';

export const SHOW_ACCOUNT_SIDE_BAR = '@common/SHOW_ACCOUNT_SIDE_BAR';

export const SHOW_TERMS_SIDE_BAR = '@common/SHOW_TERMS_SIDE_BAR'
export const SHOW_GUEST_SIDEBAR = '@common/SHOW_GUEST_SIDEBAR';
export  const SET_ENABLE_WHEEL = '@common/SET_ENABLE_WHEEL';
export const SET_TABLE_DATE = '@common/SET_TABLE_DATE';
export const IS_MOBILE_PLATFORM = '@common/IS_MOBILE_PLATFORM';


export const PROVIDER_FILTER_SLUG_PAGE = '@common/PROVIDER_FILTER_SLUG_PAGE';
export const FETURES_FILTER_SLUG_PAGE = '@common/FETURES_FILTER_SLUG_PAGE';
export const THEME_FILTER_SLUG_PAGE = '@common/THEME_FILTER_SLUG_PAGE';
export const VOLATILITY_FILTER_SLUG_PAGE = '@common/VOLATILITY_FILTER_SLUG_PAGE';
export const TAG_FILTER_SLUG_PAGE = '@common/TAG_FILTER_SLUG_PAGE';
export const UPDATE_PLAY_JACKPOT = '@common/UPDATE_PLAY_JACKPOT';
export const UPDATE_TICKET_ID = '@common/UPDATE_TICKET_ID';
export const UPDATE_BET_TICKETS = '@common/UPDATE_BET_TICKETS';
export const OPEN_ACCOUNT_SIDEBAR = '@common/OPEN_ACCOUNT_SIDEBAR';
export const UPDATE_QUEST_ENDED = '@common/UPDATE_QUEST_ENDED';
export const UPDATE_CASHOUTS = '@common/UPDATE_CASHOUTS';
export const NAVIGATE_TO_PENDING_TICKETS = '@common/NAVIGATE_TO_PENDING_TICKETS';
export const SET_IS_IN_APP = '@common/SET_IS_IN_APP';
export const SET_FOOTER_IN_VIEW = '@common/SET_FOOTER_IN_VIEW';
export const OPEN_INFO_SIDEBAR = '@common/OPEN_INFO_SIDEBAR';
export const OPEN_PAGINATION_BOTTOM = '@common/OPEN_PAGINATION_BOTTOM';
export const SET_BOTTOM_THIN = '@common/SET_BOTTOM_THIN';
export const SET_FROM_ACC_PG = '@common/SET_FROM_ACC_PG';
export const SET_IS_GUEST = '@common/SET_IS_GUEST';
export const SET_IS_CATEG = '@common/SET_IS_CATEG';
export const SET_MORE_PAGINATION = '@common/SET_MORE_PAGINATION';
export const SHOW_SCROLL_TO_TOP = '@common/SHOW_SCROLL_TO_TOP';
export const SET_CATEG_SLIDER_READY = '@common/SET_CATEG_SLIDER_READY';
export const SET_IS_THEME_PAGE = '@common/SET_IS_THEME_PAGE';
export const SET_IS_SPORT_PAGE = '@common/SET_IS_SPORT_PAGE';
export const UPDATE_RAFFLE_STATUS = '@common/UPDATE_RAFFLE_STATUS';
export const UPDATE_USER_RAFFLE_STATUS = '@common/UPDATE_USER_RAFFLE_STATUS';
export const SET_SAMSUNG_BROWSER = '@common/SET_SAMSUNG_BROWSER';
export const UPDATE_RAFFLE_WINNERS = '@common/UPDATE_RAFFLE_WINNERS';




export function setFooterInView(inView: boolean) {
    return {type: SET_FOOTER_IN_VIEW, payload: inView}
}
export function updRaffleStatus(status: any) {
    return {type: UPDATE_RAFFLE_STATUS, payload: status}
}

export function updRaffleWinners(winners: Array<any>) {
    return {type: UPDATE_RAFFLE_WINNERS, payload: winners}
}
export function setIsSamsungBrowser(x: boolean) {
    return {type: SET_SAMSUNG_BROWSER, payload: x}
}

export function updUserRaffleStatus(status: string) {
    return {type: UPDATE_USER_RAFFLE_STATUS, payload: status}
}

export function setIsSportPage(isSport: boolean) {
    return {type: SET_IS_SPORT_PAGE, payload: isSport}
}

export function setCategSliderReady(ready: boolean) {
    return {type: SET_CATEG_SLIDER_READY, payload: ready}
}

export function setIsThemePage(x: boolean) {
    return {type: SET_IS_THEME_PAGE, payload: x}
}

export function showScrollToTop(show: boolean) {
    return {type: SHOW_SCROLL_TO_TOP, payload: show}
}

export function setMorePagination(is: boolean) {
    return {type: SET_MORE_PAGINATION, payload: is}
}

export function setIsCategPage(is: boolean) {
    return {type: SET_IS_CATEG, payload: is}
}


export function setIsGuest(x: boolean) {
    return {type: SET_IS_GUEST, payload: x}
}

export function setFromAccPg(show: boolean) {
    return {type: SET_FROM_ACC_PG, payload: show}
}

export function setBottomThin(thin: boolean) {
    return {type: SET_BOTTOM_THIN, payload: thin}
}

export function openPaginationBottom(open: boolean) {
    return {type: OPEN_PAGINATION_BOTTOM, payload: open}
}

export function openInfoSidebar(inView: boolean) {
    return {type: OPEN_INFO_SIDEBAR, payload: inView}
}

export function updateCashouts(cashouts:Array<any>) {
    return {type: UPDATE_CASHOUTS, payload: cashouts}
}

export function openAccountSidebar(open:boolean) {
    return {type: OPEN_ACCOUNT_SIDEBAR, payload: open}
}

export function setIsInApp(inApp: boolean) {
    return {type: SET_IS_IN_APP, payload: inApp}
}

export function navigateToPendingTickets(value: boolean) {
    return {type: NAVIGATE_TO_PENDING_TICKETS, payload: value}
}

export function updateAllGamesList(games:Array<GameInterface>) {
    return {type: UPDATE_ALL_GAMES_LIST, payload: games}
}
export function updateQuestEnded(isEnded: boolean) {
    return {type: UPDATE_QUEST_ENDED, payload: isEnded}
}

export function updateTicketId(ticketId:string) {
    return {type: UPDATE_TICKET_ID, payload: ticketId}
}

export function updateBets(tickets:Array<any>) {
    return {type: UPDATE_BET_TICKETS, payload: tickets}
}

export function updatePlayJackpot(jp:any) {
    return {type: UPDATE_PLAY_JACKPOT, payload: jp}
}

export function allGamesListIsUpdating(updating:boolean) {
    return {type: ALL_GAMES_LIST_IS_UPDATING, payload: updating}
}

export function updateSearchValue(value:string) {
    return {type: UPDATE_SEARCH_VALUE, payload: value}
}

// export function updateLiveRTP({hourlyHot = [], hourlyCold = [], dailyHot = [], dailyCold = [] }) {
//     return {type: UPDATE_LIVE_RTP, payload: {hourlyHot, hourlyCold, dailyHot, dailyCold }}
// }

export function updateLiveRTP(rtp: any ) {
    return {type: UPDATE_LIVE_RTP, payload: rtp}
}


export const updateWinnersList = (list:Array<Winner>) => ({
    type: UPDATE_WINNERS_LIST,
    payload: list,
});

export function updateFilterProvidersList(list:Array<any>) {
    return {type: UPDATE_FILTER_PROVIDERS_LIST, payload: list}
}

export function showSearchBar(show:boolean) {
    return {type: SHOW_SEARCH_BAR, payload: show}
}

export function applySearchProvidersFilter(list:Array<any>) {
    return {type: APPLY_SEARCH_PROVIDERS_FILTER, payload: list}
}

//Slug page filters

export function providerFilterSlugPage(list:Array<any>) {
    return {type: PROVIDER_FILTER_SLUG_PAGE, payload: list}
}

export function featuresFilterSlugPage(list:Array<any>) {
    return {type: FETURES_FILTER_SLUG_PAGE, payload: list}
}

export function themeFilterSlugPage(list:Array<any>) {
    return {type: THEME_FILTER_SLUG_PAGE, payload: list}
}

export function volatilityFilterSlugPage(list:Array<any>) {
    return {type: VOLATILITY_FILTER_SLUG_PAGE, payload: list}
}

export function tagFilterSlugPage(list:Array<any>) {
    return {type: TAG_FILTER_SLUG_PAGE, payload: list}
}



export function applySearchSort(sort:string) {
    return {type: APPLY_SEARCH_SORT, payload: sort}
}

export function updateSnackBar(message:string, type:string, active:boolean = false, link_label?:string, link_function?: () => void) {
    return {type: UPDATE_SNACKBAR, payload: {message, type, active, link_label, link_function}}
}

export function  removeSnackBar(index:number) {
    return {type: REMOVE_SNACKBAR, payload: index}
}

export function saveCategoryGamesCarousels(rows:object) {
    return {type: SAVE_CATEGORY_GAMES_CAROUSELS, payload: rows}
}

export function updateProvidersNames(rows:object) {
    return {type: UPDATE_PROVIDERS_NAMES, payload: rows}
}

export function updateVendorsNames(rows:Array<Vendor>) {
    return {type: UPDATE_VENDORS_NAMES, payload: rows}
}

export function showAccountSideBar(show:boolean) {
    return {type: SHOW_ACCOUNT_SIDE_BAR, payload: show}
}

export function showTermsSideBar(show:boolean){
    return{ type: SHOW_TERMS_SIDE_BAR, payload: show }
}
export function showGuestSideBar(show:boolean) {
    return {type: SHOW_GUEST_SIDEBAR, payload: show}
}

export function setEnableWheel(enable: string) {
    return {type: SET_ENABLE_WHEEL, payload: enable}
}

export function isMobilePlatform(mob:boolean) {
    return {type: IS_MOBILE_PLATFORM, payload: mob}
}