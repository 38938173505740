import {FC, useState} from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from '../../styles/TopGamesSlider.module.scss';
import Card from 'themes/spins/ui/game/card';
import clsx from 'clsx';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import "swiper/css";
import "swiper/css/pagination";
import { Navigation } from "swiper";
import { Pagination } from "swiper";
import CMS from "utils/cms";
import GameInterface from "interfaces/game";
import Game from "utils/game";
import TopGamesNumber from "themes/spins/svg/TopGamesNumber";
import ArrowRight from "@/template/svg/arrow-right";

interface Props {
    json: Array<any>;
}

const TopGamesSlider: FC<Props> = ({ json }) => {
    const [swiper, setSwiper] = useState<any>();
    const [isEnd, setIsEnd] = useState<boolean>(false);
    const [isBeginning, setIsBeginning] = useState<boolean>(true);

    const [hover, setHover] = useState<any>(null);
    const games = Game.getAll();
    const data = CMS.jsonTopGameSlider(json);

    const getGameInfo = (gameId:string):GameInterface | undefined => {
        return games?.find((item:any) => item.gameId === gameId);
    };

    return (
        <div  data-section="top-games-slider" data-section-title='Top 10 Păcănele' className={clsx(styles.topGamesSlider, 'slideClass', 'theme-slider', 'topGameThemeSlider')}>
            <div className={styles.topGamesSlider__box}>
                <div className={'gamesSlider__title'}>
                    <div className={clsx(styles.topGamesSlider__title, 'theme_textColor_secondary')}>{data.title}</div>
                </div>

                <div className={'buttons-wrapperSlide'}>
                    <div className={'arrowButtons-wrapperSlide'}>
                        <button
                            aria-label={"slide"}
                            disabled={isBeginning}
                            onClick={() => {
                                swiper.slidePrev()
                                setIsEnd(false)
                            }}
                                className={clsx('btnSlider-first', 'theme_btnSlider_arrow', isBeginning && 'locked')}>
                            <span className={'theme__svg'}><ArrowRight/></span>
                        </button>
                        <button
                            aria-label={"slide"}
                            disabled={isEnd}
                            onClick={() => {
                                swiper.slideNext()
                                setIsBeginning(false)
                            }}

                            className={clsx('btnSlider-second', 'theme_btnSlider_arrow', isEnd && 'locked')}>
                            <span className={'theme__svg'}><ArrowRight /></span>
                        </button>
                    </div>
                </div>

            </div>

            <Swiper pagination={{
                clickable: true,
            }}
                modules={[Pagination, Navigation]}
                className={clsx(styles.themes__mySwiper,  'swiper-wrapper','swiper-style')}
                spaceBetween={0}
                slidesPerView='auto'
                onSwiper={(sw) => setSwiper(sw)}
                onReachBeginning={() => setIsBeginning(true)}
                onReachEnd={() => setIsEnd(true)}
                onSlideNextTransitionStart={() => {setIsBeginning(false)}}
                onSlidePrevTransitionStart={() => {setIsEnd(false)}}

            >
                {/*<PaginationArrow onClick={() => swiper.slideNext()}/>*/}
                {/*<PaginationArrow onClick={() => swiper.slidePrev()} rightArrow />*/}

                {data.games.map((item:any, i:number) => {
                    const game = getGameInfo(item.game_id);

                    if (game) {
                        return (
                            <SwiperSlide key={`top_games-${i}`} className={clsx(styles.topGamesSlider__swiper_item, 'topGamesSlider__swiper_item')} style={{width: 400, zIndex: data.games.length - i}}>
                                <div className={clsx(styles.topGamesSlider__block, hover === i && styles.hover)} >
                                    <div className={styles.hoverEffect}></div>
                                    
                                    <TopGamesNumber className={clsx(styles.topGamesSlider__index, i + 1 === 10 && styles.last)} number={i + 1} />

                                    <div
                                        className={styles.topGamesSlider__card}
                                        onMouseEnter={() => setHover(i)}
                                        onMouseLeave={() => setHover(null)}
                                    >
                                        <Card
                                            page={'live-casino'} width={228} game={game}
                                            providerName={'hideSpanTopBlock'}
                                            demoButtonStyle={'styleButtonDemo'}
                                            className={'topGameBlock'}
                                            fromTop
                                        />
                                    </div>
                                </div>

                            </SwiperSlide>
                        )
                    }
                })}

            </Swiper>

        </div>
    )
}

export default TopGamesSlider