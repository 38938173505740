import styles from 'themes/spins/styles/card.module.scss';
import clsx from 'clsx';
import CloseIcon from "../../svg/closeicon";
import React, {useState, useEffect} from 'react';
import GameInterface from "interfaces/game";
import {FC} from "react";
import strings from "utils/strings";
import {useDispatch, useSelector} from 'react-redux';
import {openMiniGameModal, openFullGameModal} from "store/modals/actions";
import { useRouter } from 'next/router';
import useAuth from "hooks/auth";
import FavoriteButton from "./favoriteButton";
import PlayNowIconSpins from 'themes/spins/svg/playNowIconSpins'
import Game from "@/AbsoluteComponents/utils/game";
import CMS from "@/AbsoluteComponents/utils/cms";


const PopUpMini = () => {
    const auth = useAuth();
    const hide:any = () => {
        dispatch(openMiniGameModal(undefined))
    }
    const keepOverlay:any = (e:any) => {
        e.stopPropagation();
    }

    const [isTransition, setIsTransition] = useState<boolean>(false)
    const [provider, setProvider] = useState<any>({})



    useEffect(() => {
        setTimeout(() => {
            setIsTransition(true)
        }, 700)
    }, [])

    const router = useRouter();

    const game:GameInterface | undefined = useSelector((state:any) => state.modals.openMiniGame);
    const allProviders = CMS.jsonAllProvidersPages()
    const categories = game?.categories

    let localProvider;

    if (categories && categories.length > 0) {
        localProvider = allProviders.providers.find((prov: any) => game?.categories.includes(prov.category_id));
    }

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(openMiniGameModal(undefined))

    }, [router.pathname])



    useEffect(() => {
        let scrollPosition = 0;
        if(game){
            const root =  document.getElementsByTagName('html')[0]

            scrollPosition = window.pageYOffset;
            root.style.overflow = 'hidden';
            root.style.top = `-${scrollPosition}px`
            root.classList.add('bodyScrollHide')

        } else if(!game) {
            const root =  document.getElementsByTagName('html')[0]
            root.classList.remove('bodyScrollHide')

            root.style.removeProperty('overflow');

            }

        }, [game])


    return(
        <div style={{transition: `${isTransition && 'all 0.3s ease'}`}} className={clsx(styles.overlay, game && styles.active, )} onClick={hide}>
        <div style={{transition: `${isTransition && 'all 0.3s ease'}`}} className={clsx(styles.popup, 'themes__popup','clickedBtn', 'themes__popup' , Boolean(game) && styles.active)} onClick={keepOverlay}>
            {/*<div className={clsx(styles.popup)}>*/}

                <div className={styles.popup__wrapper}>
                    <div className={clsx(styles.popup__closeicon, 'themes__popup')}
                         onClick={() => dispatch(openMiniGameModal(undefined))}>

                        <span className={'theme__svg_white'}><CloseIcon /></span>
                    </div>
                    <div className={clsx(styles.popup__imageWrapper)}>
                        <div><img src={game?.thumbnail} alt={game?.friendlyName || "Game"}/></div>


                        {localProvider !== undefined && (
                            <div className={styles.popup__providerImage}>

                                {!Boolean(localProvider.image_dark?.length) && (
                                    <img src={localProvider['thumbnail_src']}  alt={localProvider['name']}/>
                                )}

                                {Boolean(localProvider.image_dark?.length) && (
                                    <>
                                        <img data-hide-on-theme="dark" src={CMS.getPublicFilePath(localProvider.image_dark[0].name, 'slots_test', localProvider.image_dark[0].path)}  alt={localProvider['name']}/>
                                        <img data-hide-on-theme="light" src={localProvider['thumbnail_src']}  alt={localProvider['name']}/>
                                    </>
                                )}
                            </div>
                        )}

                        {/*<div className={styles.popup__providerImage}>*/}
                        {/*    <img src={game?.providerImage} alt="img"/>*/}
                        {/*</div>*/}

                    </div>

                    <div className={styles.popup__gameNameBlock}>
                        <div className={styles.popup__gameName}>
                            {/*{game !== undefined &&  <FavoriteButton game={game} styledIcon />}*/}
                            {/*{ auth.isLoggedIn()  && (game !== undefined &&  <FavoriteButton game={game} styledIcon />)}*/}
                            <div className={'theme_textColor_secondary'}>{game?.friendlyName}</div>
                            {/*{(typeof game?.rtp === "string" && Number(game.rtp) > 0 && !game?.hotRTP && !game?.coldRTP)  &&*/}
                            {/*    <div className={styles.card__subtitle}>RTP {(Number(game?.rtp) * 100).toFixed(2)}%</div>*/}
                            {/*}*/}
                        </div>

                        {game &&
                            <button onClick={() => auth.runGame(game, false)}
                                    className={clsx("btn__primary", styles.popup__btnSeeMore)}>
                                <PlayNowIconSpins />
                                {strings['joaca']}
                            </button>

                        }

                        {(game?.demoAvailable) && <div className={clsx(styles.favorite_block, styles.favoriteDemoBtnWrap)} onClick={() => auth.runGame(game, true)}>
                            { auth.isLoggedIn() &&  <div className={clsx('theme_bg_popup', styles.bgPopup)}>
                                {/*<FavoriteFalseIcon />*/}
                                {auth.isLoggedIn() && (game !== undefined && <FavoriteButton game={game} styledIcon/>)}
                                <span
                                    className={clsx('theme_textColor_secondary', styles.favoriteText)}>{strings['adauga_favorit']}</span>
                            </div>
                            }

                            <button
                                    className={clsx(styles.popup__demoBtn,'theme_bg_popup')}
                                    type={'button'}>
                                <span className={'theme_textColor_secondary'}>Demo</span>
                                    {/*<img className={styles.popup__demoBtnArrow}*/}
                                    {/*src="/default/icons/menuArrow.svg" alt="img"/>*/}
                            </button>
                        </div>}
                    </div>

                </div>
                <div className={clsx(styles.popup__seeMoreWrapper, 'theme_borderTop')}
                     onClick={() => {
                         dispatch(openFullGameModal(game))
                         dispatch(openMiniGameModal(undefined))
                     }}
                >
                    <button

                        className={clsx(styles.popup__seeMoreBtn, 'theme__headerContainer__list')}>{strings['jocuri_recomandate']}

                    </button>
                </div>

            </div>
        </div>
    )
}
export default PopUpMini;