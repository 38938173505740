import strings from "@/AbsoluteComponents/utils/strings";
import { Josefin_Sans } from '@next/font/google'
import QuestLeaderboardInterface from "@/AbsoluteComponents/interfaces/quest_leaderboard_interface";

export const statusesText = {
    'win': 'Câștigătoare',
    'loss': 'Pierzătoare',
    'inProgress': 'În progres',
    'cancelled': 'Anulat',
    'pending': "În așteptare"
};

// export const location = window.location.href

export const textColor = {
    successColor: '#229C0F',
    primaryLightColor: '#ff006E04',
    errorColor: '#FF0000',
    pendingColor: '#B05A0A'
};

export const bgColor = {
    successColor: 'rgba(0, 110, 4, 0.10)',
    primaryLightColor: '#ff006E04',
    errorColor: 'rgba(224, 32, 32, 0.10)',
    pendingColor: 'rgba(176, 90, 10, 0.10)'
};

export const statusesColor = {
    win: {
        text:textColor.successColor,
        bg:bgColor.successColor
    },

    loss: {
        text:textColor.errorColor,
        bg:bgColor.errorColor
    },

    pending: {
        text:textColor.pendingColor,
        bg:bgColor.pendingColor
    }
};


const statuses = [

    {
        key: 'pending',
        label: 'În așteptare'
    },
    {
        key: 'ok',
        label: 'Aprobat'
    },
    {
        key: 'cancelled',
        label: 'Anulat'
    },
    {
        key: 'rejected',
        label: 'Respins'
    },
    {
        key: 'error',
        label: 'Eroare'
    }

];

export const DepositStatusesColor = {
    ok: {
        text:textColor.successColor,
        bg:bgColor.successColor
    },

    rejected: {
        text:textColor.errorColor,
        bg:bgColor.errorColor
    },

    cancelled: {
        text:textColor.errorColor,
        bg:bgColor.errorColor
    },

    error: {
        text:textColor.errorColor,
        bg:bgColor.errorColor
    },

    pending: {
        text:textColor.pendingColor,
        bg:bgColor.pendingColor
    }
};

export const passwordRules = [
    {
        'match': function (text:string):boolean {
            return text.match(/^.{6,}$/) !== null;
        },

        'description': strings['6_caractere']
    },

    {
        'match': function(text:string):boolean {
            return text.match(/[a-z]/) !== null;
        },
        
        'description': strings['o_litera_mica']
    },

    {
        'match': function(text:string):boolean {
            return text.match(/[A-Z]/) !== null;
        },

        'description': strings['o_majuscula']
    },

    {
        'match': function(text:string):boolean {
            return text.match(/[?=.*\d]/) !== null;
        },

        'description': strings['o_cifra']
    },
];

export const wheel_visible = true

export const has_sport = true

export const bingo_path = '/bingo/widget/mini-bingo'

export const hardcoded_quest = false

export const smartico_visible = true

export const hardcoded_quest_json:QuestLeaderboardInterface = {
    "leader": {
        "nickname": "ca*******89",
        "points": 447531,
        "level": 25,
        "levelName": "25"
    },
    "leaderBoard": {
        "0": {
            "pointsNeeded": 0,
            "restPlayers": "1000+"
        },
        "1": {
            "pointsNeeded": 150,
            "restPlayers": "1000+"
        },
        "2": {
            "pointsNeeded": 600,
            "restPlayers": "100+"
        },
        "3": {
            "pointsNeeded": 1500,
            "restPlayers": "100+"
        },
        "4": {
            "pointsNeeded": 3000,
            "restPlayers": "100+"
        },
        "5": {
            "pointsNeeded": 5250,
            "restPlayers": "100+"
        },
        "6": {
            "pointsNeeded": 7500,
            "restPlayers": "10+"
        },
        "7": {
            "pointsNeeded": 10500,
            "restPlayers": "10+"
        },
        "8": {
            "pointsNeeded": 13500,
            "restPlayers": "10+"
        },
        "9": {
            "pointsNeeded": 17250,
            "restPlayers": "10+"
        },
        "10": {
            "pointsNeeded": 21750,
            "restPlayers": "10+"
        },
        "11": {
            "pointsNeeded": 27000,
            "restPlayers": "9"
        },
        "12": {
            "pointsNeeded": 33000,
            "restPlayers": "10+"
        },
        "13": {
            "pointsNeeded": 40500,
            "restPlayers": "7"
        },
        "14": {
            "pointsNeeded": 49500,
            "restPlayers": "6"
        },
        "15": {
            "pointsNeeded": 60000,
            "restPlayers": "1"
        },
        "16": {
            "pointsNeeded": 72000,
            "restPlayers": "1"
        },
        "17": {
            "pointsNeeded": 87000,
            "restPlayers": "1"
        },
        "18": {
            "pointsNeeded": 105000,
            "restPlayers": "4"
        },
        "19": {
            "pointsNeeded": 126000,
            "restPlayers": "3"
        },
        "20": {
            "pointsNeeded": 150000,
            "restPlayers": "2"
        },
        "21": {
            "pointsNeeded": 180000,
            "restPlayers": "0"
        },
        "22": {
            "pointsNeeded": 212050,
            "restPlayers": "0"
        },
        "23": {
            "pointsNeeded": 250000,
            "restPlayers": "0"
        },
        "24": {
            "pointsNeeded": 300000,
            "restPlayers": "0"
        },
        "25": {
            "pointsNeeded": 360000,
            "restPlayers": "1"
        },
        "26": {
            "pointsNeeded": 450000,
            "restPlayers": "0"
        },
        "27": {
            "pointsNeeded": 570000,
            "restPlayers": "0"
        },
        "28": {
            "pointsNeeded": 720000,
            "restPlayers": "0"
        },
        "29": {
            "pointsNeeded": 900000,
            "restPlayers": "0"
        },
        "30": {
            "pointsNeeded": 1200000,
            "restPlayers": "0"
        }
    },
    "maxLevelTop": {
        "first": {
            "nickname": "ca*******89",
            "level": 25,
            "points": 447531,
            "reachedAt": "2024-06-18T16:15:03.213Z"
        },
        "second": {
            "nickname": "23",
            "level": 20,
            "points": 166399,
            "reachedAt": "2024-05-10T23:25:19.931Z"
        },
        "third": {
            "nickname": "so*ek",
            "level": 20,
            "points": 160772,
            "reachedAt": "2024-05-30T11:29:41.865Z"
        }
    },
    "state": {
        "endTime": "2024-07-01T07:00:00.000Z",
        "status": "Ended"
    },
    "apiFail": false
}


export const info_icon = `<svg  width="20" height="20" class="svg-icon" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24">
            <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-.001 5.75c.69 0 1.251.56 1.251 1.25s-.561 1.25-1.251 1.25-1.249-.56-1.249-1.25.559-1.25 1.249-1.25zm2.001 12.25h-4v-1c.484-.179 1-.201 1-.735v-4.467c0-.534-.516-.618-1-.797v-1h3v6.265c0 .535.517.558 1 .735v.999z"/></svg>
    `