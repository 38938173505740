import {FC} from 'react';
import TextField from "ui/textfield";
import clsx from 'clsx';
import styles from "@/template/styles/signup.module.scss";
import strings from 'utils/strings';

interface Props {
    errors:any
}

const StepTwo:FC<Props> = ({errors}) => {
    
    

    return (
        <div >
            <div className={clsx('row', styles.row)}>
                <div className={clsx('col-sm-6 col-12', styles.col, 'signUp_filed')}>
                    <div className="mb-16">
                        
                        <TextField
                            type={'text'}
                            label={'Nume de utilizator'}
                            placeholder={'Nume de utilizator'}
                            className={styles.modal__field}
                            {...{
                                name: 'reg_nickname',
                                autoComplete: 'off'
                            }}
                        />
                        
                        {errors['nickname'] && <p style={{paddingTop: 4}} className="errorMsg">{errors['nickname']}</p>}
                    </div>
                </div>
                
                <div className={clsx('col-sm-6 col-12', styles.col, 'signUp_filed')}>
                    <div className="mb-16">
                        <TextField
                            type={'text'}
                            label={'Prenume'}
                            placeholder={'Prenume'}
                            className={styles.modal__field}
                            {...{
                                name: 'firstName',
                                autoComplete: 'off'
                            }}
                        />
                        
                        {errors['firstName'] &&
                            <p style={{paddingTop: 4}} className="errorMsg">{errors['firstName']}</p>}
                    </div>
                </div>
                
                <div className={clsx('col-sm-6 col-12', styles.col, 'signUp_filed')}>
                    <div className="mb-16">
                        <TextField
                            type={'text'}
                            label={'Numele de familie'}
                            placeholder={'Numele de familie'}
                            className={styles.modal__field}
                            {...{
                                name: 'lastName',
                                autoComplete: 'off'
                            }}
                        />
                        
                        {errors['lastName'] && <p style={{paddingTop: 4}} className="errorMsg">{errors['lastName']}</p>}
                    </div>
                </div>
                
                {/*<div className={clsx('col-sm-6 col-12', styles.col, 'signUp_filed')}>*/}
                {/*    <div className="mb-16">*/}
                {/*        <TextField*/}
                {/*            type={'text'}*/}
                {/*            label={'Poreclă'}*/}
                {/*            placeholder={'Poreclă'}*/}
                {/*            className={styles.modal__field}*/}
                {/*            {...{*/}
                {/*                name: 'nickName',*/}
                {/*                autoComplete: 'off'*/}
                {/*            }}*/}
                {/*        />*/}
                {/*        */}
                {/*        {errors['nickName'] && <p style={{paddingTop: 4}} className="errorMsg">{errors['nickName']}</p>}*/}
                {/*    </div>*/}
                {/*</div>*/}
                
                <div className={clsx('col-sm-6 col-12', styles.col, 'signUp_filed')}>
                    <div className="mb-16">
                        <TextField
                            type={'text'}
                            label={'Adresa străzii'}
                            placeholder={'Adresa străzii'}
                            className={styles.modal__field}
                            {...{
                                name: 'address',
                                autoComplete: 'off'
                            }}
                        />
                        
                        {errors['address'] && <p style={{paddingTop: 4}} className="errorMsg">{errors['address']}</p>}
                    </div>
                </div>
                
                <div className={clsx('col-sm-6 col-12', styles.col, 'signUp_filed')}>
                    <div className="mb-16">
                        <TextField
                            type={'text'}
                            label={'Localitate'}
                            placeholder={'Localitate'}
                            className={styles.modal__field}
                            {...{
                                name: 'city',
                                autoComplete: 'off'
                            }}
                        />
                        
                        {errors['city'] && <p style={{paddingTop: 4}} className="errorMsg">{errors['city']}</p>}
                    </div>
                </div>
                
                <div className={clsx('col-sm-6 col-12', styles.col, 'signUp_filed')}>
                    <div className="mb-30">
                        <TextField
                            type={'text'}
                            label={'Cod numeric personal'}
                            placeholder={'Cod numeric personal'}
                            className={styles.modal__field}
                            {...{
                                name: 'cnp',
                                autoComplete: 'off'
                            }}
                        />
                        
                        {errors['cnp'] && <p style={{paddingTop: 4}} className="errorMsg">{errors['cnp']}</p>}
                    </div>
                </div>
            </div>
            
            <hr className={'form-delimiter mb-16'}/>
            
            <div className="mb-16">
                <TextField
                    type={'text'}
                    label={'Cod promotional'}
                    placeholder={'Cod promotional'}
                    className={styles.modal__field}
                    {...{
                        name: 'promoCode',
                        autoComplete: 'off',
                        maxLength: 20
                    }}
                />
                
                {errors['promoCode'] && <p style={{paddingTop: 4}} className="errorMsg">{errors['promoCode']}</p>}
            </div>
            
            <div className={clsx("mb-16", 'first-checkbox')}>
                <label className="checkbox">
                <input type="checkbox" name={'receiveEmail'} value={'agree'} defaultChecked/>
                    <span className="checkbox__toggle"/>
                    <span style={{fontSize: '13px'}} className={'theme_text_mainColor'} >{strings['bonus_email']}.</span>
                </label>
                {errors['receiveEmail'] && <p style={{paddingTop: 4}} className="errorMsg">{errors['receiveEmail']}</p>}
            </div>

            <div className={clsx("mb-20", 'second-checkbox')}>
                <label className="checkbox">
                    <input type="checkbox" name={'receiveSMS'} value={'agree'} defaultChecked/>
                    <span className="checkbox__toggle"/>
                    <span style={{fontSize: '13px'}} className={'theme_text_mainColor'}>{strings['bonus_sms']}.</span>
                </label>
                {errors['receiveSMS'] && <p style={{paddingTop: 4}} className="errorMsg">{errors['receiveSMS']}</p>}
            </div>
        </div>
    )
}

export default StepTwo;