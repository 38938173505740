import React, {useEffect} from "react";
import CMS from "@/AbsoluteComponents/utils/cms";
import SmarticoIframe from "@/template/small_ui/smartico_iframe";
import useAuth from "@/AbsoluteComponents/hooks/auth";
import {getAuthToken} from "@/AbsoluteComponents/utils/cookies";
import CircularProgress from "@mui/material/CircularProgress";
import {useDispatch} from "react-redux";
import {openLoginModal} from "@/AbsoluteComponents/store/modals/actions";
import SmarticoClass from "@/AbsoluteComponents/utils/smartico_class";

interface Props {
    json: any
    api_id: string
}

const SmarticoWidgetPage:React.FC<Props> = (props) => {
    
    const auth = useAuth()
    const {json, api_id} = props
    const data = CMS.getAllFields(json)
    const {widget_id, widget_type} = data
    const dispatch = useDispatch()
    const id = `smartico_minigame_${widget_id}`
    
    useEffect(() => {
        if(!getAuthToken().length && !auth.isLoggedIn()) {
            dispatch(openLoginModal(true))
        }
        if(!auth.isLoggedIn()) return
        if(typeof window !== "undefined") {
            if(widget_type === SmarticoClass.WidgetTypes.Minigames) SmarticoClass.showMiniGame(id, widget_id)
            if(widget_type === SmarticoClass.WidgetTypes.FWPopup) SmarticoClass.fwPopupMiniGame(widget_id)
        }
    }, [auth.isLoggedIn()]);
    
    if(widget_type === SmarticoClass.WidgetTypes.FWPopup) return null
    return (
        <div>
            {auth.isLoggedIn() ? (
                <iframe style={{width: "100%"}} id={id} />
            ) : (
                <CircularProgress />
            )}
        </div>
    )
}

export default SmarticoWidgetPage