import {FC, useEffect, useState} from 'react';
import 'swiper/css';
import "swiper/css";
import "swiper/css/pagination";
import styles from "themes/spins/styles/providersSlider.module.scss";
import Link from 'next/link';
import CMS from "utils/cms";
import { Navigation } from "swiper";
import { Pagination } from "swiper";
import clsx from 'clsx';
import { Swiper, SwiperSlide } from 'swiper/react';
import strings from "utils/strings";
import Skeleton from '@mui/material/Skeleton';
import ArrowRight from "@/template/svg/arrow-right";
import { useTheme } from 'next-themes'
import {useDispatch, useSelector} from "react-redux";
import {useRouter} from "next/router";
import Image from "next/image"

interface Props {
    json: any
    api_id: string
}

const ProvidersSlider:FC<Props> = ({json, api_id}) => {
    let data:any = CMS.jsonProvidersSlider(json);

    const [value, setValue] = useState(0);

    const router = useRouter();

    const dispatch = useDispatch();

    const [swiper, setSwiper] = useState<any>();
    const activeProviders: Array<any> = useSelector((state: any) => state.common.activeSearchProviders);
    const { theme, setTheme } = useTheme();
    const [isEnd, setIsEnd] = useState<boolean>(false);
    const [isBeginning, setIsBeginning] = useState<boolean>(true);

    useEffect(() => {
        setValue(value => value + 1);
    }, []);

    const sliders = [];
    const skeletonSlides:Array<any> = []

    for(let i = 0; i < 20; i++) {
        skeletonSlides.push({
            cardId: i,
            cards: [{cardNum: 1},{cardNum: 2}]
        })
    }

    for (let i = 0; i < data.providers.length; i+=2) {
        const provider1 = data.providers[i];
        const provider2 = data.providers[i + 1];

        sliders.push({
            provider1,
            provider2
        });
    }

    const selectProviders = (category_id: string) => {
        let list: Array<any> = [];
        list  = JSON.parse(JSON.stringify(activeProviders));


        if (list.filter((id: any) => id === category_id).length) {
            list = list.filter((id: any) => id !== category_id)
        }

            else {
            list.push(category_id);

            if(list.length > 1){
                list.shift();
            }
        }

    };



    return (
        <div  className={clsx(styles.root, 'slideClass' , 'furnizorBlock')} style={{position: 'relative'}}>

            <div className={clsx('gamesSlider-box', 'sliderTitleBox')}>
                <div className={clsx('gamesSlider', 'furnizori')}>
                    <span className={clsx('gamesSlider__title', 'theme_textColor_secondary')}>{data.title}</span>
                </div>
                
                <div className={'buttons-wrapperSlide'}>
                    {sliders.length > 0 &&
                        
                        <Link
                            className={clsx(
                                'furnizoriHover-seeMore',
                                'btnSlideGame',
                                'theme_text_responsive_mainMobile',
                                'btn__bg__theme'
                            )}
                            href={'/all-providers'}
                        >

                            {strings['vezi_toate']}
                        </Link>
                    }


                    <div className={'arrowButtons-wrapperSlide'}>
                        <button
                            aria-label={"slide"}
                            disabled={isBeginning}
                            onClick={() => {
                                swiper.slidePrev()
                                setIsEnd(false)
                            }}
                                className={clsx('btnSlider-first', 'theme_btnSlider_arrow', isBeginning && 'locked')}>
                            <span className={'theme__svg'}><ArrowRight/></span>
                        </button>
                        <button
                            aria-label={"slide"}
                            disabled={isEnd}
                            onClick={() => {
                                swiper.slideNext()
                                setIsBeginning(false)
                            }}

                            className={clsx('btnSlider-second', 'theme_btnSlider_arrow', isEnd && 'locked')}>
                            <span className={'theme__svg'}><ArrowRight /></span>
                        </button>
                    </div>

                </div>
            </div>
            

            <Swiper
                pagination={{
                    clickable: true,
                }}
                modules={[Pagination, Navigation]}
                className={clsx(styles.themes__mySwiper, 'swiper-init-padding', 'swiper-providers')}
                slidesPerView='auto'
                onSwiper={(sw:any) => setSwiper(sw)}
                onReachBeginning={() => setIsBeginning(true)}
                onReachEnd={() => setIsEnd(true)}
                onSlideNextTransitionStart={() => {setIsBeginning(false)}}
                onSlidePrevTransitionStart={() => {setIsEnd(false)}}
            >

                {/*<PaginationArrow onClick={() => swiper.slideNext()}/>*/}
                {/*<PaginationArrow onClick={() => swiper.slidePrev()} rightArrow />*/}

                {sliders.length ? (
                    sliders.map((item:any, i) => {

                        return (
                            <SwiperSlide
                                className={'providerSlide'}
                                key={`providers-slider-item--${i}`}
                                style={{width: 'auto'}}
                            >
                                {Object.keys(item).map((key:any, i:number) => {
                                    const provider = item[key];
                                    
                                    if (!provider) return null;

                                    return (
                                        <Link
                                            href={'/'+(provider['api_id'] || '')}
                                            key={`provider-item-${i}-${key}`}
                                            className={clsx(styles.item,  'provider-item-wrapper',
                                                'theme__sliderButtonContainer',
                                                router.query.slug === provider['api_id'] && 'theme_providers__item__active',
                                            )}
                                        >
                                            {!Boolean(provider.image_dark?.length) && (
                                                <img
                                                    width={150}
                                                    height={100}
                                                    src={provider['thumbnail_src']}
                                                    alt={provider['name']}
                                                />
                                            )}

                                            {Boolean(provider.image_dark?.length) && (
                                                <>
                                                    <img
                                                        width={150}
                                                        height={100}
                                                        data-hide-on-theme="dark"
                                                        src={CMS.getPublicFilePath(provider.image_dark[0].name, api_id, provider.image_dark[0].path)}
                                                        alt={provider['name']}
                                                    />
                                                    
                                                    <img
                                                        width={150}
                                                        height={100}
                                                        data-hide-on-theme="light"
                                                        src={provider['thumbnail_src']}
                                                        alt={provider['name']}
                                                        
                                                    />

                                                </>
                                            )}

                                        </Link>

                                    )
                                })}
                            </SwiperSlide>
                        )
                    })
                ) : (
                    skeletonSlides.map((sk:any, i: number) => (
                        <SwiperSlide className={'providerSlide'} key={`skeleton-card${i}`} style={{width: 'auto'}}>

                            <div style={{padding: '0'}} className={clsx(styles.item, 'provider-item-wrapper')}>
                                <Skeleton variant="rectangular" style={{width: '100%', height: '100%'}} animation="wave"/>
                            </div>
                            
                            <div style={{padding: '0'}} className={clsx(styles.item, 'provider-item-wrapper')}>
                                <Skeleton variant="rectangular" style={{width: '100%', height: '100%'}} animation="wave"/>
                            </div>

                        </SwiperSlide>
                    ))
                )}
            </Swiper>

        </div>
    )
};

export default ProvidersSlider;