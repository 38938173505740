import { FC } from 'react';
import CMS from "utils/cms";
import strings from "utils/strings";

interface Props {
    json: object;
}


const BonusConditions: FC<Props>  = ({ json }) => {
    const data = CMS.jsonBonusConditions(json);

    return (
        <>
            <div className="ReactModal__Inner__Header">
                <h1 className={'ReactModal__Title'}>{data.title.length > 0 ? data.title : strings['termeni_and_conditii']}</h1>
            </div>

            <div className="ReactModal__Inner__Content" style={{textAlign: 'left'}}>
                <div dangerouslySetInnerHTML={{__html: data.text.replaceAll('\n', '<br/>')}}/>
            </div>
        </>
    )
}

export default BonusConditions;