import {createRef, FC, useEffect, useRef, useState} from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import GameInterface from 'interfaces/game';
import styles from 'themes/spins/styles/categoryGamesSlider.module.scss';
import clsx from 'clsx';
import Card from 'themes/spins/ui/game/card';
import strings from "utils/strings";
import Link from 'next/link';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from "swiper";
import { Pagination } from "swiper";
import CMS from "utils/cms";
import { useMediaQuery } from 'react-responsive';
import Game from "utils/game";
import Skeleton from '@mui/material/Skeleton';
import {useRouter} from 'next/router';
import ArrowForwardSpins from "themes/spins/svg/arrowForwardSpins";
import ArrowRight from "@/template/svg/arrow-right";
import useCommon from "@/AbsoluteComponents/hooks/common";

interface Props {
    json: Array<any>;
    externalGames?: Array<GameInterface>;
    title?: string;
    count?: number;
    index: number;
    api_id?: string;
    disableLazyImage?: boolean;
    disableDemo?: boolean;
    onSliderInit?: () => void;

}

const CategoryGamesSlider: FC<Props> = ({
    json,
    externalGames,
    title,
    count,
    index,
    api_id,
    disableLazyImage,
    disableDemo,
    onSliderInit
}) => {
    
    const elem = useRef<any>(null)
    const common_hook = useCommon()
    
    const [swiper, setSwiper] = useState<any>();
    const [isEnd, setIsEnd] = useState<boolean>(false);
    const [isBeginning, setIsBeginning] = useState<boolean>(true);
    const [loading, setLoading] = useState(false);
    const [cardSkeleton, setCardSkeleton] = useState<Array<any>>([])
    const allGames:Array<GameInterface> = Game.getAll();
    const router = useRouter()
    const data = CMS.jsonCategoryGamesSlider(json);
    const isPromotionPage:boolean = router.route.slice(1, 9) === 'promotii';
    const [games, setGames] = useState<GameInterface[]>([]);
    const isMedia1 = useMediaQuery({
        query: '(max-width: 1199px)'
    });

    const getGamesByCategory = ():Array<GameInterface> => {
        const fullList = allGames.filter((g:any) => (g && g.categories?.includes(data.category_id)));

        if (data.category_id) {
            fullList.sort((a:any, b:any) => {
                if (a.categoryOrder[data.category_id] > b.categoryOrder[data.category_id]) {
                    return 1;
                }

                if (a.categoryOrder[data.category_id] < b.categoryOrder[data.category_id]) {
                    return -1;
                }

                return 0;
            })
        }

        return fullList.filter((g:any) => g.categories?.includes(data.category_id)).slice(0, ( /*data.count*/ 12 ));
    };
    

    useEffect(() => {
        
        common_hook.observe(elem.current)
        
        if (!externalGames) {

        } else {
            setGames(externalGames);
        }
        
        const gameCard = document.getElementById('singleGameCard')

        if(gameCard) {
            let skeletCards: Array<any> = []
            let cardWidth = gameCard.clientWidth
            let cardHeight = gameCard.clientHeight

            for(let i = 0; i < 10; i++) {
                skeletCards.push({
                    cardId: i,
                    width: cardWidth,
                    height: cardHeight
                })
            }

            setCardSkeleton(skeletCards)
        }
        

    }, []);

    const categoryGames:Array<GameInterface> = getGamesByCategory();
    let mainGames = !games.length ? [] : games;

    if (!externalGames) {
        mainGames = categoryGames;
    }

    if (!mainGames.length) return null;


    return (
        <div
            ref={elem}
            data-section={CMS.BLOCK_CATEGORY_GAMES_SLIDER} data-section-title={title || data.category_title}
            className={clsx(
                 styles.categoryGames,
                 'slideClass',
                 'theme-slider',
                 'swiper-init-padding',
                 'styleMarginBottom',
                 isPromotionPage && 'promo_pg_slider'
            )}
        >
            <div className={clsx('gamesSlider-box', isPromotionPage && 'noPadding')}>

                <div className={'gamesSlider'}>
                    <span style={{padding: `${isPromotionPage && 0}`}} className={clsx('gamesSlider__title', 'theme_textColor_secondary')}>
                        {title || data.category_title}
                    </span>
                </div>

                <div className={'buttons-wrapperSlide'}>
                    {data.api_id.length > 0 &&
                        <Link   href={`/${data.api_id}`} className={clsx('linkMore-btn', 'btnSlideGame', 'btn__bg__theme', 'theme_text_responsive_mainMobile')}>
                            {/*<span style={{marginRight: '5px'}}>*/}
                            {/*    <span className={clsx('theme__svgArrow', 'theme_arrow_white')}><ArrowForwardSpins /></span>*/}
                            {/*</span>*/}
                            {strings['vezi_toate']}

                        </Link>
                    }

                    <div className={'arrowButtons-wrapperSlide'}>
                        <button
                            aria-label={"slide"}
                            disabled={isBeginning}
                            onClick={() => {
                            swiper.slidePrev()
                                setIsEnd(false)
                            }}
                                className={clsx('btnSlider-first', 'theme_btnSlider_arrow',  isBeginning && 'locked')}>
                            <span className={'theme__svg'}><ArrowRight/></span>
                        </button>
                        <button
                            aria-label={"slide"}
                            disabled={isEnd}
                            onClick={() => {
                                swiper.slideNext()
                                setIsBeginning(false)
                            }}

                            className={clsx('btnSlider-second', 'theme_btnSlider_arrow', isEnd && 'locked')}>
                            <span className={'theme__svg'}><ArrowRight /></span>
                        </button>
                    </div>
                </div>
                
            </div>

            {(loading /*&& storedGames.length === 0*/) && <div style={{paddingTop: 42, paddingLeft: 50}}>
                arrow
            </div>}

            {isMedia1 && loading ? (
                <div className={styles.sliderContainer}>
                    <div className={styles.slider}>
                        {mainGames.length > 0 && (
                            mainGames.map((game: GameInterface, i: number) => {
                                return (

                                    <Card key={`category_games-${index}-${game.gameId}`}
                                          disableDemo={disableDemo} disableLazyLoading={disableLazyImage}
                                          game={game}
                                          homeSliderItem minimize={data.minimize}
                                    />

                                );
                            }))
                        }
                    </div>
                </div>

            ) : (

                (mainGames.length > 0) && (
                    <Swiper
                        pagination={{
                            clickable: true,
                        }}
                        modules={[Pagination, Navigation]}
                        className={clsx( 'block_category_game_swiper', isPromotionPage && 'noMarginRight')}
                        slidesPerView='auto'
                        onSwiper={(sw) => {
                            setSwiper(sw);
                            if (typeof onSliderInit === "function") onSliderInit();
                        }}
                        onReachBeginning={() => setIsBeginning(true)}
                        onReachEnd={() => setIsEnd(true)}

                        onSlideNextTransitionStart={() => {setIsBeginning(false)}}
                        onSlidePrevTransitionStart={() => {setIsEnd(false)}}
                    >

                        {mainGames.length > 0 ? (
                            mainGames.map((game:GameInterface, i:number) => {
                                return (
                                    <SwiperSlide key={`category_games-${index}-${game.gameId}`} className={''}>

                                        <Card disableDemo={disableDemo} disableLazyLoading={disableLazyImage}
                                              game={game} homeSliderItem minimize={data.minimize} />
                                    </SwiperSlide>
                                )
                            })
                        ) : (
                            cardSkeleton?.map((sk, i) => {
                                return (
                                    <SwiperSlide key={'skeleton' + sk.cardId}>
                                        <Skeleton
                                            variant="rectangular"
                                            className={'skeletonGameCard'}
                                            animation="wave"
                                        />
                                    </SwiperSlide>
                                )
                            })
                        )}


                    </Swiper>
                )

            )}
        </div>
    )
}
export default CategoryGamesSlider;