import CMS from "utils/cms";
import React, {FC, useEffect} from 'react';
import styles from 'themes/spins/styles/footer.module.scss';
import strings from "utils/strings";
import Image from 'next/image';
import MenuFooter from 'themes/spins/ui/footer/menu'
import clsx from 'clsx'
import Link from 'next/link';
import { useRouter } from 'next/router';
import LogoFooterSpins from 'themes/spins/svg/logoFooterSpins'
import InstagramSpins from 'themes/spins/svg/instagramSpins';
import FacebookSpins from 'themes/spins/svg/facebookSpins';
import useAuth from "@/AbsoluteComponents/hooks/auth";


interface Props {
    json: object;
    api_id: any
}

const FooterInfo: FC<Props> = ({ json, api_id })  => {

    // const data = CMS.getFooterInfo(json)

    const generalSettings = CMS.getDocumentGeneralSettings();

    const router = useRouter();

    const dataUser = useAuth();

    let isSportPage:boolean = router.pathname.slice(1, 6) === 'sport'

    const data = CMS.getCopyright()
    

    
    return (
        <div id={'footerContents'} className={clsx(styles.contents, !dataUser.isLoggedIn() && styles.paddingFooter)}>

            <div className={clsx(styles.contents__top,  'footer_border_bottom')}>

                <div className={clsx(styles.contents__sides, styles.left)}>
                    <MenuFooter />
                </div>

                <div className={clsx(styles.contents__sides, styles.right)}>

                    <div className={styles.menu_logo}>
                        <Link   aria-label={"home"} href={'/'}>
                            <Image src={"/spins/images/Logo.webp"} alt={"logo-mid"} width={242} height={78} />
                        </Link>
                    </div>

                    <div className={clsx(styles.contents__about, 'theme_footer_text')} dangerouslySetInnerHTML={{__html: data.content}}/>

                </div>

            </div>

            <div className={clsx(styles.contents__bottom, )}>
                <div className={styles.socialMedia}>
                    <Link   aria-label={"insta"} className={clsx(styles.socialLink, 'theme__svg', styles.sidebar__facebook)}  target="_blank" href={generalSettings.instagram}>
                       <InstagramSpins />
                    </Link>

                    <Link   aria-label={"fb"} className={clsx(styles.socialLink,'theme__svg', styles.sidebar__facebook)}  target="_blank" href={generalSettings.facebook}>
                        <FacebookSpins />
                    </Link>
                </div>
                



                <div className={styles.marks}>
                    <div className={styles.contents__img}>
                        
                        <a aria-label={"onjn_ru"} className={styles.onjn_url} href={data.onjn_url}>
                        </a>
                        
                        <div className={styles.contents__item}>
                            <Image className={styles.contents__logo}
                                   src={CMS.getPublicFilePath(data.image[0].name, api_id, data.image[0].path)}
                                   alt={strings['logo']} width={157} height={72}/>
                        </div>
                    
                    </div>
                </div>
            </div>
        
        
        </div>
    )
}

export default FooterInfo;