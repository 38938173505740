import {FC, useState, useEffect, useRef} from 'react';
import useAuth from "hooks/auth";
import { useDispatch  } from 'react-redux'

interface Props {
    url:string,
    amount:number,
    onSuccess():void,
}

const DepositIframe:FC<Props> = ({url,amount,onSuccess}) => {
    const [cash, setCash] = useState(0);

    const timer:any = useRef(null);
    const iframe:any = useRef(null);
    const observer:any = useRef(null);
    const auth = useAuth();
    const dispatch = useDispatch()

    const handleLoad = (e:any) => {
        try {
            // console.log('handleLoad',e.target.contentWindow.location.href);
        } catch (e) {

        }
    }

    const updateBalance = async () => {
        await auth.updateBalance();

        timer.current = setTimeout(updateBalance, 500);
    };


    useEffect(() => {
        setCash(auth.getCash() + amount);

        return () => {
            clearTimeout(timer.current);
            clearInterval(timer.current);
            observer.current?.disconnect();
        }

    }, []);

    useEffect(() => {
        if (cash > 0 && auth.getCash() >= cash) {
            setTimeout(() => {
                onSuccess();
            }, 1000);
        }
    }, [auth.getCash()]);

    if (url === 'return null') return null;

    return (
        <div className={'deposit_iframe'}>
            <iframe src={url} onLoad={handleLoad}/>
        </div>
    )
}

export default DepositIframe;