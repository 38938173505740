import {FC} from "react";
import styles from '../../styles/card.module.scss';
import GameInterface from "interfaces/game";
import clsx from 'clsx';
import Card from "./card";
import {useEffect, createRef, useState} from 'react';
import { useSelector } from 'react-redux'
import Skeleton from '@mui/material/Skeleton';
import { STATIC_PATHS } from 'utils/cms';
import { useRouter } from 'next/router'


interface Props {
    games: Array<GameInterface>;
    page: string;
    innerScroll?: boolean;
    disableCLS?: boolean;
    onCardClick?(game: any, gameUrl: string): void;
    isPublic?: boolean
}

const Grid: FC<Props> = ({games, page, innerScroll, disableCLS, onCardClick, isPublic}) => {
    const limit:number = 35;
    const gridId = 'game_grid_'+page;
    const showSearchBar:boolean = useSelector((state:any) => state.common.showSearchBar);
    const girdRef:any = createRef();
    const [currentLimit, setCurrentLimit] = useState(limit);
    const [watch, setWatch] = useState(false);
    const [gamesLoaded, setGamesLoaded] = useState<boolean>(false);
    const static_path = Array(STATIC_PATHS);
    //UNDO START
    const gamesTemplates:Array<number> = [];
    const route = useRouter();

    for(let i = 0; i < limit; i++) {
        gamesTemplates.push(i)
    }

    // UNDO END

    const isSlots:boolean = page === 'slots';
    const isCateg:boolean = page === 'category';


    const onPageScroll = () => {
        const grid:any = document.getElementById(gridId);
        const gridEnd:number = grid?.offsetTop + grid?.offsetHeight;

        if (!gridEnd) return;

        if ((gridEnd - 100) < (window.scrollY + window.innerHeight)) {
            if (games.length > limit) {
                setCurrentLimit((current) => current + limit);
            }
        }
    };

    const onInnerScroll = (e:any) => {
        const gridEnd:number = e.target?.offsetTop + e.target?.offsetHeight;

        if (!gridEnd) return;


        if ((e.target?.scrollTop + gridEnd) > e.target?.scrollHeight - 100) {
            if (games.length > limit) {
                setCurrentLimit((current) => current + limit);

            }
        }
    };



    useEffect(() => {
        if (games.length) {
            setCurrentLimit(limit);

            if (!watch) {
                setWatch(true);

                if (!innerScroll) {
                    window.addEventListener("scroll", onPageScroll);
                }
            }

            setGamesLoaded(true)
        }

    }, [games]);


    return (
        <div
            className={clsx(
                styles.grid,
                innerScroll && styles.grid__scroll,
                isSlots && styles.grid__slots,
                isCateg && styles.grid__categ
            )}
            ref={girdRef}
            id={gridId}
            onScroll={onInnerScroll}
        >

            {(!games.length && !gamesLoaded && !isPublic && disableCLS !== true) && (
                gamesTemplates.map((x,i) => (
                    <div style={{transform: 'translateX(2.5%) translateY(-0.25%)', opacity: '0'}} className={clsx(styles.grid__item, 'skeletonTemplateGrid')} key={`grid_game_item-${i}`}>
                        <Skeleton
                            style={{
                                display: 'block',
                                width: '100%',
                                aspectRatio: '100000/144421',
                                transform: 'scale(0.95)',
                                borderRadius: '5px',
                                marginRight: '1px',
                                opacity: '0',
                            }}
                        >

                        </Skeleton>
                    </div>
                ))
            )}

            {games.map((game:GameInterface, i:number) => {
                if (i < currentLimit) return (
                    <div className={styles.grid__item} key={`grid_game_item-${game.gameId}`}>
                        <Card onCardClick={onCardClick}  game={game}/>
                    </div>
                )


            })}

            {/*UNDO START*/}
        </div>
    )

}
export default Grid;