function PariuriIconSpins(){
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="20" viewBox="0 0 24 20" fill="none" className={'svg-icon'}>
            <path d="M11.6956 6.6862H15.8307M11.6956 6.6862L9.58629 10.1381L11.6617 13.6491H15.7969M11.6956 6.6862L10.1296 4.10618M15.8307 6.6862L17.8141 10.1381M15.8307 6.6862L17.3694 4.10618M15.7969 13.6491L17.8141 10.1381M15.7969 13.6491L17.2195 16.1715M17.8141 10.1381L20.8111 10.1381M10.1296 4.10618L11.6617 1.60449M10.1296 4.10618H7.43555M17.3694 4.10618L15.7969 1.60449M17.3694 4.10618H19.9803M17.2195 16.1715L16.0425 18.4186M17.2195 16.1715H19.9803M20.8111 10.1381L22.2061 8.06029M20.8111 10.1381L22.2061 12.1694" stroke="white" strokeWidth="1.5"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M6.93376 9.02441C4.33189 9.02441 2.22266 11.1336 2.22266 13.7355C2.22266 16.3374 4.33189 18.4466 6.93376 18.4466C9.53564 18.4466 11.6449 16.3374 11.6449 13.7355C11.6449 11.1336 9.53564 9.02441 6.93376 9.02441ZM7.25671 11.0646C7.25671 10.6503 6.92093 10.3146 6.50671 10.3146C6.0925 10.3146 5.75671 10.6503 5.75671 11.0646V14.3115V14.7375L6.12262 14.9557L8.15018 16.1646C8.50595 16.3768 8.96633 16.2603 9.17846 15.9046C9.39059 15.5488 9.27415 15.0884 8.91838 14.8763L7.25671 13.8855V11.0646Z" fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M21.5455 10.0509C21.5455 14.3339 18.0734 17.8059 13.7905 17.8059C12.5486 17.8059 11.3749 17.514 10.3342 16.995C9.95203 17.3938 9.50043 17.7254 8.99883 17.9705C10.3965 18.818 12.0365 19.3059 13.7905 19.3059C18.9019 19.3059 23.0455 15.1623 23.0455 10.0509C23.0455 4.93951 18.9019 0.795898 13.7905 0.795898C8.8051 0.795898 4.74039 4.73769 4.54297 9.67463C5.01346 9.39714 5.53639 9.19909 6.09331 9.09892C6.5626 5.26504 9.8298 2.2959 13.7905 2.2959C18.0734 2.2959 21.5455 5.76794 21.5455 10.0509Z" fill="white"/>
            <path d="M6.93376 8.27441C3.91768 8.27441 1.47266 10.7194 1.47266 13.7355C1.47266 16.7516 3.91768 19.1966 6.93376 19.1966C9.94985 19.1966 12.3949 16.7516 12.3949 13.7355C12.3949 10.7194 9.94985 8.27441 6.93376 8.27441Z" stroke="white" strokeWidth="1.5"/>
        </svg>
    )
}
export default PariuriIconSpins