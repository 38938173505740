import {FC, useEffect, useState} from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import styles from 'themes/spins/styles/categoriesButtonsSlider.module.scss';
import "swiper/css";
import "swiper/css/pagination";
import Link from 'next/link';
import CMS from "utils/cms";
import clsx from 'clsx';
import { useRouter } from 'next/router';
import {useSelector} from "react-redux";
import ArrowRight from "@/template/svg/arrow-right";
import {margin} from "@mui/system";

interface Props {
    json: object;
    api_id: string;
    second?: boolean;
}

const CategoriesButtonsSlider: FC<Props> = ({ json, api_id, second }) => {
    const data:any = CMS.jsonCategoriesButtonsSlider(json);
    const router = useRouter()
    const isHomePage = router.pathname === '/'
    const isCasino = router.pathname === '/casino-live'
    const isCategPg:boolean = useSelector((state:any) => state.common.isCategPage)
    const isThemesPage:boolean = useSelector((state:any) => state.common.isThemesPage)

    const [swiper, setSwiper] = useState<any>();
    const [isEnd, setIsEnd] = useState<boolean>(false);
    const [isBeginning, setIsBeginning] = useState<boolean>(true);

    const setAlign = () => {

        let categ_slider = document.getElementById('categ_slider')

        if(categ_slider) {
            let slides = categ_slider.getElementsByClassName('sliderButtonContainer')
            let singleSlide = slides[0]
            let slidesWidth = singleSlide?.getBoundingClientRect().width * slides.length
            let swiper_wrapper = categ_slider.getElementsByClassName('swiper-wrapper')[0] as HTMLDivElement
            let sliderContainerWidth = swiper_wrapper.getBoundingClientRect().width
            
            const getWidths = ():number => {
                let width_sum:number = 0
                let allSlides = Array.from(slides)
                allSlides?.map((item) => {
                    let width = item.getBoundingClientRect().width
                    width_sum += width
                })
                
                return width_sum
            }
            
            const getMargins = ():number => {
                let margin_sum:number = 0

                let slides_array: any[] = Array.from(slides)
                
                slides_array.map((sl) => {
                    let margin_left = Number(window.getComputedStyle(sl).marginLeft.replace("px", ""))
                    let margin_right = Number(window.getComputedStyle(sl).marginRight.replace("px", ""))
                    margin_sum += margin_left + margin_right
                })
                
                return margin_sum
            }
            
            let widths = getWidths()
            let margins = getMargins()

            if((widths + margins) > sliderContainerWidth) {
                swiper_wrapper.style.justifyContent = 'start'
            } else {
                swiper_wrapper.style.justifyContent = 'center'
            }
        }
    }

    useEffect(() => {

        setAlign()
        window.addEventListener("resize", setAlign)

        return () => {
            window.removeEventListener("resize", setAlign)
        }

    }, []);

    let isNoPadding:boolean = !isHomePage && !isCategPg && !isCasino && !isThemesPage


    if (Array.isArray(data.slider) && data.slider.length > 0) {

        return (
            <div
                id={'categ_slider'}
                className={clsx(
                    styles.slider,
                    !data.move_higher && styles.slider__sliderRelative ,
                    data.slider.length >= 10 && styles.slider__alignLeft,
                )}>

                <div
                    className={clsx(
                        styles.slider__inner,
                        !data.move_higher && styles.slider__innerRelative,
                    )}
                >
                    
                    <Swiper
                        slidesPerView={'auto'}
                        centerInsufficientSlides={false}
                        className={clsx(
                            styles.swiper,
                            isNoPadding && styles.noPadding
                        )}
                    >
                        {data.slider.map((item:any, i:number) => {
                            
                            
                            const isCurrentCateg = router.asPath === "/"+item['api_id']
                            
                            
                            return (
                                <SwiperSlide
                                    className={clsx(
                                        styles.slider__swiper_item,
                                        "theme_box_bg_shaded4",
                                        "sliderButtonContainer",
                                    )}
                                    
                                    key={'categories_buttons_slider-'+i}
                                >
                                    
                                    <Link
                                        href={'/'+item['api_id']}
                                        title={item['title']}
                                        
                                        className={clsx(
                                            styles.slider__item,
                                            isCurrentCateg && styles.current,
                                            
                                        )}
                                    >
                                        
                                        <div
                                            className={clsx(
                                                'icon_div',
                                                'theme_icon_div',
                                                styles.icon_div,
                                                item.title === "Drops & Wins" && "drops_wins",
                                                item.title === "SuperSlots" && "super_slots",
                                                item.title === "Jackpots" && "jackpots",
                                                item.title === "Cumpără Speciale" && "specials",
                                            )}
                                            
                                            style={{
                                                mask: `url('${CMS.getPublicFilePath(item.icon[0]['name'], api_id, item.icon[0]['path'])}') no-repeat center / contain`,
                                                WebkitMask: `url('${CMS.getPublicFilePath(item.icon[0]['name'], api_id, item.icon[0]['path'])}') no-repeat center / contain`,
                                            }}>
                                        </div>
                                        
                                        <div className={clsx(styles.slider__item__title, "theme_white_dark_text")}>
                                            {item['title']}
                                        </div>
                                    
                                    </Link>
                                
                                </SwiperSlide>
                            )
                        })}
                    </Swiper>
                </div>
            </div>
        );
    }

    return null;
};

export default CategoriesButtonsSlider;