function PlayNowIconSpins() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
            {/*<mask id="mask0_1623_232243" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="25">*/}
            {/*    <rect y="0.470703" width="24" height="24" fill="#D9D9D9"/>*/}
            {/*</mask>*/}
            {/*<g mask="url(#mask0_1623_232243)">*/}
                <path d="M5 18.6811C5 19.4635 5.85783 19.9428 6.5241 19.5328L16.6161 13.3224C17.2506 12.9319 17.2506 12.0095 16.6161 11.619L6.5241 5.40861C5.85783 4.9986 5 5.47795 5 6.26027V18.6811Z" fill="#15191E"/>
            {/*</g>*/}
        </svg>
    )
}
export default PlayNowIconSpins