import Link from 'next/link';
import styles from '../../styles/footer.module.scss';
import strings from "@/AbsoluteComponents/utils/strings";
import clsx from 'clsx';
import CMS from "@/AbsoluteComponents/utils/cms";

const SpinsMenufooterView = () => {


    
    const footer_menu_raw: Array<any> = CMS.getFooterMenu()
    let footer_menu = CMS.getAllFields(footer_menu_raw[0])?.menu
    const split_menu_nr = Math.ceil(footer_menu.length / 2)
    
    const menu_first_column = footer_menu.slice(0, split_menu_nr)
    const menu_second_column = footer_menu.slice(split_menu_nr, footer_menu.length)
    const combined_menus = [ ...menu_first_column, ...menu_second_column]

    return (
        <div className={'theme_footer_text'}>

            <h5 className={clsx(styles.menu_heading, 'theme_textColor_secondary')}>
                {strings['menu']}
            </h5>

            <div className={clsx(styles.menuContainer)}>

                <div className={clsx(styles.menu, styles.firstColumn)}>

                    {menu_first_column.length ? menu_first_column.map((item:any, i:number) => {
                        return (
                            <div key={"menu_first_column" + i}>
                                <Link   className={styles.menu__item} href={item.link}>{item.name}</Link>
                            </div>
                        )
                    }) : null}

                </div>

                <ul className={clsx(styles.menu, styles.secondColumn)}>

                    {menu_second_column.length ? menu_second_column.map((item:any, i:number) => {
                        return (
                            <div key={"menu_second_column" + i}>
                                <Link   className={styles.menu__item} href={item.link}>{item.name}</Link>
                            </div>
                        )
                    }) : null}

                </ul>
            </div>

            <div className={clsx(styles.mobile_menu_container, styles.menu)}>
                {footer_menu?.length ? footer_menu.map((item:any, i:number) => {
                    return (
                        <div key={"menu_second_column" + i}>
                            <Link   className={styles.menu__item} href={item.link}>{item.name}</Link>
                        </div>
                    )
                }) : null}
            </div>

        </div>
    )
}
export default SpinsMenufooterView;