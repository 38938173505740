import styles from "themes/spins/styles/accountMenu.module.scss";
import {FC} from 'react';
import clsx from 'clsx';
import {showAccountSideBar, showGuestSideBar} from "store/common/actions";
import {useDispatch} from 'react-redux';
import {useRouter} from 'next/router';

interface Props {
    title: string,
    forGuest?: boolean,
    marginTitle?: boolean,
    homePage?:boolean;
    count?:number;
    h1?:boolean;
    // hideArrow?:boolean
}

export const Title: FC<Props>  = ({title, forGuest, marginTitle, homePage, count, h1}) => {
    const dispatch = useDispatch();
    const router = useRouter();
    const isAccPage = router.pathname.search('account') > -1;
    const clickHandle = () => {

        let width = window.innerWidth

        if(homePage) {
            router.push('/');
        } else if (forGuest && width <= 1300 && isAccPage) {
            dispatch(showGuestSideBar(true));
        } else {
            dispatch(showAccountSideBar(true));
        }
    }



    return (
        <div className={clsx(styles.profile, 'profileBtnTitle' ,'pointerEvent',
            router.route.search('favorites') > -1 && 'favoriteTitleBlock',
            forGuest && styles.profile__forGuest,)} >

            <span style={{display:'flex', alignItems: 'center', gap: '8px', }}
                  // className={clsx( router.route.search('favorites') ? 'favoriteTitle' : '')}
                  onClick={clickHandle}>

                <button
                    type={'button'}
                    className={clsx('btn-account')}
                    aria-label={"back"}
                >
                   <img src={'/default/icons/backicon.svg'} alt="img"/>
                </button>

                {h1 ? (
                    <h1 className={clsx(styles.profile__title, 'titleStylePages',marginTitle && 'marginTitle')}>
                        <span className={'theme_textColor_secondary'}>{title}</span>
                        {count !== undefined && <span className={styles.profile__titleCount}>{count}</span>}
                    </h1>
                ) : (
                    <h2 className={clsx(styles.profile__title, 'titleStylePages',marginTitle && 'marginTitle')}>
                        <span className={'theme_textColor_secondary'}>{title}</span>
                        {count !== undefined && <span className={styles.profile__titleCount}>{count}</span>}
                    </h2>
                )}

            </span>
        </div>
    )
}


export default Title





















