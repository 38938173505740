import {DeviceType} from "@/AbsoluteComponents/interfaces/types";

export default class CommonClass {
    static custom_events = {
        "listen_pin_timeout":"listen_pin_timeout"
    }
    
    static local_storage_items = {
        "send_pin_info":"send_pin_info",
        "change_pwd_token": "change_pwd_token"
    }

    static device_type(navigator:any, userAgent:any):DeviceType | "" {
        if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
            return "App Store"
        } else if (/Huawei/i.test(navigator.userAgent) && (userAgent.includes('huawei') || userAgent.includes('honor'))) {
            return "AppGallery"
        } else if (userAgent.includes('android') && !userAgent.includes('huawei')) {
            return "Google Play"
        } else {
            return ""
        }
    }
    
    static reasonFactory(key:number) {
        const reasons:any = {
            30: "Alte Motive",
            11: "Fără Motiv",
            12: "Probleme Tehnice",
            13: "Nu îmi plac bonusurile",
            15: "Nu îmi plac serviciile oferite",
            16: "Vreau să joc pe alte site-uri",
            17: "Nu am timp să joc acum. Poate revin mai târziu",
            18: "Nu îmi place platforma",
            32: "Anulat din cauza timpului",
            20: "Conectare dintr-o țară diferită față de țara de înregistrare",
            21: "Datele jucătorului nu corespund cu documentele furnizate",
            22: "Cerere de la jucător",
            23: "Cerere de fraudă",
            24: "Cont duplicat",
            25: "Documentele EDD/KYC nu au fost furnizate",
            26: "Abuz de bonusuri",
            27: "Jucător cu probleme de joc",
            28: "Posibil PEP",
            29: "Risc AML-CFT",
            33: "Anulat pentru abuz de contract",
            34: "Suspendat din cauza timpului",
            19: "KYC nu a fost confirmat",
            35: "Suspendat pentru precauție",
            44: "Găsit în baza de date internă de excluziune",
            14: "Probleme de joc responsabil"
        };
        
        return reasons[key] || "Motiv necunoscut";
    }
}