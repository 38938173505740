import clsx from "clsx";
import {useEffect, useState} from "react";
import st from "../styles/Ui.module.scss"


interface Props {
    className?: string
    onChange?(): void
    error?:boolean
}

const WritePinFields:React.FC<Props>  = ({className, onChange, error}) => {
    
    const [pin, setPin] = useState(['', '', '', '']);
    const maxLength = 1
    const id_root = `input_pin_`
    
    const handleChange = (index:number, value:string) => {
        const newPin = [...pin];
        newPin[index] = value;
        setPin(newPin);
        
        const next_input = document.getElementById(`${id_root}${index + 1}`)
        const prev_input = document.getElementById(`${id_root}${index - 1}`)
        
        if(next_input && value.length) {
            next_input.focus()
        }
        
        if(prev_input && !value.length) {
            prev_input.focus()
        }
    };
    
    const onPasteHandler = (e:any) => {
        const clipboardData = e.clipboardData;
        const pastedText = clipboardData.getData('text');
        
        let arr:Array<string> = []
        
        if(pastedText.length === 4) {
            pastedText.split("").map((t:string) => {
                if(Number(t) || t === "0") {
                    arr.push(t)
                }
            })
            
            if(arr.length === 4) {
                
                arr.map((str, i) => {
                    let elem = document.getElementById(`${id_root}${i}`)
                    
                    if(elem) {
                        (elem as HTMLInputElement).value = str
                    }
                    
                })
                
                setPin(arr)
                
            }
        }
    }
    
    useEffect(() => {
        const first_input = document.getElementById(`${id_root}0`)
        
        if(first_input) {
            first_input.focus()
        }
        
    }, []);
    
    return (
        <div className={clsx(st.pin_fields, className && className)}>
            <div className={st.fields}>
                {pin.map((digit: any, index: number) => {
                    return (
                        <input
                            key={index}
                            name={`pin${index}`}
                            // type={"number"}
                            value={digit}
                            onChange={(e: any) => {
                                if (Number(e.target.value) || e.target.value === "" || e.target.value === "0") {
                                    handleChange(index, e.target.value)
                                }
                                
                                if (typeof onChange === "function") {
                                    onChange()
                                }
                            }}
                            maxLength={maxLength}
                            className={st.inputs}
                            id={`${id_root}${index}`}
                            onPaste={onPasteHandler}
                        />
                    )
                })}
            </div>
            
            {error && (
                <span className={st.error_span}>
                    {/*{t("incorrect_code")}*/}
                </span>
            )}
        </div>
    )
}

export default WritePinFields