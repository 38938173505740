import styles from "@/template/styles/accountMenu.module.scss";
import strings from "utils/strings";
import {passwordRules} from "../../config/constants";
import Checked from "themes/spins/svg/checked";
import clsx from 'clsx';
import { FC, useEffect } from "react";
import Close from 'themes/spins/svg/close';


interface Props {
    password:string,
    passwordFocus?: boolean,
    resetPasswordClass?: boolean,
    passwordClass?:boolean,
    className?:string,
    onError?(msg:String):void,
}

const SpinsRulesPasswordView: FC<Props> = ({password, passwordFocus, resetPasswordClass, passwordClass, className, onError}) => {

    let allMatch:boolean = true;
    const invalidDescriptions:Array<string> = [];

    passwordRules.map((item, i) => {
        if (!item['match'](password)) {
            allMatch = false;

            invalidDescriptions.push(item.description.toLowerCase())
        }
    });

    useEffect(() => {
        if (!allMatch && onError && password.length) {
            onError(invalidDescriptions.join(', '))
        }
    }, [password]);


    return (
        <div>
            <input type="hidden" name={'password_rules'} value={allMatch.toString()}/>
            <input type="hidden" name={'password_pending_rules'} value={invalidDescriptions.join(', ')}/>
            {passwordFocus &&
                <div className={clsx(passwordClass && styles.passwordRules, resetPasswordClass && 'reset', className)}>

                    <div className={styles.passwordRules__list}>

                        {passwordRules.map((item, i) => {
                            return (
                                <div
                                    key={`password-rule-${i}`}
                                    className={clsx(
                                        styles.passwordRules__list__item,
                                        item['match']!(password) && styles.checked
                                    )}
                                >

                                    <span className={clsx(
                                        styles.passwordRules__listIcon,
                                        item['match']!(password) && 'theme_yellow_bg'
                                    )}>

                                    </span>

                                    <span className={clsx(styles.description, 'theme_white_dark_text', 'moz_text_sm_1')}>
                                        {item['description']}
                                    </span>

                                </div>
                            )
                        })}

                    </div>
                </div>
            }
        </div>
    )
}

export default SpinsRulesPasswordView





















