const HistoryIcon = () => {
    return (
        <svg className={"svg-icon"} width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 8V12.5" stroke="#A4AAB6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M15.8971 14.75L12 12.5" stroke="#A4AAB6" strokeWidth="1.5" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path d="M6.73438 9.84814H2.98438V6.09814" stroke="#A4AAB6" strokeWidth="1.5" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path
                d="M6.16635 18.3336C7.32014 19.4874 8.79015 20.2732 10.3905 20.5915C11.9908 20.9098 13.6496 20.7464 15.1571 20.122C16.6646 19.4976 17.9531 18.4402 18.8596 17.0835C19.7661 15.7268 20.25 14.1317 20.25 12.5C20.25 10.8683 19.7661 9.27325 18.8596 7.91655C17.9531 6.55984 16.6646 5.50242 15.1571 4.878C13.6496 4.25357 11.9908 4.0902 10.3905 4.40853C8.79015 4.72685 7.32014 5.51259 6.16635 6.66637L2.98438 9.84835"
                stroke="#A4AAB6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export default HistoryIcon