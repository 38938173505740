function SupportIconSpins() {
    return(
        <svg className={'svg-icon'} xmlns="http://www.w3.org/2000/svg" width="24" height="23" viewBox="0 0 24 23" fill="none">
            <path d="M9.53906 8.9987V8.6191C9.53906 7.34844 10.5729 6.31836 11.8435 6.31836V6.31836C13.1101 6.31836 14.1405 7.3451 14.1405 8.61165V8.61165C14.1405 9.3098 13.8225 9.96992 13.2766 10.405L12.7508 10.824C12.1563 11.2979 11.81 12.0167 11.81 12.777V12.777" stroke="white" strokeWidth="1.5" strokeLinecap="round"/>
            <circle cx="11.8356" cy="14.8063" r="0.753579" fill="white"/>
            <circle cx="11.8382" cy="20.7884" r="1.47103" fill="white"/>
            <path d="M11.9448 20.7875C15.683 20.7875 18.9107 18.5683 20.4188 15.3569C21.0011 14.1169 21.3271 12.729 21.3271 11.2639C21.3271 6.0041 17.1265 1.74023 11.9448 1.74023C6.76309 1.74023 2.5625 6.0041 2.5625 11.2639C2.5625 12.6665 2.86125 13.9984 3.39767 15.1974" stroke="white" strokeWidth="1.5"/>
            <path d="M0.580655 15.3696C0.0140288 13.2549 1.26897 11.0813 3.38365 10.5147L3.67161 10.4375C4.20508 10.2946 4.75341 10.6112 4.89635 11.1446L6.46488 16.9985C6.60782 17.5319 6.29124 18.0803 5.75778 18.2232L5.46982 18.3004C3.35514 18.867 1.18151 17.6121 0.614887 15.4974L0.580655 15.3696Z" fill="white"/>
            <path d="M23.0923 15.3696C23.6589 13.2549 22.4039 11.0813 20.2893 10.5147L20.0013 10.4375C19.4678 10.2946 18.9195 10.6112 18.7766 11.1446L17.208 16.9985C17.0651 17.5319 17.3817 18.0803 17.9151 18.2232L18.2031 18.3004C20.3178 18.867 22.4914 17.6121 23.058 15.4974L23.0923 15.3696Z" fill="white"/>
        </svg>
    )
}

export default SupportIconSpins