const SettingsIcon = () => {
    return (
        <svg className={"svg-icon"} width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12 17.75C14.8995 17.75 17.25 15.3995 17.25 12.5C17.25 9.60051 14.8995 7.25 12 7.25C9.10051 7.25 6.75 9.60051 6.75 12.5C6.75 15.3995 9.10051 17.75 12 17.75Z"
                stroke="#A4AAB6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M5.23022 17.2744L5.11801 19.0149C5.10996 19.1264 5.12659 19.2383 5.16673 19.3427C5.20687 19.4471 5.26953 19.5413 5.35025 19.6187C6.42896 20.6419 7.73431 21.3955 9.15978 21.8181C9.26716 21.8493 9.38011 21.8565 9.49057 21.8391C9.60102 21.8217 9.70628 21.7801 9.79885 21.7174L11.25 20.75H12.75L14.2012 21.7174C14.2937 21.7801 14.399 21.8217 14.5094 21.8391C14.6199 21.8565 14.7328 21.8494 14.8402 21.8182C16.2657 21.3956 17.571 20.6419 18.6497 19.6188C18.7305 19.5414 18.7931 19.4471 18.8333 19.3428C18.8734 19.2384 18.8901 19.1264 18.882 19.0149L18.7698 17.2745L19.5198 15.9755L21.0832 15.2024C21.1838 15.1536 21.2724 15.0832 21.3428 14.9963C21.4131 14.9093 21.4634 14.808 21.49 14.6994C21.8368 13.2536 21.8368 11.7463 21.49 10.3005C21.4633 10.1919 21.4131 10.0905 21.3427 10.0036C21.2724 9.91662 21.1838 9.84624 21.0832 9.79745L19.5199 9.02441L18.7699 7.72537L18.8821 5.98491C18.8901 5.87337 18.8735 5.76143 18.8333 5.65706C18.7932 5.55269 18.7306 5.45845 18.6498 5.38105C17.5711 4.35787 16.2658 3.60423 14.8403 3.18164C14.7329 3.15043 14.62 3.14328 14.5095 3.1607C14.3991 3.17812 14.2938 3.21968 14.2012 3.28243L12.7501 4.24979H11.2501L9.79892 3.28239C9.70636 3.21965 9.6011 3.17808 9.49064 3.16066C9.38018 3.14323 9.26724 3.15038 9.15985 3.18158C7.73439 3.60418 6.42904 4.35783 5.35034 5.38103C5.26962 5.45842 5.20695 5.55266 5.16681 5.65703C5.12667 5.7614 5.11003 5.87334 5.11809 5.98488L5.23028 7.72527L4.48028 9.02431L2.91689 9.79737C2.81627 9.84616 2.72765 9.91653 2.65733 10.0035C2.58701 10.0904 2.53673 10.1918 2.51006 10.3004C2.16331 11.7462 2.16331 13.2535 2.51008 14.6993C2.53674 14.8079 2.58702 14.9093 2.65734 14.9962C2.72765 15.0832 2.81628 15.1535 2.91689 15.2023L4.48022 15.9754L5.23022 17.2744Z"
                stroke="#A4AAB6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export default SettingsIcon