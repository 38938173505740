import Modal from 'react-modal';
import React, { FC, useState, useEffect } from 'react';
import clsx from 'clsx';
import Button from "@/template/small_ui/button";
import {useDispatch, useSelector} from 'react-redux';
import {openShareTIcket} from "store/modals/actions";
import styles from "@/template/styles/shareModal.module.scss";
import {updateSnackBar, updateTicketId} from "store/common/actions";
import ModalLayout from "@/template/ui/modal_layout";
import Image from 'next/image';



interface  Props {

}

const ShareTicketModal: FC<Props> = () => {
    const open = useSelector((state:any) => state.modals.openShareTicket)
    const dispatch = useDispatch()
    const [link, setLink] = useState<string>('')
    const id = useSelector((state:any) => state.common.ticketId)

    const commonShareFUnction = (link:string, sm: string) => {
        window.open(link)
    }

    const buttons:Array<any> = [
        {
            id: 0,
            image: 'whatsApp.svg',
            text: 'Whatsapp',
            onClick: (link:string) => {
                commonShareFUnction(`whatsapp://send?text=`+link, 'Whatsapp')
            }
        },

        {
            id: 1,
            image: 'twitter.svg',
            text: 'Twitter',
            onClick: (link:string) => {
                commonShareFUnction(`https://twitter.com/intent/tweet?url=`+link, 'Whatsapp')

            }
        },

        {
            id: 2,
            image: 'telegram.svg',
            text: 'Telegram',
            onClick: (link:string) => {
                commonShareFUnction(`https://t.me/share/url?url=${link}`, 'Telegram')
            }
        },

        {
            id: 3,
            image: 'facebook.svg',
            text: 'Facebook',
            onClick: (link:string) => {
                commonShareFUnction('https://www.facebook.com/sharer/sharer.php?u='+ encodeURIComponent(link), 'Facebook')
            }
        },
    ];

    const copyLink = (toCopy: string) => {
        navigator.clipboard.writeText(toCopy)
    }

    useEffect(() => {

        let root = window.origin

        if(id.length) {
            setLink(root + '/' + 'sport?sportTicketId=' + id)
        }

    }, [id])
    
    return (
        <Modal
            ariaHideApp={false}
            isOpen={open}
            className={clsx(styles.modal, 'ReactModal__Deposit', 'theme_box_bg_shaded3')}
            onRequestClose={() => {
                dispatch(updateTicketId(''))
            }}
        >

            <ModalLayout title={'Share'} onClose={() => {dispatch(openShareTIcket(false))}} >
                <>
                    <div className={styles.modalContent}>

                        <div className={styles.modalContent__buttons}>
                            {buttons.map((btn:any, i) => {
                                return (
                                    <div onClick={() => btn.onClick(link)} className={styles.modalContent__item} key={'socialShare'+i}>
                                        <Image className={styles.modalContent__smImage} src={'/default/icons/shareIcons/'+btn.image} alt={btn.image} width={64} height={64}/>

                                        <span className={clsx(styles.modalContent__smName, 'theme_white_dark_text', 'theme_share_ticket')}>
                                            {btn.text}
                                        </span>
                                    </div>
                                )
                            })}
                        </div>

                        <div className={clsx(styles.modalContent__shareSection, 'theme_box_no_shadow')}>

                            <div className={clsx(styles.link, 'theme_white_dark_text')}>
                                {link}
                            </div>

                            <Button
                                className={styles.button}
                                onClick={() => {
                                    copyLink(link)
                                    dispatch(updateSnackBar('Link-ul este copiat', 'success' ))
                                }}
                                text={'copiază'}
                            />

                        </div>
                    </div>
                </>
            </ModalLayout>

        </Modal>
    )
}

export default ShareTicketModal