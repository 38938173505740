function SlotsIconSpins() {
    return(
        <svg className={'svg-icon'} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M8 22C8 21.4477 8.44772 21 9 21H15C15.5523 21 16 21.4477 16 22V23H8V22Z" fill="#929396"/>
            <path d="M18 22C18 21.4477 18.4477 21 19 21H21C21.5523 21 22 21.4477 22 22C22 22.5523 21.5523 23 21 23H19C18.4477 23 18 22.5523 18 22Z" fill="#929396"/>
            <path d="M2 22C2 21.4477 2.44772 21 3 21H5C5.55228 21 6 21.4477 6 22C6 22.5523 5.55228 23 5 23H3C2.44772 23 2 22.5523 2 22Z" fill="#929396"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M21 5H17.6586C16.8349 2.66962 14.6124 1 12 1C9.38756 1 7.16508 2.66962 6.34141 5H3C2.44772 5 2 5.44772 2 6V8H22V6C22 5.44772 21.5523 5 21 5ZM12.7858 3.41844L12.4755 2.46353C12.3259 2.00287 11.6741 2.00287 11.5245 2.46352L11.2142 3.41844C11.1473 3.62445 10.9553 3.76393 10.7387 3.76393H9.73462C9.25025 3.76393 9.04887 4.38374 9.44072 4.66844L10.253 5.25861C10.4283 5.38593 10.5016 5.61162 10.4347 5.81763L10.1244 6.77254C9.97471 7.2332 10.502 7.61626 10.8938 7.33156L11.7061 6.74139C11.8814 6.61407 12.1186 6.61407 12.2939 6.74139L13.1062 7.33156C13.498 7.61626 14.0253 7.2332 13.8756 6.77254L13.5653 5.81763C13.4984 5.61162 13.5717 5.38593 13.747 5.25861L14.5593 4.66844C14.9511 4.38374 14.7497 3.76393 14.2654 3.76393H13.2613C13.0447 3.76393 12.8527 3.62445 12.7858 3.41844Z" fill="#929396"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M3 10C1.89543 10 1 10.8954 1 12V17C1 18.1046 1.89543 19 3 19H7V10H3ZM2.75 13.5H4.75V13.7574C4.40666 14.0397 3.97634 14.4519 3.60483 14.9312C3.26271 15.3725 2.93269 15.9188 2.80523 16.504C2.74647 16.7738 2.97386 17 3.25 17C3.52614 17 3.75079 16.7718 3.84057 16.5106C3.94656 16.2023 4.14049 15.8724 4.39517 15.5438C4.78039 15.0469 5.24673 14.6275 5.55 14.4L5.75 14.25V12.5H2.75C2.47386 12.5 2.25 12.7239 2.25 13C2.25 13.2761 2.47386 13.5 2.75 13.5Z" fill="#929396"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M15 10H9V19H15V10ZM10.75 13.5H12.75V13.7574C12.4067 14.0397 11.9763 14.4519 11.6048 14.9312C11.2627 15.3725 10.9327 15.9188 10.8052 16.504C10.7465 16.7738 10.9739 17 11.25 17C11.5261 17 11.7508 16.7718 11.8406 16.5106C11.9466 16.2023 12.1405 15.8724 12.3952 15.5438C12.7804 15.0469 13.2467 14.6275 13.55 14.4L13.75 14.25V12.5H10.75C10.4739 12.5 10.25 12.7239 10.25 13C10.25 13.2761 10.4739 13.5 10.75 13.5Z" fill="#929396"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M21 10H17V19H21C22.1046 19 23 18.1046 23 17V12C23 10.8954 22.1046 10 21 10ZM18.75 13.5H20.75V13.7574C20.4067 14.0397 19.9763 14.4519 19.6048 14.9312C19.2627 15.3725 18.9327 15.9188 18.8052 16.504C18.7465 16.7738 18.9739 17 19.25 17C19.5261 17 19.7508 16.7718 19.8406 16.5106C19.9466 16.2023 20.1405 15.8724 20.3952 15.5438C20.7804 15.0469 21.2467 14.6275 21.55 14.4L21.75 14.25V12.5H18.75C18.4739 12.5 18.25 12.7239 18.25 13C18.25 13.2761 18.4739 13.5 18.75 13.5Z" fill="#929396"/>
        </svg>
    )
}

export default SlotsIconSpins