import useAuth from "@/AbsoluteComponents/hooks/auth";
import styles from "@/template/styles/Header.module.scss";
import clsx from "clsx";
import SpinsOffersIcon from "@/AbsoluteComponents/themes/spins/svg/spinsOffersIcon";
import Link from "next/link";
import React from "react";
import OffersIcon from "themes/spins/svg/offersIcon";
import {FC} from "react";
import Offer from "@/AbsoluteComponents/interfaces/offer";
import {useSelector} from "react-redux";
import strings from "@/AbsoluteComponents/utils/strings";
import ActiveOffersIcon from "@/AbsoluteComponents/themes/spins/svg/ActiveOffersIcon";
interface Props{
    isSideBar?:boolean,
    mobileOffers?:boolean,
    needTitle?:boolean,
    columnSidebar?:boolean
}
const ActiveOffers:FC<Props> = ({isSideBar, mobileOffers, needTitle, columnSidebar}) => {

    const offer:Array<Offer> = useSelector((state:any) => state.account.offers);

    return (
        <div>
            <div className={clsx(styles.columnFields, 'offersComponent', "theme_text_mainColor")}>

                <div className={clsx(isSideBar && styles.offersComponent__wrapper)}>
                     <span className={clsx(styles.columnFields__icon, mobileOffers && "theme_white_dark_text")}>
                         <ActiveOffersIcon />
                     </span>
                </div>

                {needTitle && (
                    <span
                        className={clsx(styles.columnFields__title)}
                    >
                        {strings['oferte_active']}
                    </span>
                )}

                {offer.length > 0 &&
                    <span
                        className={clsx(
                            'headerOffer',
                            'bellPromotion',
                            isSideBar && 'sidebarBadge',
                            mobileOffers && 'mobileSideBar',
                            columnSidebar && 'columnSidebarBadge'
                        )}>

                        <span>{offer.length > 100 ? '100+' : offer.length}</span>
                    </span>
                }

            </div>
        </div>



    )
}
export default ActiveOffers