
function RtpColdSpins(){
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
            {/*<mask id="mask0_1001_2634" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="25">*/}
            {/*    <rect y="0.368164" width="24" height="24" fill="#D9D9D9"/>*/}
            {/*</mask>*/}
            <g mask="url(#mask0_1001_2634)">
                <path d="M11 22.3682V18.2182L7.75 21.4182L6.35 20.0182L11 15.3682V13.3682H9L4.35 18.0182L2.95 16.6182L6.15 13.3682H2V11.3682H6.15L2.95 8.11816L4.35 6.71816L9 11.3682H11V9.36816L6.35 4.71816L7.75 3.31816L11 6.51816V2.36816H13V6.51816L16.25 3.31816L17.65 4.71816L13 9.36816V11.3682H15L19.65 6.71816L21.05 8.11816L17.85 11.3682H22V13.3682H17.85L21.05 16.6182L19.65 18.0182L15 13.3682H13V15.3682L17.65 20.0182L16.25 21.4182L13 18.2182V22.3682H11Z" fill="#4759FE"/>
            </g>
        </svg>
    )
}
export default RtpColdSpins