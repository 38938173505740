import {useRef, useEffect, ReactNode} from 'react';
import { createPortal } from 'react-dom';
import {ClickAwayListener} from '@mui/base';
import styles from '@/template/styles/depositSelectDropDown.module.scss';
import clsx from 'clsx';

interface PortalProps {
    children: ReactNode,
    open: boolean,
    onClose():void
    name?:string
}

export const SelectorPortal = (props: PortalProps) => {
    const {open, onClose, name} = props;

    const ref = useRef<Element | null>(null);

    useEffect(() => {

        const wrapperElement = document.createElement('div');
        wrapperElement.setAttribute("class", 'selector-options');
        document.body.appendChild(wrapperElement);
        ref.current = wrapperElement;

        return () => {
            document.body.removeChild(wrapperElement);
        }
    }, []);

    return  (open && ref.current) ? (

        <ClickAwayListener onClickAway={onClose}>
            <div
                onClick={() => {
                    if(name == "deposit_method") {
                        onClose()
                    }
                }}
                className={clsx(styles.portal, name == "deposit_method" && styles.deposit_portal)}>
                {createPortal(props.children, ref.current, )}
            </div>
        </ClickAwayListener>

    ) : null

}

export default SelectorPortal