import styles from "themes/spins/styles/casinoSportToggler.module.scss";
import clsx from 'clsx';
import React, {FC} from 'react';
import SportIconSpins from "themes/spins/svg/sportIconSpins";
import CasinoLifeSpins from "themes/spins/svg/casinoLifeSpins";


interface Props {
    setType(type: "casino" | "sport"):void
    offerType: "casino" | "sport"
}

const CasinoSportsToggler:FC<Props> = ({ offerType, setType, }) => {

    let offerTypesArr:any = [
        {
          name:  "casino",
            icon: <CasinoLifeSpins/>
        },
        {
            name: "sport",
            icon: <SportIconSpins />
        }
    ]

    return (
        <div className={clsx(styles.main, 'theme_searchInput_header')}>

            {offerTypesArr.map((x: any, i:number) => {
                return (
                    <div style={{borderRadius: 20}}
                        key={'offer' + i }
                        onClick={() => {
                            // @ts-ignore
                            setType(x.name)
                        }}
                        className={clsx(
                            styles.sides,
                            styles.left,
                            styles.toggleButtonsCasino,
                            'theme__headerContainer__list',
                            offerType=== x.name && 'themes_balance_wrapper',


                        )}
                    >
                        <span
                            className={clsx( 'theme_white_dark_text' )}
                            style={{marginRight: 4}}
                        >
                            {x.icon}
                        </span>
                        
                        <span
                            className={clsx( offerType=== x.name && 'theme_white_dark_text')}
                        >
                            {x.name}
                        </span>
                    </div>

                )
            })}

            <div className={clsx(styles.selector, offerType === "sport" && styles.floated)}>

            </div>

        </div>
    )
}

export default CasinoSportsToggler