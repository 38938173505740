import strings from "utils/strings";
import TextField from "ui/textfield";
import clsx from 'clsx';
import {useEffect, useState} from "react";
import SelectDropDown from "ui/selectDropDown";
import useAuth from "hooks/auth";
import {useDispatch} from 'react-redux';
import {updateSnackBar} from "store/common/actions";
import {getSupportUser} from "services/account";
import {useForm} from 'react-hook-form';
import {FC} from 'react';
import Button from "@/template/small_ui/button";
import { useSelector} from 'react-redux';
import {openSupport} from "store/modals/actions";

interface Props {
    publicForm: boolean,
    modal?: boolean,
}


const ContactForm: FC<Props>  = ({modal, publicForm}:any) => {
    const auth = useAuth();
    const user = auth.getUser();
    const inView = useSelector((state:any) => state.common.footerInView)
    const [pgLoaded, setPgLoaded] = useState<boolean>()
    const open = useSelector((state:any) => state.modals.openSupport);

    // console.log(open);

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue
    } = useForm();

    const [sending, isSending] = useState(false);
    const [sentCount, setSentCount] = useState(0);

    const dispatch = useDispatch();

    const subjects = [
        {
            key: '1',
            label: 'Parolă uitată',
        },
        {
            key: '2',
            label: 'Înregistrare',
        },
        {
            key:'3',
            label: 'Depunere/Retragere',
        },
        {
            key:'4',
            label: 'Promoții',
        },
        {
            key: '5',
            label: 'Limite'
        },
        {
            key: '6',
            label: 'Reclamații'
        },
        {
            key: '7',
            label: 'Raportează alt jucător'
        },
        {
            key: '8',
            label: 'Raportează erori ale jocului'
        },
        {
            key: '9',
            label: 'Altele'
        },
    ];


    const onSubmit = async (data:any) => {
        const firstName:string = publicForm ? data.firstName : user.firstName;
        const lastName:string = publicForm ? data.lastName : user.lastName;
        const email:string = data.email;
        const message:string = data.text;
        const subject:string = data.subject;

        let label:string = '';

        subjects.map((item:any) => {
            if (item.key === subject) {
                label = item.label;
            }
        });

        isSending(true);
        const request = await getSupportUser(firstName, lastName, email, message, label, subject );
        isSending(false);

        if(!request.error) {
            setSentCount(sentCount + 1);
            dispatch(updateSnackBar('Mesajul a fost trimis cu succes', 'success'));
            dispatch(openSupport(false));

            setValue('text', '', {shouldValidate: false});
        } else {
            dispatch(updateSnackBar( request.message || '', 'error'));
        }

    };

    const emailProps = {
        name: 'email'
    };

    const lastNameProps = {
        name: 'lastName'
    }

    const firstNameProps = {
        name: 'firstName'
    }

    useEffect(() => {
        if (user) {
            setValue('email', user.email,  {shouldValidate: false})
            setValue('firstName',user.lastName,  {shouldValidate: false});
            setValue('lastName',user.firstName,  {shouldValidate: false})
        } else {
            setValue('email', '',  {shouldValidate: false})
            setValue('firstName', '',  {shouldValidate: false});
            setValue('lastName', '',  {shouldValidate: false})
        }

    }, [user]);

    useEffect(() => {
        setPgLoaded(true)
    }, [])


    return (
        <div  className={clsx(!modal && 'container','spinsRow', 'supportWrapper', !modal && 'supportMarginBot')}>

            <div className={clsx( !modal && "row")}>
                <form
                    style={{
                        position: 'relative',
                        height: `auto`,
                        paddingRight: '0 !important'
                    }}
                    onSubmit={handleSubmit(onSubmit)}
                    key={'message-'+sentCount}
                    className={'contactForm'}
                >

                    <div className={clsx(modal)} >

                        {publicForm === true &&
                            <div className={clsx( !modal ? 'colMargin' : 'colModalMargin')} >
                                <div className={modal ? 'col-12' : 'col-md-12'}>
                                    <div className="mb-3">
                                        <div className={clsx('depositFilter-formInput-support',)}>

                                            <TextField
                                                noShadow
                                                {...register('firstName', {required: "Acest cimp trebuie completat",})}
                                                label={'Prenume'}
                                                type={'text'}
                                                placeholder={'Prenume'} {...firstNameProps}
                                            />
                                            {errors.firstName?.type === "required" && (
                                                <p className={clsx("errorMsg", 'with-mt')}>{strings['cimp_obligatoriu']}</p>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className={clsx(modal ? 'col-12' : 'col-md-12')}>
                                    <div className="mb-3">
                                        <div className={clsx('depositFilter-formInput-support',)}>
                                            <TextField
                                                noShadow
                                                {...register('lastName', {
                                                required: "Acest cimp trebuie completat",
                                            })}
                                                label={'Numele de familie'}
                                                type={'text'}
                                                placeholder={'Prenume'} {...lastNameProps}
                                            />
                                            {/*<button className={'contactForm-btnClose'}><CloseIcon /></button>*/}
                                            {errors.lastName?.type === "required" && (
                                                <p className={clsx("errorMsg", 'with-mt')}>{strings['cimp_obligatoriu']}</p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className={clsx('depositFilter-formInput-support',)}>
                                    <TextField
                                        noShadow
                                        {...register('email', {
                                        required: "Acest cimp trebuie completat",
                                    })}
                                               label={'Email'}
                                               type={'email'}
                                               placeholder={'email'} {...emailProps}/>
                                    {/*<button className={'contactForm-btnClose'}><CloseIcon /></button>*/}
                                    {errors.email?.type === "required" && (
                                        <p className={clsx("errorMsg", 'emailSupport')}>{strings['cimp_obligatoriu']}</p>
                                    )}

                                    {/*<TextField defaultValue={'user email'}  label={'EMAIL'} type={'email'} placeholder={'email'} {...emailProps}/>*/}
                                </div>
                            </div>
                        }

                        <div style={{padding: '0 !important'}} className={clsx(!modal ? ' colMargin' : 'col_modal')}>

                            <div className={clsx(!modal && 'col-md-12', 'colPadding')}>
                                <div className="mb-20">
                                    <SelectDropDown
                                        noShadow
                                        inputProps={register('subject', {
                                            required: "Acest cimp trebuie completat",
                                        })}
                                        // label={'Subiect'}
                                        options={[
                                            {key: '', label: 'Subiect'},
                                            ...subjects.map((item:any) => ({
                                                key: item.key,
                                                label: item.label
                                            }))
                                        ]}
                                        onChange={(key) => {
                                            setValue('subject', key, {shouldValidate: true});
                                        }}
                                    />
                                    {errors.subject?.type === "required" && (
                                        <p className={clsx("errorMsg", 'emailSupport')}>{strings['cimp_obligatoriu']}</p>
                                    )}
                                </div>
                            </div>

                            <div className={'textArea'} style={{padding: '0 1px'}}>
                                <span className={clsx('textArea-text', 'theme_white_dark_text')}>{strings['descrie_problema']}</span>
                                <textarea
                                    {...register('text', {
                                        required: "Acest cimp trebuie completat",
                                    })}
                                    className={clsx(
                                        'textArea-input',
                                        'noShadow',
                                        'theme_box_simple',
                                        'theme_white_dark_text',
                                        'theme_textarea_input'
                                    )}
                                />

                                {errors.text?.type === "required" && (
                                    <p className={clsx("errorMsg", 'messageSupport')}>{strings['cimp_obligatoriu']}</p>
                                )}
                            </div>
                        </div>

                    </div>
                    
                    <Button type={'submit'} loading={sending} text={strings['trimite']} style={{margin: `${modal ? "35px auto 0" : "35px auto 35px"}`, width: "fit-content"}}/>
                    
                </form>
            </div>
        </div>
    )
}
export default ContactForm;