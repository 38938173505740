
function EmailIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
            {/*<mask id="mask0_919_46170" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="25">*/}
            {/*    <rect x="0.25" y="0.5" width="24" height="24" fill="#D9D9D9"/>*/}
            {/*</mask>*/}
            {/*<g mask="url(#mask0_919_46170)">*/}
                <path d="M12.25 22.5C10.8667 22.5 9.56667 22.2375 8.35 21.7125C7.13333 21.1875 6.075 20.475 5.175 19.575C4.275 18.675 3.5625 17.6167 3.0375 16.4C2.5125 15.1833 2.25 13.8833 2.25 12.5C2.25 11.1167 2.5125 9.81667 3.0375 8.6C3.5625 7.38333 4.275 6.325 5.175 5.425C6.075 4.525 7.13333 3.8125 8.35 3.2875C9.56667 2.7625 10.8667 2.5 12.25 2.5C13.6333 2.5 14.9333 2.7625 16.15 3.2875C17.3667 3.8125 18.425 4.525 19.325 5.425C20.225 6.325 20.9375 7.38333 21.4625 8.6C21.9875 9.81667 22.25 11.1167 22.25 12.5V13.95C22.25 14.9333 21.9125 15.7708 21.2375 16.4625C20.5625 17.1542 19.7333 17.5 18.75 17.5C18.1667 17.5 17.6167 17.375 17.1 17.125C16.5833 16.875 16.15 16.5167 15.8 16.05C15.3167 16.5333 14.7708 16.8958 14.1625 17.1375C13.5542 17.3792 12.9167 17.5 12.25 17.5C10.8667 17.5 9.6875 17.0125 8.7125 16.0375C7.7375 15.0625 7.25 13.8833 7.25 12.5C7.25 11.1167 7.7375 9.9375 8.7125 8.9625C9.6875 7.9875 10.8667 7.5 12.25 7.5C13.6333 7.5 14.8125 7.9875 15.7875 8.9625C16.7625 9.9375 17.25 11.1167 17.25 12.5V13.95C17.25 14.3833 17.3917 14.75 17.675 15.05C17.9583 15.35 18.3167 15.5 18.75 15.5C19.1833 15.5 19.5417 15.35 19.825 15.05C20.1083 14.75 20.25 14.3833 20.25 13.95V12.5C20.25 10.2667 19.475 8.375 17.925 6.825C16.375 5.275 14.4833 4.5 12.25 4.5C10.0167 4.5 8.125 5.275 6.575 6.825C5.025 8.375 4.25 10.2667 4.25 12.5C4.25 14.7333 5.025 16.625 6.575 18.175C8.125 19.725 10.0167 20.5 12.25 20.5H17.25V22.5H12.25ZM12.25 15.5C13.0833 15.5 13.7917 15.2083 14.375 14.625C14.9583 14.0417 15.25 13.3333 15.25 12.5C15.25 11.6667 14.9583 10.9583 14.375 10.375C13.7917 9.79167 13.0833 9.5 12.25 9.5C11.4167 9.5 10.7083 9.79167 10.125 10.375C9.54167 10.9583 9.25 11.6667 9.25 12.5C9.25 13.3333 9.54167 14.0417 10.125 14.625C10.7083 15.2083 11.4167 15.5 12.25 15.5Z" fill="#929396"/>
            {/*</g>*/}
        </svg>
    )
}
export default EmailIcon