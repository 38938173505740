const AccIcon = () => {
    return (
        <svg className={"svg-icon"} width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12 21.5C16.9706 21.5 21 17.4706 21 12.5C21 7.52944 16.9706 3.5 12 3.5C7.02944 3.5 3 7.52944 3 12.5C3 17.4706 7.02944 21.5 12 21.5Z"
                stroke="#A4AAB6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M12 15.5C14.0711 15.5 15.75 13.8211 15.75 11.75C15.75 9.67893 14.0711 8 12 8C9.92893 8 8.25 9.67893 8.25 11.75C8.25 13.8211 9.92893 15.5 12 15.5Z"
                stroke="#A4AAB6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M5.98047 19.1913C6.54542 18.0806 7.40671 17.1478 8.469 16.4963C9.53128 15.8448 10.7531 15.5 11.9993 15.5C13.2455 15.5 14.4673 15.8448 15.5296 16.4963C16.5919 17.1478 17.4532 18.0806 18.0181 19.1913"
                stroke="#A4AAB6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export default AccIcon