import React, {useState, useEffect, useRef} from 'react'
import {refreshToken} from "services/account";
import {
    getAuthToken, getExpiresIn, getLoggedInTime,
    getRefeshToken,
    saveRedirectTarget,
    setAuthToken, setExpiresIn, setLoggedInTime,
    setRefreshToken
} from "utils/cookies";
import useAuth from "hooks/auth";
import {useDispatch, useSelector} from 'react-redux';
import { useRouter } from 'next/router';
import useGame from "hooks/game";
import {setEnableWheel, showAccountSideBar, setIsGuest} from "store/common/actions";
import clsx from 'clsx';
import {openLoginModal} from "@/AbsoluteComponents/store/modals/actions";
import {bingo_path} from "@/AbsoluteComponents/themes/spins/config/constants";

interface Props {
    children:any
}

const SpinsGeneralComponentView:React.FC<Props> = ({  children}) => {
    
    // GENERAL DEPENDANCIES START
    const auth = useAuth()
    const dispatch = useDispatch();
    const router = useRouter()
    const gameHook = useGame()
    let isAccount:boolean = router.pathname.search('/account') !== -1;
    let isAvailableOffers = router.pathname.search('/account/available-offers') !== -1
    let isBingo = router.pathname === bingo_path
    // GENERAL DEPENDANCIES END
    
    // WHEEL DEPENDANCIES START
    const [canSpin, setCanSpin] = useState<boolean>(false);
    const [dataLoaded, setDataLoaded] = useState<boolean>(false);
    const [spinnedToday, setSpinnedToday] = useState<boolean>(true);
    const [isDefault, setIsDefault] = useState<boolean | null>(null);
    const wheelStatus:any = useSelector((state:any) => state.account.spinStatus);
    const timer:any = useRef(null);
    
    const runRefreshToken = async () => {
        const loggedTimestamp = Number(getLoggedInTime());
        const expiresInInSeconds = Number(getExpiresIn());
        // const expiresInInSeconds = 4;
        if(expiresInInSeconds && loggedTimestamp) {
            const expiresInInMilliseconds = expiresInInSeconds * 1000;
            const futureTimestamp = loggedTimestamp + expiresInInMilliseconds;
            const difference = 15000;
            
            if(Number(getExpiresIn()) > 0 && Date.now() >= futureTimestamp - difference) {
                (window as any)._refreshToken()
            }
        }
    }
    
    
    // WHEEL DEPENDANCIES END
    
    useEffect(() => {

        const onResize = () => {
            if(isAccount && !isAvailableOffers) {
                if(window.innerWidth > 1300) {
                    dispatch(showAccountSideBar(true))
                } else {
                    dispatch(showAccountSideBar(false))
                }
            }

            if(!isAccount){
                dispatch(showAccountSideBar(false))
            }

            if(isAvailableOffers) {
                dispatch(showAccountSideBar(false))
            }
        }

        onResize()

        window.addEventListener('res', onResize)


        return () => {
            window.removeEventListener('resize', onResize)
        }

        // SIDEBARS APPEAR/DISAPPEAR END SIDEBARS APPEAR/DISAPPEAR END SIDEBARS APPEAR/DISAPPEAR END
    }, [router])

    useEffect(() => {

        if(auth.isLoggedIn()) {

            (window as any)._refreshToken = async () => {
                if(getRefeshToken().length) {
                    const req = await refreshToken()

                    const data = req.response
                    if(typeof data === "object" && Object.keys(data).length && data.hasOwnProperty("accessToken")) {
                        setAuthToken(data["accessToken"])
                        setRefreshToken(data["refreshToken"])
                        setExpiresIn(data["expiresIn"])
                        setLoggedInTime(Date.now())

                        const tokenUpdateEvent = new CustomEvent("token_update", {
                            detail: {
                                expiresIn: String(data["expiresIn"])
                            }
                        })

                        window.dispatchEvent(tokenUpdateEvent)
                    }
                }
            }

            runRefreshToken()

            timer.current = setInterval(() => {
                runRefreshToken()
            }, 5000)

        } else {
            clearInterval(timer.current)
        }

        if(router.asPath.includes("/joc") && router.asPath.includes("/real") && !getAuthToken()) {
            saveRedirectTarget(router.asPath)
            router.push("/")
            dispatch(openLoginModal(true));
        }

        if(auth.isLoggedIn()) {
            auth.updateDocs()
        }

    }, [auth.isLoggedIn(), auth.isUpdating()])

    useEffect(() => {
        setIsGuest(getAuthToken().length === 0);
        auth.updateProfile();
        gameHook.updateQuestLaderboard()

    }, [])

    useEffect(() => {
        if (!auth.isLoggedIn()) dispatch(setIsGuest(true))

    }, [auth.isUpdating()]);

    useEffect(() => {
        if(auth.isLoggedIn()) {
            auth.updateQuestStatus("GET")
        }

    }, [auth.isLoggedIn()]);

    // WHEEL USE EFFECTS START   WHEEL USE EFFECTS START   WHEEL USE EFFECTS START   WHEEL USE EFFECTS START
    useEffect(() => {
        const getMethods = async() => {
            if(auth.isLoggedIn()) {

                setCanSpin(true);

                let attributes: any
                let wasTriggered: string

                if(wheelStatus.length) {
                    attributes = wheelStatus

                    let triggered = attributes.find((attr: any) => {
                        return attr.attributeName === 'WOFTriggered'
                    })

                    let wof_prizes = attributes.find((attr: any) => {
                        return attr.attributeName === 'WOFPrizes'
                    })

                    wasTriggered = triggered.value


                    if(wasTriggered === 'NotTriggered') {
                        setSpinnedToday(false)
                        // setCanSpin(true)
                    } else if (wasTriggered === 'Triggered') {
                        setSpinnedToday(true)
                        setCanSpin(false)
                    }

                    if (wof_prizes.value === 'Default') {
                        setIsDefault(true)
                        setCanSpin(false)

                    } else {
                        setIsDefault(false)
                        setCanSpin(true)
                    }
                }

                setDataLoaded(true)
            }
        }

        if(auth.isLoggedIn() && wheelStatus.length) {
            getMethods()
        }

        if(!auth.isLoggedIn()) {
            setCanSpin(false)
            dispatch(setEnableWheel('loading'))
        }

    }, [auth.isLoggedIn(), wheelStatus]);

    useEffect(() => {
        if(dataLoaded) {
            if( canSpin && !spinnedToday && auth.isLoggedIn() && !isDefault) {
                dispatch(setEnableWheel('enable'))
            } else if (!canSpin || spinnedToday || isDefault === null || isDefault === true) {
                dispatch(setEnableWheel('disable'))
            }
        }

    }, [canSpin, dataLoaded, spinnedToday, auth.isLoggedIn(), isDefault])
    
    // WHEEL USE EFFECTS END   WHEEL USE EFFECTS END   WHEEL USE EFFECTS END   WHEEL USE EFFECTS END
    
    return (
        <div id={"gen_component"} className={clsx('general_component', isBingo && "no_header_footer")}>
            {children}
        </div>
    )
}

export default SpinsGeneralComponentView