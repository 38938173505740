import React, { useState } from 'react';
import clsx from 'clsx';
import { FC } from 'react';
import CMS from 'utils/cms';
import strings from 'utils/strings';
import { Title } from 'components/title';
import CasinoSportsToggler from "@/template/small_ui/casino_sports_toggler";
import styles from 'themes/spins/styles/cmsPromotii.module.scss';
import PromotionItem from "themes/spins/cms-views/promotions/promotion_item";

interface Props {
    json: any;
    api_id?: string;
}

const PromotionsBlock: FC<Props> = ({ json }) => {
    const data = CMS.getPromotions(json);
    const [offerType, setOfferType] = useState<'casino' | 'sport'>('casino');

    const filteredPromotions = data.all_promotions.filter((prom: any) =>
        offerType === 'sport' ? prom.is_sport : !prom.is_sport
    );

    return (
        <div style={{paddingBottom: '100px'}}>

            <div className={clsx(styles.header)}>
                <Title homePage forGuest title={strings['promotions']} />

                <div className={styles.switch} >
                    <CasinoSportsToggler
                        setType={(type: 'casino' | 'sport') => {
                            setOfferType(type);
                        }}
                        offerType={offerType}
                    />
                </div>

            </div>

            <div className={clsx(styles.promotions_block, 'promotionsBlock__items', )}>
                {filteredPromotions.length > 0 &&
                    filteredPromotions.map((item: any) => (
                        <PromotionItem  key={item.api_id} item={item}/>
                    ))}
            </div>

        </div>
    );
};

export default PromotionsBlock;