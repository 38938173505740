import React, { useRef, useEffect } from 'react';

interface Props {
    play: string
    isLoaded?():void
    muted?: boolean
    click: number
    hover: boolean | null
    clickEnd: boolean
}

const SoundPlayer: React.FC<Props> = ({play, isLoaded, muted, click, hover, clickEnd}) => {
    const spinAudioRef = useRef<HTMLAudioElement>(null);
    const clickAudioRef = useRef<HTMLAudioElement>(null);
    const hoverAudioRef = useRef<HTMLAudioElement>(null);
    const clickEndAudioRef = useRef<HTMLAudioElement>(null);

    useEffect(() => {
        if(play === 'spin') {
            if(spinAudioRef.current !== null) {
                spinAudioRef.current.play()

            }
        }

    }, [play])

    useEffect(() =>{
        if(clickAudioRef.current !== null && click > 0) {
            clickAudioRef.current.play()
        }
    }, [click])

    useEffect(() =>{
        if(hoverAudioRef.current !== null && hover) {
            hoverAudioRef.current.play()
        }
    }, [hover])

    useEffect(() =>{
        if(clickEndAudioRef.current !== null && clickEnd) {
            clickEndAudioRef.current.play()
        }
    }, [clickEnd])

    return (
        <div>
            {/*<audio muted={muted} onLoadedMetadata={isLoaded} ref={spinAudioRef}>*/}
            {/*    <source src="/sound/spin2.mp3" type="audio/mpeg" />*/}
            {/*</audio>*/}

            <audio muted={muted} onLoadedMetadata={isLoaded} ref={spinAudioRef}>
                <source src="/sound/spin-sound2.mp3" type="audio/mpeg" />
            </audio>

            {/*<audio muted={muted} ref={tadaAudioRef}>*/}
            {/*    <source src="/sound/tada.mp3" type="audio/mpeg" />*/}
            {/*</audio>*/}

            <audio muted={muted} ref={clickAudioRef}>
                <source src="/sound/click-sound.mp3" type="audio/mpeg" />
            </audio>

            <audio muted={muted} ref={hoverAudioRef} >
                <source src="/sound/hover-sound.mp3" type="audio/mpeg" />
            </audio>

            <audio muted={muted} ref={clickEndAudioRef} >
                <source src="/sound/click-end.mp3" type="audio/mpeg" />
            </audio>

        </div>
    );
};

export default SoundPlayer;
