import clsx from "clsx";
import {useSelector} from "react-redux";
import {useEffect} from "react";


const PaginationBottom = () => {

    const active:boolean = useSelector((state:any) => state.common.paginationBottom)
    const thin:boolean = useSelector((state:any) => state.common.bottomThin)

    useEffect(() => {

        const controllerZendesk = () => {
            let windowWidth = window.innerWidth
            if(active && (windowWidth >= 850 && windowWidth <= 1000)) {
                document.body.classList.add('upperZendesk')
            } else {
                document.body.classList.remove('upperZendesk')
            }
        }

        controllerZendesk()

        window.addEventListener("resize", controllerZendesk)

        return () => {
            window.removeEventListener("resize", controllerZendesk)
        }

    }, [active]);

    return (
        <div className={clsx(
            'pagination-bottom',
            'theme_pagination',
            active && 'active',
            thin && 'thin'
        )}/>
    )
}

export default PaginationBottom