import {useRef, useEffect, ReactNode} from 'react';
import { createPortal } from 'react-dom';
import {ClickAwayListener} from '@mui/base';

interface PortalProps {
    children: ReactNode,
    open: boolean,
    onClose():void
}

export const SelectorPortal = (props: PortalProps) => {
    const {open, onClose} = props;

    const ref = useRef<Element | null>(null);

    useEffect(() => {

        const wrapperElement = document.createElement('div');
        wrapperElement.setAttribute("class", 'selector-options');
        document.body.appendChild(wrapperElement);
        ref.current = wrapperElement;

        return () => {
            document.body.removeChild(wrapperElement);
        }
    }, []);

    return  (open && ref.current) ? <ClickAwayListener onClickAway={onClose}><div>{createPortal(props.children, ref.current, )}</div></ClickAwayListener> : null


}

export default SelectorPortal