import GameInterface from "interfaces/game";

export const OPEN_LOGIN_MODAL = '@modals/OPEN_LOGIN_MODAL';
export const OPEN_UPLOAD_DOCUMENT_MODAL = '@modals/OPEN_UPLOAD_DOCUMENT_MODAL';
export const OPEN_UPLOAD_MAIN_DOCUMENT_MODAL = '@modals/OPEN_UPLOAD_MAIN_DOCUMENT_MODAL';
export const OPEN_DEPOSIT_MODAL = '@modals/OPEN_DEPOSIT_MODAL';
export const OPEN_WITHDRAW_MODAL = '@modals/OPEN_WITHDRAW_MODAL';
export const UPDATE_CAMPAIGN_ID = '@modals/UPDATE_CAMPAIGN_ID';
export const OPEN_SUPPORT_MODAL = '@modals/OPEN_SUPPORT_MODAL';
export const OPEN_FORGOT_PASSWORD_MODAL = '@modals/OPEN_FORGOT_PASSWORD_MODAL';
export const OPEN_MINI_GAME_MODAL = '@modals/OPEN_MINI_GAME_MODAL';
export const OPEN_FULL_GAME_MODAL = '@modals/OPEN_FULL_GAME_MODAL';
export const OPEN_SIGNUP_MODAL = '@modals/OPEN_SIGNUP_MODAL';
export const OPEN_PARTICIPATION_MODAL = '@modals/OPEN_PARTICIPATION_MODAL';
export const OPEN_VERIFICATION_MODAL = '@modals/OPEN_VERIFICATION_MODAL';
export const OPEN_WHEEL_MODAL = '@modals/OPEN_WHEEL_MODAL';
export const OPEN_DEPOSIT_AFTER_LOGIN = '@modals/OPEN_DEPOSIT_AFTER_LOGIN';
export const OPEN_HOW_TO_PLAY_QUETS = '@modals/OPEN_HOW_TO_PLAY_QUETS';
export const OPEN_THE_QUETS = '@modals/OPEN_THE_QUETS';
export const OPEN_QUEST_ENDED = '@modals/OPEN_QUEST_ENDED';
export const OPEN_SHARE_TICKET = '@modals/OPEN_SHARE_TICKET';
export const OPEN_SPORT_TICKET_MODAL = '@modals/OPEN_SPORT_TICKET_MODAL';
export const SET_MODAL_MINI_GAME_ID = '@modals/SET_MODAL_MINI_GAME_ID';
export const OPEN_RESET_PWD_MODAL = '@modals/OPEN_RESET_PWD_MODAL';
export const OPEN_SET_NICKNAME = '@modals/OPEN_SET_NICKNAME';
export const OPEN_CLAIM_PROMOTION = '@modals/OPEN_CLAIM_PROMOTION';
export const OPEN_CLAIM_PROMOTION_DETAILS = '@modals/OPEN_CLAIM_PROMOTION_DETAILS';
export const OPEN_REOPEN_ACCOUNT_MODAL = '@modals/OPEN_REOPEN_ACCOUNT_MODAL';



export function openWheelModal(open:boolean) {
    return {type: OPEN_WHEEL_MODAL, payload: open}
};

export function openSportTicket(open:boolean) {
    return {type: OPEN_SPORT_TICKET_MODAL, payload: open}
};

export function openShareTIcket(open:boolean) {
    return {type: OPEN_SHARE_TICKET, payload: open}
};

export function openQuestEnded(open:boolean) {
    return {type: OPEN_QUEST_ENDED, payload: open}
};

export function openLoginModal(open:boolean) {
    return {type: OPEN_LOGIN_MODAL, payload: open}
};

export  function openUploadDocument(open:boolean) {
    return { type: OPEN_UPLOAD_DOCUMENT_MODAL, payload: open}
}

export  function openUploadMainDocument(open:boolean) {
    return { type: OPEN_UPLOAD_MAIN_DOCUMENT_MODAL, payload: open}
}

export  function openDeposit(open:boolean) {
    return { type: OPEN_DEPOSIT_MODAL, payload: open}
}

export  function openWithdraw(open:boolean) {
    return { type: OPEN_WITHDRAW_MODAL, payload: open}
}

export function updateCampaignId(id:string) {
    return { type: UPDATE_CAMPAIGN_ID, payload: id}
}

export  function openSupport(open:boolean) {
    return { type: OPEN_SUPPORT_MODAL, payload: open}
}

export function openForgotPasswordModal(open:boolean) {
    return { type: OPEN_FORGOT_PASSWORD_MODAL, payload: open}
}

export function openMiniGameModal(game:GameInterface | undefined) {
    return { type: OPEN_MINI_GAME_MODAL, payload: game}
}

export function openFullGameModal(game:GameInterface | undefined) {
    return { type: OPEN_FULL_GAME_MODAL, payload: game}
}

export function openParticipation(open:boolean) {
    return { type: OPEN_PARTICIPATION_MODAL, payload: open}
}

export function openSignupModal(open:boolean) {
    return { type: OPEN_SIGNUP_MODAL, payload: open}
}

export function openVerificationModal(open:boolean) {
    return { type: OPEN_VERIFICATION_MODAL, payload: open}
}

export function openDepositAfterLogin(open:boolean) {
    return { type: OPEN_DEPOSIT_AFTER_LOGIN, payload: open}
}

export function openHowToPlayQuest(open:boolean) {
    return { type: OPEN_HOW_TO_PLAY_QUETS, payload: open}
}

export function openTheQuest(open:boolean) {
    return { type: OPEN_THE_QUETS, payload: open}
}

export function setModalMiniGameId(x:string) {
    return { type: SET_MODAL_MINI_GAME_ID, payload: x}
}

export function openResetPwdModal(x:string) {
    return { type: OPEN_RESET_PWD_MODAL, payload: x}
}

export function openSetNickName(x:boolean) {
    return { type: OPEN_SET_NICKNAME, payload: x}
}

export function openClaimPromotion(x:any) {
    return { type: OPEN_CLAIM_PROMOTION, payload: x}
}

export function openClaimPromotionDetails(x:any) {
    return { type: OPEN_CLAIM_PROMOTION_DETAILS, payload: x}
}

export function openReopenAccountModal(x:any) {
    return { type: OPEN_REOPEN_ACCOUNT_MODAL, payload: x}
}

