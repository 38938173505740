import CMS from "utils/cms";
import {FC, useState, useEffect} from 'react';
import styles from 'themes/default/styles/landingPage.module.scss'
import Button from "@/template/small_ui/button";
import clsx from 'clsx'
import {useDispatch} from 'react-redux';
import {openSignupModal} from 'store/modals/actions';
import PageProgress from "@/template/small_ui/PageProgress";
import {useRouter} from 'next/router';
import useAuth from "hooks/auth";


interface Props {
    json: any;
    api_id: string;
}


const LandingPage: FC<Props> = ({json, api_id}) => {
    const [showPreloader, setShowPreloader] = useState<boolean>(true);

    const dataUser = useAuth();

    let data = CMS.jsonLandingPage(json);
    let router = useRouter();

    useEffect(() => {
        return () => {
            document.body.classList.remove('landingPage')
        }
    },[])

    if (typeof window !== "undefined") {

        let footer = document.getElementById('footer')
        document.body.classList.add('landingPage')

    }

    const linkColumn1 = () => {
        if (data.column_1_link.length > 0) {
            router.push(data.column_1_link)
        }
    }

    const linkColumn2 = () => {
        if (data.column_2_link.length > 0) {
            router.push(data.column_2_link)
        }
    }

    const linkColumn3 = () => {
        if (data.column_3_link.length > 0) {
            router.push(data.column_3_link)
        }
    }

    const installApp = () => {
        if (data.full_width_link.length > 0) {
            router.push(data.full_width_link)
        }
    }


    const dispatch = useDispatch();

    let headerImageSrc: string = data.header_image.length ? CMS.getPublicFilePath(data.header_image[0].name, api_id, data.header_image[0].path) : '';
    let fullWidthImageSrc: string = data.header_image.length ? CMS.getPublicFilePath(data.header_image[0].name, api_id, data.header_image[0].path) : '';

    useEffect(() => {
        setShowPreloader(false);
    }, []);

    return (
        <>
            <div className={styles.block}>
                <div className={styles.header__image} style={{backgroundImage: `url(${headerImageSrc})`}}>

                    <div className={styles.header__offerBlock}>
                        {/*<div className={styles.border__img}></div>*/}
                        {/*<img className={styles.border__img} src="/images/border.svg" alt=""/>*/}

                        {/*<div className={styles.border__block}></div>*/}
                        <div  className={styles.header__wrapper}>
                            {data.banner_title.length > 0 && <p className={styles.header__offerBlock__title}>{data.banner_title}</p>}
                            <div className={styles.header__offerBlock__content}>
                                { data.title_rounds.length > 0 && <p className={styles.header__offerBlock__contentTitle}>{data.title_rounds}</p>}
                                <p className={styles.header__offerBlock__contentRound}>{data.banner_num_rounds}</p>
                                <p className={styles.header__offerBlock__contentType}>{data.rounds_title}</p>
                            </div>
                            <div className={styles.header__offerBlock__content}>
                                {data.title_free_rounds.length > 0 && <p className={styles.header__offerBlock__contentTitle}>{data.title_free_rounds}</p>}
                                <p className={styles.header__offerBlock__contentRound}>+ {data.banner_free_rounds}</p>
                                <p className={styles.header__offerBlock__contentType}>{data.free_rounds_title}</p>
                            </div>
                            <div className={styles.header__offerBlock__content}>
                                {data.prize_title.length > 0 && <p className={styles.header__offerBlock__contentTitle}>{data.prize_title}
                                    <span className={styles.bonus}>{data.prize_sum} {data.banner_currency}</span></p>}
                                <p className={styles.header__offerBlock__contentRound}>+{data.banner_sum} {data.banner_currency}</p>
                            </div>
                            {/*<button className={styles.header__offerBlock__button}>click</button>*/}

                            {!dataUser.isLoggedIn() && !dataUser.isUpdating() &&
                                <Button className={clsx(styles.header__offerBlock__button, 'btnNewAccount')}
                                        onClick={() => dispatch(openSignupModal(true))}
                                        large
                                        type={'button'}
                                        text={'Deschide cont'}/>
                            }

                        </div>
                    </div>


                </div>

                <div className={clsx(styles.cards)}>
                    <div className={clsx(styles.cards__wrapper, 'col-xl-4', 'col-lg-4', 'col-md-4', 'col-sm-4', 'col-12')}>
                        <div className={clsx(styles.cards__block)} onClick={linkColumn1}>
                            {data.column_1_image.length > 0 &&
                                <div className={styles.cards__block__imageWrapper}>
                                    <img className={styles.cards__block__image}
                                         src={CMS.getPublicFilePath(data.column_1_image[0].name, api_id, data.column_1_image[0].path)}
                                         alt="img"/>
                                </div>}
                            <div className={styles.cards__block__title}
                                 dangerouslySetInnerHTML={{__html: data.column_1_title}}/>
                            <div className={styles.cards__block__description}
                                 dangerouslySetInnerHTML={{__html: data.column_1_description}}/>
                        </div>
                    </div>

                    <div className={clsx(styles.cards__wrapper, 'col-xl-4', 'col-lg-4', 'col-md-4', 'col-sm-4', 'col-12')}>
                        <div className={clsx(styles.cards__block)} onClick={linkColumn2}>
                            {(data.column_2_image.length > 0) &&
                                <div className={styles.cards__block__imageWrapper}>
                                    <img className={styles.cards__block__image}
                                         src={CMS.getPublicFilePath(data.column_2_image[0].name, api_id, data.column_2_image[0].path)}
                                         alt="img"/>
                                </div>}
                            <div className={styles.cards__block__title}
                                 dangerouslySetInnerHTML={{__html: data.column_2_title}}/>
                            <div className={styles.cards__block__description}
                                 dangerouslySetInnerHTML={{__html: data.column_2_description}}/>

                        </div>
                    </div>

                    <div className={clsx(styles.cards__wrapper, 'col-xl-4', 'col-lg-4', 'col-md-4', 'col-sm-4', 'col-12')}>
                        <div className={clsx(styles.cards__block,)} onClick={linkColumn3}>
                            {data.column_3_image.length > 0 &&
                                <div className={styles.cards__block__imageWrapper}>
                                    <img className={styles.cards__block__image}
                                         src={CMS.getPublicFilePath(data.column_3_image[0].name, api_id, data.column_3_image[0].path)}
                                         alt="img"/>
                                </div>}
                            <div className={styles.cards__block__title}
                                 dangerouslySetInnerHTML={{__html: data.column_3_title}}/>
                            <div className={styles.cards__block__description}
                                 dangerouslySetInnerHTML={{__html: data.column_3_description}}/>
                        </div>
                    </div>


                </div>
                <div className={styles.fullWidthImage__block}>

                    <div className={styles.fullWidthImage} style={{backgroundImage: `url(${fullWidthImageSrc})`}}></div>

                    <div className={styles.fullWidthImage__content}>
                        <div className={clsx(styles.cards__block__title, styles.fullWidth__title)} style={{marginTop: 0}}
                             dangerouslySetInnerHTML={{__html: data.full_image_title}}/>
                        <div className={clsx(styles.cards__block__description, styles.fullWidth__description)}
                             dangerouslySetInnerHTML={{__html: data.full_image_description}}/>
                        <Button className={styles.header__offerBlock__buttonSecond}
                                onClick={installApp}
                                large type={'button'}
                                text={'test'}/>
                    </div>

                </div>
            </div>

            <PageProgress api_id={api_id} types={[]} force={showPreloader}/>
        </>
    )
}
export default LandingPage;