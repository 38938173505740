

const DeleteFromFavorite = () => {
    return (
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M3.75 10.5005C3.75 7.8 6.11914 5.7207 8.9 5.7207C10.3335 5.7207 11.5948 6.39284 12.5 7.26254C13.4052 6.39284 14.6665 5.7207 16.1 5.7207C18.8809 5.7207 21.25 7.8 21.25 10.5005C21.25 12.3635 20.3777 13.9319 19.2215 15.2311C18.0693 16.5259 16.5829 17.6127 15.239 18.5223L12.9204 20.0918C12.6665 20.2637 12.3335 20.2637 12.0796 20.0918L9.76097 18.5223C8.41714 17.6127 6.93066 16.5259 5.77849 15.2311C4.62235 13.9319 3.75 12.3635 3.75 10.5005ZM9.5 14.1579L10.3421 15L12.5075 12.8346L14.6579 15L15.5 14.1579L13.3346 11.9925L15.5 9.84211L14.6579 9L12.5075 11.1654L10.3421 9L9.5 9.84211L11.6654 11.9925L9.5 14.1579Z" fill="#E02020"/>
        </svg>
    )
}

export default DeleteFromFavorite