function Seven() {
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="102" height="116" viewBox="0 0 102 116" fill="none">
            <g filter="url(#filter0_d_1577_191224)">
                <path d="M66.5763 34.2637L48.7923 81.9997H37.1283L51.8883 43.1197L55.2723 44.3437H34.3203V34.2637H66.5763Z" fill="#FBB90E"/>
            </g>
            <g filter="url(#filter1_d_1577_191224)">
                <path fillRule="evenodd" clipRule="evenodd" d="M69.4557 32.2637L50.1815 83.9997H34.2298L48.5251 46.3437H32.3203V32.2637H69.4557ZM51.4236 44.3437L37.1283 81.9997H48.7923L66.5763 34.2637H34.3203V44.3437H51.4236Z" fill="white"/>
            </g>
            <defs>
                <filter id="filter0_d_1577_191224" x="2.32031" y="2.26367" width="96.2559" height="111.736" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset/>
                    <feGaussianBlur stdDeviation="16"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0.984314 0 0 0 0 0.72549 0 0 0 0 0.054902 0 0 0 0.5 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1577_191224"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1577_191224" result="shape"/>
                </filter>
                <filter id="filter1_d_1577_191224" x="0.320312" y="0.263672" width="101.135" height="115.736" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset/>
                    <feGaussianBlur stdDeviation="16"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0.984314 0 0 0 0 0.72549 0 0 0 0 0.054902 0 0 0 0.5 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1577_191224"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1577_191224" result="shape"/>
                </filter>
            </defs>
        </svg>
    )
}
export default Seven