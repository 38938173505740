import styles from 'themes/spins/styles/search.module.scss';
import {useDispatch, useSelector} from 'react-redux';
import {showSearchBar, updateSearchValue, applySearchProvidersFilter, applySearchSort} from "store/common/actions";
import clsx from 'clsx';
import ArrowLeft from "themes/default/svg/arrow-left";
import OutlinedSelector from "@/template/small_ui/OutlinedSelector";
import Filter from 'themes/default/svg/filter';
import {useState, useEffect, FC, useRef} from 'react';
import strings from 'utils/strings';
import Game from 'utils/game'
import GameInterface from "interfaces/game";
import useAuth from "hooks/auth";
import ArrowRight from "@/template/svg/arrow-right";
import ArrowSearchSpins from 'themes/spins/svg/arrowSearchSpins'
import ResetIconSpins from 'themes/spins/svg/resetIconSpins'
import SearchIcon from "themes/spins/svg/SearchIcon";
import {ClickAwayListener} from '@mui/base';
import CloseIcon from 'themes/spins/svg/close';
import CMS from "@/AbsoluteComponents/utils/cms";
import Button from '@/template/small_ui/button'
import useCommon from "@/AbsoluteComponents/hooks/common";

let prevActive: boolean = false;

interface Props {
    disableClickAwayListener?:boolean;
}

const  SearchBar: FC<Props> = ({disableClickAwayListener}) => {
    const dispatch = useDispatch();
    const auth = useAuth()
    const common_hook = useCommon()
    const inputRef = useRef<HTMLInputElement | null>(null)

    const [asideFilter, setAsideFilter] = useState(false);
    const [game, setGame] = useState<GameInterface | undefined>(undefined);
    const [openFilters, setOpenFilters] = useState(false);
    const [filterCloseCount, setFilterCloseCount] = useState(0);
    const games = Game.getAll()
    const active: boolean = useSelector((state: any) => state.common.showSearchBar);
    const providers: Array<any> = CMS.jsonAllProvidersPages().providers;

    const activeProviders: Array<any> = useSelector((state: any) => state.common.activeSearchProviders);

    const value: string = useSelector((state: any) => state.common.searchValue);
    const searchSort: string = useSelector((state: any) => state.common.searchSort);

    const gamesList: Array<GameInterface> = Game.getAll();
    const [open, setOpen] = useState(false);

    const [isTransition, setIsTransition] = useState<boolean>(false);

    useEffect(() => {
        setTimeout(() => {
            setIsTransition(true)
        }, 700)
    }, [])


    const selectProviders = (category_id: string) => {
        let list: Array<any> = JSON.parse(JSON.stringify(activeProviders));

        if (list.filter((id: any) => id === category_id).length) {
            list = list.filter((id: any) => id !== category_id)
        } else {
            list.push(category_id);
        }

        dispatch(applySearchProvidersFilter(list));
    };

    const manageProviders = () =>{
        setOpenFilters(!openFilters);
        // console.log('clickaway', openFilters)
    }

    const reset = () => {
        dispatch(applySearchProvidersFilter([]));
    };

    const showFilter = () => {
        setAsideFilter(true);
    }
    const hideFilter = () => {
        setAsideFilter(false)
    }

    const hide = () => {
        setAsideFilter(false)
    }

    const keepOverlay: any = (e: any) => {
        e.stopPropagation();
    }


    useEffect(() => {

        const initGame = () => {
            let game: GameInterface | undefined

            const randomIndex: number = Math.floor(Math.random() * (gamesList.length - 1));

            if (gamesList[randomIndex]) game = gamesList[randomIndex];

            if (game) setGame(game);
        };

        initGame()

        if (!active) {
            dispatch(applySearchProvidersFilter([]));
        }

        if(active && inputRef.current) {
            inputRef.current.focus();
        }

    }, [active])

    useEffect(() => {
        setTimeout(() => {
            const overlay: any = document.getElementById('search-overlay');
            const navbar = document.getElementById('navbar');

            const navHeight = navbar?.offsetHeight || 0;

            if (navHeight > 0 && overlay !== null) {
                overlay.style.top = navHeight + 'px';
            }

            prevActive = active;
        }, (active && !prevActive) ? 400 : 0);

        if (!active) document.body.style.overflow = '';

    }, [active, openFilters, value, activeProviders]);

    return (
        <>
            <div id={"search_bar"} className={clsx(styles.search_bar, active && styles.active, active && 'theme_bgSearch', 'theme_border_bottom')}>

                <div className={clsx(styles.search_bar__inner)}>

                    <div className={'searchBlock-responsive'}>
                        {/*<div style={{display: 'flex', alignItems: 'center'}}>*/}
                        
                        {/*</div>*/}
                        
                        <div className={'search_bar__inputField'}
                             onChange={(e: any) => dispatch(updateSearchValue(e.target.value))}
                        >
                            <button
                                aria-label={"search"}
                                type={'button'} className={clsx(styles.search_bar__back, "theme_box_bg_shaded3", "theme_text_mainColor")}
                                onClick={() => {
                                    dispatch(showSearchBar(false))
                                    common_hook.hideZendesk(false)
                                }}
                            >
                                <ArrowSearchSpins/>
                            </button>
                            
                            <input
                                ref={inputRef}
                                className={clsx('searchBlock-responsive__input', 'theme_searchBlock-responsive__input', "theme_basic_input")}
                                type={'text'}
                                placeholder={"Caută în " + games.length + " de jocuri"}
                                name={'search'}
                            />
                            <span className={clsx(styles.search__iconResponsive, 'theme__svg')}><SearchIcon/></span>
                        </div>
                    
                    </div>
                    
                    <div className={clsx('buttons_wrapper_search', styles.filters)}>
                        
                        <div className={styles.right}>
                            <button
                                aria-label={"search"}
                                type={'button'} className={clsx(styles.back_btn, "theme_box_bg_shaded3", "theme_text_mainColor")}
                                onClick={() => {
                                    dispatch(showSearchBar(false))
                                    common_hook.hideZendesk(false)
                                }}
                            >
                                <ArrowSearchSpins/>
                            </button>
                        </div>
                        
                        <div className={styles.left}>
                            {game &&
                                <Button
                                    onClick={(e: any) => auth.runGame(game, false, () => {
                                    }, e)}
                                    text={'i\'m feeling lucky'}
                                />
                            }
                            
                            <div  className={clsx(styles.search_bar__filterIcon, 'themes_rootOutlinedSelected')} onClick={showFilter}>
                                <span  className={'theme__svg'}><Filter/></span>
                                <span className={clsx('search_bar__filterText', 'theme_textColor_secondary')}>{strings['filtre']}</span>
                                {(activeProviders.length > 0) &&
                                    <span className={styles.search_bar__selectedProvider}>{activeProviders.length}</span>}
                            </div>
                        </div>
                        

                        <div className={styles.search__furnizorWrapper}>
                            <OutlinedSelector
                                key={`search-filter-${filterCloseCount}-toggle-` + active.toString()}
                                labelIcon={<Filter/>}
                                options={[{key: '', label: `${strings['furnizori']}`}]}
                                disableClickAwayListener
                                bgColored
                                bgColoredActive
                                onOpen={() => {
                                    setOpenFilters(true);
                                }}
                                onClose={() => {
                                    setOpenFilters(false);
                                }}
                            />
                        </div>


                        <div className={styles.search__sortWrapper}>
                            <OutlinedSelector
                                label={strings['sorteaza']}
                                defaultValue={searchSort}
                                bgColored
                                bgColoredActive
                                onChange={(value) => {
                                    dispatch(applySearchSort(value));
                                }}
                                options={[
                                    {key: 'a-z', label: 'Alfabetic A-Z'},
                                    {key: 'z-a', label: 'Alfabetic Z-A'},
                                ]}
                            />
                        </div>
                    </div>

                </div>

            </div>

            {(openFilters && active) &&
                <ClickAwayListener onClickAway={() => {
                    setFilterCloseCount(filterCloseCount + 1);
                    !disableClickAwayListener ? manageProviders() : () => {

                    }
                }}>
                    <div className={clsx(styles.search_bar__furnizorBlock, 'theme_providers')}>
                        
                        {providers.length > 0 &&
                            <>
                                {(openFilters && active && activeProviders.length > 0) &&
                                    <div className={clsx(styles.search_bar__reset, 'theme_textColor_secondary')}>
                                        <div>{activeProviders.length} {strings['selectate']}</div>
                                        <div onClick={reset}>
                                            <span className={'theme_svg_whiteDark'}><ResetIconSpins/></span>
                                            <span style={{marginLeft: ' 8px'}}>{strings['reseteaza']}</span>
                                        </div>
                                    </div>}
                                <div className={styles.search_bar__providers}>

                                    {providers.map((provider, i: number) => {
                                        return (
                                            <div
                                                key={`provider-${i}`}
                                                className={clsx(
                                                    styles.search_bar__providers__item,
                                                    'theme_providers__item',
                                                    activeProviders.includes(provider['category_id']) && 'theme_providers__item__active'
                                                )}
                                                onClick={() => {
                                                    selectProviders(provider['category_id'])
                                                }}
                                            >
                                                {!Boolean(provider.image_dark?.length) && (
                                                    <img src={provider['thumbnail_src']}  alt={provider['name']}/>
                                                )}

                                                {Boolean(provider.image_dark?.length) && (
                                                    <>
                                                        <img data-hide-on-theme="dark" src={CMS.getPublicFilePath(provider.image_dark[0].name, 'slots_test', provider.image_dark[0].path)}  alt={provider['name']}/>
                                                        <img data-hide-on-theme="light" src={provider['thumbnail_src']}  alt={provider['name']}/>
                                                    </>
                                                )}
                                            </div>
                                        )
                                    })}
                                </div>
                            </>
                        }
                    </div>
                </ClickAwayListener>
            }

            <div className={clsx(styles.searchOverlay, asideFilter && styles.active, )} onClick={hide}>
                <div className={clsx(styles.search__sideBar, asideFilter && styles.active, 'theme_bgSearch', )}
                     onClick={keepOverlay}>
                    <div className={styles.search__sideBar__filterText}>
                        <p className={clsx(styles.search__sideBar__text, 'theme_textColor_secondary')}>{strings['filtreaza']}</p>
                        <span
                            style={{cursor: 'pointer'}}
                              onClick={hideFilter}>
                            <span className={'theme__svg'}><CloseIcon /></span>
                     </span>
                    </div>
                    <div className={styles.furnizoriSideBar}>
                        <OutlinedSelector
                            label={'Sortează'}
                            bgColored
                            bgColoredActive
                            style
                            defaultValue={searchSort}
                            onChange={(value) => {
                                dispatch(applySearchSort(value));
                            }}
                            options={[
                                {key: 'a-z', label: 'Alfabetic A-Z'},
                                {key: 'z-a', label: 'Alfabetic Z-A'},
                            ]}
                        />
                    </div>

                    <div className={styles.search_bar__filterWrapper}>
                        <div className={styles.search_bar__filterWrapper__btn}>
                            {activeProviders.length > 0 &&
                                <div className={clsx('sidebarReset_btn', 'theme_textColor_secondary')}>
                                    <div>{activeProviders.length} {strings['selectate']}</div>
                                    <button onClick={reset}
                                            style={{marginBottom: 0}}
                                            className={clsx(styles.search_bar__reset)}>
                                        <span className={'theme_svg_whiteDark'}><ResetIconSpins/></span>
                                        <span style={{marginLeft: 8}} className={'theme_textColor_secondary'}>{strings['reseteaza']}</span>
                                    </button>
                                </div>}
                        </div>

                    </div>

                    {providers.length > 0 &&

                        <div style={{paddingBottom: '100px'}} className={clsx(styles.search_bar__providers, 'row')}>
                            {providers.map((provider, i: number) => {
                                return (
                                    <div
                                        key={`provider-${i}`}
                                        className={clsx(styles.search_bar__providers__item,
                                            'theme__sliderButtonContainer', 'col-4',
                                            activeProviders.includes(provider['category_id']) && styles.active,
                                            activeProviders.includes(provider['category_id']) && 'theme_providers__item__active')
                                    }
                                        onClick={() => {
                                            selectProviders(provider['category_id'])
                                        }}
                                    >
                                        {!Boolean(provider.image_dark?.length) && (
                                            <img src={provider['thumbnail_src']}  alt={provider['name']}/>
                                        )}

                                        {Boolean(provider.image_dark?.length) && (
                                            <>
                                                <img data-hide-on-theme="dark" src={CMS.getPublicFilePath(provider.image_dark[0].name, 'slots_test', provider.image_dark[0].path)}  alt={provider['name']}/>
                                                <img data-hide-on-theme="light" src={provider['thumbnail_src']}  alt={provider['name']}/>
                                            </>
                                        )}
                                    </div>
                                )
                            })}
                        </div>
                    }
                </div>
            </div>
        </>
    );
}

export default SearchBar