import styles from 'themes/spins/styles/userDropDown.module.scss';
import strings from "utils/strings";
import Link from 'next/link';
import clsx from 'clsx';
import {ClickAwayListener} from '@mui/base';
import Vector from 'themes/default/svg/vector';
import ArrowRight from "@/template/svg/arrow-right";
import VerificationIndicator from "components/VerificationIndicator";
import React from "react";
import VerificationStatus from "interfaces/VerificationStatus";
import {useSelector} from "react-redux";
import useAuth from "hooks/auth";
import {useState, useEffect} from 'react'
import {useRouter} from 'next/router';
import LogoutIcon from "themes/spins/svg/logouticon";
import {validationTypes} from "@/AbsoluteComponents/utils/constants";
import Image from "next/image";

const SpinsUserDropDownView = () => {
    
    const auth = useAuth();
    const user = auth.getUser();
    const router = useRouter();
    const currentRoute = router.pathname;
    const [open, setOpen] = useState(false);
    const smartico_props = useSelector((state:any) => state.account.smarticoProps)
    
    
    const toOpen = () => {
        setOpen(!open)
    }
    const setOpenFalse = () => {
        setOpen(false)
    }
    
    useEffect(() => {
        setOpen(false);
    }, [currentRoute]);
    
    let allVerified:boolean = true;
    
    const verificationStatus:Array<VerificationStatus> = useSelector((state:any) => state.account.verificationStatus);
    const opened:Array<VerificationStatus> = useSelector((state:any) => state.account.validation_opened);
    
    verificationStatus.map(item => {
        if (!item.verified) allVerified = false;
    });
    
    if(user == undefined) return null
    
    return  (
        <ClickAwayListener onClickAway={setOpenFalse}>
            <div>
                
                <div  className={clsx(styles.wrapper, open && styles.active)}>
                    
                    <button
                        onClick={toOpen}
                        className={clsx(
                            styles.auth, 'auth',
                            (!allVerified || opened) && styles.not_verified,
                            typeof smartico_props === "undefined" && "loading"
                        )}>
                        
                        { (typeof smartico_props === "object" && Object.keys(smartico_props).length && smartico_props.hasOwnProperty("avatar_id")) ? (
                            <img src={smartico_props?.avatar_id} alt={""} width={100} height={100} />
                        ) : (
                            <div className={clsx(styles.auth__name, 'theme__headerContainer__list', "theme_white_dark_text")}>
                                {user.firstName[0]}{user.lastName[0]}
                            </div>
                        )}
                    
                    </button>
                    
                    <span className={clsx(styles.vector, 'themes__vector', (!allVerified || opened) && styles.not_verified_chevrone)}>
                       <Vector />
                    </span>
                    
                    <div className={clsx(styles.dropDown, 'theme_headerDropDown')}>
                        
                        <ul className={clsx(styles.dropDown__links, "theme_white_dark_text")} >
                            
                            <li className={clsx(router.pathname === '/account/profile' && 'theme_headerDropDown__selected')}>
                                <Link   href={'/account/profile'}>
                                    {strings['personal_details']}
                                </Link>
                                {/*<img src="/spins/images/arrowRight.svg" alt="arrow"/>*/}
                                <ArrowRight />
                            </li>
                            
                            {(opened) &&
                                <li style={{padding: '0'}} className={clsx(router.pathname === '/account/account-validation' && 'theme_headerDropDown__selected')}>
                                    <VerificationIndicator text={strings['ver_identity']} identity mobile={false}/>
                                    <ArrowRight/>
                                </li>
                            }
                            
                            {(!allVerified) &&
                                <li style={{padding: '0'}}>
                                    <VerificationIndicator text={strings['ver_email']} mobile={false}/>
                                    <ArrowRight/>
                                </li>
                            }
                            
                            <li className={clsx(router.pathname === '/account/my-documents' && 'theme_headerDropDown__selected')}>
                                <Link   href={'/account/my-documents'}>{strings['documentele_mele'] }</Link>
                                <ArrowRight />
                            </li>
                            <li className={clsx(router.pathname === '/account/password-change' && 'theme_headerDropDown__selected')}>
                                <Link   href={'/account/password-change'}>{strings['schimbare_parola']}</Link>
                                <ArrowRight />
                            </li>
                            <li className={clsx(router.pathname === '/account/activity' && 'theme_headerDropDown__selected')}>
                                <Link   href={'/account/activity'}>{strings['activity_history']}</Link>
                                <ArrowRight />
                            </li>
                            <li className={clsx(router.pathname === '/account/responsable-game' && 'theme_headerDropDown__selected')}>
                                <Link   href={'/account/responsable-game'}>{strings['limit_settings']}</Link>
                                <ArrowRight />
                            </li>
                        </ul>
                        
                        <button onClick={() => auth.signOut()} className={styles.dropDown__logOut}>
                            <span className={'theme__svg'}><LogoutIcon /></span>
                            <span className={clsx(styles.userLogOut, 'theme_textColor_secondary')}>{strings['iesire_din_cont']}</span>
                        </button>
                    </div>
                
                </div>
            
            </div>
        </ClickAwayListener>
    )
}

export default SpinsUserDropDownView;
