import {FC, useEffect, useState} from 'react';
import styles from 'themes/spins/styles/FullWidthCarousel.module.scss';
import strings from 'utils/strings';
import {Swiper, SwiperSlide} from "swiper/react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CMS from "utils/cms";
import Link from 'next/link';
import clsx from 'clsx';
import {Pagination} from "swiper";
import {Autoplay} from "swiper";
import {useSelector, useDispatch} from 'react-redux';
import {fullWidthSliderLoaded} from 'store/cms/actions';
import Button from "@/template/small_ui/button";
import {useRouter} from 'next/router'
import PageProgress from '@/template/small_ui/PageProgress'
import Image from "next/image"
import Head from "next/head";


interface Props {
    json: object;
    api_id: string;
    first?:boolean;
}

const FullWidthCarousel: FC<Props> = ({ json, api_id, first }) => {
    
    const [swiper, setSwiper] = useState<any>();
    
    const [bullet, setBullet] = useState(0);
    
    const router = useRouter()
    
    const data = CMS.jsonFullWidthCarousel(json);
    
    const dispatch = useDispatch();
    
    const desktopImageLoaded = useSelector((state:any) => Boolean(state.cms.uploadedFullWidthSlider[`desktop_${api_id}`]));
    const mobileImageLoaded = useSelector((state:any) => Boolean(state.cms.uploadedFullWidthSlider[`mobile_${api_id}`]));
    
    
    useEffect(() => {
        if (data.sliders[0]?.image.length) {
            const desktopImage = document.createElement('img');
            desktopImage.src = CMS.getPublicFilePath(data.sliders[0].image[0].name, api_id, data.sliders[0].image[0].path);
            
            desktopImage.onload = () => {
                // setDesktopImageLoaded(true);
                dispatch(fullWidthSliderLoaded(`desktop_${api_id}`));
            }
        } else {
            dispatch(fullWidthSliderLoaded(`desktop_${api_id}`));
        }
        
        if (data.sliders[0]?.mobile_image.length) {
            const mobileImage = document.createElement('img');
            mobileImage.src = CMS.getPublicFilePath(data.sliders[0].mobile_image[0].name, api_id, data.sliders[0].mobile_image[0].path);
            
            mobileImage.onload = () => {
                // setMobileImageLoaded(true);
                dispatch(fullWidthSliderLoaded(`mobile_${api_id}`));
            }
        } else {
            dispatch(fullWidthSliderLoaded(`mobile_${api_id}`));
        }
        
        
    }, []);
    
    useEffect(() => {
        const element = document.getElementById('main_container');
        if (first && element) {
            element.style.paddingTop = "0";
        }
        return () => {
            element && (element.style.paddingTop = "");
        };
    }, [first]);
    
    return (
        <>
            {/*<Head>*/}
            {/*    <meta property="og:image" content={CMS.getPublicFilePath(data.sliders[0].mobile_image[0].name, api_id, data.sliders[0].mobile_image[0].path)} />*/}
            {/*    <meta property="og:image:width" content="800" />*/}
            {/*    <meta property="og:image:height" content="600" />*/}
            {/*</Head>*/}
            
            <div className={clsx(styles.slider, styles.sliderWrapper, 'full-width-carousel-root')}>
                <Swiper
                    autoplay={{ delay: 10000 }}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Pagination, Autoplay]}
                    className={styles.slider__child}
                    allowTouchMove={true}
                    speed={900}
                    loop={true}
                    spaceBetween={0}
                    slidesPerView='auto'
                    translate={"no"}
                    onSwiper={(sw) => {
                        setSwiper(sw,);
                    }}
                    onSlideChange={(data) => {
                        setBullet(data.realIndex)
                    }}
                
                >
                    {data.sliders.map((slide:any, i:number) => {
                        const hideBtn = slide.promotion_api_id === '';
                        
                        return (
                            <SwiperSlide key={`slide-${i}`}>
                                <div className={clsx(styles.sliderBlock, styles.imagesSlider)}>
                                    {slide.image.length > 0 && (
                                        <img
                                            loading="lazy"
                                            style={{maxWidth: '100%', height: 'auto'}}
                                            width={1910}
                                            height={576}
                                            src={CMS.getPublicFilePath(slide.image[0].name, api_id, slide.image[0].path)}
                                            alt={slide.title}
                                        />
                                    )}
                                    
                                    <div  className={styles.slider__moreInfo}>
                                        <h3 className={styles.slider__title}>{slide.title}</h3>
                                        
                                        {hideBtn ? null : (
                                            <Button
                                                text={strings['afla_mai_multe']}
                                                onClick={()=>{
                                                    router.push(slide.url_extern.length ? slide.url_extern : '/promotii/'+slide.api_id)
                                                }}
                                            />
                                        )}
                                    </div>
                                </div>
                                
                                
                                <div
                                    title={slide.title}
                                    className={clsx(styles.sliderBlock, styles.imagesSlider, styles.imagesSliderMobile)}
                                    onClick={()=>{
                                        router.push(slide.url_extern.length ? slide.url_extern : '/promotii/'+slide.api_id)
                                    }}
                                >
                                    {slide.mobile_image.length > 0 &&
                                        <img
                                            loading="lazy"
                                            style={{minWidth: '100vw', height: 'auto'}}
                                            width={408}
                                            height={306}
                                            src={CMS.getPublicFilePath(slide.mobile_image[0].name, api_id, slide.mobile_image[0].path)} alt={slide.title}
                                        />
                                    }
                                    
                                    {/*{hideBtn ? null : (*/}
                                    {/*    <Button*/}
                                    {/*        text={strings['afla_mai_multe']}*/}
                                    {/*        */}
                                    {/*    />*/}
                                    {/*)}*/}
                                
                                </div>
                            
                            </SwiperSlide>
                        )
                    })}
                </Swiper>
                
                {(first && !desktopImageLoaded && !mobileImageLoaded) &&
                    <PageProgress force types={[]} api_id={api_id}/>
                }
            </div>
        </>
    );
};

export default FullWidthCarousel;