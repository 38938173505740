import React, {useEffect, useRef, useState} from "react";
// @ts-ignore
import numberFormatter from 'number-formatter';
// @ts-ignore
import styles from "@/template/styles/JackpotLive.module.scss"
import clsx from "clsx";



interface Props {
    id: string;
    jackpot: any
    className: string
    textWidth: number
    textHeight: number
    couldBeDistort?: boolean
    value:any
    isolated?:boolean
    style?:any
    is_static?:boolean
}

const JackpotCounterNoAnim: React.FC<Props> = ({ id, value, className,  textHeight, textWidth, couldBeDistort, style, is_static }) => {
    const interval = useRef<any>(null);
    const elem = useRef<any>(null);
    
    const getConfig = () => {
        let end_offset = 1
        let start_offset = 0
        let increment = 0.01
        
        if(value > 100) {
            start_offset = 20
        } else if(value > 60 && value <= 100) {
            start_offset = 10
        } else if (value > 10 && value <= 60) {
            start_offset = value - 2
        } else  if (value <= 10){
            start_offset = value - 0.1
        }
        
        if(is_static) {
            start_offset = 0
            increment = 0
        }
        
        return {
            end_offset:end_offset,
            start_offset: start_offset,
            interval: 200,
            increment: increment
        }
    }
    

    const generateValues = () => {
        let initial = value - getConfig().start_offset;
        
        const test:boolean = false
        const coreFunction = () => {
            if (!elem.current) return;
            initial += getConfig().increment;
            
            let valueRefactored = initial && numberFormatter("# ##0.00", initial.toFixed(2));
            let split = valueRefactored.split('');
            
            let valueRefactoredIncremented = initial && numberFormatter("# ##0.00", (initial + getConfig().increment).toFixed(2));
            let splitIncremented = valueRefactoredIncremented.split('');
            
            if(splitIncremented.length > split.length) {
                splitIncremented = splitIncremented.slice(1)
            }
            
            const fragment = document.createDocumentFragment();
            
            if(initial >= value - getConfig().end_offset) {
                clearInterval(interval.current)
                split.forEach((x: any, i:number) => {
                    
                    const innerDiv = document.createElement('div');
                    const div = document.createElement('div');
                    div.className = clsx(styles.value);
                    
                    const slideInnerDiv = document.createElement('div');
                    
                    slideInnerDiv.textContent = x;
                    
                    innerDiv.appendChild(slideInnerDiv);
                    
                    const minWidthFactor = (x === "." || x === " ") ? 0.3 : 0.1;
                    innerDiv.style.minWidth = `${textWidth - (textWidth * minWidthFactor)}px`;
                    innerDiv.style.textAlign = 'center';
                    innerDiv.style.transition = 'none';
                    innerDiv.style.animationName = '';
                    
                    div.appendChild(innerDiv);
                    fragment.appendChild(div);
                });
                elem.current.innerHTML = '';
                elem.current.appendChild(fragment);
                return;
            }
            
            split.forEach((x: any, i:number) => {
                
                const innerDiv = document.createElement('div');
                innerDiv.className = styles.slide;
                if(!test) {
                    if (splitIncremented[i] !== x) {
                        innerDiv.classList.add(styles.rolling)
                    } else {
                        innerDiv.classList.remove(styles.rolling)
                    }
                }
                
                
                const div = document.createElement('div');
                
                div.className = clsx(styles.value);
                
                const slideInnerDiv = document.createElement('div');
                const slideInnerDiv1 = document.createElement('div');
                slideInnerDiv.textContent = x;
                slideInnerDiv1.textContent = (splitIncremented[i] !== " " || splitIncremented[i] !== ".") ?  splitIncremented[i] : "";
                innerDiv.appendChild(slideInnerDiv);
                innerDiv.appendChild(slideInnerDiv1);
                
                const minWidthFactor = (x === "." || x === " ") ? 0.3 : 0.1;
                innerDiv.style.minWidth = `${textWidth - (textWidth * minWidthFactor)}px`;
                innerDiv.style.textAlign = 'center';
                innerDiv.style.transition = '0.2s all';
                
                div.appendChild(innerDiv);
                fragment.appendChild(div);
            });
            
            // Clear the current content and append the new elements
            elem.current.innerHTML = '';
            elem.current.appendChild(fragment);
            
        }
        
        coreFunction()
        const counting = (start:boolean) => {
            if(!test || !is_static) {
                if(start) {
                    interval.current = setInterval(() => {
                        coreFunction()
                    }, getConfig().interval);
                } else {
                    clearInterval(interval.current)
                }
            }
        }
        
        
        if (typeof window !== "undefined") {
            const isSamsungBrowser = navigator.userAgent.match(/SamsungBrowser/i);
            
            const observerOptions = {
                rootMargin: '200px',
            };
            
            if ('IntersectionObserver' in window && !isSamsungBrowser) {
                const IO = new IntersectionObserver((entries, observer) => {
                    entries.forEach(entry => {
                        if (entry.isIntersecting) {
                            counting(true)
                        } else {
                            counting(false)
                        }
                    });
                }, observerOptions);
                
                IO.observe(elem.current);
            } else {
                counting(true)
            }
        }
        
    };
    
    useEffect(() => {

        generateValues()
        return () => {
            clearInterval(interval.current)
        }
    }, []);
    
    return (
        <div
            ref={elem}
            id={id}
            style={style ? style : {}}
            className={className}
        >

        </div>
    )
};

export default JackpotCounterNoAnim
