import React, {FC, useState, useEffect} from 'react';
import CMS from "utils/cms";
import styles from 'themes/spins/styles/card.module.scss';
import GameInterface from "interfaces/game";
import Game from "utils/game";
import CircularProgress from '@mui/material/CircularProgress';
import clsx from 'clsx';
import GameGrid from "themes/spins/ui/game/grid";
import OutlinedSelector from "themes/spins/small_ui/OutlinedSelector";
import Filter from "themes/spins/svg/filter";
import {
    featuresFilterSlugPage,
    providerFilterSlugPage, setIsCategPage,
    themeFilterSlugPage,
    volatilityFilterSlugPage
} from "store/common/actions";
import { useRouter } from 'next/router'
import { useSelector, useDispatch } from 'react-redux'
import Button from "@/template/small_ui/button";
import { STATIC_PATHS } from 'utils/cms'
import GamesFilterGrid from "themes/spins/cms-views/pageTypeCategoryGames/GamesFilter";
import ResetIconSpins from "themes/spins/svg/resetIconSpins";
import strings from "utils/strings";
import useCommon from "@/AbsoluteComponents/hooks/common";
import CloseIcon from "@/template/svg/closeicon";


interface Props {
    json:any;
    api_id:string;
}

const PageTypeCategoryGames:FC<Props> = ({json, api_id}) => {
    const data = CMS.jsonCategoryGamesPage(json);
    const allGames:Array<GameInterface> = Game.getAll();
    const providers:Array<any> = CMS.jsonAllProvidersPages().providers
    const alphabeticOptions = [
        {key: 'a-z', label: 'Alfabetic A-Z'},
        {key: 'z-a', label: 'Alfabetic Z-A'},
    ]
    const common_hook = useCommon()

    const [games, setGames] = useState<Array<GameInterface>>(allGames.filter((g:any) => g.categories.includes(data.category_id)));
    const [loading, setLoading] = useState(false);
    const [isCateg, setIsCateg] = useState<boolean | undefined>(undefined);
    const [searchSort, setSearchSort] = useState<string>('')
    const [sortChanged, setSortChanged] = useState<boolean>(false)
    const [overlayActive, setOverlayActive] = useState<boolean>(false)
    const [elementRemoved, setElementRemoved] = useState<boolean>(true)
    const [openProviderFilter, setOpenProviderFilter] = useState<boolean>(false)
    const [openFeatureFilter, setOpenFeatureFilter] = useState<boolean>(false)
    const [openThemeFilter, setOpenThemeFilter] = useState<boolean>(false)
    const [openVolFilter, setOpenVolFilter] = useState<boolean>(false)
    const [sortOpen, setSortOpen] = useState<boolean>(false)

    const activeProviders:Array<any> = useSelector((state: any) => state.common.slugSearchProviders);
    const activeFeatures: Array<any> = useSelector((state: any) => state.common.slugSearchFeatures);
    const activeThemes: Array<any> = useSelector((state: any) => state.common.slugSearchTheme);
    const activeVolatility: Array<any> = useSelector((state: any) => state.common.slugSearchVolatility);
    const dispatch = useDispatch()
    const route = useRouter();
    const static_path = Array(STATIC_PATHS);

    

    let volatility = Game.getFilters().volatilityFilters;
    let allThemes = Game.getFilters().themesFilters;
    let features = Game.getFilters().featuresFilters;

    const categIsProvider:boolean = providers?.some((prov) => prov.category_id === data.category_id);
    const tempMatchGames = ():Array<GameInterface> => {
        let result:Array<GameInterface> = [];
        games?.forEach((game:GameInterface) => {
            const isProvider = activeProviders.length === 0 || game.categories.some(category => activeProviders.includes(category));
            const isFeature = activeFeatures.length === 0 || activeFeatures.some((feature) => game.features?.includes(feature));
            //@ts-ignore
            const isTheme = activeThemes.length === 0 || activeThemes.some((theme) => game.themes?.includes(theme));
            const isVolatility = activeVolatility.length === 0 || activeVolatility.includes(game.volatility);
            if(isProvider && isFeature && isTheme && isVolatility) {
                result.push(game);
            }
        })
        
        if (searchSort === 'a-z') {
            result.sort((a:any, b:any):number => {
                const prevName = a.friendlyName;
                const nextName = b.friendlyName;
                
                if (prevName > nextName) {
                    return 1;
                }
                
                if (prevName < nextName) {
                    return -1;
                }
                
                return 0;
            });
            
        } else if(searchSort === 'z-a') {
            result.sort((a:any, b:any):number => {
                const prevName = a.friendlyName;
                const nextName = b.friendlyName;
                
                if (prevName > nextName) {
                    return -1;
                }
                
                if (prevName < nextName) {
                    return 1;
                }
                
                return 0;
            });
        }
        
        return result;
    }

    const availableGames = tempMatchGames()
    
    
    const selectFilters = (provider_id:string , action:(arg:any)=> any) => {
        let list:Array<any> = JSON.parse(JSON.stringify(activeProviders));

        if (list.filter((id:any) => id === provider_id).length) {
            list = list.filter((id:any) => id !== provider_id)
        } else {
            list.push(provider_id);
        }

        dispatch(action(list));
    };

    const selectMultipleFilters = (item: any, initialArray:Array<any>): Array<any> => {
        let list: Array<any> = [ ... initialArray];

        if(list.filter((x:any) => x === item).length) {
            list = list.filter((x:any) => x !== item)
        } else {
            list.push(item);
        }

        return list
    }

    let areProviders:boolean = activeProviders.length > 0
    let areVolatilities:boolean = activeVolatility.length > 0
    let areThemes:boolean = activeThemes.length > 0
    let areFeatures:boolean = activeFeatures.length > 0
    const emptyStates = () => {
        dispatch(providerFilterSlugPage([]));
        dispatch(featuresFilterSlugPage([]));
        dispatch(themeFilterSlugPage([]));
        dispatch(volatilityFilterSlugPage([]));
    }

    const toggleFilter = (show: boolean) => {
        setOverlayActive(show)
        common_hook.hideZendesk(show)
    }

    useEffect(() => {
        const gamesFound:Array<GameInterface> = allGames.filter((g:any) => g.categories.includes(data.category_id));
        
        gamesFound.sort((a:any, b:any) => {
            if (a.categoryOrder[data.category_id] > b.categoryOrder[data.category_id]) {
                return 1;
            }

            if (a.categoryOrder[data.category_id] < b.categoryOrder[data.category_id]) {
                return -1;
            }

            return 0;
        });

        setGames(gamesFound);

        const timeout = setTimeout(() => {
            setElementRemoved(false)
        }, 700)

        dispatch(setIsCategPage(true))

        return ()=> {
            clearTimeout(timeout)
            dispatch(setIsCategPage(false))
        }

    }, []);

    useEffect(() => {

        dispatch(providerFilterSlugPage([]))

    }, [route.query.slug])


    const router = useRouter();

    return (
        <div data-section='page-type-categories-slide' >

            <div className={styles.header}>
                <h1
                    className={clsx(
                        styles.page_title,
                        'theme_textColor_secondary'
                    )}
                >
                    {data.title}
                    <span className={styles.page_title__count}>
                        {games.length}
                    </span>
                </h1>

                <button onClick={() => {toggleFilter(true)}} className={clsx(styles.filtersBtn, styles.pageCategs)}>
                    <span className={'theme__svg'}>
                        <Filter />
                    </span>
                </button>

                <div className={styles.filters}>

                    {!categIsProvider && (
                        <OutlinedSelector
                            label={'furnizori'}
                            options={[{key: '', label: areProviders? '' : 'toți' }]}
                            providers={providers}
                            filtersPage
                            selectedCount={
                                //@ts-ignore
                                areProviders? activeProviders.length : ''
                            }
                        />
                    )}

                    {/*<OutlinedSelector*/}
                    {/*    label={'caracteristici'}*/}
                    {/*    options={[{key: '', label: areFeatures? '' : 'toate' }]}*/}
                    {/*    features={features}*/}
                    {/*    filtersPage*/}
                    {/*    selectedCount={*/}
                    {/*        //@ts-ignore*/}
                    {/*        activeFeatures? activeFeatures.length : ''*/}
                    {/*    }*/}
                    {/*/>*/}

                    {/*<OutlinedSelector*/}
                    {/*    label={'teme'}*/}
                    {/*    options={[{key: '', label: areThemes? '' : 'toate' }]}*/}
                    {/*    themes={allThemes}*/}
                    {/*    filtersPage*/}
                    {/*    selectedCount={*/}
                    {/*        //@ts-ignore*/}
                    {/*        activeThemes? activeThemes.length : ''*/}
                    {/*    }*/}
                    {/*/>*/}

                    {/*<OutlinedSelector*/}
                    {/*    label={'volatilitate'}*/}
                    {/*    options={[{key: '', label: areVolatilities? '' : 'toate' }]}*/}
                    {/*    volatility={volatility}*/}
                    {/*    filtersPage*/}
                    {/*    selectedCount={*/}
                    {/*        //@ts-ignore*/}
                    {/*        activeVolatility? activeVolatility.length : ''*/}
                    {/*    }*/}
                    {/*/>*/}


                    <OutlinedSelector
                        label={'Sortează'}
                        defaultValue={searchSort}
                        onChange={(value) => {
                            setSearchSort(value)
                            setSortChanged(true)
                        }}
                        options={alphabeticOptions}
                        filtersPage
                    />

                    <Button
                        style={{minHeight:40, paddingTop:2}}
                        onClick={() => {
                        setSearchSort('');
                        emptyStates();
                        setSortChanged(false)
                    }} className={clsx(
                        (
                            activeProviders.length > 0 ||
                            activeThemes.length > 0 ||
                            activeFeatures.length > 0 ||
                            activeVolatility.length > 0 ||
                            sortChanged
                        ) ? 'btn__bg__themeHovered'
                            :
                            'btn__bg__themeHovered_notActive'
                        )}
                        text={'resetează'}
                    />


                </div>

                <div onClick={() => {toggleFilter(false)}} className={clsx(styles.overlayBlock, overlayActive && styles.overlayBlockActive)}>

                </div>

                {/*OVERLAY FILTERS START OVERLAY FILTERS START OVERLAY FILTERS START OVERLAY FILTERS START */}

                {!elementRemoved && (
                    <div className={clsx(styles.overlayFilters, 'theme_providersSide', overlayActive && styles.overlayFiltersActive)}>

                        <div id={'mobFilterHeader'} className={clsx(styles.overlayFilters__header, 'theme_textColor_secondary')}>
                            <h4 className={styles.overlayHeading}>
                                Filtrează
                            </h4>

                            <button
                                onClick={() => {toggleFilter(false)}}
                                className={clsx(styles.closeButton, 'closeButton__FilterOverly')}
                            >
                                <span style={{transform: 'scale(0.6)'}}>
                                    <CloseIcon/>
                                </span>
                            </button>


                        </div>

                        <button
                            style={{ minHeight: 40 }}
                            onClick={() => {
                                setSearchSort('');
                                emptyStates()
                                setSortChanged(false)
                            }}
                            className={clsx(
                                (
                                    activeProviders.length > 0 ||
                                    activeThemes.length > 0 ||
                                    activeFeatures.length > 0 ||
                                    activeVolatility.length > 0 ||
                                    sortChanged
                                ) ? 'btn__bg__themeHovered'
                                    :
                                    'btn__bg__themeHovered_notActiveMobile',
                                'resetButton_recommended'
                            )}
                        >
                            <span className={'theme_svg_whiteDark'}><ResetIconSpins/></span>
                            <span style={{marginLeft: 8,}} className={'theme_textColor_secondary'}>{strings['reseteaza']}</span>
                        </button>

                        {/*<Button onClick={() => {*/}
                        {/*    setSearchSort('');*/}
                        {/*    emptyStates()*/}
                        {/*}} className={styles.resetButton} text={'resetează'} />*/}

                        <div className={styles.scrollFilters} >

                            {/*PROVIDERS MOBILE FILTER START PROVIDERS MOBILE FILTER START PROVIDERS MOBILE FILTER START */}

                            {!categIsProvider && (
                                <>
                                    <OutlinedSelector
                                        label={'furnizori'}
                                        coloredLabel
                                        options={[{key: '', label: areProviders? '' : 'toți' }]}
                                        disableClickAwayListener
                                        style
                                        className={styles.outlinedFilter}
                                        onOpen={() => {
                                            setOpenProviderFilter(true);
                                        }}
                                        onClose={() => {
                                            setOpenProviderFilter(false);
                                        }}
                                        selectedCount={areProviders? activeProviders.length : ''}
                                    />

                                    <GamesFilterGrid
                                        activeItems={activeProviders}
                                        open={openProviderFilter}
                                        filterItems={providers}
                                        onClickItem={(e: React.MouseEvent<HTMLDivElement>, item:any) => {
                                            selectFilters(item['category_id'], providerFilterSlugPage)
                                        }}
                                    />

                                    {/*PROVIDERS MOBILE FILTER END PROVIDERS MOBILE FILTER END PROVIDERS MOBILE FILTER END */}

                                    <div className={clsx(styles.divider)}></div> {/* DIVIDER */}
                                </>
                            )}

                            {/*FEATURES MOBILE FILTER START FEATURES MOBILE FILTER START FEATURES MOBILE FILTER START */}
                            {/*<>*/}
                            {/*    <OutlinedSelector*/}
                            {/*        // key={'search-filter-toggle-'+active.toString()}*/}
                            {/*        label={'caracteristici'}*/}
                            {/*        coloredLabel*/}
                            {/*        options={[{key: '', label: areFeatures? '' : 'toate' }]}*/}
                            {/*        disableClickAwayListener*/}
                            {/*        style*/}
                            {/*        className={styles.outlinedFilter}*/}
                            {/*        onOpen={() => {*/}
                            {/*            setOpenFeatureFilter(true);*/}
                            {/*        }}*/}
                            {/*        onClose={() => {*/}
                            {/*            setOpenFeatureFilter(false);*/}
                            {/*        }}*/}
                            {/*        selectedCount={areFeatures? activeFeatures.length : ''}*/}
                            {/*    />*/}

                            {/*    <GamesFilterGrid*/}
                            {/*        checkboxFilter={true}*/}
                            {/*        activeItems={activeFeatures}*/}
                            {/*        open={openFeatureFilter}*/}
                            {/*        filterItems={features}*/}
                            {/*        onClickItem={(e: React.MouseEvent<HTMLDivElement>, item:any) => {*/}
                            {/*            dispatch(featuresFilterSlugPage(selectMultipleFilters(item, activeFeatures)));*/}
                            {/*        }}/>*/}

                            {/*</>*/}
                            {/*FEATURES MOBILE FILTER END FEATURES MOBILE FILTER END FEATURES MOBILE FILTER END */}

                            {/*<div className={clsx(styles.divider)}></div> /!* DIVIDER *!/*/}

                            {/*THEMES MOBILE FILTER START THEMES MOBILE FILTER START THEMES MOBILE FILTER START */}
                            {/*<>*/}
                            {/*    <OutlinedSelector*/}
                            {/*        label={'teme'}*/}
                            {/*        coloredLabel*/}
                            {/*        options={[{key: '', label: areThemes? '' : 'toate' }]}*/}
                            {/*        disableClickAwayListener*/}
                            {/*        style*/}
                            {/*        className={styles.outlinedFilter}*/}
                            {/*        onOpen={() => {*/}
                            {/*            setOpenThemeFilter(true);*/}
                            {/*        }}*/}
                            {/*        onClose={() => {*/}
                            {/*            setOpenThemeFilter(false);*/}
                            {/*        }}*/}
                            {/*        selectedCount={areThemes? activeThemes.length : ''}*/}
                            {/*    />*/}

                            {/*    <GamesFilterGrid*/}
                            {/*        checkboxFilter={true}*/}
                            {/*        activeItems={activeThemes}*/}
                            {/*        open={openThemeFilter}*/}
                            {/*        filterItems={allThemes}*/}
                            {/*        onClickItem={(e: React.MouseEvent<HTMLDivElement>, item:any) => {*/}
                            {/*            dispatch(themeFilterSlugPage(selectMultipleFilters(item, activeThemes)));*/}
                            {/*        }}*/}
                            {/*    />*/}
                            {/*</>*/}
                            {/*THEMES MOBILE FILTER END THEMES MOBILE FILTER END THEMES MOBILE FILTER END */}

                            {/*<div className={clsx(styles.divider)}></div> /!* DIVIDER *!/*/}

                            {/*THEMES MOBILE FILTER START THEMES MOBILE FILTER START THEMES MOBILE FILTER START */}
                            {/*<>*/}
                            {/*    <OutlinedSelector*/}
                            {/*        label={'volatilitate'}*/}
                            {/*        coloredLabel*/}
                            {/*        options={[{key: '', label: areVolatilities? '' : 'toate' }]}*/}
                            {/*        disableClickAwayListener*/}
                            {/*        style*/}
                            {/*        className={styles.outlinedFilter}*/}
                            {/*        onOpen={() => {*/}
                            {/*            setOpenVolFilter(true);*/}
                            {/*        }}*/}
                            {/*        onClose={() => {*/}
                            {/*            setOpenVolFilter(false);*/}
                            {/*        }}*/}
                            {/*        selectedCount={*/}
                            {/*            activeVolatility? activeVolatility.length : ''*/}
                            {/*        }*/}
                            {/*    />*/}

                            {/*    <GamesFilterGrid*/}
                            {/*        checkboxFilter={true}*/}
                            {/*        activeItems={activeVolatility}*/}
                            {/*        open={openVolFilter}*/}
                            {/*        filterItems={volatility}*/}
                            {/*        onClickItem={(e: React.MouseEvent<HTMLDivElement>, item:any) => {*/}
                            {/*            dispatch(volatilityFilterSlugPage(selectMultipleFilters(item.value, activeVolatility)));*/}
                            {/*        }}*/}
                            {/*    />*/}
                            {/*</>*/}
                            {/*THEMES MOBILE FILTER END THEMES MOBILE FILTER END THEMES MOBILE FILTER END */}

                            {/*<div className={clsx(styles.divider)}></div>*/}

                            <OutlinedSelector
                                label={'Sortează'}
                                coloredLabel
                                defaultValue={searchSort}
                                style
                                disableClickAwayListener
                                className={styles.outlinedFilter}
                                onOpen={() => {
                                    setSortOpen(true)
                                }}
                                onClose={() => {
                                    setSortOpen(false)
                                }}
                                options={alphabeticOptions}
                            />

                            {sortOpen && (
                                <div className={styles.sort_filters}>
                                    {alphabeticOptions.map((opt: any, i:number) => {
                                        return (
                                            <div
                                                className={clsx(
                                                    styles.sort_filters__item,
                                                    'theme_white_dark_text',
                                                    searchSort === opt.key && "theme_providers__selected"
                                                    // selectedKey === item.key &&  'theme_providers__selected'
                                                )}
                                                onClick={() => {
                                                    setSearchSort(opt.key)
                                                    setSortChanged(true)
                                                }}
                                                key={"alphabeticOptions"+i}
                                            >
                                                {opt.label}
                                            </div>
                                        )
                                    })}
                                </div>
                            )}


                        </div>
                    </div>
                )}

            </div>

            {/*OVERLAY FILTERS END OVERLAY FILTERS END OVERLAY FILTERS END OVERLAY FILTERS END */}


            {loading && <div style={{textAlign: 'center', padding: 30}}><CircularProgress/></div>}


            {availableGames.length === 0 &&
                <div className={clsx('games-not-found', 'theme_textColor_secondary')}>
                    NU S-A GĂSIT NIMIC
                </div>
            }

            <GameGrid disableCLS={!availableGames.length} games={availableGames} page={'category'} key={`grid-${api_id}`}/>

        </div>
    )
}

export default PageTypeCategoryGames