import {ClickAwayListener} from '@mui/base';
import st from '@/template/styles/balance.module.scss'
import React, {ReactNode, useState} from "react";
import clsx from "clsx";
import strings from "@/AbsoluteComponents/utils/strings";
import {useDispatch, useSelector} from "react-redux";
import useAuth from "@/AbsoluteComponents/hooks/auth";
import {useRouter} from "next/router";
import Offer from "@/AbsoluteComponents/interfaces/offer";
import SmarticoClass from "@/AbsoluteComponents/utils/smartico_class";
import CMS from "@/AbsoluteComponents/utils/cms";
import SmarticoMiniGame from "@/AbsoluteComponents/interfaces/smartico_minigames";
import BonusCenterIcon from "@/AbsoluteComponents/themes/spins/svg/bonusCenterIcon";
import {openBonusCenter} from "@/AbsoluteComponents/store/account/actions";
import ArrowLeft from "@/template/svg/arrow-left";
import ActiveOffersIcon from "@/AbsoluteComponents/themes/spins/svg/ActiveOffersIcon";
import Chevrone from "@/template/svg/Chevrone";
import NewArrow from "@/AbsoluteComponents/themes/spins/svg/newArrow";
import ArrowRight from "@/template/svg/arrow-right";
import {openWheelModal, setModalMiniGameId} from "@/AbsoluteComponents/store/modals/actions";
import WheelSpins from "@/AbsoluteComponents/themes/spins/svg/wheelSpin";
import GiftBoxIcon from "@/AbsoluteComponents/themes/spins/svg/giftBoxIcon";
import styles from "@/AbsoluteComponents/themes/spins/styles/balance.module.scss";
import InstantWinsIcon from "@/template/svg/instant_wins_icon";
import ClaimableOfferInterface from "@/AbsoluteComponents/interfaces/claimable_offer_interface";

interface Bonus {
    icon: ReactNode
    label:string
    blink: boolean
    qt:number
    image_address?:string
    onClick():void
    visible:boolean
}

const BonusCenter = () => {
    const enableSpin: string = useSelector((state: any) => state.common.enableSpinWheel);
    const offer:Array<Offer> = useSelector((state:any) =>  state.account.offers);
    const [open, setOpen] = useState(false)
    const dispatch = useDispatch()
    const router  = useRouter()
    const auth = useAuth()
    const isMobile = useSelector((state:any) => Boolean(state.common.isMobile));
    const giftbox = auth.getSmarticoMiniGames().find((x:SmarticoMiniGame) =>
        x.saw_game_type === SmarticoClass.saw_game_types.giftbox
    )
    const instant_win_data = CMS.getInstantWin()
    const claimable_offer:Array<ClaimableOfferInterface> = useSelector((state:any) => state.account.claimableOffers) || []
    // console.log("claimable_offer", claimable_offer)
    
    const smartico_wheel = auth.getSmarticoMiniGames().find((x:SmarticoMiniGame) =>
        x.saw_game_type === SmarticoClass.saw_game_types.wheel
    )
   
    const is_wheel = Boolean(typeof smartico_wheel === "object" && Object.keys(smartico_wheel).length)
    const blink = giftbox || Boolean(offer.length) || enableSpin === "enable"
    const smartico_props = useSelector((state:any) => state.account.smarticoProps);
    
    let badge_qt = offer.length + claimable_offer.length
    
    if(typeof giftbox !== "undefined" && Boolean(Object.keys(giftbox).length)) {
        badge_qt += 1
    }
    
    
    if(enableSpin === "enable") {
        badge_qt += 1
    }
    
    const bonus_list:Array<Bonus> = [
        {
            icon:<ActiveOffersIcon />,
            label: "bonusurile mele",
            blink: offer.length > 0,
            qt: offer.length + claimable_offer.length,
            image_address: "",
            onClick: () => {
                if(auth.isLoggedIn()){
                    router.push("/account/available-offers")
                }
            },
            visible: true
        },
        
        {
            icon: <InstantWinsIcon />,
            label: instant_win_data?.menu_label || "",
            blink: false,
            qt: 0,
            onClick: () => {
                if(auth.isLoggedIn()){
                    router.push(instant_win_data?.slug || "/")
                }
            },
            visible: !instant_win_data.canceled
        },
        
        {
            icon:<WheelSpins />,
            label: "roata norocului",
            blink: false,
            qt: 0,
            image_address: "",
            onClick: () => {
                if(smartico_wheel?.id) {
                    SmarticoClass.openDashboardMiniGame(smartico_wheel.id)
                }
            },
            visible: is_wheel
        },
        

        
        {
            // icon: <GiftBoxIcon />,
            icon: <></>,
            label: strings["play_hub"],
            blink: true,
            qt: 0,
            image_address: smartico_props?.avatar_id ? smartico_props?.avatar_id : "",
            onClick: () => {
                SmarticoClass.openDashboard()
            },
            visible: true
        },
    ]
    
    return (
        
        <>
            {open && (
                <div className={styles.drop_down_overlay}/>
            )}
            
            <ClickAwayListener onClickAway={() => {
                setOpen(false)
            }}>
                
                <div
                    onClick={() => {
                        setOpen(!open)
                    }}
                    className={clsx(st.bonusCenter, 'theme_dropDownBalance' , open && st.active, blink && st.blink)}
                >
                    <BonusCenterIcon className={st.mainIcon}/>
                    
                    <div
                        className={clsx(
                            st.bonus_container,
                            'theme_dropDownBalance',
                            'themes_balance_wrapper',
                        
                        )}
                    >
                        {bonus_list.map((item:Bonus, i:number) => {
                            
                            return item.visible && (
                                <div
                                    style={{
                                        animationDelay: `${0.2 * i}s`
                                    }}
                                    key={"bonus box"+i}
                                    onClick={item.onClick}
                                    className={clsx(
                                        st.bonus_container__items,
                                        'theme_dropDownBalance__item',
                                        "clickable",
                                        item.blink && st.bonusStyle
                                    )}
                                >
                                    {item.image_address?.length ? (
                                        <>
                                            <div className={clsx(st.left, st.sides, st.with_image)}>
                                                
                                                <img
                                                    src={smartico_props?.avatar_id}
                                                    alt={""}
                                                    width={100}
                                                    height={100}
                                                />
                                                
                                                <p className={clsx(st.bonus_container__title, 'theme_textColor_secondary')}>{item.label} </p>
                                            </div>
                                        
                                        </>
                                    ) : (
                                        <>
                                            <div className={clsx(st.left, st.sides,)}>
                                                <div className={st.icon_container}>
                                                    {item.icon}
                                                </div>
                                                
                                                <p className={clsx(st.bonus_container__title, 'theme_textColor_secondary')}>{item.label} </p>
                                            </div>
                                            
                                            <div className={clsx(st.right, st.sides, "theme_white_dark_text")}>
                                                {item.qt > 0 ? item.qt : <ArrowRight/>}
                                            </div>
                                        </>
                                    )}
                                
                                
                                </div>
                            )
                        })}
                    </div>
                </div>
            </ClickAwayListener>
        </>
    
    )
}


export default BonusCenter