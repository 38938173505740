const CasinoLiveIcon = () => {
    return (
        <svg className={"svg-icon"} width="20" height="23" viewBox="0 0 20 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M1.93185 5.65473C0.864919 5.94061 0.231755 7.03728 0.517638 8.10422L3.77327 20.2544C4.05915 21.3213 5.15583 21.9545 6.22276 21.6686L13.035 19.8433C14.1019 19.5574 14.7351 18.4607 14.4492 17.3938L11.1936 5.24361C10.9077 4.17668 9.811 3.54351 8.74407 3.8294L1.93185 5.65473ZM5.29407 8.38756L4.31668 12.8844C4.21541 13.3504 4.35246 13.8618 4.67313 14.2147L7.76801 17.6204C8.42351 18.3418 9.46521 18.0627 9.67222 17.1102L10.6496 12.6133C10.7509 12.1474 10.6138 11.6359 10.2932 11.283L7.19827 7.87733C6.54277 7.15599 5.50108 7.43511 5.29407 8.38756Z"
            />
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M9.22546 1.48286C9.51134 0.415927 10.608 -0.217239 11.6749 0.0686447L18.4872 1.89397C19.5541 2.17986 20.1873 3.27653 19.9014 4.34346L16.6458 16.4936C16.5043 17.0216 16.1642 17.4434 15.7275 17.7001C15.7414 17.338 15.7019 16.9676 15.6032 16.5992L14.3773 12.0241C15.6983 11.448 16.8227 10.4802 17.5897 9.23833L17.7631 8.95751C18.2626 8.14867 18.3331 7.14608 17.9516 6.27533L17.8476 6.03787C17.5605 5.38267 16.9912 4.89348 16.3002 4.70834C15.3701 4.45913 14.3821 4.80127 13.8053 5.57225L13.6192 5.82094C13.4048 6.10754 13.2471 6.43242 13.1544 6.77815C13.2471 6.43242 13.2729 6.07218 13.2305 5.71677L13.1937 5.40838C13.0797 4.45229 12.395 3.66196 11.465 3.41275C11.3909 3.3929 11.3163 3.37677 11.2413 3.36432C10.584 2.8712 9.76798 2.59979 8.91992 2.62314L9.22546 1.48286Z"
            />
        </svg>

    )
}

export default CasinoLiveIcon