import {FC, useEffect, useState, useRef} from 'react';
import styles from 'themes/spins/styles/PageTypeInformation.module.scss';
import clsx from 'clsx';
import CMS from "utils/cms";
import TransactionsLayout from "themes/spins/ui/account-transactions-view";
import {useDispatch} from 'react-redux';
import {openInfoSidebar, setFromAccPg, showScrollToTop} from "store/common/actions";
import AccPageTitle from "themes/spins/small_ui/acc_page_title";
import {useRouter} from 'next/router';


interface Props {
    json: object;
}


const PageTypeInformation: FC<Props> = ({json}) => {
    const data = CMS.jsonBlockPages(json);
    const {title} = data
    const dispatch = useDispatch()


    useEffect(() => {
        dispatch(showScrollToTop(true))

        return () => {
            dispatch(setFromAccPg(false))
            dispatch(showScrollToTop(false))
        }
    }, []);


    return (

        <TransactionsLayout>
            
            <>
                <AccPageTitle
                    infoPage
                    onClick={() => {
                        dispatch(openInfoSidebar(true))
                    }}
                    title={title}
                />

                <div style={{paddingBottom: '32px'}}>

                </div>

                {data.sections.map((item: any, i: number) => {

                    return (
                        <div
                            key={`info-${i}`}
                            className={clsx(styles.info_section, 'theme_page_information')}
                        >
                            {item.title.length > 0 && <h3 className={styles.infoTitle}>{item.title}</h3>}

                            <div
                                 className={clsx(
                                     item.with_border === true && clsx('border_block', styles.border_block),
                                 )} dangerouslySetInnerHTML={{__html: item.content}}
                            />

                        </div>
                    )

                })}
            </>

        </TransactionsLayout>
    )
}

export default PageTypeInformation;