function AddIconSpin() {
    return (
        <svg className={'svg-icon'} xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
            {/*<mask id="mask0_1519_2614" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="24">*/}
            {/*    <rect x="0.5" width="24" height="24" fill="#D9D9D9"/>*/}
            {/*</mask>*/}
            {/*<g mask="url(#mask0_1519_2614)">*/}
                <path d="M11.5 13H6.5C5.94772 13 5.5 12.5523 5.5 12C5.5 11.4477 5.94772 11 6.5 11H11.5V6C11.5 5.44772 11.9477 5 12.5 5C13.0523 5 13.5 5.44772 13.5 6V11H18.5C19.0523 11 19.5 11.4477 19.5 12C19.5 12.5523 19.0523 13 18.5 13H13.5V18C13.5 18.5523 13.0523 19 12.5 19C11.9477 19 11.5 18.5523 11.5 18V13Z"/>
            {/*</g>*/}
        </svg>
    )
}
export default AddIconSpin