import styles from "themes/spins/styles/card.module.scss";
import React from 'react'
import clsx from 'clsx';
import Checkbox  from '@mui/material/Checkbox';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CMS from "@/AbsoluteComponents/utils/cms";

interface Props {
    open: boolean
    filterItems: Array<any>
    onClickItem(e: React.MouseEvent<HTMLDivElement>, item:any):void
    activeItems: Array<any>
    checkboxFilter?: boolean

}

const GamesFilterGrid:React.FC<Props> = ({open ,filterItems,activeItems,onClickItem, checkboxFilter}) => {

    const maxLength:number = 16

    const shorts = ():Array<string> => {
        let arr: Array<string> = []
        filterItems.map((item) => {
            if(item.length <= maxLength && !item.hasOwnProperty('value')){
                arr.push(item)
            }
        })

        return arr
    }
    const longs = ():Array<string> => {
        let arr: Array<string> = []
        filterItems.map((item) => {
            if(item.length > maxLength && !item.hasOwnProperty('value')){
                arr.push(item)
            }
        })

        return arr
    }

    let shortTerms = shorts()
    const combineArrays = ():Array<any> => {
        let arr: Array<string> = []

        for(let i = 0; i < shortTerms.length; i++) {

            arr.push(shortTerms[i])

            if(i % 2 === 1 && longs().length){
                if(longs()[i] !== undefined) {
                    arr.push(longs()[i])
                }
            }
        }

        return arr
    }
    const addRest = ():Array<string> => {
        let arr: Array<string> = []

        longs().map((item, i) => {
            if(!combineArrays().includes(item)) {
                arr.push(item)
            }
        })

        return [...combineArrays(), ...arr]
    }

    let items = addRest()

    return open ?
        (
            <div className={styles.providersFilterGrid}>
                {filterItems.length > 0 &&
                    <>
                        <div className={clsx(
                            styles.grid,
                            styles.mobileGrid,
                            checkboxFilter && styles.checkBoxesGrid,
                        )}>

                            {((filterItems[0].hasOwnProperty('thumbnail_src')  || filterItems[0].hasOwnProperty('value')) ? filterItems : items).map((item, i:number) => {

                                let hasKeys: boolean = item.hasOwnProperty('value')

                                let long:boolean = (hasKeys && item.value.length > maxLength) || (!hasKeys && item.length > maxLength)

                                return !checkboxFilter ? (
                                    <div
                                        key={`provider-${i}`}
                                        className={clsx(styles.item, 'theme_providers__item',
                                         activeItems.includes(item.category_id) && styles.active
                                        )}
                                        //@ts-ignore
                                        onClick={(e:React.MouseEvent<HTMLDivElement>, x:any) => {
                                            onClickItem(e, item)
                                        }}
                                    >
                                        {!Boolean(item.image_dark.length) && (
                                            <img src={item['thumbnail_src']}  alt={item['name']}/>
                                        )}

                                        {Boolean(item.image_dark.length) && (
                                            <>
                                                <img data-hide-on-theme="dark" src={CMS.getPublicFilePath(item.image_dark[0].name, 'slots_test', item.image_dark[0].path)}  alt={item['name']}/>
                                                <img data-hide-on-theme="light" src={item['thumbnail_src']}  alt={item['name']}/>
                                            </>
                                        )}
                                    </div>
                                ) : (
                                    <div
                                        //@ts-ignore
                                        onClick={(e:React.MouseEvent<HTMLDivElement>, x:any) => {
                                            e.stopPropagation()
                                            onClickItem(e, item)
                                        }}

                                        key={`provider-${i}`}
                                        className={clsx(
                                            styles.checkItem,
                                            long && styles.long,
                                        )}
                                    >
                                        <Checkbox
                                            checked={activeItems.includes(item) || activeItems.includes(item.value)}
                                            checkedIcon={<CheckBoxIcon style={{fill: "#006E04"}} />}
                                        />

                                        <span  className={clsx(styles.checkItem__label, 'theme_textColor_secondary')}>
                                            {item.hasOwnProperty('value') ? item.value : item}
                                        </span>
                                    </div>
                                )
                            })}
                        </div>
                    </>
                }
            </div>
        ) : null

}

export default GamesFilterGrid