import {COOKIE_AUTH_TOKEN} from '../utils/cookies';
import type {NextApiRequest, NextApiResponse} from 'next';
import {getCookie} from 'cookies-next';
import * as process from "process";

export default (endpoint: string, options?: any, req?: NextApiRequest, res?: NextApiResponse) => {
    const token: any = getCookie(COOKIE_AUTH_TOKEN, {req, res});
    const shortenedOrigin = process.env.NEXT_PUBLIC_WS_REFERRER?.replace(/^https?:\/\//, '');
    
    // console.log("PRODUCTION FETCH")
    let defaultOptions: any = {
        method: options.method,
        headers: {
            // mode: 'cors',
            // 'Accept': 'application/json',
            // TODO: document.location.hostname
            //'Origin': process.env.NEXT_PUBLIC_SITEMAP_ORIGIN,
            
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            'Origin': shortenedOrigin,
            'Referer': shortenedOrigin,
            'x-site-id': process.env.NEXT_PUBLIC_SITE_ID
        }
    };

    if (options['X-Forwarded-For']) {
        defaultOptions['X-Forwarded-For'] = options['X-Forwarded-For'];
    }

    if (options.body) {
        defaultOptions.body = options.body
    }

    if (endpoint.search('/player/verification/document') > -1 && options?.method?.toLowerCase() === 'post' && process.env.NODE_ENV === "production") {
        delete defaultOptions.headers['Content-Type'];
    }

    if (endpoint.search('/balance') > -1) {
        // console.log('balance token', token);
    }

    if (endpoint.search('/player/play/real') > -1) {
        // console.log('token', token, req?.cookies);
    }

    if (endpoint.search('/payment/paymentInfo') > -1) {
        defaultOptions.headers = {
            'Accept': 'application/json',
            'Accept-Encoding': 'gzip, deflate, br',
            'Accept-Language': 'en-US,en;q=0.9',
            'Access-Control-Allow-Origin': '*',
            // 'Connection': 'keep-alive',
            'Content-Type': 'application/json',
            'Host': process.env.NEXT_PUBLIC_SITE_ID,
            'Origin': shortenedOrigin,
            'Referer': shortenedOrigin,
            'sec-ch-ua': '"Not?A_Brand";v="8", "Chromium";v="108", "Google Chrome";v="108"',
            'sec-ch-ua-mobile': '?0',
            'sec-ch-ua-platform': '"Windows"',
            'Sec-Fetch-Dest': 'empty',
            'Sec-Fetch-Mode': 'cors',
            'Sec-Fetch-Site': 'same-site',
            // 'sw_referer': 'cashpot.ro',
            'User-Agent': 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/108.0.0.0 Safari/537.36',
            // 'x-site-id': process.env.SITE_ID
            'x-site-id': process.env.NEXT_PUBLIC_SITE_ID
            // 'x-site-id': 'dcfc8df9-4736-423a-a1cd-377ed95563c8'

        };
    }


    if (typeof token === "string") {
        defaultOptions.headers = {...defaultOptions.headers, ...{'Authorization': `Bearer ${token}`}};
    }

    // defaultOptions.headers = {...defaultOptions.headers, ...{'Authorization': `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzaXRlVHlwZSI6IlNreXdpbmQzNjAiLCJzaXRlSWQiOiI1Y2RkOGE1Ny0yMWE2LTRjNGItOThlOC1lZTc1MTJkMDQ0NDAiLCJwbGF5ZXJJZCI6IjUwMWJkNzI0LWFmYTctNTU3Ni04ZWZmLTVlYmI4ODViY2ViZSIsImN1cnJlbmN5IjoiUk9OIiwiaXNUZXN0IjpmYWxzZSwicGVyc29uYWxOdW1iZXIiOiI2NjEyODAiLCJlbWFpbCI6InZhc2lsZS50b21pdGFAY2FzaHBvdC5ybyIsInNlc3Npb25JZCI6IjQwNjQ2MzI1LTlmN2EtNDUxNS04YTg2LWIzMDcwZmJkNjQ2NSIsImlhdCI6MTY3MDgyOTYzOSwiZXhwIjoxNjcwODcyNzE5fQ.xIdWteogEBdCPipskypAJdOugDAns4dcEG0lZWuZYW0`}};

    if (typeof options === "object" && options.headers) {
        // defaultOptions = {...defaultOptions, ...options};
        defaultOptions.headers = {...defaultOptions.headers, ...options.headers}
    }


    // return fetch('https://site-api.spin.ro'+endpoint, defaultOptions);
    // return fetch('https://site-api.cashpot.ro' + endpoint, defaultOptions);
    // let root = is_staging ? stageConfig.site_api_root : site_api_root
    // let root = site_api_root
    // return fetch(root + endpoint, defaultOptions);
    return fetch(process.env.NEXT_PUBLIC_SITE_API + endpoint, defaultOptions);
    //     // return fetch(process.env.SITE_API + endpoint, defaultOptions);
}