function PoliticaIconSpins() {
    return(
        <svg className={'svg-icon'} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            {/*<g filter="url(#filter0_di_912_34718)">*/}
                <path fillRule="evenodd" clipRule="evenodd" d="M8.5 3.5C6.01472 3.5 4 5.51472 4 8V18H4.03544C4.27806 19.6961 5.73676 21 7.5 21H19C19.5523 21 20 20.5523 20 20V5C20 4.17157 19.3284 3.5 18.5 3.5H8.5ZM7.5 15.5H18.5V19.5H7.5C6.39543 19.5 5.5 18.6046 5.5 17.5C5.5 16.3954 6.39543 15.5 7.5 15.5ZM15.5194 8.54104C15.8182 8.25419 15.8279 7.77941 15.541 7.4806C15.2542 7.18179 14.7794 7.1721 14.4806 7.45896L11.875 9.96034L10.5194 8.65896C10.2206 8.3721 9.74582 8.38179 9.45896 8.6806C9.1721 8.97941 9.18179 9.45419 9.4806 9.74104L11.3556 11.541C11.6458 11.8197 12.1042 11.8197 12.3944 11.541L15.5194 8.54104Z" fill="white"/>
            {/*</g>*/}
            <defs>
                <filter id="filter0_di_912_34718" x="-2" y="-1" width="28" height="28" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="1"/>
                    <feGaussianBlur stdDeviation="1"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_912_34718"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_912_34718" result="shape"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="1"/>
                    <feGaussianBlur stdDeviation="1"/>
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.6 0"/>
                    <feBlend mode="normal" in2="shape" result="effect2_innerShadow_912_34718"/>
                </filter>
            </defs>
        </svg>
    )
}

export default PoliticaIconSpins