function Vector() {
    return (
        // <svg className={'svg-icon'} width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
        //     <path d="M5.00081 5.99977L0.757812 1.75677L2.17281 0.342773L5.00081 3.17177L7.82881 0.342773L9.24381 1.75677L5.00081 5.99977Z" fill="white"/>
        // </svg>

        <svg width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.5 5.91673L0.5 1.91673L1.43333 0.983398L4.5 4.05007L7.56667 0.983398L8.5 1.91673L4.5 5.91673Z" fill="#929396"/>
        </svg>
    )
}
export default Vector