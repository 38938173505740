import {updateLiveRTP, updateWinnersList} from "store/common/actions";
import {useEffect,useRef} from 'react';
import {useDispatch} from 'react-redux';
import WinnerInterface from "interfaces/winner";
import ReconnectingWebSocket from 'reconnecting-websocket';
import {useSelector} from 'react-redux';
import GameInterface from "interfaces/game";

function WSS() {
    const dispatch = useDispatch();
    const wsSender = useRef<any>(null);
    const allGames:Array<GameInterface> = useSelector((state:any) => state.common.allGames);

    useEffect(() => {
        const url:string = process.env.NEXT_PUBLIC_CASHPOT_WSS_ENDPOINT || '';
        const ws = new ReconnectingWebSocket(url);

        ws.onopen = (event) => {
            ws.send(`42${JSON.stringify(['authorize', {
                'siteId':  process.env.NEXT_PUBLIC_SITE_ID,
            }])}`);

            const autoSend = () => {
                ws.send('2');
                wsSender.current = setTimeout(autoSend, 25000);
            };

            wsSender.current = setTimeout(autoSend, 25000);
        };

        ws.onclose = function(event) {
            clearTimeout(wsSender.current);
        };

        ws.onerror = function(event) {
        };

        ws.addEventListener('error', (event) => {
        });

        ws.onmessage = function (event) {
            if (event.data.search('42') === 0) {
                try {
                    const json = JSON.parse(event.data.replace('42', ''));

                    if (Array.isArray(json) && json[0] === 'live-rtp' && json[1] && typeof json[1].data === "object") {

                        let rtpObj:any = {}

                        if(json[1].data?.hourly?.hot.length > 0) {
                            rtpObj.hourlyHot = json[1].data?.hourly?.hot
                        }

                        if(json[1].data?.hourly?.cold.length > 0) {
                            rtpObj.hourlyCold = json[1].data?.hourly?.cold
                        }

                        if(json[1].data?.daily?.hot.length > 0) {
                            rtpObj.dailyHot = json[1].data?.daily?.hot
                        }

                        if(json[1].data?.daily?.cold.length > 0) {
                            rtpObj.dailyCold = json[1].data?.daily?.cold
                        }

                        dispatch(updateLiveRTP(rtpObj));

                    }

                    if (Array.isArray(json) && json[0] === 'top-winners' && json[1] && typeof json[1].data === "object") {
                        const winners:Array<WinnerInterface> = [];

                        json[1].data?.winners?.map((w:any) => {
                            const savedGame = allGames.find(game => game.gameId === w['gameId']);

                            winners.push({
                                currency: w['currency'] || '',
                                roundId: w['roundId'] || '',
                                playerId: w['playerId'] || '',
                                nickName: w['nickName'] || '',
                                providerId: w['providerId'] || '',
                                vendorId: w['vendorId'] || '',
                                providerName: w['providerName'] || '',
                                friendlyName: w['friendlyName'] || '',
                                imageUrl: savedGame?.thumbnail  || '',
                                winDate: w['winDate'] || '',
                                gameId: w['gameId'] || '',
                                mobileId: w['mobileId'] || '',
                                totalWin: w['totalWin'] || 0,
                            });
                        });

                        dispatch(updateWinnersList(winners));
                    }
                } catch (e) {}
            }
        };

        return () => {
            ws.close();
            clearTimeout(wsSender.current);
        }

    }, []);

    return null;
}

export default WSS