
function CloseIcon() {
    return(

        <svg className={'svg-icon'} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_203_9969)">
                <path d="M9.89531 7.99753L15.6143 2.28252C15.8655 2.03057 16.0063 1.68916 16.0057 1.3334C16.0051 0.977647 15.8633 0.636685 15.6113 0.385525C15.3594 0.134364 15.0179 -0.0064203 14.6622 -0.0058577C14.3064 -0.00529509 13.9655 0.136569 13.7143 0.388525L7.99931 6.10352L2.28431 0.388525C2.15994 0.264163 2.01231 0.165513 1.84982 0.0982092C1.68733 0.030905 1.51318 -0.00373577 1.33731 -0.00373577C1.16143 -0.00373577 0.98728 0.030905 0.824794 0.0982092C0.662307 0.165513 0.514668 0.264162 0.390306 0.388524C0.265945 0.512886 0.167296 0.660525 0.0999918 0.823011C0.0326877 0.985498 -0.00195313 1.15965 -0.00195312 1.33552C-0.00195312 1.5114 0.0326877 1.68555 0.0999918 1.84804C0.167296 2.01052 0.265945 2.15816 0.390306 2.28252L6.10531 7.99753L0.393307 13.7105C0.142147 13.9617 0.00104618 14.3023 0.00104618 14.6575C0.00104618 15.0127 0.142147 15.3534 0.393307 15.6045C0.644467 15.8557 0.985113 15.9968 1.34031 15.9968C1.6955 15.9968 2.03615 15.8557 2.28731 15.6045L8.00231 9.88953L13.7143 15.6055C13.9656 15.8575 14.3067 15.9993 14.6625 15.9998C15.0184 16.0002 15.3598 15.8593 15.6118 15.608C15.8638 15.3567 16.0056 15.0156 16.006 14.6598C16.0065 14.3039 15.8656 13.9625 15.6143 13.7105L9.89531 7.99753Z" fill="#84789C"/>
            </g>
            <defs>
                <clipPath id="clip0_203_9969">
                    <rect width="16" height="15.996" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}

export  default CloseIcon;