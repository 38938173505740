import {FC} from 'react';
import Head from 'next/head';
import clsx from 'clsx';


interface Props {
    pageTitle?: string
    title?: string
    accountMenu?:JSX.Element
    children:JSX.Element
    className?:string
}

const TransactionsLayout:FC<Props> = ({
                                          children,
                                          title,
                                          accountMenu,
                                          pageTitle,
                                          className
}) => {

    return (
        <div className={clsx('page-container', 'deposit-responsive', 'acc-page', className && className)}>
            
            {pageTitle ? (
                <Head>
                    <title>{pageTitle}</title>
                    <meta name="description" content="spins" />
                </Head>
            ) : null}
            

            <div  className={'acc_page_row'}>
                <div id={'acc_page_content'} className={'acc_page_content'}>

                    {children}

                </div>
            </div>

        </div>
    )

};

export default TransactionsLayout