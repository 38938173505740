import {FC} from 'react';
import styles from 'themes/spins/styles/signup.module.scss';
import {useDispatch} from 'react-redux';
import clsx from 'clsx';
import {openVerificationModal} from 'store/modals/actions';
import useAuth from "hooks/auth";
import VerificationIndicatorViewInterface
    from "interfaces/ui-view-interfaces/verification_indicator_view_interface";
import strings from 'utils/strings';
import {useRouter} from "next/router";

const VerificationIndicatorView:FC<VerificationIndicatorViewInterface> = ({ ... props}) => {

    const {
        mobile,
        allVerified,
        text,
        identity
    } = props

    const dispatch = useDispatch();
    const auth = useAuth();
    const router = useRouter()
    
    if (auth.isLoggedIn()) {
        return (
            <div
                className={clsx(styles.indicator, mobile && styles.indicator__mobile)}
                onClick={() => {
                    if(identity) {
                        router.push("/account/account-validation")
                    } else {
                        dispatch(openVerificationModal(true))
                    }
                }}
            >
                <span className={'verification-title'}>{text ? text : ""}</span>
                <span className={'not-approved-account-message'} style={{color: 'white', textAlign: "start", maxWidth: "90%"}}>{identity? strings['not_approved_yet_ident'] : strings['not_approved_yet_email']}</span>
            </div>
        )
    }

    return null;
}

export default VerificationIndicatorView