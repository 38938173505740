function One() {
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="83" height="116" viewBox="0 0 83 116" fill="none">
            <g filter="url(#filter0_d_1577_191206)">
                <path d="M34.3496 34.2637H48.6776V81.9997H38.1656V44.3437H34.3496V34.2637Z" fill="#FBB90E"/>
            </g>
            <g filter="url(#filter1_d_1577_191206)">
                <path fillRule="evenodd" clipRule="evenodd" d="M32.3496 32.2637H50.6776V83.9997H36.1656V46.3437H32.3496V32.2637ZM34.3496 34.2637V44.3437H38.1656V81.9997H48.6776V34.2637H34.3496Z" fill="white"/>
            </g>
            <defs>
                <filter id="filter0_d_1577_191206" x="2.34961" y="2.26367" width="78.3281" height="111.736" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset/>
                    <feGaussianBlur stdDeviation="16"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0.984314 0 0 0 0 0.72549 0 0 0 0 0.054902 0 0 0 0.5 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1577_191206"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1577_191206" result="shape"/>
                </filter>
                <filter id="filter1_d_1577_191206" x="0.349609" y="0.263672" width="82.3281" height="115.736" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset/>
                    <feGaussianBlur stdDeviation="16"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0.984314 0 0 0 0 0.72549 0 0 0 0 0.054902 0 0 0 0.5 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1577_191206"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1577_191206" result="shape"/>
                </filter>
            </defs>
        </svg>
    )
}
export default One