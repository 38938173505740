import styles from '@/template/styles/outlinedSelector.module.scss';
import {FC, useState, useEffect} from 'react';
import DropdownCaret from 'themes/default/svg/dropdownCaret';
import clsx from 'clsx';
import {ClickAwayListener} from '@mui/base';
import  { useSelector, useDispatch } from 'react-redux';
import {
    providerFilterSlugPage,
    featuresFilterSlugPage,
    themeFilterSlugPage,
    volatilityFilterSlugPage
} from "store/common/actions";
import Checkbox  from '@mui/material/Checkbox';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CMS from "@/AbsoluteComponents/utils/cms";


interface Options {
    key:string,
    label:string,
}

interface Props {
    label?:string,
    labelIcon?:any,
    defaultValue?:string,
    options:Array<Options>,
    onOpen?(): void,
    onClick?():void,
    onClose?(): void,
    onChange?(key:string): void,
    disableClickAwayListener?: boolean,
    borderHide?: boolean,
    style?:boolean,
    selectedCount?: number | string,
    className?: string
    providers?: any
    features?:any
    themes?:any
    volatility?:any
    tags?:any,
    slotsStyle?: boolean,
    bgColored?:boolean
    bgColoredActive?:boolean,
    coloredLabel?:boolean,
    filtersPage?:boolean,
}

const OutlinedSelector: FC<Props> = ({
     className,
     label,
     labelIcon,
     coloredLabel,
     style,
     options,
     onOpen,
     slotsStyle,
     onClick,
     onClose,
     disableClickAwayListener,
     onChange,
     defaultValue,
     borderHide,
     selectedCount,
     providers,
     features,
     bgColored,
     themes,
     volatility,
     bgColoredActive,
     filtersPage,
     tags
}) => {

    const [open, setOpen] = useState(false);
    const [selectedKey, selectKey] = useState(options[0]?.key || '');
    const activeProviders:Array<any> = useSelector((state: any) => state.common.slugSearchProviders);
    const activeFeatures: Array<any> = useSelector((state: any) => state.common.slugSearchFeatures);
    const activeThemes: Array<any> = useSelector((state: any) => state.common.slugSearchTheme);
    const activeVolatility: Array<any> = useSelector((state: any) => state.common.slugSearchVolatility);
    const [hideElement, setHideElement] = useState<boolean>(true)
    const dispatch = useDispatch();
    const isCategPage = useSelector((state:any) => state.common.isCategPage)

    const handleChange = (key:string) => {
        if (typeof onChange === "function") onChange(key);
        selectKey(key);
        setOpen(false);
    };

    const option:Options | undefined = options.find((item:Options) => item.key === selectedKey);

    const selectProviders = (provider_id:string) => {
        let list:Array<any> = JSON.parse(JSON.stringify(activeProviders));

        if (list.filter((id:any) => id === provider_id).length) {
            list = list.filter((id:any) => id !== provider_id)
        } else {
            list.push(provider_id);
        }

        dispatch(providerFilterSlugPage(list));
    };

    const selectFilters = (item: any, initialArray:Array<any>): Array<any> => {
        let list: Array<any> = [ ... initialArray];

        if(list.filter((x:any) => x === item).length) {
            list = list.filter((x:any) => x !== item)
        } else {
            list.push(item);
        }

        // console.log(initialArray)

        return list
    }

    //Added end

    useEffect(() => {
        if (defaultValue) {
            selectKey(defaultValue);
        }

        const timeout = setTimeout(() => {
            setHideElement(false)
        }, 700)

        return ()=> {
            clearTimeout(timeout)
        }

    }, []);

    useEffect(() => {
        if (open) {
            if (typeof onOpen === "function") onOpen();
        } else {
            if (typeof onClose === "function") onClose();
        }
    }, [open]);



    return (
        <ClickAwayListener onClickAway={() => !disableClickAwayListener ? setOpen(false) : () => {}}>
            <div className={
                clsx(
                    styles.root,
                    (filtersPage ||  bgColored) && 'themes_rootOutlinedSelected',
                    (bgColoredActive &&  open) && 'themes_rootOutlinedSelected_Active',
                    className && className,
                    style && styles.mobileFilter,
                    (filtersPage && open)  && 'themes_rootOutlinedSelected_Active',
                    open && styles.active,
                    slotsStyle && 'theme_searchInput_header',
                    borderHide && styles.hideBorder,
                )}
                onClick={() => setOpen(!open)}
            >
                {label !== undefined && <div className={clsx(styles.root__label, 'theme__label', coloredLabel && 'themes_coloredLabel')}>{label}</div>}

                {labelIcon !== undefined && <div className={clsx(styles.root__icon, 'theme__svg')}>{labelIcon}</div>}

                <div
                    className={clsx(styles.root__selected, selectedCount && styles.counter, 'theme_textColor_secondary',
                    )}
                >
                    {/*{option?.label || ''}*/}
                   <span className={clsx(selectedCount && 'selectedCount_color')}

                       // style={{color: selectedCount ? 'green' : ''}}
                   >{selectedCount? selectedCount : option?.label || ''}</span>
                </div>

                <span className={clsx(styles.root__caret, 'theme__svg')}><DropdownCaret/></span>

                {/*PROVIDERS GRID START*/}
                {(open && providers) && (
                    <div className={clsx(styles.providersContainer, styles.desktopFilters, styles.providers, open && styles.opened, 'theme_providers')}>
                        {providers.length > 0 &&
                            <>
                                <div className={styles.grid}>
                                    {providers.map((provider: any, i:number) => {

                                        return (
                                            <div
                                                key={`provider-${i}`}
                                                className={clsx(styles.item, 'theme_providers__item' ,activeProviders.includes(provider.category_id) && styles.active)}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    selectProviders(provider['category_id']);
                                                }}
                                            >

                                                {!Boolean(provider.image_dark?.length) && (
                                                    <img src={provider['thumbnail_src']}  alt={provider['name']}/>
                                                )}

                                                {Boolean(provider.image_dark?.length) && (
                                                    <>
                                                        <img
                                                            data-hide-on-theme="dark"
                                                            src={CMS.getPublicFilePath(provider.image_dark[0].name, 'slots_test', provider.image_dark[0].path)}
                                                            alt={provider['name']}
                                                        />

                                                        <img
                                                            data-hide-on-theme="light"
                                                            src={provider['thumbnail_src']}
                                                            alt={provider['name']}
                                                        />
                                                    </>
                                                )}

                                            </div>
                                        )
                                    })}
                                </div>
                            </>
                        }
                    </div>
                )}
                {/*PROVIDERS GRID END*/}


                {/*FEATURES GRID START*/}
                {(open && features) && (
                    <div className={clsx(styles.providersContainer, styles.select, styles.features, open && styles.opened, 'theme_providers')}>
                        {features.length > 0 &&
                            <>
                                {/*<h6 className={styles.search_bar__title}>Furnizori</h6>*/}
                                <div className={clsx(styles.grid, styles.checkBoxesGrid)}>
                                    {features.map((feat: any, i:number) => {

                                        return (
                                            <div
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    dispatch(featuresFilterSlugPage(selectFilters(feat, activeFeatures)));
                                                }}
                                                key={`provider-${i}`}
                                                className={clsx(styles.checkItem)}
                                            >
                                                <Checkbox
                                                    checked={activeFeatures.includes(feat)}
                                                    checkedIcon={<CheckBoxIcon style={{fill: "#006E04"}} />}
                                                />

                                                <span  className={clsx(styles.checkItem__label, 'theme_textColor_secondary')}>
                                                    {feat}
                                                </span>
                                            </div>
                                        )
                                    })}
                                </div>
                            </>
                        }
                    </div>
                )}
                {/*FEATURES GRID END*/}


                {/*THEMES GRID START*/}
                {(open && themes) && (
                    <div className={clsx(styles.providersContainer, styles.select, styles.themes, open && styles.opened, 'theme_providers')}>
                        {themes.length > 0 &&
                            <>
                                {/*<h6 className={styles.search_bar__title}>Furnizori</h6>*/}
                                <div className={clsx(styles.grid, styles.checkBoxesGrid)}>
                                    {themes.map((theme: any, i:number) => {


                                        return (
                                            <div
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    dispatch(themeFilterSlugPage(selectFilters(theme, activeThemes)))
                                                }}
                                                key={`provider-${i}`}
                                                className={clsx(styles.checkItem,)}
                                            >
                                              <Checkbox
                                                    checked={activeThemes.includes(theme)}
                                                    checkedIcon={<CheckBoxIcon style={{fill: "#006E04"}} />}

                                                />
                                                <span className={clsx(styles.checkItem__label, 'theme_textColor_secondary')}>
                                                    {theme}
                                                </span>
                                            </div>
                                        )
                                    })}
                                </div>
                            </>
                        }
                    </div>
                )}
                {/*THEMES GRID END*/}


                {/*VOLATILITY GRID START*/}
                {(open && volatility) && (
                    <div className={clsx(styles.providersContainer, styles.select, styles.themes, open && styles.opened, 'theme_providers')}>
                        {volatility.length > 0 &&
                            <>
                                {/*<h6 className={styles.search_bar__title}>Furnizori</h6>*/}
                                <div className={clsx(styles.grid, styles.checkBoxesGrid)}>
                                    {volatility.map((vol: any, i:number) => {
                                        //
                                        // console.log(provider)
                                        return (
                                            <div
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    dispatch(volatilityFilterSlugPage(selectFilters(vol.value, activeVolatility)))
                                                }}
                                                key={`provider-${i}`}
                                                className={clsx(styles.checkItem)}
                                            >
                                                <Checkbox
                                                    checked={activeVolatility.includes(vol.value)}
                                                    checkedIcon={<CheckBoxIcon style={{fill: "#006E04"}} />}
                                                    onClick={() => {
                                                        dispatch(volatilityFilterSlugPage(selectFilters(vol.value, activeVolatility)))
                                                    }}
                                                />

                                                <span className={clsx(styles.checkItem__label, 'theme_textColor_secondary')}>
                                                    {vol.text}
                                                </span>
                                            </div>
                                        )
                                    })}
                                </div>
                            </>
                        }
                    </div>
                )}
                {/*VOLATILITY GRID END*/}

                {(options.length > 1 && !hideElement) &&
                    <div className={clsx(styles.root__options, isCategPage && styles.categ_page, 'theme_providers')}>
                        {options.map((item:Options, i:number) => {
                            return (
                                <div
                                    key={`option-item-${i}-${item.key}`}
                                    onClick={() => handleChange(item.key)}
                                    className={clsx(styles.root__options__item, 'theme_providers__itemOutlined', selectedKey === item.key &&  'theme_providers__selected')}
                                >
                                    <span className={'theme_textColor_secondary'}>{item.label}</span>
                                </div>
                            );
                        })}
                    </div>
                }
            </div>
        </ClickAwayListener>
    );
}

export default OutlinedSelector