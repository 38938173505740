import Image from 'next/image';
import styles from 'themes/spins/styles/pageProgress.module.scss';
import {FC, useEffect, useRef, useState} from 'react';
import CMS from "utils/cms";
import {useSelector} from 'react-redux';
import static_values from "@/AbsoluteComponents/utils/static_values";

interface Props {
    types:Array<any>;
    api_id:string;
    force?:boolean;
}

const PageProgress:FC<Props> = ({types,api_id, force}) => {
    const categoryGamesCarousels:any = useSelector((state:any) => state.common.categoryGamesCarousels);
    const imgRef = useRef<any>(null)
    const availableCarouselsCount = () => {
        let count = 0;

        types.map((type:any) => {
            if (type['type'] === CMS.BLOCK_CATEGORY_GAMES_SLIDER) count++;
        });

        return count
    };

    const loadedCarouselsCount = () => {
        let count = 0;

        if (typeof categoryGamesCarousels[api_id] === "object") {
            count = Object.keys(categoryGamesCarousels[api_id]).length;
        }

        return count
    };
    
    useEffect(() => {

        if(imgRef.current) {
            const loadImage = () => {
                return new Promise((resolve, reject) => {
                    let src = imgRef.current.src
                    
                    const img = document.createElement('img');
                    img.src = src;

                    img.onload = () => {
                        (imgRef.current as HTMLElement)?.classList?.add(styles.loaded)
                        resolve(true);
                    }

                    img.onerror = () => {
                        resolve(false);
                    }
                    
                })
            }
            
            loadImage()
        }
        
    }, []);
    

    if ((availableCarouselsCount() > loadedCarouselsCount()) || force) {
        return (
            <div className={styles.root}>
                <Image
                    ref={imgRef}
                    className={'first-logo'}
                    src={"/spins/images/Logo.webp"}
                    alt={'logo'}
                    width={200}
                    height={67}
                />
            </div>
        );
    }

    return null;
}

export default PageProgress;