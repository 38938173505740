import styled from "styled-components";
import Image from 'next/image';

export const WheelContainer = styled.div `
  margin: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  position: relative;
  padding: 0!important;
  
`;

export const Header = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    gap: 10px;
    padding: 12px;
    
`

export const CloseBtn = styled.button`
    width: 32px;
    height: 32px;
    font-size: 14px;
    border-radius: 40px;
    background: none;
    border: 2px solid #9190ae;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        color: #9190ae;
        width: 14px;
        height: 14px;
        
    }

    &:hover {
        background: rgba(145, 144, 174, 0.2);
    }

`

export const ToggleSound = styled.button`
    width: 32px;
    height: 32px;
    font-size: 14px;
    border-radius: 40px;
    background: none;
    border: none;
    padding: 0;

    svg {
        color: #9190ae;
        width: 20px;
        height: 20px;
    }

    &:hover {
        background: rgba(145, 144, 174, 0.2);
    }

`

export const BigWin = styled.div`
    text-align: center;
    font-family: Poppins;
    font-size: 48px;
    font-style: normal;
    font-weight: 800;
    line-height: 110%; /* 52.8px */
    text-transform: uppercase;
    background: linear-gradient(180deg, #FFFFF0 0%, #FEF47F 10.93%, #FEE24F 53.12%, #FEA237 104.07%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    position: relative;
    white-space: nowrap;
    
`

export const BigWinShadow = styled.div`
    text-align: center;
    font-family: Poppins;
    font-size: 48px;
    font-style: normal;
    font-weight: 800;
    line-height: 110%; /* 52.8px */
    text-transform: uppercase;
    opacity: 0.5;
    background: linear-gradient(180deg, #FEFB90 0%, #FEE24F 53.12%, #FEA237 104.07%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    position: absolute;
    top: 5px;
    left: 0;
    white-space: nowrap;
`

export const Absolute = styled.div`
    margin: 56px auto 0;

    @media (max-height: 920px) {
        margin: 30px auto 0;
    }

    @media (max-width: 600px) {
        margin: 15px auto 0;
        height: 300px;
    }
`


export const WheelConstruction = styled.div`
    position: relative;
    width: 335px;
    height: 335px;

    @media (max-width: 600px) {
        transform: scale(0.85);
    }

    @media (max-width: 400px) {
        transform: scale(0.75);

    }
    
    @media (max-width: 370px) {
        transform: translateX(-1vw) scale(0.75);
        
    }
    
    @media (max-width: 364px) {
        transform: translateX(-2.3vw) scale(0.75);
    }

    &.hidden {
        opacity: 0;
    }
`

export const Projector1 = styled(Image)`
    position: absolute;
    top: -200px;
    left: -200px;
    min-width: 500px;
    height: auto;
    object-fit: cover;
    pointer-events: none;

    animation: projectorAnim1 2s linear infinite ;
    -webkit-animation: projectorAnim1 2s linear infinite ;

    img:last-child {
        margin-top: -8px;
    }

    @keyframes projectorAnim1 {
        0% {
            transform:scale(1);
            opacity: 0.7;
        }

        50% {
            transform:scale(1.04);
            opacity: 1;
        }

        100% {
            transform:scale(1);
            opacity: 0.7;
        }
    }
`

export const Projector2 = styled(Image)`
    position: absolute;
    top: 0;
    right: -200px;
    min-width: 500px;
    height: auto;
    object-fit: cover;
    pointer-events: none;

    animation: projectorAnim2 2s linear infinite ;
    -webkit-animation: projectorAnim2 2s linear infinite ;
    animation-delay: 0.5s;

    img:last-child {
        margin-top: -8px;
    }

    @keyframes projectorAnim2 {
        0% {
            transform:scale(1);
            opacity: 0.7;
        }

        50% {
            transform:scale(1.04);
            opacity: 1;
        }

        100% {
            transform:scale(1);
            opacity: 0.7;
        }
    }
`

export const Projector3 = styled(Image)`
    position: absolute;
    bottom: 0;
    left: 0;
    min-width: 100%;
    height: auto;
    object-fit: cover;
    pointer-events: none;
    border-radius: 0 0 20px 20px;
    clip-path: inset(1px);
`

export const Stars = styled(Image)`
    position: absolute;
    bottom: 0;
    left: 0;
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
    pointer-events: none;
    animation: starsAnim 10s linear infinite;
    -webkit-animation: starsAnim 10s linear infinite;
    
    img:last-child {
        margin-top: -8px;
    }

    @keyframes starsAnim {
        0% {
            transform:scale(1);
        }

        50% {
            transform:scale(1.08);
        }

        100% {
            transform:scale(1);
        }
    }

    @media (max-width: 600px) {
        position: absolute;
        bottom: 0;
        left: 0;
        max-width: 100%;
        height: auto;
        object-fit: contain;
        pointer-events: none;
        animation: starsAnim 10s linear infinite;
        -webkit-animation: starsAnim 10s linear infinite;

        @keyframes starsAnim {
            0% {
                transform:scale(1.2);
            }

            50% {
                transform:scale(1.3);
            }

            100% {
                transform:scale(1.2);
            }
        }
    }
    
`

export const IndicatorImage = styled(Image)`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -34%;
`

export const WheelBg = styled(Image)`
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
`

export const LoadingContainer = styled.div`
    position: absolute;
    bottom: 0px;
    left: 0;
    height: 85%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: inherit;
    
`

export const Overlayed = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
`


export const Front = styled.div`
    //position: unset;
    width: 238px;
    margin: 31px auto 0;
    pointer-events: none;
    opacity: 0;
    transform: translateY(-20px);
    transition: 0.3s ease-out;
    -webkit-transition: 0.3s ease-out;
    position: relative;
    
    &.appear {
        opacity: 1;
        transform: translateY(0);
    }

    @media (max-width: 600px) {
        transform: translateY(0) scale(0.8) !important;
        margin: 10px auto 0;
    }
`;


// CENTER BTN START SPIN START
export const SpinButton = styled.div`
    width: 232px;
    height: 232px;
    background: none;
    //border: none;
    position: relative;
    transform: scale(1);
    transition: 0.2s all;
`

export const ButtonSpinningElem = styled(Image)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &.appear {
        animation: spinButtonLoad 1.5s forwards ease-in-out;
        -webkit-animation: spinButtonLoad 1.5s forwards ease-in-out;
        transform-origin: center;

        @keyframes spinButtonLoad {
            0% {
                transform: translate(-50%, -50%) rotate(0);
                animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            }
            50% {
                transform: translate(-50%, -50%) rotate(450deg);
                animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
            }
            100% {
                transform: translate(-50%, -50%) rotate(900deg);
                animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            }
        }
    }
   
`
export const Shadow = styled(Image)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -40%);
    object-fit: contain;
`

export const TapTo = styled(Image)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50px, -50%);
    object-fit: contain;

    &.appear {
        animation: tapToAnim 1s forwards;
        -webkit-animation: tapToAnim 1s forwards;
        
        @keyframes tapToAnim {
            0% {
                animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
                transform: translate(-50px, -50%);
            }
            50% {
                transform: translate(-65px, -50%);
                animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
            }
            100% {
                transform: translate(-75px, -50%);
                animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            }
        }
    }
    
`

export const ButtonSpinArrow = styled(Image)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(17px, -45%);
    object-fit: contain;
`

export const ButtonGlow = styled(Image)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-58%, -90%);
`



export const SpinText = styled(Image)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -45%) scale(1) rotate(0);
    object-fit: contain;

    &.appear {
        animation: SpinTextAnimation 1s forwards;
        -webkit-animation: SpinTextAnimation 1s forwards;

        @keyframes SpinTextAnimation {
            0% {
                animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
                transform: translate(-50%, -45%) scale(0.5) rotate(0);
            }
            50% {
                transform: translate(-50%, -45%) scale(0.8) rotate(20deg);
                animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
            }
            100% {
                transform: translate(-50%, -45%) scale(1) rotate(0);
                animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            }
        }
    }
    
`

export const SpinBulb = styled(Image)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -39%) scale(1);
    transition: 2s all;
    -webkit-transition: 2s all;
    pointer-events: none;
    
    &.appear {
        animation: bulbAnimation 0.5s forwards;
        -webkit-animation: bulbAnimation 0.5s forwards;
        transform-origin: center;

        @keyframes bulbAnimation {
            0% {
                transform: translate(-50%, -45%) scale(1);
                opacity: 1;
            }
            90% {
                opacity: 1;
            }
            100% {
                opacity: 0;
                transform: translate(-50%, -45%) scale(0.3);
            }
        }
    }
`


export const SpinBtnCenter = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: none;
    width: 72px;
    height: 72px;
    color: #fff;
    border: none;
    
    &.no_cursor {
        cursor: none;
    }
    
    &.disabled {
        cursor: default !important;
    }
`

// CENTER BTN START SPIN END






