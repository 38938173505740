function Four() {
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="105" height="117" viewBox="0 0 105 117" fill="none">
            <g filter="url(#filter0_d_1577_191215)">
                <path d="M34.4688 72.5595V72.4875L64.9248 34.6875H64.9968V64.6395H70.6847V72.5595H64.9968V82.9995H54.4848V72.5595H34.4688ZM54.9888 57.6555L49.4448 65.0715L48.4368 64.6395H54.4848V57.1515L54.9888 57.6555Z" fill="#FBB90E"/>
            </g>
            <g filter="url(#filter1_d_1577_191215)">
                <path fillRule="evenodd" clipRule="evenodd" d="M32.4688 74.5595V71.782L63.9678 32.6875H66.9967V62.6395H72.6847V74.5595H66.9967V84.9995H52.4848V74.5595H32.4688ZM54.4848 72.5595V82.9995H64.9967V72.5595H70.6847V64.6395H64.9967V34.6875H64.9248L34.4688 72.4875V72.5595H54.4848ZM54.4848 58.3297L49.7677 64.6395H54.4848V58.3297Z" fill="white"/>
            </g>
            <defs>
                <filter id="filter0_d_1577_191215" x="2.46875" y="2.6875" width="100.217" height="112.312" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset/>
                    <feGaussianBlur stdDeviation="16"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0.984314 0 0 0 0 0.72549 0 0 0 0 0.054902 0 0 0 0.5 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1577_191215"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1577_191215" result="shape"/>
                </filter>
                <filter id="filter1_d_1577_191215" x="0.46875" y="0.6875" width="104.217" height="116.312" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset/>
                    <feGaussianBlur stdDeviation="16"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0.984314 0 0 0 0 0.72549 0 0 0 0 0.054902 0 0 0 0.5 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1577_191215"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1577_191215" result="shape"/>
                </filter>
            </defs>
        </svg>
    )
}
export default Four