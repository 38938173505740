import Head from 'next/head';
import clsx from 'clsx';
import strings from 'utils/strings';
import Image from 'next/image';
import styles from "../styles/Page404.module.scss"
import ArrowToLeft from "@/AbsoluteComponents/themes/spins/svg/ArrowToLeft";
import {useRouter} from 'next/router';
import {useEffect} from "react";


const Page404View = () => {
    const router = useRouter();
    
    useEffect(() => {
        document.body.classList.add('page404')
        
        return () => {
            document.body.classList.remove('page404')
        }
        
    }, []);
    
    return (
        <div className={styles.page}>

            <Head>
                <title>Spins - 404 Not Found</title>
            </Head>
            
            <div className={styles.container}>

                <Image  className={styles.container__image} src={'/spins/images/404.webp'} alt={'img'} width={1800} height={682}/>

                <h1 className={clsx('theme_white_dark_text')}>
                    {strings['404pagetext']}
                </h1>

                <button aria-label={"back"} onClick={() => {router.back()}} className={clsx("errorBlock__title", "btn__bg__theme")}>
                    <ArrowToLeft />
                </button>

            </div>
        </div>
    )
}

export default Page404View;