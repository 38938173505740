import country_codes from '@/AbsoluteComponents/public/json/country_codes.json';

function useCommon() {
    
    const getCountryCodes = () => {
        if(Array.isArray(country_codes) && country_codes.length){
            return country_codes
        }
        
        return []
    }
    
    const getCountryCode = (country:string) => {
        return getCountryCodes().find((item => item.code === country))
    }
    
    const hideZendesk = (hide:boolean) => {
        
        if(hide && document.body) {
            document.body.classList.add("hideZendesk")
        } else {
            document.body.classList.remove("hideZendesk")
        }
    }
    
    const getPercentage = (from:number, to:number, val:number) => {
        
        let questPercentage:number = 0;
        
        let currentLvTotalPoints:number = to - from;
        
        let currentLvUserPoints:number = val - from;
        let percent:number = currentLvTotalPoints / 100;
        
        questPercentage = parseFloat((currentLvUserPoints/percent).toFixed(2))
        if(to > val && questPercentage === 100) {
            questPercentage = 99.99
        }
        
        return questPercentage
    
    }
    
    const observe = (ref: any, treshold?:number) => {
        // if (ref === null) return;
        //
        // const children = ref.children;
        // const children_arr = Array.from(children);
        //
        // const getTotalHeight = () => {
        //     const rect = ref.getBoundingClientRect();
        //     const totalHeight = rect.height;
        //     return totalHeight;
        // };
        //
        // const totalHeight = getTotalHeight();
        //
        // if (typeof window !== "undefined") {
        //     const isSamsungBrowser = navigator.userAgent.match(/SamsungBrowser/i);
        //
        //     const observerOptions = {
        //         rootMargin:treshold ? treshold+"px" : '250px',
        //     };
        //
        //     if ('IntersectionObserver' in window && !isSamsungBrowser) {
        //         const IO = new IntersectionObserver((entries, observer) => {
        //             entries.forEach(entry => {
        //                 if (entry.isIntersecting) {
        //                     (entry.target as HTMLElement).style.height = "auto";
        //                     children_arr.forEach((item:any) => {
        //                         entry.target.appendChild(item);
        //                     });
        //                 } else {
        //                     setTimeout(() => {
        //                         (entry.target as HTMLElement).style.height = totalHeight + "px";
        //                         entry.target.innerHTML = "";
        //                     }, 100);
        //                 }
        //             });
        //         }, observerOptions);
        //
        //         IO.observe(ref);
        //     } else {
        //         children_arr.forEach(item => {
        //             (item as HTMLElement).style.display = "block"; // Adjust this based on your styling needs
        //         });
        //     }
        // }
    };
    
    const endTimeFactory = (end_time_stamp:Array<any>):any => {
        let obj:any = {}
        end_time_stamp?.length && end_time_stamp.map((ts:any) => {
            obj[ts.unit_name] = ts.value
        })
        
        if(typeof obj.hour === "undefined") {
            obj.hour = "00"
        }
        const { year, month, day, hour } = obj;
        const formattedDate = new Date(`${year}-${month}-${day}T${hour}:00:00Z`);
        return formattedDate
    }
    
    
    return {
        hideZendesk,
        observe,
        getPercentage,
        endTimeFactory,
        getCountryCode,
        getCountryCodes
    }

}

export default useCommon
