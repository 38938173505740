import styles from 'themes/spins/styles/depositSelectDropDown.module.scss';
import {FC, useState, useEffect} from 'react';
import clsx from 'clsx';
import SelectorPortal from "./Portal";
import ArrowRight from 'themes/spins/svg/arrow-right'


interface Options {
    key:string,
    label:string,
    icon?:any,
    subLabel?:any,
}

interface Props {
    label?:string,
    name?:string,
    labelIcon?:any,
    defaultValue?:string,
    options:Array<Options>,
    onOpen?(): void,
    onClose?(): void,
    onChange?(key:string): void,
    formatLabel?(options:Options): string,
    disableClickAwayListener?: boolean,
    inputProps?: any,
    className?: string,
    id?: string,
    noShadow?: boolean
    bgStyle?:boolean,
    bonusItem?: boolean,
    position?:boolean

}

const SelectDropDown: FC<Props> = ({
        label,
        labelIcon,
        options,
        name,
        onOpen,
        onClose,
        disableClickAwayListener,
        onChange,
        defaultValue,
        inputProps,
        className,
        formatLabel,
        id,
        noShadow,
        bgStyle,
        bonusItem,
        position
}) => {

    const [anchor, setAnchor] = useState<Element | null>(null);
    const [selectedKey, selectKey] = useState(options[0]?.key || '');

    const handleChange = (key:string) => {
        if (typeof onChange === "function") onChange(key);
        selectKey(key);
        setAnchor(null);
    };
    const option:Options | undefined = options.find((item:Options) => item.key === selectedKey);

    const detectPosition = () => {
        let position:any = {
            width: anchor?.getBoundingClientRect()?.width || 0,
            top: anchor?.getBoundingClientRect()?.top || 0,
            left: anchor?.getBoundingClientRect()?.left || 0,
            bottom: 'auto',
        };

        if (anchor) {
            const height = window.innerHeight;
            const top = anchor.getBoundingClientRect().top;

            if ( (height - top) < 300 ) {
                position.top = 'auto';
                position.bottom = (height - top) - anchor.clientHeight;
            }
        }

        return position;
    }

    useEffect(() => {
        if (defaultValue) {
            handleChange(defaultValue);
        }

        if (options.length === 1) {
            handleChange(options[0].key);
        }
    }, []);

    useEffect(() => {
        if (Boolean(anchor)) {
            if (typeof onOpen === "function") onOpen();
        } else {
            if (typeof onClose === "function") onClose();
        }
    }, [anchor]);
    
    return (
        <>
            <div
                id={id}
                className={clsx(
                    styles.root,
                    'theme_box_bg_shaded2',
                    bgStyle && 'bgStyleDropdownBonus',
                    bgStyle && styles.depositBgStyleDropdownBonus,
                    Boolean(anchor) && styles.active,
                    option?.icon && styles.hasIcon,
                    className,
                    noShadow && styles.noShadow
                )}
                onClick={(e:any) => {
                    if (options.length > 1) setAnchor(e.currentTarget);
                }}
                style={{
                    paddingRight: options.length < 2 ? 8 : undefined,

                }}
            >
                <input
                    type="text"
                    style={{opacity: 0, position: 'absolute', pointerEvents: 'none'}}
                    name={name}
                    value={selectedKey}
                    onChange={() => {}} {...inputProps}
                />

                <div className={styles.root__inner} >

                    {option?.icon && <span className={clsx(styles.root__inner__icon, option.subLabel && 'iconLabel')}>{option?.icon}</span>}

                    <div style={{maxWidth: '95%'}} className={styles.wrapperBlock}>
                        {label !== undefined && <div className={clsx(styles.root__label,'theme_label_text' , option?.subLabel && 'labelDepositSublabel')}>{label}</div>}
                        <div  className={clsx(styles.root__labelWrapper, option?.subLabel && 'labelWrapper_subLabel')}>
                            <div className={clsx(clsx(styles.root__selected, 'theme_textColor_secondary'))}>
                                {(formatLabel && option) ? formatLabel(option) : (option?.label || '')}
                            </div>

                            {options.length > 1 && <span className={styles.root__caret}>
                                <ArrowRight />
                            </span>}
                        </div>
                    </div>
                </div>
            </div>


            <SelectorPortal
                open={Boolean(anchor)}
                onClose={() => setAnchor(null)}
                name={name}
            >

                {options.length > 1 &&
                    <div
                        className={clsx(
                            styles.root__options,
                            'theme_front_elements_bg',
                            'theme_front_element_border',
                            name == "deposit_method" && styles.bottom_options
                        )}
                        style={detectPosition()}
                    >
                        {options.map((item:Options, i:number) => {
                            
                            
                            return (
                                <div key={`option-item-${i}-${item.key}`}
                                    className={clsx(styles.root__options__item__wrapper, item.subLabel && styles.wrapperSubtitle)}
                                >
                                    <div  onClick={() => handleChange(item.key)}
                                        className={clsx(styles.root__options__item,  bonusItem && 'bonusItem' ,item.subLabel && 'subLabel',
                                            selectedKey === item.key &&  'themeItemBg_selector',
                                        )}
                                    >
                                        <div className={clsx(styles.item_wrapper, item.subLabel && styles.item_wrapper_sublabel)}>

                                            {item.icon &&
                                                <span className={styles.root__options__item__icon}>
                                                    {item.icon}
                                                </span>
                                            }

                                            <span>{item.label}</span>
                                        </div>
                                        
                                        {item.subLabel &&
                                            <div className={'visa_type_group'}>
                                                {item.subLabel}
                                            </div>
                                        }
                                        
                                    </div>
                                    
                                </div>
                            );
                        })}
                    </div>
                }

            </SelectorPortal>

        </>
    );
}

export default SelectDropDown