import { FC } from 'react';
import Head from 'next/head';
import CMS from "utils/cms";
import { NextSeo } from 'next-seo';
import {useRouter} from "next/router";


interface Props {
    json: object;
    api_id: string
}
interface opengraphImg {
    url:string
    width:number
    height:number
    alt:string
    
}
const Seo:FC<Props> = ({json, api_id}) => {
    const data = CMS.getAllFields(json);
    const {
        title,
        description,
        keywords,
        opengraph_title,
        opengraph_description,
        opengraph_images,
    } = data
    
    const router = useRouter()
    
    let additionalMetaTags = [];
    
    if (data.keywords) {
        additionalMetaTags.push({
            name: 'keywords',
            content: keywords || "",
        });
    }
    
    const gen_settings = CMS.getAllFields(CMS.getGenSettingsJSON())
    const {general_og_image} = gen_settings
    
    const imgs:Array<opengraphImg> = Boolean(Array.isArray(opengraph_images) && opengraph_images.length) ?
        opengraph_images.map((x:any) => {
            let res:opengraphImg = {
                url: CMS.getFileFromCMS(x.image[0].path),
                alt: title,
                height: 600,
                width: 800
            }

            return res
        }) : []

    // return (
    //     <Head>
    //
    //         {title.length > 0 && (
    //             <>
    //                 <title>{title}</title>
    //                 <meta name="title" content={title} />
    //                 <meta property="og:title" content={title} />
    //             </>
    //         )}
    //
    //         {description.length > 0 && (
    //             <>
    //                 <meta name="description" content={description} />
    //                 <meta property="og:description" content={description} />
    //             </>
    //         )}
    //         {keywords.length > 0 && <meta name="keywords" content={keywords} />}
    //
    //     </Head>
    // )

    return (
        <NextSeo
            title={title || ''}
            description={description || ''}
            additionalMetaTags={additionalMetaTags}
            canonical={process.env.NEXT_PUBLIC_WS_REFERRER + router.asPath}
            openGraph={{
                type: "website",
                url: process.env.NEXT_PUBLIC_WS_REFERRER + router.asPath,
                title: opengraph_title || title || '',
                description: opengraph_description || description || '',
                ...(imgs.length > 0 ?
                    { images: imgs } : {
                        images: [{
                            url: CMS.getFileFromCMS(general_og_image[0]?.path) || "",
                            alt: title,
                            height: 1200,
                            width: 630
                        }]
                    }
                ),
            }}

        />
    )
}

export default Seo