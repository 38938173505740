import { setCookie, getCookie, deleteCookie } from 'cookies-next';
import type { NextApiRequest, NextApiResponse } from 'next';
import VerificationStatus from "../interfaces/VerificationStatus";

export const COOKIE_AUTH_TOKEN:string = 'user_temp_token';
export const COOKIE_PLAYER_ID:string = 'playerId';
export const COOKIE_PROFILE_LOADED:string = 'profile_loaded';
export const COOKIE_REALITY_CHECK_END_TINE:string = 'reality_check_end_time';
export const POST_LOGIN_REDIRECT:string = 'post_login_redirect';
export const VERIFICATION_STATUS:string = 'verification_status';
export const COOKIE_AFFILIATE_ID:string = 'affiliate_id';
export const COOKIE_AFFILIATE_TOKEN:string = 'affiliate_token';
export const COOKIE_SUB_ID:string = 'subid';
export const COOKIE_OPTIMIZE_TOKEN:string = 'optimize_token';
export const COOKIE_REFRESH_TOKEN:string = 'refresh_token';
export const COOKIE_EXPIRES_IN:string = 'session_expires_in';
export const COOKIE_LOGGED_IN_TIME:string = 'logged_in_time';



export function setAuthToken(token: string, req?:NextApiRequest, res?:NextApiResponse): void {
    setCookie(COOKIE_AUTH_TOKEN, token, {
        path: '/',
        req,
        res,
        sameSite: 'none',
        secure: true,
    });
}

export function setPlayerId(id: string, req?:NextApiRequest, res?:NextApiResponse): void {
    setCookie(COOKIE_PLAYER_ID, id, {
        path: '/',
        req,
        res,
        sameSite: 'none',
        secure: true,
    });
}

export function setRefreshToken(token: string, req?:NextApiRequest, res?:NextApiResponse): void {
    setCookie(COOKIE_REFRESH_TOKEN, token, {path: '/', req, res});
}

export function setExpiresIn(expires: number, req?:NextApiRequest, res?:NextApiResponse): void {
    setCookie(COOKIE_EXPIRES_IN, expires, {path: '/', req, res});
}

export function getAuthToken(req?:NextApiRequest, res?:NextApiResponse):string {
    const token = getCookie(COOKIE_AUTH_TOKEN, {path: '/', req, res});
    return typeof token === "string" ? token.toString() : '';
}

export function getPlayerId(req?:NextApiRequest, res?:NextApiResponse):string {
    const id = getCookie(COOKIE_PLAYER_ID, {path: '/', req, res});
    return typeof id === "string" ? id.toString() : '';
}

export function getRefeshToken(req?:NextApiRequest, res?:NextApiResponse):string {
    const token = getCookie(COOKIE_REFRESH_TOKEN, {path: '/', req, res});
    return typeof token === "string" ? token.toString() : '';
}

export function getExpiresIn(req?:NextApiRequest, res?:NextApiResponse):string {
    const token = getCookie(COOKIE_EXPIRES_IN, {path: '/', req, res});
    return typeof token === "string" ? token.toString() : '';
}

export function getSessionToken(req?:NextApiRequest, res?:NextApiResponse):string {
    const token = getCookie("session_token", {path: '/', req, res});
    // console.log('getAuthToken', token);
    return typeof token === "string" ? token.toString() : '';
}

export function deleteAuthToken(): void {
    deleteCookie(COOKIE_AUTH_TOKEN);
}

export function deletePlayerId(): void {
    deleteCookie(COOKIE_PLAYER_ID);
}

export function deleteRefteshToken(): void {
    deleteCookie(COOKIE_REFRESH_TOKEN);
}

export function deleteExpiresIn(): void {
    deleteCookie(COOKIE_EXPIRES_IN);
}

export function setProfileLoaded(): void {
    setCookie(COOKIE_PROFILE_LOADED, '1', {path: '/',});
}

export function getProfileLoaded(req?:NextApiRequest, res?:NextApiResponse):string {
    const token = getCookie(COOKIE_PROFILE_LOADED, {path: '/', req, res});
    // console.log('getAuthToken', token);
    return typeof token === "string" ? token.toString() : '';
}

export function deleteProfileLoaded(): void {
    deleteCookie(COOKIE_PROFILE_LOADED);
}


export function setRealityCheckEndTime(endTime:number): void {
    setCookie(COOKIE_REALITY_CHECK_END_TINE, endTime, {path: '/'});
}

export function setLoggedInTime(time:number): void {
    setCookie(COOKIE_LOGGED_IN_TIME, time, {path: '/'});
}

export function getRealityCheckEndTime():number {
    const endTime = getCookie(COOKIE_REALITY_CHECK_END_TINE, {path: '/'});

    return typeof endTime ? Number(endTime) : 0;
}
export function getLoggedInTime():number {
    const endTime = getCookie(COOKIE_LOGGED_IN_TIME, {path: '/'});
    
    return typeof endTime ? Number(endTime) : 0;
}

export function deleteRealityCheckEndTime(): void {
    deleteCookie(COOKIE_REALITY_CHECK_END_TINE);
}

export function deleteLoggedInTime(): void {
    deleteCookie(COOKIE_LOGGED_IN_TIME);
}

export function saveRedirectTarget(url:string):void {
    localStorage.setItem(POST_LOGIN_REDIRECT, url)
}

export function getRedirectTarget():string {
    return localStorage.getItem(POST_LOGIN_REDIRECT) || '';
}

export function deleteRedirectTarget():void {
    localStorage.removeItem(POST_LOGIN_REDIRECT);
}

export function saveVerificationStatus(list:Array<VerificationStatus>):void {
    localStorage.setItem(VERIFICATION_STATUS, JSON.stringify(list));
}

export function getVerificationStatus():Array<VerificationStatus> {
    const json:string | null = localStorage.getItem(VERIFICATION_STATUS);

    try {
        return json ? JSON.parse(json) : [];
    } catch (e) {
        return []
    }
}

export function deleteVerificationStatus():void {
    localStorage.removeItem(VERIFICATION_STATUS);
}

export function getAffiliateId():string {
    const value = getCookie(COOKIE_AFFILIATE_ID, {path: '/'});

    return typeof value === "string" ? value : '';
}

export function getAffiliateToken():string {
    const value = getCookie(COOKIE_AFFILIATE_TOKEN, {path: '/'});

    return typeof value === "string" ? value : '';
}

export function getSubId():string {
    const value = getCookie(COOKIE_SUB_ID, {path: '/'});

    return typeof value === "string" ? value : '';
}

export function getOptimizeToken():string {
    const value = getCookie(COOKIE_OPTIMIZE_TOKEN, {path: '/'});

    return typeof value === "string" ? value : '';
}