import Link from 'next/link';
import {useEffect, useRef} from 'react';
import clsx from 'clsx';
import {useRouter} from 'next/router';
import styles from "@/template/styles/Header.module.scss";
import {openInfoSidebar} from "@/AbsoluteComponents/store/common/actions";
import {useDispatch, useSelector} from 'react-redux';
// @ts-ignore
import menu from "public/json/info_pages_menu.json"
import useCommon from "@/AbsoluteComponents/hooks/common";
import CMS from 'utils/cms'

const TermsSideBarView = () => {
    // DECOMENT ALL PAGE!!!!!!

    const router = useRouter();
    const currentRoute = router.asPath;
    const dispatch = useDispatch();

    const show = useSelector((state: any) => state.common.openInfoSidebar);
    const menuData = CMS.getInfoMenuData()

    const inView = useSelector((state:any) => state.common.footerInView)
    const common_hook = useCommon()
    const prevInView = useRef(inView);

    const isInfoPage = (): boolean => {

        let currentPage = menuData.find((item:any) => {
            return item.link.value.text === router.asPath
        })

        return Boolean(currentPage)
    }

    let isPage = isInfoPage()


    useEffect(() => {
        const onResize = () => {

            if(isPage) {
                if(window.innerWidth > 1300) {
                    dispatch(openInfoSidebar(true))
                } else {
                    dispatch(openInfoSidebar(false))
                }
            }
        }

        onResize()

    }, [router.asPath])

    useEffect(() => {
        let w_width = window.innerWidth
        if(show && w_width < 440) {
            common_hook.hideZendesk(true)
        } else {
            common_hook.hideZendesk(false)
        }
    }, [show]);



    return (

        <>
            <div
                onClick={() => {
                    dispatch(openInfoSidebar(false))
                }}
                className={clsx(styles.infoSideBarOverlay, (isPage && show) && styles.active)}
            >
            </div>

            <div
                id={'termsSidebar'}
                className={clsx(
                    styles.termsSidebar,
                    'theme_front_elements_bg',
                    (isPage && show) &&
                    styles.active,
                    'theme_acc_page_sidebar',
                )}
            >

                <div
                    className={clsx(
                        'sidebarAccountMode',
                        'theme__sidebarAccountMode',
                        styles.linksBox
                    )}
                >

                    {menuData.map((item:any, i:number) => {

                        let link: string = item.link.value.text
                        let name: string = item.name.value.text

                        return (
                            <Link
                                aria-label={"open menu"}
                                onClick={() => {
                                    if(window.innerWidth <= 1300) {
                                        dispatch(openInfoSidebar(false))
                                    }
                                }}
                                key={'info_link_' + name}
                                href={link}
                                className={clsx((currentRoute === link) && 'clickedLink')}
                            >
                                <div className={clsx('sidebarAccountMode__text', 'theme_textColor_secondary')}>
                                    {name}
                                </div>
                            </Link>
                        )
                    })}

                </div>
            </div>

        </>

    )
}
export default TermsSideBarView