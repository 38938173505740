
import { createStore, applyMiddleware, combineReducers } from 'redux';
import modals from './modals/reducer';
import account from './account/reducer';
import cms from './cms/reducers';
import common from './common/reducer';
import {createWrapper} from 'next-redux-wrapper';
import thunkMiddleware from 'redux-thunk';

const bindMiddleware = (middleware:any) => {
    if (process.env.NODE_ENV !== 'production') {
        const { composeWithDevTools } = require('redux-devtools-extension')
        return composeWithDevTools(applyMiddleware(...middleware))
    }
    return applyMiddleware(...middleware)
}

const reducer = combineReducers({
    modals,
    account,
    cms,
    common
});


const store = createStore(reducer);

export default store;

// const initStore = () => {
//     return createStore(reducer, bindMiddleware([thunkMiddleware]))
// }
//
// export const wrapper =tStore = () => {
//     return createStore(reducer, bindMiddleware([thunkMiddleware]))
// }
//
// export const wrapper = createWrapper(initStore);