import {useEffect, FC} from 'react';
import useAuth from "../hooks/auth";
import jwt, {Secret} from "jsonwebtoken";
import UserInterface from "../interfaces/user";


const ZendeskControl:FC = () => {
    const auth = useAuth();

    const logged:boolean = auth.isLoggedIn();

    useEffect(() => {
        // @ts-ignore
        // if (window.zE) {
        //     // @ts-ignore
        //     const zEF = window.zE;
        //
        //     if (logged) {
        //         const user:UserInterface = auth.getUser();
        //
        //         const signingKeyId:Secret = process.env.NEXT_PUBLIC_ZENDESK_SIGNING_KEY_ID?.toString() || '';
        //
        //         // @ts-ignore
        //         const token:string = jwt.sign(
        //
        //             {
        //                 email: user.email,
        //                 name: `${user.firstName} ${user.lastName}`,
        //                 scope: 'user',
        //                 external_id: user.email,
        //             },
        //             signingKeyId,
        //             {
        //                 algorithm: 'HS256',
        //                 keyid: process.env.NEXT_PUBLIC_ZENDESK_KEY_ID,
        //             }
        //         );
        //
        //         zEF('messenger', 'loginUser', function (callback: (arg0: any) => void) {
        //             callback(token);
        //         });
        //
        //     } else {
        //
        //         zEF('messenger', 'logoutUser');
        //     }
        // }
    }, [logged]);

    return null;
}

export default ZendeskControl;