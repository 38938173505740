import React, {useEffect, useState} from 'react';
import clsx from 'clsx';
import {openWheelModal} from 'store/modals/actions';
import {useDispatch, useSelector} from 'react-redux'
import style from '../../../styles/wheel.module.scss';
import {useRouter} from 'next/router'
import Modal from 'react-modal';
import WheelSpinningElements from "./components/Wheel";
import {
    WheelContainer,
    Header,
    CloseBtn,
    WheelConstruction,
    Overlayed,
    LoadingContainer,
    Front,
    SpinButton,
    SpinBtnCenter,
    Absolute,
    IndicatorImage,
    WheelBg,
    Projector1,
    Projector2,
    Projector3,
    Stars,
    SpinBulb,
    ButtonSpinningElem,
    ButtonGlow, TapTo, ButtonSpinArrow, SpinText, ToggleSound, Shadow, BigWin, BigWinShadow
} from "../../styledWheel/StyledWheel";
import useAuth from "hooks/auth";
import PriseContainer from "./components/priseContainer";
import NoCash from "./components/noCash";
import HaveParticipated from './components/haveParticipated';
import SoundPlayer from "./components/soundPlayer";
import CircularProgress from '@mui/material/CircularProgress';
import Bottom from "./components/bottom/bottom";
import {updateProfileAttributes} from "services/account";
import {setEnableWheel} from "store/common/actions";
import useCommon from "@/AbsoluteComponents/hooks/common";
import CloseIcon from "@/template/svg/closeicon";
import Image from "next/image"
import SpeakerIcon from "@/AbsoluteComponents/themes/spins/svg/SpeakerIcon";
import CMS from "@/AbsoluteComponents/utils/cms";
import isMobile from "is-mobile";


const WheelModal = () => {
    const dispatch = useDispatch()
    const open = useSelector((state:any) => state.modals.openWheel);
    const auth = useAuth()
    const common_hook = useCommon()
    //States
    const [hoverCount, setHoverCount] = useState<boolean | null>(false)
    const [clickedCount, setClickedCount] = useState<number>(0)
    const [clickEnd, setClickEnd] = useState<boolean>(false)
    const [clickMouseDown, setClickMouseDown] = useState(false)
    const [scenario, setScenario] = useState<string>('')
    const [playSound, setPlaySound] = useState<string>('')
    const [isLoggingIn, setIsLoggingIn] = useState<boolean>(false)
    const [imagesLoaded, setImagesLoaded] = useState<boolean>(false)
    const [isSpinning, setIsSpinning] = useState<boolean>(false);
    const [isStartSpinning, setIsStartSpinning] = useState<boolean>(false);
    const [isEndSpinning, setIsEndSpinning] = useState<boolean>(false);
    const [spinEnded, setSpinEnded] = useState<boolean>(false)
    const [selectedWheel, setSelectedWheel] = useState<number>(0)
    const [rotateTo, setRotateTo] = useState<number>(0);
    const [audioLoaded, setaudioIsLoaded] = useState<boolean>(false);
    const startAnimationTime = 1000;
    const fixedDegrees = [0, 30, 60, 90, 120, 150, 180, 210, 240, 270, 300, 330, 360];
    const [initialPosition, setInitialPosition] = useState<number | null>(fixedDegrees[Math.ceil(Math.random() * (fixedDegrees.length - 1))])
    const [showPrisePopup, setShowPrisePopup ] = useState<boolean>(false)
    const [muted, setMuted] = useState<boolean>(false)
    const [prise, setPrise] = useState<any>({})
    const [enableSpin, setEnableSpin] = useState<boolean>(false)
    const [spinnedToday, setSpinnedToday] = useState<boolean >(false)
    const [prizeValue, setPrizeValue] = useState<string>('')
    const [dataLoaded, setDataLoaded] = useState<boolean>(false)
    const [loadAnim, setLoadAnim] = useState<boolean>(false)
    const [toggleLights,setToggleLights] = useState<boolean>(false)
    const [openCount, setOpenCount] = useState<number>(0)
    const [prizes, setPrizes] = useState<Array<any>>([])
    const [isDefault, setIsDefault] = useState<boolean | null>(null)
    const [currentSpinning, setCurrentSpinning] = useState<boolean>(false)
    const isMobile:boolean = useSelector((state:any) => state.common.isMobile);
    const wheelStatus:any = useSelector((state:any) => state.account.spinStatus);
    const depositNum:number = useSelector((state:any) => state.account.depositNum);
    
    const test = false
    
    let cms_prizes_raw = CMS.getWheelPrizes()
    let cms_prizes = CMS.getAllFields(cms_prizes_raw[0])
    let big_win = cms_prizes.big_win || ""
    let cms_prizes_list = cms_prizes.prizes.sort((a:any, b:any) => Number(a.order) - Number(b.order))
    const startSpinDeg:number = 30;
    const router = useRouter()
    const modal = router.query.modal
    
    const disabledListener = () => {
        let disabled = isStartSpinning || isSpinning || isEndSpinning || !enableSpin || spinnedToday || isDefault === null || isDefault || !auth.isLoggedIn()
        
        if(test) {
            // console.log("isStartSpinning", isStartSpinning)
            // console.log("isSpinning", isSpinning)
            // console.log("isEndSpinning", isEndSpinning)
            // console.log("enableSpin", enableSpin)
            // console.log("spinnedToday", spinnedToday)
            // console.log("isDefault", isDefault)
            // console.log(wheelStatus)
            // console.log("disabled", disabled)
        }
        
        return disabled
    }

    const audioLoadedHandle = () => {
        setaudioIsLoaded(true)
    }

    const closePrisePopup = () => {
        setShowPrisePopup(false)
    }

    const wheels = [
        {
          id: 0,
          name: 'wheel1',
            images: [
                {
                    name: 'mainImage',
                    url: '/default/images/Wheel/Castigurile.webp'
                },
                {
                    name: 'background',
                    url: '/spins/images/wheel/wheel-bg.webp'
                },
                {
                    name: 'indicator',
                    url: '/spins/images/wheel/indicator.svg'
                },
                {
                    name: 'projector1',
                    url: '/spins/images/wheel/projector1.webp'
                },
                {
                    name: 'projector2',
                    url: '/spins/images/wheel/projector2.webp'
                },
                {
                    name: 'projector3',
                    url: '/spins/images/wheel/projector3.webp'
                },
                
                {
                    name: 'stars',
                    url: '/spins/images/wheel/stars_bg.webp'
                },
                
                {
                    name: 'spinBulb',
                    url: '/spins/images/wheel/button/spinBulb.webp'
                },
                
                {
                    name: 'buttonSpinningElem',
                    url: '/spins/images/wheel/button/buttonSpinningElem.webp'
                },
                
                {
                    name: 'buttonGLow',
                    url: '/spins/images/wheel/button/buttonGLow.webp'
                },
                
                {
                    name: 'wheel_shadow',
                    url: '/spins/images/wheel/button/wheel_shadow.svg'
                },
                
                {
                    name: 'tapTo',
                    url: '/spins/images/wheel/button/tapTo.webp'
                },
                
                {
                    name: 'buttonSpinArrow',
                    url: '/spins/images/wheel/button/buttonSpinArrow.webp'
                },
                
                {
                    name: 'spinText',
                    url: '/spins/images/wheel/button/spinText.webp'
                },
                
                {
                    name: 'cursor',
                    url: '/spins/images/wheel/cursor.webp'
                },
                
                {
                    name: 'cursor_clicked',
                    url: '/spins/images/wheel/cursor_clicked.webp'
                },

                {
                    name: 'top_prise_text1',
                    url: '/spins/images/wheel/top_prise_text1.webp'
                },

                {
                    name: 'top_prise_text2',
                    url: '/spins/images/wheel/top_prise_text2.webp'
                },
                
                {
                    name: 'lock',
                    url: '/spins/images/wheel/lock.webp'
                },
                
            ],

            prises: prizes
        },
 
    ]

    const enableSpinning = (x: boolean) => {
        setEnableSpin(x)
    }
    

    const imageLoaded = (src:string) => {
        return new Promise((resolve, reject) => {
            const img = document.createElement('img');
            img.src = src;

            img.onload = () => {
                resolve(true);
            }

            img.onerror = () => {
                resolve(false);
            }
        })
    }

    const loadImages = async () => {
        let wheelImages = wheels[selectedWheel].images
        
        let allImages = [...wheelImages]
        let arr = []
        for (let i = 0; i <= allImages.length - 1; i++) {
            await imageLoaded(allImages[i].url)
            arr.push(allImages[i].url)

            if(arr.length === allImages.length) {
                setImagesLoaded(true)
            }
        }
    }
    
    let images: any[] = wheels[selectedWheel].images

    //Images
    let mainImage = images.find((img) => img.name === 'mainImage');
    let background = images.find((img) => img.name === 'background');
    let indicator = images.find((img) => img.name === 'indicator');
    let projector1 = images.find((img) => img.name === 'projector1');
    let projector2 = images.find((img) => img.name === 'projector2');
    let projector3 = images.find((img) => img.name === 'projector3');
    let stars = images.find((img) => img.name === 'stars');
    let spinBulb = images.find((img) => img.name === 'spinBulb');
    let buttonSpinningElem = images.find((img) => img.name === 'buttonSpinningElem');
    let buttonGLow = images.find((img) => img.name === 'buttonGLow');
    let wheel_shadow = images.find((img) => img.name === 'wheel_shadow');
    let tapTo = images.find((img) => img.name === 'tapTo');
    let buttonSpinArrow = images.find((img) => img.name === 'buttonSpinArrow');
    let spinText = images.find((img) => img.name === 'spinText');
    let cursor = images.find((img) => img.name === 'cursor');
    let cursor_clicked = images.find((img) => img.name === 'cursor_clicked');
    let top_prise_text1 = images.find((img) => img.name === 'top_prise_text1');
    let top_prise_text2 = images.find((img) => img.name === 'top_prise_text2');
    let lock = images.find((img) => img.name === 'lock');

    const setPlay = (type: 'spin' | 'tada'): void => {
        setPlaySound(type)
    }

    const spin = async() => {
        if(auth.isLoggedIn() && !auth.isUpdating()) {

            setIsStartSpinning(true);
            setPlay('spin')
            setCurrentSpinning(true)
            setTimeout(() => {
                setIsStartSpinning(false);
                setIsSpinning(true);
            }, startAnimationTime);
            
            setCustomCursor(false, true)
        }

    };

    const fetchData =  () => {

        let selectedPrises = cms_prizes_list
        let degreeRate = 30;
        let prize:any = selectedPrises.find((x:any) => {
            return x.api_value === prizeValue
        })
        
        let priseIndex = selectedPrises.indexOf(prize);
        
        setRotateTo((priseIndex * degreeRate * -1) - 90);
        setPrise(selectedPrises[priseIndex]);
        setTimeout(async() => {
            let attributes: any[]
            await updateProfileAttributes('Triggered')
                .then((res) => {
                    attributes = res.response.attributes

                    let triggered = attributes.find((attr: any) => {
                        return attr.attributeName === 'WOFTriggered'
                    })

                    if(triggered.value === 'Triggered') {
                        setSpinnedToday(true)
                        dispatch(setEnableWheel('disable'))
                    }

                    let prizes = attributes.find((attr: any) => {
                        return attr.attributeName === 'WOFPrizes'
                    })
                })
            setIsSpinning(false);
            setIsEndSpinning(true);
        }, 4000);
        
        setTimeout( () => {
            setIsSpinning(false)
            setIsStartSpinning(false)
            setShowPrisePopup(true)
            setInitialPosition(priseIndex * degreeRate)
            setSpinEnded(true)
            setCurrentSpinning(false)
            
            if(test) {
                setIsDefault(true)
                setSpinnedToday(true)
            }
            
        }, 8000);
    };
    
    const setCustomCursor = (modal_open:boolean, remove?:boolean) => {
        
        const wheel_spin_button = document.getElementById("wheel_spin_button");
        const wheel_cursor = document.getElementById("wheel_cursor") as HTMLImageElement
        const spin_btn_parent = document.getElementById("spin_btn_parent") as HTMLImageElement
        
        
        if(wheel_cursor && wheel_spin_button && !isMobile && !disabledListener() && spin_btn_parent) {
            
            const setCursorPosition = (e: any) => {
                
                const rect = wheel_spin_button.getBoundingClientRect();
                let x = e.clientX - rect.left;
                let y = e.clientY - rect.top;
                let enter = x >= 0 && x <= rect.width && y >= 0 && y <= rect.height;
                wheel_cursor.style.opacity = enter ? "1" : "0";
                
                wheel_spin_button.style.cursor = enter ? "none" : "default"
                wheel_cursor.style.top = y + "px";
                wheel_cursor.style.left = x + "px";
            }
            
            const setCursorClicked = (clicked:boolean) => {
                wheel_cursor.src = clicked ? cursor_clicked.url : cursor.url
                
                if(clicked) {
                    setClickMouseDown(true)
                } else {
                    setClickMouseDown(false)
                }
            }
            const clkUp = () => setCursorClicked(true);
            const clkDown = () => setCursorClicked(false);
            
            if (remove) {
                wheel_cursor.style.opacity = "0";
                wheel_spin_button.style.cursor = "default";
                const clonedElement = wheel_spin_button.cloneNode(true);
                spin_btn_parent.replaceChild(clonedElement, wheel_spin_button)
                wheel_cursor.remove()
                
                return;
            }
            
            if (modal_open) {
                window.addEventListener("mousemove", setCursorPosition);
                window.addEventListener("mousedown", clkUp);
                window.addEventListener("mouseup", clkDown);
            } else {
                window.removeEventListener("mousemove", setCursorPosition);
                window.removeEventListener("mousedown", clkUp);
                window.removeEventListener("mouseup", clkDown);
            }
            
        }
    }
    
    const animateToggler = (load: boolean) => {
        const spin_wheel_bulb = document.getElementById('spin_wheel_bulb');
        const spin_wheel_btn_rotating = document.getElementById('spin_wheel_btn_rotating');
        const spin_tap_to_text = document.getElementById('spin_tap_to_text');
        const wheel_spin_text = document.getElementById('wheel_spin_text');
        const big_win = document.getElementById('big_win');
        
        let prizes_elems:Array<any> = []
        const elements:Array<any> = [
            spin_wheel_bulb,
            spin_wheel_btn_rotating,
            spin_tap_to_text,
            wheel_spin_text,
            big_win
        ]
        
        cms_prizes_list.map((p:any) => {
            let pr = document.getElementById("spin_wheel_prize_"+p.order)
            if(Boolean(pr)) {
                prizes_elems.push(pr)
            }
        })
        
        const are_elements = elements.every(elem => elem !== null);
        
        if(load && are_elements && prizes_elems) {
            [ ...elements, ...prizes_elems].map((elem:any) => {
                elem.classList.add('appear');
            })
            setLoadAnim(load)
        } else if(!load && are_elements && prizes_elems) {
            
            [ ...elements, ...prizes_elems].map((elem:any) => {
                elem?.classList.remove('appear');
            })
        }
        
    }
    
    const conditionalOpenAnim = () => {
        if(imagesLoaded && dataLoaded && audioLoaded && !auth.isUpdating() && !loadAnim && open) {
            animateToggler(true)
        }
    }
    
    const modalCloseRequest = () => {
        animateToggler(false)
        setLoadAnim(false)
        setTimeout(() => {
            dispatch(openWheelModal(false))
            setCustomCursor(false)
            
        }, 100)
    }
    
    useEffect(() => {
        if(modal === 'wheel') {
            setScenario('wheel')
            dispatch(openWheelModal(true))
        } else if (modal === 'wheelNoCash' && auth.isLoggedIn()) {
            dispatch(openWheelModal(true))
            setScenario('noCash')
        } else if (modal === 'wheelHaveParticipated' && auth.isLoggedIn()) {
            dispatch(openWheelModal(true))
            setScenario('haveParticipated')
        } else if (modal === 'wheelSoundInquire' && auth.isLoggedIn()) {
            setScenario('soundInquire')
        }
    }, [router.query.modal, auth.isLoggedIn()])

    useEffect(() => {
        if (isStartSpinning) {
            fetchData();
        }
    }, [isStartSpinning]);

    useEffect(() => {
        if(auth.isLoggedIn() && isLoggingIn) {
            dispatch(openWheelModal(true))
            setIsLoggingIn(false)
        }
        if(!auth.isLoggedIn()) {
            setDataLoaded(false)

        }
        
        
    }, [auth.isLoggedIn()])
    

    useEffect(() =>   {
        
        
        const spinCheck = async () => {
            let attributes: any
            let wasTriggered: string

            if(wheelStatus.length) {
                attributes = wheelStatus
                
                let triggered = attributes.find((attr: any) => {
                    return attr.attributeName === 'WOFTriggered'
                })
                
                let wof_prizes = attributes.find((attr: any) => {
                    return attr.attributeName === 'WOFPrizes'
                })
                
                wasTriggered = triggered.value
                
                if(test) {
                    setSpinnedToday(false);
                    setIsDefault(false)
                    
                } else {
                    if(wasTriggered === 'NotTriggered') {
                        setSpinnedToday(false)
                    }
                    if (wasTriggered === 'Triggered') {
                        setSpinnedToday(true)
                        setSpinEnded(true)
                    }
                    
                    if (wof_prizes.value === 'Default') {
                        setIsDefault(true)
                    } else {
                        setIsDefault(false)
                    }
                }
                
                let prizes = attributes.find((attr: any) => {
                    return attr.attributeName === 'WOFPrizes'
                })

                if(test) {
                    setPrizeValue("10 RON Bonus")
                } else {
                    setPrizeValue(prizes.value)
                }
            }
        }

        if(auth.isLoggedIn()) {
            spinCheck()
        } else {
            setDataLoaded(true)
            setSpinEnded(false)
        }

    }, [auth.isLoggedIn(), wheelStatus, /*auth.getUserBalance()*/])
    
    useEffect(() => {
        if(auth.isUpdating()) {
            setDataLoaded(false)
        }
    }, [auth.isUpdating()])

    //Anim USE EFFECT

    useEffect(() => {
        setTimeout(() => {
            setToggleLights(!toggleLights)
        },1000)

    }, [toggleLights])
    
    useEffect(() => {
        if(test) {
            const update = async () => {
                await updateProfileAttributes('NotTriggered')
            }
            
            update()
        }
        
        loadImages()
    }, [])

    useEffect(() => {
        setCustomCursor(open)
        conditionalOpenAnim()
        
    }, [imagesLoaded, dataLoaded, audioLoaded, auth.isUpdating(), open, openCount])
    
    return (
        <Modal
            className={clsx(style.modal, 'wheel-modal')}
            onAfterClose={() => {
                setIsSpinning(false)
                setIsEndSpinning(false)
                setIsStartSpinning(false)
                setHoverCount(null)
                setClickedCount(0)
                setClickEnd(false)
                common_hook.hideZendesk(false)
                animateToggler(false)

            }}
            ariaHideApp={false}
            onAfterOpen={() => {
                // loadImages();
                common_hook.hideZendesk(true)
                setCustomCursor(true)
                conditionalOpenAnim()
            }}
            onRequestClose={modalCloseRequest}
            shouldCloseOnEsc={true}
            isOpen={open}
            contentLabel="Example Modal"
        >

            <div style={{
                width: "100%",
                minHeight: '100%',
                display: "flex",
                justifyContent: 'space-between',
                flexDirection: "column",
                padding: "50px 70px !important",
                overflow: 'hidden',
                position: "relative"
            }}>

                <WheelContainer >

                    <Header>

                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            gap: '10px',
                        }}>
                            <ToggleSound onClick={() => {setMuted(!muted)}}>
                                <SpeakerIcon muted={muted} />
                            </ToggleSound>
                            
                            <CloseBtn onClick={modalCloseRequest} className={style.closeBtn}>
                                <CloseIcon />
                            </CloseBtn>
                        </div>

                    </Header>

                    <SoundPlayer
                        clickEnd={clickEnd}
                        click={clickedCount}
                        hover={hoverCount}
                        muted={muted}
                        isLoaded={() => {
                            audioLoadedHandle()
                        }}
                        play={playSound}
                    />
                    
                    <Front id={"big_win"} >
                        <BigWin>
                            {big_win}
                        </BigWin>
                        <BigWinShadow>
                            {big_win}
                        </BigWinShadow>
                        <Image src={top_prise_text2.url}  alt={top_prise_text2.name} width={131} height={35} />
                    </Front>
                    
                     <Absolute>
                        <WheelConstruction className={clsx((!imagesLoaded || !audioLoaded || auth.isUpdating() || !dataLoaded || !loadAnim) && 'hidden')}  >
                            
                            <Projector1 src={projector1.url} alt={projector1.name} width={1000} height={300} />
                            <Projector2 src={projector2.url} alt={projector2.name} width={1000} height={300} />
                            <IndicatorImage  src={indicator.url} alt={indicator.name} width={400} height={200} />
                            
                            <WheelBg
                                className={"image"}
                                src={background.url}
                                alt={background.name}
                                width={2000}
                                height={2000}
                                
                            />
                            
                            <WheelSpinningElements
                                prizes_list={cms_prizes_list}
                                initialPosition={ initialPosition}
                                rotateBefore={typeof initialPosition === 'number' ?  (initialPosition - 100) : 0}
                                startSpinDeg={startSpinDeg}
                                rotateTo={rotateTo}
                                isSpinning={isSpinning}
                                isEndSpinning={isEndSpinning}
                                isStartSpinning={isStartSpinning}
                                mainImage={mainImage}
                                imagesLoaded = {imagesLoaded}
                                audioLoaded = {audioLoaded}
                                dataLoaded = {dataLoaded}
                                prise={prise}
                            />

                            <Overlayed>
                                <SpinButton id={"spin_btn_parent"} >
                                    
                                    <Shadow src={wheel_shadow.url} width={111} height={84} alt={wheel_shadow.name} />
                                    
                                    <TapTo id="spin_tap_to_text" src={tapTo.url} width={72} height={100} alt={tapTo.name} />
                                    
                                    <ButtonSpinArrow src={buttonSpinArrow.url} width={50} height={50} alt={buttonSpinArrow.name} />
                                    
                                    <ButtonSpinningElem id="spin_wheel_btn_rotating" src={buttonSpinningElem.url} width={72} height={72} alt={buttonSpinningElem.name} />
                                    
                                    <ButtonGlow src={buttonGLow.url} width={72} height={72} alt={buttonGLow.name} />
                                    
                                    <SpinText id="wheel_spin_text" src={spinText.url} width={72} height={72} alt={spinText.name} />
                                    
                                    <SpinBulb
                                        id="spin_wheel_bulb"
                                        src={spinBulb.url}
                                        width={67}
                                        height={90}
                                        alt={spinBulb.name}
                                    />
                                    
                                    <SpinBtnCenter
                                        id={"wheel_spin_button"}
                                        onClick={() => {
                                            let disabled = isStartSpinning || isSpinning || isEndSpinning || !enableSpin || spinnedToday || isDefault === null || isDefault
                                            if(!disabled && enableSpin) {
                                                setClickedCount(clickedCount + 1)
                                                spin()
                                                
                                            }
                                        }}
                                    >
                                        <div
                                            style={{
                                                width: "100%",
                                                height: "100%",
                                                position: "relative"
                                                
                                            }}
                                        >
                                            
                                            <Image
                                                id={"wheel_cursor"}
                                                src={clickMouseDown ? cursor_clicked.url : cursor.url}
                                                alt={cursor.name}
                                                width={37}
                                                height={50}
                                                style={{
                                                    position: "absolute",
                                                    transform: 'translate(-70%, -4px)',
                                                    top: "0",
                                                    left: "0",
                                                    opacity: "0",
                                                    pointerEvents: "none"
                                                }}
                                            />
                                            
                                            <ButtonGlow src={buttonGLow.url} width={72} height={72} alt={buttonGLow.name} />
                                            
                                        </div>
                                        
                                    </SpinBtnCenter>
                                    {disabledListener() && (
                                        <>
                                            
                                            <Image
                                                
                                                src={background.url}
                                                alt={background.name}
                                                width={37}
                                                height={50}
                                                style={{
                                                    width: "72px",
                                                    height: "72px",
                                                    position: "absolute",
                                                    left: "50%",
                                                    top: "50%",
                                                    transform: "translate(-50%, -50%)"
                                                }}
                                            />
                                            
                                            <Image
                                                src={lock.url}
                                                alt={lock.name}
                                                width={37}
                                                height={50}
                                                style={{
                                                    width: "72px",
                                                    height: "72px",
                                                    position: "absolute",
                                                    left: "50%",
                                                    top: "calc(50% + 5px)",
                                                    transform: "translate(-50%, -50%)"
                                                }}
                                            />
                                        </>
                                    )}
                                </SpinButton>
                                
                            </Overlayed>
                        </WheelConstruction>
                     </Absolute>
                    
                     {(!imagesLoaded || !audioLoaded || auth.isUpdating() || !dataLoaded || !loadAnim)  && (
                         <LoadingContainer>
                            <CircularProgress />
                        </LoadingContainer>
                     )}

                </WheelContainer>
                
                <Projector3 src={projector3.url} alt={projector2.name} width={1000} height={300} />
                
                <Stars src={stars.url} alt={stars.name} width={1000} height={300} />
                
                <div className={style.bottomSection}>
                    {!currentSpinning  && (
                        <Bottom
                            closeModal={modalCloseRequest}
                            isDefault={isDefault}
                            spinEnded={spinEnded}
                            audioLoaded={audioLoaded}
                            setDataLoaded={(x: boolean) => {setDataLoaded(x)}}
                            imagesLoaded={imagesLoaded}
                            isSpinnedToday={spinnedToday}
                            setSpinnedToday={(x:boolean) => { setSpinnedToday(x)}}
                            setEnable={(x: boolean): void => {enableSpinning(x)}}
                            setIsLoggingIn={(x:boolean) => {setIsLoggingIn(x)}}
                            test={test}
                        />
                     )}
                    
                </div>

            </div>
            {scenario === 'haveParticipated' && <HaveParticipated closePopup={closePrisePopup}/>}
            {scenario === 'noCash' && <NoCash closePopup={closePrisePopup}/>}
            {/*@ts-ignore*/}
            
            <PriseContainer
                showPrisePopup={showPrisePopup}
                endSpinning={() => {setIsEndSpinning(false)}}
                clickEnd={(x: boolean) => {setClickEnd(x)}}
                setPlay={(type):void => {
                    {/*@ts-ignore*/}
                    setPlay(type)
                }}
                closePopup={closePrisePopup}
                prise={prise}
            />
            


        </Modal>

    );
}



export default WheelModal