import ServiceResponse from '../interfaces/service_response';
import qs from 'query-string';
import cashpotFetch from "./cashpot_interceptor";
import {getAuthToken, getPlayerId, getRefeshToken} from "@/AbsoluteComponents/utils/cookies";
import process from "process";
import {RaffleType} from "@/AbsoluteComponents/utils/small_types";

const toggleFetch = (url:string, params:any) => {
    if (process.env.NODE_ENV !== 'production') {
        return fetch(`/api${url}`, params);
    } else {
        return cashpotFetch(url, params);
    }
};

const shortenedOrigin = process.env.NEXT_PUBLIC_WS_REFERRER?.replace(/^https?:\/\//, '');

const sport_headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json; charset=utf-8',
    'Cache-Control': 'no-cache',
    'Origin': shortenedOrigin,
    'Referer': shortenedOrigin,
    'sec-ch-ua': '"Not?A_Brand";v="8", "Chromium";v="108", "Google Chrome";v="108"',
    'sec-ch-ua-mobile': '?0',
    'sec-ch-ua-platform': '"Windows"',
    'Sec-Fetch-Dest': 'empty',
    'Sec-Fetch-Mode': 'cors',
    'Sec-Fetch-Site': 'same-site',
    // 'sw_referer': 'cashpot.ro',
    'User-Agent': 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/108.0.0.0 Safari/537.36',
    'x-site-id': process.env.NEXT_PUBLIC_SITE_ID,
    // 'x-sw-trace-id': "ef60f086-e10b-4441-b0df-63a450e278d0",
    'Authorization': `Bearer ${getAuthToken()}`,
}

export const getSportInfo = async(token:string) => {

    let response:ServiceResponse = {
        error: false,
    };

    try {
        // const req = await toggleFetch(`/v1/${token.length > 0 ? 'player/play/real' : 'site/demo'}/00000003-5ef5-433e-8aba-205090cc39f6`, {
        //     method: 'GET',
        // })

        const req = await toggleFetch(`/v1/player/play/real/00000003-5ef5-433e-8aba-205090cc39f6`, {
            method: 'GET',
        })
        
        const body = await req.json();
        
        if(body['url'].length) {
            response.response = body
        } else {
            response.response = []
            response.error = true
        }

        return response;

    } catch (error) {
        response.error = true;
        response.message = error!.toString();

        return response;
    }
}


export const fetchBetDetails = async(ticketId:string) => {
    let response:ServiceResponse = {
        error: false,
    };

    try {
        const query:any = {
            systemCode: 'CASHPOT',
            lingua: 'RO',
            hash: '',
            idTicket: ticketId,
        };

        const request = await fetch('https://exalogic.cashpot.ro/XSportDatastore/getCheckbetTicket?'+qs.stringify(query), {
            method: 'GET'
        });

        const body = await request.json();

        if(body['id'].length && Array.isArray(body['evs']) &&body['evs'].length > 0) {
            response.response = body
        } else {
            response.response = []
            response.error = true
        }

        return response;
    } catch (error) {
        response.error = true;
        response.message = error!.toString();

        return response;
    }
}

export const fetchCashoutTickets = async( ) => {
    let response:ServiceResponse = {
        error: false,
    };

    try {

        if(!getAuthToken()) return
        //
        // const request = await toggleFetch('/v1/player/game/sport/cashoutTickets', {
        //     method: 'GET',
        // });
        //
        // const request = await toggleFetch('/auth-proxy/exalogic/api/tickets/cashout', {
        //     method: 'GET',
        // });
        
        const request = await fetch(process.env.NEXT_PUBLIC_SITE_API + '/auth-proxy/exalogic/api/tickets/cashout', {
            method: 'GET',
            headers: sport_headers,
        });

        const body = await request.json();
        
        if(body.length) {
            response.response = body
        } else {
            response.response = []
            response.error = true
            response.message = 'no cashouts'
        }

        response.response = body

        return response;

    } catch (error) {
        response.error = true;
        response.message = error!.toString();

        return response;
    }
}


export const cashoutTicket = async (token: string, idTicket:string, purchaseAmount: number, cashoutAmount: number, mode: string, isMobile:boolean) => {
    let response:ServiceResponse = {
        error: false,
    };

    let reqBody = {
        token: token,
        idTicket: idTicket,
        mode: mode,
        purchaseAmount: purchaseAmount,
        cashoutAmount: cashoutAmount,
        isMobile: isMobile,
        language: "ro"
    }

    try {
        
        const request = await fetch(process.env.NEXT_PUBLIC_SITE_API + '/auth-proxy/exalogic/api/tickets/cashout', {
            method: 'PUT',
            headers: sport_headers,
            body: JSON.stringify(reqBody),

        });

        const body = await request.json();

        response.response = body

        return response;

    } catch (error) {
        response.error = true;
        response.message = error!.toString();

        return response;
    }
}


export const cashoutScheduleTicket = async (transactionId: string, idTicket:string, language: number) => {
    let response:ServiceResponse = {
        error: false,
    };
    
    let reqBody = {
        transactionId,
        idTicket,
        language
    }
    
    try {
        
        const request = await fetch(process.env.NEXT_PUBLIC_SITE_API + `/auth-proxy/exalogic/api/tickets/cashout/schedule?${qs.stringify(reqBody)}`, {
            method: 'GET',
            headers: sport_headers,
        });
        
        const body = await request.json();
        
        response.response = body
        
        return response;
        
    } catch (error) {
        response.error = true;
        response.message = error!.toString();
        
        return response;
    }
}



export const categoryGamesService = async (category_id: string, count?:number): Promise<ServiceResponse> => {
    let response:ServiceResponse = {
        error: false,
    };

    try {
        const query:any = {
            categories: category_id
        };

        if (count) {
            query['limit'] = count;
        }

        const request = await toggleFetch('/v1/site/category?'+qs.stringify(query), {});
        const body = await request.json();

        if (typeof body['categories'] == "object" && Array.isArray(body['categories'][category_id]['games'])) {
            response.response = body['categories'][category_id];
        } else {
            response.response = [];
        }

        return response;
    } catch (error) {
        response.error = true;
        response.message = error!.toString();

        // if (error.response) {
        //     response.message = error.response.data;
        // } else if (error.request) {
        //     response.message = error.request;
        // } else {
        //     response.message = error.message;
        // }

        return response;
    }
}

export const getAllGames = async (): Promise<ServiceResponse> => {
    let response:ServiceResponse = {
        error: false,
    };

    try {
        response.response = {
            desktop: [],
            mobile: [],
        };

        const r = await toggleFetch('/games', {method: 'get'});

        const body:any = await r.json();

        if (Array.isArray(body?.desktop) && Array.isArray(body?.mobile)) {
            response.response = {
                desktop: body.desktop,
                mobile: body.mobile,
            };
        }

        return response;
    } catch (error) {
        response.error = true;
        response.message = error!.toString();

        // if (error.response) {
        //     response.message = error.response.data;
        // } else if (error.request) {
        //     response.message = error.request;
        // } else {
        //     response.message = error.message;
        // }

        return response;
    }
}

export const searchGames = async (keyword:string): Promise<ServiceResponse> => {
    let response:ServiceResponse = {
        error: false,
    };

    try {
        response.response = {
            total: 0,
            games: [],
        };

        const request = await toggleFetch('/v1/site/search?keyword='+keyword, {method: 'get'});
        const body:any = await request.json();

        if (Array.isArray(body?.games)) {
            response.response = {
                total: body.total || 0,
                games: body.games,
            };
        }

        return response;
    } catch (error) {
        response.error = true;
        response.message = error!.toString();

        // if (error.response) {
        //     response.message = error.response.data;
        // } else if (error.request) {
        //     response.message = error.request;
        // } else {
        //     response.message = error.message;
        // }

        return response;
    }
}


export const playRealGame = async (gameId:string, mobile:boolean = false): Promise<ServiceResponse> => {
    let response:ServiceResponse = {
        error: false,
    };

    const headers:any = {};

    if (mobile) headers['User-Agent'] = 'Mozilla/5.0 (Linux; Android 8.0.0; mobile-app-android) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/84.0.4147.111 Mobile Safari/537.36';

    try {
        response.response = {
            total: 0,
            games: [],
        };

        const request = await toggleFetch('/v1/player/play/real/'+gameId,
            {
                method: 'GET',
                headers
            });
        const body:any = await request.json();

        response.response = body;

        return response;
    } catch (error) {
        response.error = true;
        response.message = error!.toString();

        return response;
    }
}

export const playDemoGame = async (gameId:string, mobile:boolean = false): Promise<ServiceResponse> => {
    let response:ServiceResponse = {
        error: false,
    };

    const headers:any = {};

    if (mobile) headers['User-Agent'] = 'Mozilla/5.0 (Linux; Android 8.0.0; mobile-app-android) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/84.0.4147.111 Mobile Safari/537.36';

    try {
        response.response = {
            total: 0,
            games: [],
        };

        const request = await toggleFetch('/v1/site/demo/'+gameId, {
            headers
        });
        
        
        const body:any = await request.json();

        if (typeof body.url === "string") {
            response.response = body;
        }

        return response;

    } catch (error) {

        response.error = true;
        response.message = error!.toString();

        return response;
    }

}

export const getLoto = async (id:string, token:string) => {

    let response:ServiceResponse = {
        error: false,
    };

    try{
        const request = await toggleFetch(`/v1/${token.length ? "player/play/real/" : "site/demo/"}` + id, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + getAuthToken()
            }
        });

        const body:any = await request.json();

        if(body.hasOwnProperty('url') && body.url.length) {
            response.response = body
        } else {
            response.error = true
            response.message = "unknown error"
        }

        return response

    } catch(error) {
        response.error = true
        response.message = "unknown error"
        console.error(error)
        return response
    }

}


export const getProvidersNames = async (): Promise<ServiceResponse> => {
    let response:ServiceResponse = {
        error: false,
    };

    try {
        const request = await toggleFetch( '/v1/site/games/providers/names', {
            method: 'get',
        });

        const body = await request.json();

        if (body['code'] !== undefined && body['message'] !== undefined ) {
            response.error = true;
            response.message =  body['message'];
        } else {
            response.response = Array.isArray(body) ? body : [];
        }

        return response;
    } catch (error) {
        response.error =  true;
        response.message = error!.toString();

        return response;
    }

}

export const getVendorsNames = async (): Promise<ServiceResponse> => {
    let response:ServiceResponse = {
        error: false,
    };

    try {
        const request = await toggleFetch( '/v1/site/games/vendors', {
            method: 'get',
        });

        const body = await request.json();

        if (body['code'] !== undefined && body['message'] !== undefined ) {
            response.error = true;
            response.message =  body['message'];
        } else {
            response.response = Array.isArray(body) ? body : [];
        }

        return response;
    } catch (error) {
        response.error =  true;
        response.message = error!.toString();

        return response;
    }

}

// LUCKY TICKET

export function raffleService(raffleId: string):any {
    
    let endpoint_root = "https://feature-raffle.swphantom.com"
    let query:any = {}
    let token = getAuthToken()
    let site_id = process.env.NEXT_PUBLIC_SITE_ID
    
    const handleOptIn = async (method: "GET" | "POST") => {
        let response:ServiceResponse = {
            error: false,
        };
        
        if(method === "GET") {
            if(token.length) {
                query["playerId"] = token
            }
            
            if(site_id?.length) {
                query["siteId"] = site_id
            }
            
            if(site_id?.length) {
                query["siteId"] = site_id
            }
        }
        
        try {
            
            const request = await fetch(endpoint_root + "/raffle/optin", {
                method: method
            })
            
            const body = await request.json();
            
            if (body.hasOwnProperty('status')) {
                response.response = body;
            } else {
                response.error = true;
            }
            
            return response
            
        } catch(err) {
            response.error =  true;
            response.message = err!.toString();
            
            return response
        }
    }
    
    const getRaffleStatus = async ( playerId?:string) => {
        let response:ServiceResponse = {
            error: false,
        };
        
        if(playerId?.length) {
            query["playerId"] = playerId
        }
        
        if(raffleId.length) {
            query["raffleId"] = raffleId
        }
        
        try {
            const request = await fetch(endpoint_root + "/raffle/promo?"+qs.stringify(query), {
                method: "GET"
            })
            
            const body = await request.json();
            
            if (body.hasOwnProperty('runningInstances')) {
                response.response = body;
            } else {
                response.error = true;
            }
            
            return response
            
        } catch(err) {
            response.error =  true;
            response.message = err!.toString();
            
            return response
        }
    }
    
    const getRaffleInfo = async ( instanceId:string)=> {
        let response:ServiceResponse = {
            error: false,
        };
        
        if(raffleId.length) {
            query["raffleId"] = raffleId
        }
        
        if(instanceId.length) {
            query["instanceId"] = instanceId
        }
        
        try {
            const request = await fetch(endpoint_root + "/raffle/info?"+qs.stringify(query), {
                method: "GET"
            })
            
            const body = await request.json();
            
            if (body.hasOwnProperty('draws')) {
                response.response = body;
            } else {
                response.error = true;
            }
            
            return response
            
        } catch(err) {
            response.error =  true;
            response.message = err!.toString();
            
            return response
        }
    }
    
    const getWinners = async (raffleType:RaffleType, instanceId:string, playerId?:string) => {
        // TODO : check if player ID somehow works
        
        let response:ServiceResponse = {
            error: false,
        };
        
        if(raffleId.length) {
            query["raffleId"] = raffleId
        }
        
        if(instanceId) {
            query["instanceId"] = instanceId
        }
        
        if(raffleType) {
            query["raffleType"] = raffleType
        }
        
        if(playerId) {
            query["token"] = playerId
        }
        
        try {
            const request = await fetch(endpoint_root + "/raffle/winners?"+qs.stringify(query), {
                method: "GET"
            })
            
            const body = await request.json();
            
            if (body.hasOwnProperty('winners')) {
                response.response = body;
            } else {
                response.error = true;
            }
            
            return response
            
        } catch(err) {
            
            response.error =  true;
            response.message = err!.toString();
            
            return response
        }
    }
    
    const getRafflePrev = async ()=> {
        let response:ServiceResponse = {
            error: false,
        };
        
        if(raffleId.length) {
            query["raffleId"] = raffleId
        }
        
        try {
            const request = await fetch(endpoint_root + "/raffle/preview?"+qs.stringify(query), {
                method: "GET"
            })
            
            const body = await request.json();
            
            if (body.hasOwnProperty('runningInstances')) {
                response.response = body;
            } else {
                response.error = true;
            }
            
            return response
            
        } catch(err) {
            response.error =  true;
            response.message = err!.toString();
            
            return response
        }
    }
    
    return {
        handleOptIn,
        getRaffleStatus,
        getRafflePrev,
        getRaffleInfo,
        getWinners
    }
}

