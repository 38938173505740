import styles from '../../styles/footer.module.scss';
import Builder from "../../cms-views/builder";
import {useState, FC, useEffect} from 'react';
import CMS from "utils/cms";
import clsx from 'clsx';
import strings from "utils/strings";
import { useRouter } from 'next/router';
import { useInView } from 'react-intersection-observer';
import { useDispatch, useSelector } from 'react-redux';
import {setFooterInView} from 'store/common/actions'


const Footer:FC = ({ ... footerProps}) => {
    const router = useRouter();
    const [openFooter, setOpenFooter] = useState<boolean>(false)
    const [openBtnVisible, setOpenBtnVisible] = useState<boolean>(false)
    const isMobile:boolean = JSON.parse(JSON.stringify(useSelector((state:any) => state.common.isMobile)));

    const dispatch = useDispatch()
    let isSportPage:boolean = useSelector((state:any) => state.common.isSportPage)
    const { ref, inView, entry } = useInView({
        /* Optional options */
        threshold: 0,
    });

    useEffect(() => {
        setTimeout(() => {
            setOpenBtnVisible(true)
        }, 1000)
    }, [])

    useEffect(() => {
        if(inView) {
            dispatch(setFooterInView(true))
        } else {
            dispatch(setFooterInView(false))
        }
    }, [inView])

    let footer:any = typeof window !== 'undefined' ? document.querySelector('#footer') : '';
    let casinoPage =  router.route.search('/casino-live') > -1;
    useEffect(() => {
        let casinoPage =  router.route.search('/casino-live') > -1;
        if(casinoPage) {
            let footer:any = typeof window !== 'undefined' ? document.querySelector('#footer') : '';
            footer?.classList.add('addStyleFooterBlock')

        }
        return footer?.classList.remove('addStyleFooterBlock')

    }, [casinoPage]);



    return (

        <div id={'footer'}
             className={clsx(
                 styles.footer,
                 'footer_border_top',
                 'theme_front_elements_bg',
                 (isSportPage && !isMobile) && styles.sportFooter,
                 openFooter && styles.open,
                 (isSportPage && isMobile) && styles.mobileSportFooter,
             )}
             ref={ref}
        >

            {(isSportPage && !isMobile && openBtnVisible) && (
                <button
                    onClick={() => {
                        setOpenFooter(!openFooter)
                    }}
                    className={styles.toogleFooter}
                >
                    {openFooter ? (
                        <>
                            {strings['close_footer']}
                        </>
                    ) : (
                        <>
                            {strings['open_footer']}
                        </>
                    )}

                </button>
            )}

            <Builder blocks={CMS.getCopyrightBlocks()} api_id={CMS.API_ID_COPYRIGHT}/>

        </div>

    )
}
export default Footer;