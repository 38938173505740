function DepuneriiconSpins(){
    return(
        <svg className={'svg-icon'} xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
            {/*<g filter="url(#filter0_di_903_88387)">*/}
                <path fillRule="evenodd" clipRule="evenodd" d="M12.9972 23L5.81308 22.8337C4.36133 22.8042 3.13058 21.7577 2.8681 20.3295C2.28124 17.1364 2.28124 13.8631 2.8681 10.67C3.13058 9.24192 4.36133 8.19539 5.81308 8.16589L7.68247 8.11153C8.75203 8.08043 9.65661 8.89667 9.7352 9.9638L9.85915 11.6469C9.93609 12.6916 10.8062 13.5 11.8537 13.5L14.6693 13.5C15.7037 13.5 16.5674 12.7113 16.6611 11.6812L16.8193 9.94237C16.9152 8.88805 17.8161 8.09112 18.8743 8.12457L20.1813 8.1659C21.633 8.1954 22.8638 9.24192 23.1263 10.67C23.7131 13.8631 23.7131 17.1365 23.1263 20.3295C22.8638 21.7577 21.633 22.8042 20.1813 22.8337L12.9972 23ZM4.99719 15.4998C4.44491 15.4998 3.99719 15.9475 3.99719 16.4998C3.99719 17.0521 4.44491 17.4998 4.99719 17.4998L20.9972 17.4998C21.5495 17.4998 21.9972 17.0521 21.9972 16.4998C21.9972 15.9475 21.5495 15.4998 20.9972 15.4998L4.99719 15.4998Z" fill="white"/>
                <path d="M14.3663 12.4287C14.8214 12.4287 15.201 12.0809 15.2407 11.6275C15.3767 10.0769 15.4007 8.51855 15.3126 6.96473C15.5311 6.95266 15.7495 6.93867 15.9678 6.92275L17.2857 6.82669C17.7938 6.78965 18.0778 6.2231 17.8035 5.79381C16.8639 4.3233 15.6531 3.04492 14.2358 2.02681L13.7079 1.64756C13.4341 1.45086 13.0652 1.45086 12.7914 1.64756L12.2635 2.02681C10.8462 3.04492 9.63544 4.3233 8.69582 5.79381C8.42151 6.2231 8.70555 6.78965 9.21365 6.82669L10.5315 6.92275C10.7498 6.93867 10.9682 6.95266 11.1867 6.96473C11.0986 8.51855 11.1226 10.0769 11.2586 11.6275C11.2983 12.0809 11.6779 12.4287 12.133 12.4287L14.3663 12.4287Z" fill="white"/>
            {/*</g>*/}
            <defs>
                <filter id="filter0_di_903_88387" x="-1" y="-1" width="28" height="28" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="1"/>
                    <feGaussianBlur stdDeviation="1"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_903_88387"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_903_88387" result="shape"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="1"/>
                    <feGaussianBlur stdDeviation="1"/>
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.6 0"/>
                    <feBlend mode="normal" in2="shape" result="effect2_innerShadow_903_88387"/>
                </filter>
            </defs>
        </svg>
    )
}

export default DepuneriiconSpins