const RecentPlayedIcon = () => {
    return (
        <svg className={"svg-icon"} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            
            <path
                d="M3 10V5C3 4.44772 3.44772 4 4 4C4.55228 4 5 4.44772 5 5V6.35C5.85 5.28333 6.8875 4.45833 8.1125 3.875C9.3375 3.29167 10.6333 3 12 3C14.5 3 16.625 3.875 18.375 5.625C20.125 7.375 21 9.5 21 12H19C19 10.05 18.3208 8.39583 16.9625 7.0375C15.6042 5.67917 13.95 5 12 5C10.85 5 9.775 5.26667 8.775 5.8C7.775 6.33333 6.93333 7.06667 6.25 8H8C8.55228 8 9 8.44772 9 9C9 9.55228 8.55228 10 8 10H3ZM3.24137 14.1298C3.1026 13.5338 3.58451 13 4.19638 13C4.71312 13 5.14092 13.3866 5.26884 13.8872C5.56777 15.0572 6.14899 16.074 7.0125 16.9375C8.0875 18.0125 9.375 18.6667 10.875 18.9L12.075 21C9.775 21 7.75833 20.2375 6.025 18.7125C4.58583 17.4463 3.65796 15.9187 3.24137 14.1298ZM13.35 14.75L11 12.4V8C11 7.44772 11.4477 7 12 7C12.5523 7 13 7.44772 13 8V11.6L14.4 13L13.35 14.75Z"
            />
            <path
                d="M15 21.2903V15.71C15 14.9451 15.8237 14.4634 16.4903 14.8384L21.4505 17.6285C22.1302 18.0108 22.1302 18.9894 21.4505 19.3717L16.4903 22.1618C15.8237 22.5368 15 22.0551 15 21.2903Z"
            />
            
        </svg>
    )
}

export default RecentPlayedIcon