import {updateFavoriteGame} from "@/AbsoluteComponents/services/account";
import {updateSnackBar} from "@/AbsoluteComponents/store/common/actions";
import {updateFavoritesGames} from "@/AbsoluteComponents/store/account/actions";
import strings from "@/AbsoluteComponents/utils/strings";
import {useState} from "react";
import {useDispatch, useSelector} from 'react-redux';
import GameInterface from "@/AbsoluteComponents/interfaces/game";



function useFavorite () {

    const [loading, isLoading] = useState(false);
    const dispatch = useDispatch()
    const favoritesGames:Array<GameInterface> = JSON.parse(JSON.stringify(useSelector((state:any) => state.account.favoriteGames)));

    const isFavorite = (game:GameInterface):boolean => {
        const isFav:boolean = favoritesGames.findIndex((g) => g.gameId === game.gameId) > -1;
        return isFav
    }

    const addToFavorites = async (game:GameInterface, fav:boolean) => {
        isLoading(true);
        const data:any = await updateFavoriteGame(game.gameId, !fav);
        if (data.error) {
            dispatch(updateSnackBar(data.message, 'error', false));
        } else if (data.response?.success ) {
            if (fav) {
                dispatch(updateFavoritesGames(favoritesGames.filter((g) => g.gameId !== game.gameId)));
                dispatch(updateSnackBar(strings['favorite_game_removed'], 'error', false));
            } else {
                favoritesGames.push(game);
                dispatch(updateFavoritesGames(favoritesGames));
                dispatch(updateSnackBar(strings['favorite_game_added'], 'success', false));
            }
        } else {
            dispatch(updateSnackBar(strings['unknown_error'], 'error', false));
        }

        isLoading(false);
    };

    const isUpdating = ():boolean => {
        return loading
    }

    return {
        isFavorite,
        addToFavorites,
        isUpdating
    }
}

export default useFavorite