import React, {FC, useEffect, useState} from 'react';
import Button from "@/template/small_ui/button";
import strings from "utils/strings";
import styles from 'themes/spins/styles/mobileBottomBar.module.scss';
import {useDispatch, useSelector} from 'react-redux';
import {openLoginModal, openSignupModal, openWheelModal} from "store/modals/actions";
import useAuth from "hooks/auth";
import {useRouter} from 'next/router'
import Link from 'next/link';
import Balance from "@/template/ui/balance/balance-view";
import {openAccountSidebar, showTermsSideBar} from "store/common/actions";
import clsx from 'clsx'
import BurgherIonSpins from "themes/spins/svg/burgherIonSpins";
import WheelSpins from 'themes/spins/svg/wheelSpin'
import ActiveOffers from "themes/spins/ui/active0ffers";
import CircularProgress from "@mui/material/CircularProgress";
import {wheel_visible} from 'themes/spins/config/constants'
import BonusCenter from "@/template/ui/bonusCenter";
import {openBonusCenter} from "@/AbsoluteComponents/store/account/actions";
import Offer from "@/AbsoluteComponents/interfaces/offer";
import QuestIcon from "@/AbsoluteComponents/themes/spins/svg/quest_icon";
import CMS from "@/AbsoluteComponents/utils/cms";

const MobileBottomBar: FC = () => {
    const dispatch = useDispatch();
    const auth = useAuth();
    const router = useRouter();
    let isGamePage: boolean = router.pathname.slice(1, 4) === 'joc';
    const isOpen: boolean = useSelector((state: any) => state.common.openAccSidebar)
    const showAccount: boolean = useSelector((state: any) => state.common.showAccountSideBar);
    const isInfoSidebarOpen = useSelector((state: any) => state.common.openInfoSidebar);
    const isGuest = useSelector((state: any) => state.common.isGuest)
    const isSportPage = useSelector((state: any) => state.common.isSportPage)
    const [elementLoaded, setElementLoaded] = useState(false)
    const openBC:Array<Offer> = useSelector((state:any) =>  state.account.bonusCenterIsOpen);
    let not_allowed = !isGamePage

    const showSideBar = () => {
        dispatch(openAccountSidebar(!isOpen))
        if (isOpen) {
            dispatch(showTermsSideBar(false))
        }
    }

    useEffect(() => {
        setElementLoaded(true)
    }, []);


    return !isGamePage ? (

        <div id={"mobile_bottom_bar"} className={clsx(styles.root, isSportPage && styles.isSport)}>

            <div
                style={{justifyContent: !auth.isUpdating() ? "space-between" : "center"}}
                className={clsx('mobileMenu', 'themes_mobileMenu', (showAccount || isInfoSidebarOpen) && 'notVisible')}
            >

                {(auth.isLoggedIn() && !auth.isUpdating()) && (
                    <>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                            <div onClick={showSideBar}
                                 className={clsx('mobileMenu__wrapper', 'mobileMenu__wrapper__mobile', "theme_white_dark_text")}>
                                <BurgherIonSpins/>
                            </div>
                            
                            {/*<Link aria-label={"offers"} href={'/account/available-offers'} className={clsx('mobileMenu__wrapper', 'mobileMenu__wrapper__mobile')}>*/}
                            {/*    <span className={'theme__svg'}>*/}
                            {/*        <ActiveOffers mobileOffers />*/}
                            {/*    </span>*/}
                            {/*</Link>*/}
                            
                            {!isSportPage && (
                                
                                <button
                                    onClick={() => {
                                        // console.log(openBC)
                                        // dispatch(openBonusCenter(true))
                                    }}
                                    aria-label={"offers"}
                                    className={clsx('mobileMenu__wrapper', 'mobileMenu__wrapper__mobile', "theme_text_mainColor")}
                                    style={{
                                        border: "none",
                                        padding: "0"
                                    }}
                                >
                                    <BonusCenter/>
                                </button>
                            )}
                            
                            
                            {(!CMS.getCurrentQuest()?.hardcoded && !isSportPage) && (
                                <button
                                    onClick={() => {
                                        // console.log(openBC)
                                        // dispatch(openBonusCenter(true))
                                        router.push("/" + CMS.getCurrentQuest().url_id)
                                    }}
                                    aria-label={"offers"}
                                    className={clsx('mobileMenu__wrapper', 'mobileMenu__wrapper__mobile', styles.blink)}
                                    style={{
                                        border: "none",
                                        padding: "0"
                                    }}
                                >
                                    <QuestIcon/>
                                </button>
                            )}
                            
                            
                            {/*{wheel_visible &&  !isSportPage ?(*/}
                            {/*    <div*/}
                            {/*        onClick={() => {*/}
                            {/*            dispatch(openWheelModal(true))*/}
                            {/*        }}*/}
                            {/*        className={clsx('mobileMenu__wrapper', 'mobileMenu__wrapper__mobile')}*/}
                            {/*    >*/}
                            {/*        <span className={'theme_white_dark_text'}>*/}
                            {/*            <WheelSpins />*/}
                            {/*        </span>*/}
                            {/*    </div>*/}
                            {/*) : null}*/}
                        
                        </div>
                        
                        <Balance/>
                    </>
                )}
                
                {auth.isUpdating() && <CircularProgress/>}
                
                {(!auth.isLoggedIn() && !auth.isUpdating() && isGuest) && (
                    <>
                        <div onClick={showSideBar}
                             className={clsx('mobileMenu__wrapper', 'mobileMenu__wrapper__mobile', "theme_white_dark_text")}>
                            <BurgherIonSpins/>
                        </div>
                        
                        <div className={styles.guestButtons}>
                            
                            <Button text={strings['conectare']} outlined onClick={() => {
                                dispatch(openLoginModal(true))
                                
                                const root = document.getElementsByTagName('html')[0];
                                root.setAttribute('style', 'overflow-y: hidden');
                            }}/>

                            <Button text={strings['cont_nou']} onClick={() => {
                                dispatch(openSignupModal(true))

                                const root = document.getElementsByTagName('html')[0];
                                root.setAttribute('style', 'overflow-y: hidden');
                            }}/>

                        </div>
                        
                        {!isSportPage ?(
                            
                            <>
                                {!CMS.getCurrentQuest()?.hardcoded ? (
                                    <button
                                        onClick={() => {
                                            router.push("/"+CMS.getCurrentQuest().url_id)
                                        }}
                                        
                                        aria-label={"offers"}
                                        className={clsx('mobileMenu__wrapper', 'mobileMenu__wrapper__mobile', styles.blink)}
                                        style={{
                                            border: "none",
                                            padding: "0",
                                            margin: "0"
                                        }}
                                    >
                                        <QuestIcon/>
                                    </button>
                                ) : null}
                            
                            </>
                        
                        
                        ) : null}
                    </>
                )}
            
            </div>
        </div>
    ) : null
};

export default MobileBottomBar