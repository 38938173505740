const HelpIcon = () => {
    return (
        <svg className={"svg-icon"} width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12 21.5C16.9706 21.5 21 17.4706 21 12.5C21 7.52944 16.9706 3.5 12 3.5C7.02944 3.5 3 7.52944 3 12.5C3 17.4706 7.02944 21.5 12 21.5Z"
                stroke="#A4AAB6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M12 18.875C12.8284 18.875 13.5 18.2034 13.5 17.375C13.5 16.5466 12.8284 15.875 12 15.875C11.1716 15.875 10.5 16.5466 10.5 17.375C10.5 18.2034 11.1716 18.875 12 18.875Z"
                fill="#A4AAB6"/>
            <path
                d="M12 14C12.5933 14 13.1734 13.8241 13.6667 13.4944C14.1601 13.1648 14.5446 12.6962 14.7716 12.1481C14.9987 11.5999 15.0581 10.9967 14.9424 10.4147C14.8266 9.83279 14.5409 9.29824 14.1213 8.87868C13.7018 8.45912 13.1672 8.1734 12.5853 8.05765C12.0033 7.94189 11.4001 8.0013 10.8519 8.22836C10.3038 8.45543 9.83524 8.83994 9.50559 9.33329C9.17595 9.82664 9 10.4067 9 11"
                stroke="#A4AAB6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export default HelpIcon