import React, {useState, useEffect} from 'react'
import Skeleton from '@mui/material/Skeleton'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import styles from '../../styles/winner.module.scss';
import clsx from 'clsx';
import "swiper/css";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper";
import PaginationArrow from "ui/paginationArrow";
import CMS from "utils/cms";
import ArrowRight from "@/template/svg/arrow-right";

interface Props {
    json?: object;
}

const WinnersProloader:React.FC<Props> = ({json}) => {
    const [swiper, setSwiper] = useState<any>();
    const [screen, setScreen] = useState<number | undefined>(undefined)
    const data = CMS.jsonWinnersSlider(json);

    let winnersTemplates: Array<number> = []
    let quantity = 20;

    for(let i = 0; i < quantity; i++) {
        winnersTemplates.push(i)
    }

    useEffect(() => {
        let width = window.innerWidth;
        setScreen(width)
    }, [])


    return (
        <div data-section="winners-slider-preloader" className={clsx('winnerBlock', 'slideClass')}>

            <div className={'winnerBlock_titleBlock'}>
                <h4 className={clsx('gamesSlider__title', 'winnerSliderTitle', 'theme_textColor_secondary', )}>{data.title}</h4>
                <div className={('arrowButtons-wrapperSlide')}>
                    <button
                        aria-label={"slide"}
                        onClick={() => swiper.slidePrev()}
                            className={clsx('btnSlider-first', 'theme_btnSlider_arrow')}>
                        <span className={'theme__svg'}><ArrowRight/></span>
                    </button>
                    <button
                        aria-label={"slide"}
                        onClick={() => swiper.slideNext()}

                        className={clsx('btnSlider-second', 'theme_btnSlider_arrow')}>
                        <span className={'theme__svg'}><ArrowRight/></span>
                    </button>
                </div>
            </div>

            <Swiper
                pagination={{
                    clickable: true,
                }}
                modules={[Pagination, Navigation]}
                className={clsx('slideClass', 'swiper-init-padding')}
                spaceBetween={16}
                slidesPerView='auto'
                onSwiper={(sw:any) => setSwiper(sw)}
            >

                {winnersTemplates.map((x, i) => {
                    return (
                        <SwiperSlide key={`winners-${i}`} style={{marginRight: '30px'}}>
                            <div className={clsx(styles.item, styles.skeletonItem)}>
                                <Skeleton
                                    variant="rectangular"
                                    style={{
                                        display: 'block',
                                        width: '100%',
                                        height: '100%',
                                        borderRadius: '10px'
                                    }}
                                    className={'winnersSkeleton'}
                                >

                                </Skeleton>
                            </div>
                        </SwiperSlide>
                    )
                })}
            </Swiper>

        </div>
    )
}

export default  WinnersProloader