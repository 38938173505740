import {FC} from 'react';
import styles from '@/template/styles/textfield.module.scss';
import EyeIcon from '@/template/svg/eyeicon';
import {useState} from "react";
import React from "react";
import clsx from 'clsx';
import CloseIcon from "themes/default/svg/closeicon";

interface Props {
    placeholder: string
    type: string
    label?: string
    defaultValue?: string
    onInputFocus?: () => void
    className?: string
    inputStyle?: any
    noShadow?: boolean
    noMargin?:boolean
}

// eslint-disable-next-line react/display-name
const TextField: FC<Props>  = React.forwardRef((props, ref:any) => {

    const {
        placeholder,
        type,
        defaultValue,
        label,
        onInputFocus,
        className,
        inputStyle,
        noShadow,
        noMargin,
        ...others
    } = props;

    const [hidePassword, setHidePassword] = useState(true);

    const changeTypes = () => {
        setHidePassword( current => !current)
    }

    const toClear = (e:any) => {
        const element = e.currentTarget.parentNode.querySelector('input');

        if (element) {
            element.value = '';

            const e = new Event("change");
            element.dispatchEvent(e);
        }
    }
    

    return (

        <div
            style={inputStyle}
            className={clsx(
                styles.input__field_block,
                noShadow && styles.no_shadow,
                noMargin && styles.no_margin,
                className
            )}
        >

            <input
                {...others}
                ref={ref}
                defaultValue={defaultValue}
                className={clsx(styles.input__field, 'searchInput-header', 'theme_box_bg_shaded2', 'theme_basic_input')}
                type={!hidePassword ? 'text' : type}
                placeholder={placeholder}
                onFocus={onInputFocus}
            />

            <label className={clsx(styles.label, 'theme_label_text')}>
                <span>
                    {label}
                </span>
            </label>


            {type === 'password'? (

                <button
                    aria-label={"view"}
                    type={'button'}
                    onClick={changeTypes}
                    className={clsx(styles.input__field_passwordBtn, !hidePassword && 'theme_white_dark_text', 'icon_hide_pass')}
                >
                    <EyeIcon />
                </button>

            ) : (
                <button
                    aria-label={"view"}
                    type={'button'}
                    onClick={toClear}
                    className={clsx(styles.contactForm__btnClose, 'theme_text_mainColor')}>
                    <CloseIcon />
                </button>
            )

            }
        </div>
    )
});
export default TextField;