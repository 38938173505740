import Image from "@/template/cms-views/promotions/Image";
import CMS from "utils/cms";
import strings from "utils/strings";
import { FC } from 'react';
import clsx from 'clsx';
import Button from '@/template/small_ui/button'
import {useRouter} from 'next/router';

interface Props {
    item: any;
    inSidebar?: boolean
}

const PromotionItem: FC<Props> = ({ item, inSidebar }) => {

    const router = useRouter()

    return (

        <div  style={{padding: 0}} key={`image-${item.api_id}`} className={clsx( 'theme_box_promotion', 'promotionItem_wrapper')}>
            <div className={clsx('promotionsBlock__imageBlock', 'content')}>
                {item.mobile_image.length > 0 && (
                    <img src={CMS.getPublicFilePath(item.mobile_image[0].name,  'promotions', item.mobile_image[0].path)} />
                )}

                <div className={'promotionsBlock__flex'}>
                    <div className={'promotionsBlock__details'}>
                        <p className={clsx('promotionsBlock__text', 'theme_textColor_secondary')}>{item.title}</p>
                        <p className={clsx('promotionsBlock__description', 'theme__headerContainer__list')}>{item.excerpt}</p>
                    </div>

                    <div className={'promotionsBlock__container'}>
                        {/*<Link*/}
                        {/*    className={clsx('promotionsBlock__link', 'btn__primary', 'theme_btn__primary', 'promotionsBtn')}*/}
                        {/*    href={item.url_extern.length ? item.url_extern : '/promotii/' + item.api_id}*/}
                        {/*>*/}
                        {/*    <span style={{color: '#15191E'}}>{strings['toate_detaliile']}</span>*/}
                        {/*</Link>*/}

                        <Button
                            onClick={() => {router.push(item.url_extern.length ? item.url_extern : '/promotii/' + item.api_id)}}
                            className={clsx('promotionsBlock__btn')}
                            text={strings['toate_detaliile']}
                        />
                    </div>
                </div>

            </div>
        </div>
    )
};

export default PromotionItem
