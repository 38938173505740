import { FC, useState, useEffect, useRef } from 'react';
import styles from 'themes/default/styles/notifications.module.scss';
import clsx from 'clsx';
import CloseIcon from "themes/default/svg/closeicon";
import {useDispatch} from 'react-redux';
import {removeSnackBar} from "store/common/actions";
import InfoIcon  from 'themes/default/svg/info'
import WarningIcon  from 'themes/default/svg/warning';

interface Props {
    message: string,
    type: string,
    index: number,
    active: boolean,
    link_label?:string,
    link_function?():void
}

const Item:FC<Props> = ({message, type, index, active, link_label, link_function}) => {

    const [hide, setHide] = useState<boolean>(false);
    const button = useRef<any>(null);
    
    const buttonHtml = link_label ? `<button id="button_notif" class="button_notif">
        ${link_label }
      </button>` : ""
    
    
    useEffect(() => {
        if(!active) {
            setTimeout(() => {

                setHide(true);
            }, 10000)
        }
        
        let items = document.getElementsByClassName('button_notif');
        
        if (items.length > 0 && typeof link_function === 'function') {
            Array.from(items).forEach(item => {
                item.addEventListener('click', link_function);
            });
            
            return () => {
                Array.from(items).forEach(item => {
                    item.removeEventListener('click', link_function);
                });
            };
        }
        
    },[]);
    
    const dispatch = useDispatch();

    if (hide) return null;

    return (
        <div className={clsx(styles.item, type === 'success' && styles.success, type === 'error' && styles.error, type === 'info' && styles.info, type === 'warning' && styles.warning)}>
            {type === 'info' && <InfoIcon />}
            {type === 'warning' && <WarningIcon />}
            <span dangerouslySetInnerHTML={{__html: message + " "+ buttonHtml}}/>
            
            <button ref={button} onClick={() => dispatch(removeSnackBar(index))} aria-label={"clase"} type={'button'}><CloseIcon /></button>
            
            {/*{typeof link_function === "function" && (*/}
            {/*    <button className={styles.main_btn} onClick={link_function}>*/}
            {/*        {` ${link_label && link_label}`}*/}
            {/*    </button>*/}
            {/*)}*/}
        </div>
    )
}
export default Item