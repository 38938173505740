import Countdown, {CountdownRenderProps} from "react-countdown";
import React from "react";
import styles from "../styles/Ui.module.scss";
import clsx from "clsx";

interface Props {
    endTime:any
    className?:string
    dark?:boolean
    pretext?: string
}

// TODO : ask if countdown hides after completion

const StandartCountdown:React.FC<Props> = ({endTime, className, dark, pretext}) => {
    return (
        <Countdown
            date={endTime}
            renderer={(args) => renderer({ ...args, className, dark, pretext })}
            intervalDelay={1000}
            zeroPadTime={2}
            
        />
    )
}

const renderer = (args:CountdownRenderProps & {className?:string, dark?:boolean, pretext?:string}) => {
    const {days, hours, minutes, seconds, completed, className, dark , pretext} = args;
    const padValue = (value: number) => (value < 10 ? `0${value}` : `${value}`);
    const ts_list = dark ? [
        {
            val:hours,
            label: "hours"
        },
        {
            val:minutes,
            label: "minutes"
        },
        {
            val:seconds,
            label: "seconds"
        }
    ] : [
        {
            val:days,
            label: "days"
        },
        {
            val:hours,
            label: "hours"
        },
        {
            val:minutes,
            label: "minutes"
        },
        {
            val:seconds,
            label: "seconds"
        }
    ]

        return dark ? (
            <div className={clsx(styles.countdown_dark)}>
                {ts_list.map((ts:any, i) => {
                    
                    let time_unit:string = ""
                    
                     if(ts.label === "hours"){
                        time_unit = "ore"
                    } else if(ts.label === "minutes"){
                        time_unit = "min"
                    } else if(ts.label === "seconds"){
                        time_unit = "sec"
                    }
                    
                    
                    return (
                        <React.Fragment key={"ts"+i}>
                            <div
                                className={styles.value}
                            
                            >
                                {padValue(ts.val) || "00"}
                                
                                <span className={styles.time_unit}>
                                    {time_unit}
                                </span>
                            </div>
                            
                        </React.Fragment>
                    )
                })}
            </div>
        ) : (
            <div className={clsx(styles.countdown, className)}>
                
                {pretext ? (
                    <div
                        className={styles.countdown__value}
                    >
                        {pretext}
                    </div>
                ) : null}
                
                {ts_list.map((ts:any, i) => {
                    
                    let show_dots:boolean = i !== ts_list.length - 1
                    let time_unit:string = ""
                    
                    if(ts.label === "days") {
                        time_unit = "z"
                    } else if(ts.label === "hours"){
                        time_unit = "h"
                    } else if(ts.label === "minutes"){
                        time_unit = "m"
                    } else if(ts.label === "seconds"){
                        time_unit = "s"
                    }
                    
                    
                    return (
                        <React.Fragment key={"ts"+i}>
                            <div
                                className={styles.countdown__value}
                                
                            >
                                {padValue(ts.val) || "00"}
                                
                                <span className={styles.time_unit}>
                                    {time_unit}
                                </span>
                            </div>
                            
                            
                            {show_dots && (
                                <span>
                                    :
                                </span>
                            )}
                        </React.Fragment>
                    )
                })}
            </div>
        );
    // }
};


export default StandartCountdown