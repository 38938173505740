import Script from "next/script";
import React, {useEffect, useRef, useLayoutEffect, useState} from "react";
import useAuth from "@/AbsoluteComponents/hooks/auth";
import {getAuthToken, getPlayerId} from "@/AbsoluteComponents/utils/cookies";
import process from "process";
import Button from "@/template/small_ui/button";
import {useDispatch} from "react-redux";
import {openLoginModal} from "@/AbsoluteComponents/store/modals/actions";
import SmarticoClass from "@/AbsoluteComponents/utils/smartico_class";
import strings from "utils/strings";
import {useRouter} from "next/router";



interface Props {
    tournament_id:string
    type: string
    openWidget():void
    iframe_id: string
}

const SmarticoWidget:React.FC<Props> = ({tournament_id, type, openWidget, iframe_id}) => {
    
    const auth = useAuth()
    const iframe = useRef<any>(null)
    const dispatch = useDispatch()
    const router = useRouter();
    
    const webView:boolean = router.pathname.search('webview/smartico') > -1;
    
    useEffect(() => {
        if(!auth.isLoggedIn()) return
        
        const interval = setInterval(() => {
            if(typeof window !== "undefined" && window.hasOwnProperty("_smartico") && typeof window._smartico.getUserID === "function") {
                let id = window._smartico.getUserID()
                
                if(id?.length) {
                    openWidget()
                    clearInterval(interval)
                }
            }
        },500)
        
        return () => {
            clearInterval(interval)
        }
        
    }, [auth.isLoggedIn()])
    
    if (webView && !auth.isLoggedIn()) return null;
    
    if(!auth.isLoggedIn()) return <Button onClick={() => {dispatch(openLoginModal(true))}} style={{margin: "0 auto"}} text={strings["conectare"]} />
    
    return (
        
        <iframe
            id={iframe_id}
            title={`Smartico ${type} id ${tournament_id}`}
            ref={iframe}
            style={{width: "100%"}}
        />
        
    )
}

export default SmarticoWidget