import React from "react";

interface Props {
    muted:boolean
}

const SpeakerIcon:React.FC<Props> = ({muted}) => {
    return (
        <svg className={"svg-icon"} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            
            {muted ? (
                <>
                    <path d="M12.1496 1.93854C12.0445 1.8871 11.9271 1.86621 11.8107 1.87826C11.6943 1.8903 11.5836 1.9348 11.4913 2.00667L6.03554 6.24999H2.5C2.1686 6.25037 1.85087 6.38219 1.61654 6.61652C1.3822 6.85086 1.25038 7.16859 1.25 7.49999V12.5C1.25038 12.8314 1.3822 13.1491 1.61654 13.3835C1.85087 13.6178 2.1686 13.7496 2.5 13.75H6.03554L11.4913 17.9933C11.5837 18.0651 11.6943 18.1096 11.8107 18.1217C11.9271 18.1337 12.0445 18.1128 12.1496 18.0614C12.2547 18.01 12.3433 17.9301 12.4052 17.8309C12.4672 17.7316 12.5 17.617 12.5 17.5V2.49999C12.5 2.38299 12.4672 2.26833 12.4052 2.16907C12.3433 2.06982 12.2547 1.98994 12.1496 1.93854ZM2.5 7.49999H5.62271V12.5H2.5V7.49999Z" fill="white"/>
                    <path d="M17.759 9.99998L19.192 8.56695C19.3092 8.44973 19.3751 8.29075 19.3751 8.12498C19.3751 7.95921 19.3092 7.80023 19.192 7.68301C19.0748 7.56579 18.9158 7.49994 18.75 7.49994C18.5842 7.49994 18.4253 7.56579 18.308 7.68301L16.875 9.11604L15.442 7.68301C15.3248 7.56579 15.1658 7.49994 15 7.49994C14.8342 7.49994 14.6753 7.56579 14.558 7.68301C14.4408 7.80023 14.375 7.95921 14.375 8.12498C14.375 8.29075 14.4408 8.44973 14.558 8.56695L15.9911 9.99998L14.558 11.433C14.4408 11.5502 14.375 11.7092 14.375 11.875C14.375 12.0408 14.4408 12.1997 14.558 12.317C14.6753 12.4342 14.8342 12.5 15 12.5C15.1658 12.5 15.3248 12.4342 15.442 12.317L16.875 10.8839L18.308 12.317C18.3661 12.375 18.435 12.421 18.5108 12.4524C18.5867 12.4839 18.6679 12.5 18.75 12.5C18.8321 12.5 18.9134 12.4839 18.9892 12.4524C19.065 12.421 19.1339 12.375 19.192 12.317C19.25 12.2589 19.2961 12.19 19.3275 12.1142C19.3589 12.0383 19.3751 11.9571 19.3751 11.875C19.3751 11.7929 19.3589 11.7116 19.3275 11.6358C19.2961 11.56 19.25 11.4911 19.192 11.433L17.759 9.99998Z" fill="white"/>
                </>
              
            ) : (
                <>
                    <path
                        d="M17.5444 5.58118C17.4272 5.46411 17.2682 5.39838 17.1025 5.39844C16.9368 5.3985 16.778 5.46435 16.6608 5.58151C16.5436 5.69867 16.4778 5.85756 16.4777 6.02325C16.4777 6.18894 16.5434 6.34788 16.6605 6.46513C17.1248 6.92941 17.4931 7.4806 17.7444 8.08723C17.9957 8.69386 18.125 9.34405 18.125 10.0007C18.125 10.6573 17.9957 11.3075 17.7444 11.9141C17.4931 12.5207 17.1248 13.0719 16.6605 13.5362C16.5436 13.6535 16.478 13.8124 16.4782 13.9779C16.4783 14.1435 16.5442 14.3023 16.6613 14.4194C16.7783 14.5364 16.9371 14.6023 17.1027 14.6024C17.2682 14.6026 17.4271 14.537 17.5444 14.4201C18.7165 13.248 19.375 11.6583 19.375 10.0007C19.375 8.34304 18.7165 6.7533 17.5444 5.58118V5.58118Z"
                    />
                    <path
                        d="M12.1496 1.93921C12.0445 1.88777 11.9271 1.86688 11.8107 1.87893C11.6943 1.89097 11.5836 1.93547 11.4913 2.00734L6.03554 6.25066H2.5C2.1686 6.25104 1.85087 6.38286 1.61654 6.6172C1.3822 6.85153 1.25038 7.16926 1.25 7.50066V12.5007C1.25038 12.8321 1.3822 13.1498 1.61654 13.3841C1.85087 13.6185 2.1686 13.7503 2.5 13.7507H6.03554L11.4913 17.994C11.5837 18.0658 11.6943 18.1103 11.8107 18.1223C11.9271 18.1344 12.0445 18.1135 12.1496 18.0621C12.2547 18.0107 12.3433 17.9308 12.4052 17.8316C12.4672 17.7323 12.5 17.6177 12.5 17.5007V2.50066C12.5 2.38366 12.4672 2.269 12.4052 2.16975C12.3433 2.07049 12.2547 1.99061 12.1496 1.93921ZM2.5 7.50066H5.62271V12.5007H2.5V7.50066Z"
                    />
                    <path
                        d="M14.4507 7.79105C14.3927 7.84909 14.3467 7.91799 14.3153 7.99382C14.2839 8.06965 14.2677 8.15093 14.2677 8.233C14.2677 8.31508 14.2839 8.39635 14.3153 8.47218C14.3467 8.548 14.3928 8.61689 14.4508 8.67492C14.6249 8.84902 14.763 9.05571 14.8573 9.28318C14.9515 9.51066 15 9.75446 15 10.0007C15 10.2469 14.9515 10.4907 14.8573 10.7182C14.763 10.9457 14.6249 11.1523 14.4508 11.3264C14.3336 11.4436 14.2677 11.6026 14.2677 11.7684C14.2677 11.9341 14.3335 12.0931 14.4507 12.2103C14.5679 12.3275 14.7269 12.3934 14.8927 12.3934C15.0584 12.3934 15.2174 12.3276 15.3346 12.2104C15.6248 11.9202 15.855 11.5757 16.0121 11.1966C16.1691 10.8174 16.25 10.4111 16.25 10.0007C16.25 9.59029 16.1691 9.18392 16.0121 8.80478C15.855 8.42564 15.6248 8.08115 15.3346 7.79098C15.2766 7.73293 15.2077 7.68689 15.1318 7.65548C15.056 7.62407 14.9747 7.6079 14.8927 7.60791C14.8106 7.60792 14.7293 7.6241 14.6535 7.65552C14.5777 7.68694 14.5088 7.733 14.4507 7.79105Z"
                    />
                </>
            )}
        
        
        </svg>
    )
}

export default SpeakerIcon