import TextField from "./textfield";
import SelectDropDown from "./selectDropDown";
import country_codes from '@/AbsoluteComponents/public/json/country_codes.json';
import {useState, useEffect} from 'react';
import styles from '@/template/styles/phoneField.module.scss';
import Arrowright from 'themes/spins/svg/arrow-right';
import clsx from 'clsx';


const PhoneField = () => {
    const [values, setValues] = useState<any>({code: '', value: ''});

    const dropdownHandle = (value:string) => {
        setValues((currentValues:any) => ({
            ...currentValues,
            code: value
        }));
    };

    useEffect(() => {
        const codeRo:any = country_codes.find((item:any) => item.code === "RO");

        if (codeRo) {
            setValues((currentValues:any) => ({
                ...currentValues,
                code: codeRo.dial_code
            }));
        }
    }, []);

    return (
      <div className={styles.root}>
          <input type="hidden" name={'phone_number'} value={values.code+values.value} onChange={() => {}}/>

          {values.code.length > 0 &&
              <div style={{position: 'relative'}}>
                  <SelectDropDown
                      label={''}
                      formatLabel={(option) => {
                          return option.key;
                      }}
                      className={clsx(styles.root__selector)}
                      defaultValue={values.code}
                      options={country_codes.filter((item => item.code === 'RO')).map((c:any) => ({key: c.dial_code, label: `(${c.dial_code}) ${c.name}`}))}
                      onChange={dropdownHandle}
                  />
                  {/*<div className={styles.arrowBlock}>*/}
                  {/*    <Arrowright />*/}
                  {/*</div>*/}

              </div>

          }

          <TextField
              className={styles.root__input}
              label={'Număr de telefon'}
              placeholder={'Număr de telefon'}
              type={'number'}
              {...{
                  name: 'phone',
                  onChange: (e:any) => {
                      if (e.target.value.length && Number(e.target.value[0]) === 0) {
                          e.target.value = e.target.value.slice(1);
                      }

                      if (values.code === '+40' && e.target.value.length > 9) {
                          e.target.value = e.target.value.slice(0, 9);
                          return;
                      }

                      setValues((currentValues:any) => ({
                          ...currentValues,
                          value: e.target.value
                      }));
                  },
              }}
          />
      </div>
    )
}

export default PhoneField