import Footer from 'themes/spins/ui/footer';
import Header from 'themes/spins/ui/header';
import AccPagesSidebar from "themes/spins/ui/header/accPagesSidebar";
import TermsSideBarView from "themes/spins/ui/header/termsSideBar";
import MobileBottomBar from "@/template/ui/MobileBottomBar";
import {useRouter} from 'next/router';
import PaginationBottom from "themes/spins/ui/PaginationBottom";
import ScrollToTop from "themes/spins/small_ui/ScrollToTop";
import clsx from "clsx";
import GetAppBlock from "@/template/cms-views/getAppBlock";
import React from "react";

function MyAppView(props:any) {
    
    const router = useRouter();
    
    const {children, cls} = props;
    let isSportPage: boolean = router.pathname.search('sport') > -1;
    
    
    return (
        <div className={clsx(cls)}>
            
            <div id={'main_container'} className={clsx( isSportPage && "is_sport")}>
                
                <ScrollToTop />
                
                <TermsSideBarView />
                
                <AccPagesSidebar />
                
                <div id={'full_width_container'}>
                    
                    <div id={'main_wrapper'}>
                        
                        <Header/>
                        
                        {children}
                    
                    </div>
                
                </div>
                
                <PaginationBottom />
            
            </div>
            <GetAppBlock />
            
            <Footer/>
            <MobileBottomBar />
        
        </div>
    );
}

export default MyAppView;